/* eslint-disable import/prefer-default-export */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import {
  Toast,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from 'components/ui/toast';
import { CircleX, CheckIcon, AlertTriangle, XIcon } from 'lucide-react';
import { useToast } from 'hooks/use-toast';

export function Toaster() {
  const { toasts } = useToast();
  const getIconForVariant = (variant) => {
    switch (variant) {
      case 'success':
        return (
          <CheckIcon className="text-green-500 text-[#00FF00] h-4 w-4 pr-3" />
        );
      case 'destructive':
        return <CircleX className="text-red-500 text-[#FF0000] h-4 w-4 pr-3" />;
      case 'warning':
        return (
          <AlertTriangle className="text-yellow-500 text-[#FF0000] h-4 w-4 pr-3" />
        );
      // Add cases for other variants
      default:
        return null;
    }
  };
  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, closeicn, ...props }) => (
        <Toast key={id} {...props}>
          <div className="grid gap-1">
            {title && <ToastTitle>{title}</ToastTitle>}
            {description && (
              <div className="flex items-center">
                {getIconForVariant(closeicn)}
                <ToastDescription>{description}</ToastDescription>
              </div>
            )}
          </div>
          {/* {action}
          {closeicn === "destructive" ? <ToastClose variant="destructive"/> :  <ToastClose />} */}
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  );
}
