import React from 'react';
import { useGetBrandDetails } from 'hooks/brand';

const Logo = ({ onClick }) => {
  const { logoUrl } = useGetBrandDetails();

  return (
    <div className="cursor-pointer" onClick={onClick}>
      <img
        src={logoUrl}
        alt="logo"
        className="h-10 w-auto max-w-full object-contain"
      />
    </div>
  );
};

export default Logo;
