/* eslint-disable import/prefer-default-export */

import { useLazyQuery, useMutation } from '@apollo/client';
import { useAppContext } from 'AppContext';
import { toast } from 'hooks/use-toast';
import client from 'apollo';
import { PRIMARY_COLOR } from 'common/constants';
import { cloneAndRemoveTypename, fileUpload } from 'common/utils';
import { toPng } from 'html-to-image';
import {
  CREATE_HEADSHOT,
  UPDATE_HEADSHOT,
} from 'modules/Headshot/graphql/Mutations';
import {
  HADSHOT_QUERY,
  HEAD_SHOT_SIGNED_URL,
} from 'modules/Headshot/graphql/Queries';

export const useGetHeadshot = () => {
  const { setHeadshot } = useAppContext();
  const [getHeadshot] = useLazyQuery(HADSHOT_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const data = cloneAndRemoveTypename(res?.headshot) || {};
      setHeadshot(data);
    },
    onError() {},
  });

  return [getHeadshot];
};

export const useCreateHeadshot = () => {
  const [createHeadshot] = useMutation(CREATE_HEADSHOT, {
    onCompleted() {},
    onError() {},
  });

  return [createHeadshot];
};

export const useUpdateHeadshotMutation = () => {
  const { setHeadshot } = useAppContext();

  const [updateHeadshotMutation] = useMutation(UPDATE_HEADSHOT, {
    onCompleted(res) {
      const data = cloneAndRemoveTypename(res?.updateHeadshot?.data) || {};
      setHeadshot(data);
    },
    onError() {},
  });

  return [updateHeadshotMutation];
};

export const useHeadshotUpload = () => {
  const {
    state: { signature, headshot },
    setHeadshot,
  } = useAppContext();

  const [updateHeadshotMutation] = useMutation(UPDATE_HEADSHOT);

  const handleHeadshotImageUpload = async (
    file = null,
    updatedHeadshot = headshot,
  ) => {
    if (!file) {
      // ROUTE_CHANGE for download signature
      return;
    }

    const fileName = 'headshot';
    const base64String = file;
    const binaryString = atob(base64String.split(',')?.[1]);

    const arrayBuffer = new ArrayBuffer(binaryString?.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binaryString?.length; i++) {
      uint8Array[i] = binaryString?.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: 'image/png' });

    const res = await client?.query({
      query: HEAD_SHOT_SIGNED_URL,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          fileName,
        },
      },
    });

    const { signedUrl = '', key = '' } =
      await res?.data?.getHeadshotUploadSignedUrl;

    try {
      await fileUpload(signedUrl, blob);
      const response = await updateHeadshotMutation({
        variables: {
          where: {
            id: signature?.headshotId || updatedHeadshot?.id,
          },
          data: {
            ...updatedHeadshot,
            config: {
              'background-color': PRIMARY_COLOR,
              ...updatedHeadshot?.config,
              output: key,
            },
          },
        },
      });
      const data =
        cloneAndRemoveTypename(response?.data?.updateHeadshot?.data) || {};
      setHeadshot(data);
      return data;
    } catch (error) {
      // message?.error(error?.message);
      toast({
        description: error?.message,
        closeicn: 'destructive',
      });
    }
  };

  const handleGlobalHeadshotUpload = async (
    newHeadshot = headshot,
    key = '',
  ) => {
    try {
      const nodeId = key || newHeadshot?.style?.key;
      // eslint-disable-next-line no-undef
      const node = document?.getElementById(`${nodeId}`);
      // eslint-disable-next-line no-param-reassign
      // ref.current.style.borderRadius = '50%';
      const dataUrl = await toPng(node, {
        quality: 1,
        pixelRatio: 1,
        canvasHeight: 300,
        canvasWidth: 300,
        cacheBust: true,
        includeQueryParams: true,
        fontEmbedCSS: false,
        imagePlaceholder: 'headshot',
      });

      if (dataUrl) {
        const headshotUpdated = await handleHeadshotImageUpload(
          dataUrl,
          newHeadshot,
        );
        return headshotUpdated;
      }
    } catch (error) {
      const response = await updateHeadshotMutation({
        variables: {
          where: {
            id: signature?.headshotId || newHeadshot?.id,
          },
          data: {
            ...newHeadshot,
            config: {
              ...newHeadshot?.config,
            },
          },
        },
      });
      const data =
        cloneAndRemoveTypename(response?.data?.updateHeadshot?.data) || {};
      setHeadshot(data);
    }
  };

  return {
    handleHeadshotImageUpload,
    handleGlobalHeadshotUpload,
  };
};
