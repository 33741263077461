import { useAppContext } from 'AppContext';
import { omit } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from './router';

/* eslint-disable import/prefer-default-export */
export const useTableWidth = (downloadUrl = '') => {
  const {
    state: { signature },
    setSignatureWidth,
    setSignatureCTAWidth,
  } = useAppContext();
  const {
    location: { pathname },
  } = useRouter();
  const mainContentRef = useRef();
  const extraContentRef = useRef();
  const [tableWidth, setTableWidth] = useState('max-content');
  const signatureData = omit(signature, ['information.footer']);

  const setSigantureTableWidth = (width) => {
    setSignatureWidth(0);
    if (pathname.includes('templates')) {
      return;
    }
    setSignatureWidth(width);
  };

  const setSigantureCTATableWidth = (width) => {
    setSignatureCTAWidth(0);
    if (pathname.includes('templates')) {
      return;
    }
    setSignatureCTAWidth(width);
  };

  useEffect(() => {
    if (mainContentRef?.current?.clientWidth) {
      // Get the width of the referenced element
      const width = mainContentRef?.current?.clientWidth + 15;
      // Set the width in state
      setSigantureTableWidth(width);
      setTableWidth(width);
    }
  }, [mainContentRef?.current?.clientWidth]);

  useEffect(() => {
    if (extraContentRef?.current?.clientWidth) {
      // Get the width of the referenced element
      const width = extraContentRef?.current?.clientWidth - 6;
      // Set the width in state
      setSigantureCTATableWidth(width);
    }
  }, [extraContentRef?.current?.clientWidth]);

  return { tableWidth, mainContentRef, extraContentRef };
};
