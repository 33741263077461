/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { DialogContent } from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { LoaderCircle } from 'lucide-react';

const DeleteForm = ({ onClose, handleDeleteGroup }) => {
  const [loading, setLoading] = useState(false);
  return (
    <DialogContent
      className="rounded-[6px] max-w-md px-[21px] py-[22px]"
      hideCloseButton
    >
      <div>
        <h1 className="text-xl">Delete Group</h1>
        <p className="text-lg">Are you sure you want to delete this group?</p>
        <div className="flex justify-end">
          <div className="flex gap-2">
            <Button
              className="w-[50%]"
              variant="outline"
              onClick={() => onClose()}
              size="sm"
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                setLoading(true);
                await handleDeleteGroup();
                setLoading(false);
              }}
              className="w-[50%]"
              type="submit"
              disabled={loading}
              size="sm"
            >
              Submit{' '}
              {loading && (
                <LoaderCircle className="ml-2 w-8 h-8 text-gray-1 animate-spin" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
};

export default DeleteForm;
