import { Progress } from 'components/ui/progress';
import React, { useEffect, useState } from 'react';
import { cn } from 'lib/utils';
import { useWindowSize } from 'helpers/useWindowSize';

const PreparingHeadshot = ({
  percentage = 0,
  className = '',
  textClassName = '',
  headerFontSize = 32,
  wrapperHeight = 'h-screen',
}) => {
  const [progress, setProgress] = useState(percentage);
  const { size } = useWindowSize();
  const isMobile = ['xs', 'sm', 'md'].includes(size);

  useEffect(() => {
    if (progress >= 100) {
      return;
    }
    const interval = setInterval(() => {
      setProgress((prev) => prev + 15);
    }, 500);

    return () => clearInterval(interval);
  }, [progress]);

  return (
    <div
      className={cn(
        `${wrapperHeight} w-full flex items-center bg-primary-foreground justify-center flex-col`,
        className,
      )}
    >
      <h4
        className={cn(
          textClassName,
          `text-primary font-bold ${
            isMobile ? 'text-xl' : `text-[${headerFontSize}px]`
          } text-center px-4`,
        )}
      >
        Generating professional headshot styles...
      </h4>
      <Progress
        className={cn(
          'mt-6 bg-primary-foreground',
          isMobile ? 'w-[80%] max-w-[300px]' : 'w-[500px]',
        )}
        value={progress}
        showInfo={false}
      />
    </div>
  );
};

export default PreparingHeadshot;
