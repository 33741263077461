import { Check, X } from 'lucide-react';
import rightImage from 'assets/images/nachiket-patel.jpg';
import w1 from 'assets/images/w1.jpg';
import w2 from 'assets/images/w2.jpg';
import w3 from 'assets/images/w3.jpg';
import React from 'react';

const guideData = [
  {
    image: rightImage,
    icon: (
      <div className="bg-[green] rounded-tr-[8px] rounded-bl-[8px] h-full w-full max-h-[24px] max-w-[28px] flex items-center justify-center">
        <Check className="h-5 w-5" color="#ffffff" />
      </div>
    ),
    text: 'Full Face',
  },
  {
    image: w1,
    icon: (
      <div className="bg-[red] rounded-tr-[8px] rounded-bl-[8px] h-full w-full max-h-[24px] max-w-[28px] flex items-center justify-center">
        <X className="h-5 w-5" color="#ffffff" />
      </div>
    ),
    text: 'Blur',
  },
  {
    image: w3,
    icon: (
      <div className="bg-[red] rounded-tr-[8px] rounded-bl-[8px] h-full w-full max-h-[24px] max-w-[28px] flex items-center justify-center">
        <X className="h-5 w-5" color="#ffffff" />
      </div>
    ),
    text: 'Sunglasses',
  },
  {
    image: w2,
    icon: (
      <div className="bg-[red] rounded-tr-[8px] rounded-bl-[8px] h-full w-full max-h-[24px] max-w-[28px] flex items-center justify-center">
        <X className="h-5 w-5" color="#ffffff" />
      </div>
    ),
    text: 'Full Body',
  },
];

const UploadGuide = () => (
  <div className="bg-[#231E2D] flex flex-col items-start rounded-[12px] mt-[27px] p-2">
    <p className="my-4 mx-2 text-[white] text-[22px] sm:text-center sm:text-[16px] w-full">Upload tips</p>
    <div className="grid grid-cols-4 items-start justify-center gap-3 p-2 ">
      {guideData.map(({ image, icon, text }) => (
        <div className="relative flex flex-col items-center gap-2" key={text}>
          <img
            alt="Headshot"
            src={image}
            className="w-full max-w-[132px]  aspect-[1] object-cover select-none pointer-events-none rounded-[8px]"
          />
          <div className="absolute  right-0 top-0  sm:h-4 sm:w-4 text-white overflow-hidden ">
            {icon}
          </div>
          <span className="relative text-[18px] sm:text-[14px] font-[600px] text-[white]">
            {text}
          </span>
        </div>
      ))}
    </div>
  </div>
);

export default UploadGuide;
