/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from "react";

const getWindowSizeCategory = (width) => {
  if (width >= 1200) return 'xl';
  if (width >= 992) return 'lg';
  if (width >= 768) return 'md';
  if (width >= 576) return 'sm';
  return 'xs'; // Consider adding an extra-small category for widths smaller than 576
};

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth, // Set initial width
    height: window.innerHeight, // Set initial height
    size: getWindowSizeCategory(window.innerWidth), // Set initial size category
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        size: getWindowSizeCategory(window.innerWidth),
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handleResize immediately to set initial size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
