import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import React from 'react';

const UploadFile = ({
  id = 'upload-file',
  label = 'Upload File',
  accept,
  onChange,
  className,
}) => (
  <div className={`flex flex-col space-y-2 ${className}`}>
    <Label htmlFor={id} className="text-sm font-medium text-gray-700">
      {label}
    </Label>
    <Input
      id={id}
      type="file"
      accept={accept}
      onChange={onChange}
      className="border border-gray-300 rounded-lg text-sm text-gray-600 focus:outline-none focus:ring-2 focus:ring-violet-600"
    />
  </div>
);

export default UploadFile;
