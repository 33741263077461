/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
import { useAppContext } from 'AppContext';
import { ArrowRight } from 'lucide-react';
import UserProfile from 'components/UserProfile';
import { Button } from 'components/ui/button';
import { useWindowSize } from 'helpers/useWindowSize';
import { useCreateLmCheckoutLink } from 'hooks/ai-headshot';
import { get } from 'lodash';
import React from 'react';
import { usePosthogEvents } from 'hooks/posthog';

const Header = () => {
  const {
    state: { aiHeadshot, step },
    setStep,
  } = useAppContext();

  const { size } = useWindowSize();
  const { capture } = usePosthogEvents();
  const { url } = useCreateLmCheckoutLink();
  const credits = get(aiHeadshot, 'credits', 0);

  const showAllImagesItem = aiHeadshot?.isPaid
    ? [
        {
          key: 'Show All Images',
          label: (
            <div onClick={() => setStep(5)}>
              <div className="w-[250px]">
                <div>
                  <p className="m-0">Show all images</p>
                </div>
              </div>
            </div>
          ),
        },
      ]
    : [];

  const extraItems = [
    ...showAllImagesItem,
    {
      key: 'Credits',
      label: <p className="m-0">Remaining Credits: {credits}</p>,
    },
    {
      key: 'BuyMoreCredits',
      label: (
        <div>
          <div className="w-[250px] flex justify-between">
            <div>
              <a href={url} target="_blank" rel="noreferrer">
                <p
                  onClick={() => capture('Buys more credits')}
                  className="font-bold m-0 decoration-none"
                >
                  Buy more credits <ArrowRight size={20} className="ml-[4px]" />
                </p>
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <nav
      style={{ borderBottom: '1px solid #727272' }}
      className="bg-[transparent] flex justify-between h-[70px]"
    >
      <div className="flex items-center pl-[16px]">
        <div className="flex items-center justify-between gap-2">
          <img
            src="/logo32.png"
            height={40}
            width={40}
            alt="logo"
            style={{ display: 'block', margin: 'auto' }}
          />
          <div className="font-primary font-[700] text-[22px] leading-[27px] text-[#FFFFFF]">
            SyncSignature
          </div>
          {!['xs', 'sm'].includes(size) && step > 3 && (
            <a
              href="/app/dashboard"
              className="text-white-0 ml-4 text-[16px] leading-[16px] font-[500] cursor-pointer"
            >
              Dashboard
            </a>
          )}
        </div>
      </div>
      <div className="flex items-center pr-[16px] gap-4">
        {['xs', 'sm'].includes(size) || aiHeadshot?.isPaid ? (
          <>
            <h3 className="font-bold text-white-0">
              Remaining Credits: {credits}
            </h3>
            <a href={url} target="_blank" rel="noreferrer" className="">
              <Button
                onClick={() => capture('Buys more credits')}
                className="font-bold"
              >
                Buy more credits
              </Button>
            </a>
          </>
        ) : null}
        <UserProfile
          extraItems={
            ['xs', 'sm'].includes(size) ? extraItems : [...showAllImagesItem]
          }
        />
      </div>
    </nav>
  );
};

export default Header;
