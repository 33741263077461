import * as React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from 'lib/utils';
import { EmptyImage } from 'assets/svg';

const emptyVariants = cva(
  'inline-flex items-center rounded border px-2.5 py-0.5 text-xs  transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'bg-primary-foreground text-primary hover:bg-primary-foreground',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

function Empty({ className, variant, icon, ...props }) {
  return (
    <div className={cn(emptyVariants({ variant }), className)} {...props}>
      <div>
        <EmptyImage />
      </div>
    </div>
  );
}

export { Empty, emptyVariants };
