import { ApolloLink, createHttpLink, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { isObject } from 'lodash';
import { toast } from 'hooks/use-toast';
import { TOKEN } from './common/constants';

let disableToastTimeout = null;
export const cacheData = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
  fetchOptions: {
    timeout: 800000,
  },
});

// const toastMessage = ({ message: content, type }) => {
//   message?.destroy();
//   switch (type) {
//     case 'info':
//       message?.info(content);
//       break;
//     case 'success':
//       message?.success(content);
//       break;
//     case 'warning':
//       message?.warning(content);
//       break;
//     case 'error':
//       message?.error(content);
//       break;
//     default:
//       break;
//   }
// };

const toastMessage = ({ message: content, type }) => {
  switch (type) {
    case 'info':
      toast({
        description: content,
        closeicn: "success",
      })
      break;
    case 'success':
      toast({
        description: content,
        closeicn: "success",
      })
      break;
    case 'warning':
      toast({
        description: content,
        closeicn: "warning",
      })
      break;
    case 'error':
      toast({
        description: content,
        closeicn: "destructive",
      })
      break;
    default:
      break;
  }
};
const authLink = setContext((ctx, { headers }) => {
  // eslint-disable-next-line no-undef
  const userToken = localStorage.getItem(TOKEN);
  let newHeaders = headers || {};
  const userAuth = userToken ? `Bearer ${userToken}` : '';
  const socialAuth = headers?.social_token;
  const introspectSecret = process.env.REACT_APP_INTROSPECTION_SECRET;
  newHeaders = {
    ...newHeaders,
    Authorization: socialAuth || userAuth,
    'x-introspection-restriction-secret': introspectSecret,
  };

  return {
    headers: newHeaders,
  };
});

const responseMessageLink = new ApolloLink((operation, forward) =>
  forward(operation)?.map((response) => {
    const { data } = response;

    if (
      data &&
      isObject(data) &&
      Object?.keys(data)?.length > 0 &&
      data?.[`${Object?.keys(data)?.[0]}`]?.message
    ) {
      if (Object?.keys(data)?.[0] === 'forgotUserPassword') {
        if (data?.[`${Object?.keys(data)?.[0]}`]?.status !== 'ERROR') {
          setTimeout(() => {
            toastMessage({
              message:
                data?.[`${Object?.keys(data)?.[0]}`]?.message ||
                'Operation successful',
              type: 'success',
            });
          }, 1000);
        }
      } else {
        setTimeout(() => {
          const oResponse = data?.[`${Object?.keys(data)?.[0]}`];

          if (!response) {
            return;
          }

          toastMessage({
            message: oResponse?.message || 'Operation successful',
            type: oResponse?.status === 'ERROR' ? 'error' : 'success',
          });
        }, 1000);
      }
    }
    return response;
  }),
);

const errorLink = onError((options) => {
  const { graphQLErrors, networkError, response } = options;
  if (networkError?.statusCode === 405) {
    if (disableToastTimeout) {
      clearTimeout(disableToastTimeout);
    }

    disableToastTimeout = setTimeout(() => {
      if (networkError?.result?.message) {
        toastMessage({
          message: networkError?.result?.message,
          type: 'error',
        });
      }
    }, 200);
    // eslint-disable-next-line no-undef
    window.location.href = "/app/logout";
    return;
  }

  if (graphQLErrors?.length > 0) {
    const isForBidden = graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN';

    if (!isForBidden) {
      setTimeout(() => {
        toastMessage({
          message: graphQLErrors?.[0]?.message,
          type: 'error',
        });
      }, 1000);
    }
  } else {
    setTimeout(() => {

      toastMessage({
        message: 'Something went wrong!',
        type: 'error',
      });
    }, 1000);
  }

  if (response) {
    response?.errors?.map((error) => {
      const { message: errorMessage, locations, path, extensions } = error;

      // Enable when sentry integrated
      Sentry?.captureException(
        new Error(
          `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`,
        ),
      );
      if (extensions?.code === 'SESSION_EXPIRED') {
        // eslint-disable-next-line no-undef
        window.location.href = "/app/logout";
        return;
      }
      if (extensions?.code === 'FORBIDDEN') {
        // <Navigate to="/auth/access-denied" replace />;
      }

      if (
        extensions?.code === 'UNAUTHENTICATED' ||
        extensions?.code === 'UNAUTHORIZED' ||
        extensions?.code === 405 ||
        extensions?.code === 'INVALID_TOKEN' ||
        extensions?.exception?.name === 'JsonWebTokenError'
      ) {
        // eslint-disable-next-line no-undef
        window.location.href = "/app/logout";
        return;
      }

      // eslint-disable-next-line no-console
      return console?.log(
        `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console?.log(`[Network error]: ${networkError}`);
    Sentry?.captureException(new Error(`[Network error]: ${networkError}`));
  }
});

const client = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, httpLink]),
});

export default client;
