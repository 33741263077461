import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_TEMPLATES = gql`
  query Templates($pagination: Pagination) {
    templates(pagination: $pagination) {
      count
      data {
        config
        description
        id
        image
        isActive
        key
        name
        templateId
      }
    }
  }
`;
