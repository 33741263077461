/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Button } from 'components/ui/button';
import { useWorkspaceContext } from 'context/WorkspaceContext';

const AssignToGroup = ({ onClose, handleOnClick, data }) => {
  const {
    state: { groups },
  } = useWorkspaceContext();

  const [selectedGroupId, setSelectedGroupId] = useState('');

  const handleSetSelectedGroupId = (value) => {
    setSelectedGroupId(value);
  };

  const currentGroup = groups?.find((g) => g.templateId === data?.id) ?? null;
  useEffect(() => {
    if (currentGroup?.id) {
      setSelectedGroupId(currentGroup.id);
    }
  }, [currentGroup]);

  return (
    <div>
      <p className="font-medium text-[14px]">
        Select the group you want to assign this template to.
      </p>
      <Select value={selectedGroupId} onValueChange={handleSetSelectedGroupId}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select Group" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {groups.map((items) => {
              return (
                <SelectItem value={items?.id} key={items?.id}>
                  {items?.name}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
      <div className="flex justify-end gap-3 pt-4">
        <Button
          className="w-[max-content]"
          onClick={() => onClose()}
          variant="outline"
        >
          Cancel
        </Button>
        <Button onClick={() => handleOnClick({ groupId: selectedGroupId })}>
          Assign
        </Button>
      </div>
    </div>
  );
};

export default AssignToGroup;
