/* eslint-disable arrow-body-style */
import React from 'react';
import Template from './components/Template';

const Templates = () => {
  return (
    <div className="bg-primary-foreground w-full h-[calc(100vh_-_71px)] overflow-hidden">
      <div className="p-[24px]">
        <Template />
      </div>
    </div>
  );
};

export default Templates;
