import React, { useState } from 'react';
import { Button } from 'components/ui/button';
import {
  Gmail,
  Outlook,
  AppleMail,
  Yahoo,
  ThunderbirdLogoIcon,
  MicrosoftoutlookIcon,
} from 'assets/svg';
import InstallGuide from './InstallGuide';

const linkArray = [
  { icon: 'gmail', label: 'Add to Gmail', contentId: 'gmail' },
  { icon: 'outlook', label: 'Add to Outlook', contentId: 'outlook' },
  { icon: 'appleMail', label: 'Add to Apple Mail', contentId: 'applemail' },
  { icon: 'yahoo', label: 'Add to Yahoo', contentId: 'yahoo' },
  {
    icon: 'thunderbird',
    label: 'Add to Thunderbird',
    contentId: 'thunderbird',
  },
  { icon: 'outlookcom', label: 'Add to Outlook.com', contentId: 'outlookcom' },
];

const SignatureLinkButtons = ({ showLabel = true, verticalLayout = false }) => {
  const [dialogContent, setDialogContent] = useState('');
  const [openContentDialog, setOpenContentDialog] = useState(false);

  const handleLinkClick = (content) => {
    setDialogContent(content);
    setOpenContentDialog(true);
  };

  const renderIcon = (iconName) => {
    const size = showLabel ? 28 : 35;
    switch (iconName) {
      case 'gmail':
        return <Gmail height={size} width={size} className="mr-2" />;
      case 'outlook':
        return <Outlook height={size} width={size} className="mr-2" />;
      case 'appleMail':
        return <AppleMail height={size} width={size} className="mr-2" />;
      case 'yahoo':
        return <Yahoo height={size} width={size} className="mr-2" />;
      case 'thunderbird':
        return (
          <ThunderbirdLogoIcon height={size} width={size} className="mr-2" />
        );
      case 'outlookcom':
        return (
          <MicrosoftoutlookIcon height={size} width={size} className="mr-2" />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <InstallGuide
        open={openContentDialog}
        closeModal={() => setOpenContentDialog(false)}
        content={dialogContent}
      />
      <div
        className={`flex ${verticalLayout ? 'flex-col gap-y-4' : 'gap-x-[28px]'} w-full items-center justify-center`}
      >
        {linkArray.map((link) => (
          <Button
            key={link.contentId}
            variant={showLabel ? 'outline' : 'text'}
            className={`h-[54px] w-auto text-secondary-500 ${
              verticalLayout ? 'w-full' : ''
            }`}
            onClick={() => handleLinkClick(link.contentId)}
          >
            {renderIcon(link.icon)} {showLabel && link.label}
          </Button>
        ))}
      </div>
    </>
  );
};

export default SignatureLinkButtons;
