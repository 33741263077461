/* eslint-disable no-undef */
import React, { useEffect } from 'react';

const HeadwayWidget = () => {
  useEffect(() => {
    let pollInterval = null;

    const initHeadway = () => {
      const config = {
        account: process.env.REACT_APP_HEADWAY_APP_ID,
        selector: '#headway_badge',
      };

      if (window.Headway) {
        window.Headway.init(config);
      } else if (!document.querySelector('script[src="https://cdn.headwayapp.co/widget.js"]')) {
        const script = document.createElement('script');
        script.src = 'https://cdn.headwayapp.co/widget.js';
        script.async = true;
        script.onload = () => window.Headway.init(config);
        document.head.appendChild(script);
      }
    };

    const pollForUpdates = () => {
      initHeadway();
    };

    initHeadway();
    pollInterval = setInterval(pollForUpdates, 60000);

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, []);

  return (
    <div className="hw-container flex items-center justify-center flex-col">
      <div id="headway_badge">
        {/* The Headway widget will be injected here */}
      </div>
    </div>
  );
};

export default HeadwayWidget;
