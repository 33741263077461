import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const GET_WORKSPACE_TEMPLATE_LISTING = gql`
  query GetAllWorkspaceTemplate($data: GetAllWorkspaceTemplateInput!) {
    getAllWorkspaceTemplate(data: $data) {
      count
      data {
        id
        groupId
        fields
        name
        templateId
        headshotId
        headshotUrl
        workspaceId
        headshot {
          id
          image
          config
          styleId
          style {
            id
            name
            image
            key
            description
            isActive
          }
        }
        cta {
          title
          url
          shortUrl
        }
        design {
          primaryBrandColor
          font
          theme
          icons
          styles
        }
        template {
          id
          name
          image
          key
          description
          isActive
          config
          templateId
        }
      }
    }
  }
`;
export const GET_WORKSPACE_TEMPLATE = gql`
  query GetWorkspaceTemplate($where: GetWorkspaceTemplateInput!) {
    getWorkspaceTemplate(where: $where) {
      data {
        id
        fields
        name
        templateId
        headshotId
        headshotUrl
        workspaceId
        headshot {
          id
          image
          config
          styleId
          style {
            id
            name
            image
            key
            description
            isActive
          }
        }
        cta {
          title
          url
          shortUrl
        }
        design {
          primaryBrandColor
          font
          theme
          icons
          styles
        }
        template {
          id
          name
          image
          key
          description
          isActive
          config
          templateId
        }
      }
    }
  }
`;

export const GET_WORKSPACE_TEMPLATE_LOGO_SIGN_URL = gql`
  query GetWorkspaceTemplateCompanyLogoSignedUrl(
    $data: GetWorkspaceTemplateCompanyLogoSignedUrlInput!
  ) {
    getWorkspaceTemplateCompanyLogoSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
