import { getTemplateImages } from 'common/constants';

const Template7 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_7'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'John Thompson',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Real Estate Agent',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'Thompson Group',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '1234 Elm Street, CO 80202',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'jt@thompsonrealty.com',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '+1 (555) 123-9876',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://yourwebsite.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://www.thompsonrealty.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: 'FIND YOUR HOME',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: 'https://bnb.com',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#a8071a',
      font: 'Trebuchet MS, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 200,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 14,
          },
        },
        icon: {
          type: 'letter',
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#a8071a',
            size: 22,
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 80,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#a8071a',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#a8071a',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
      name: 'template 7',
      image: null,
      key: 'TEMPLATE_7',
      description: null,
      isActive: true,
      config: null,
      templateId: 7,
    },
    name: '7',
  },
  {
    id: '8be53886-3c9f-4e2a-8f94-9606f7d1a44f',
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: null,
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'DAVE VYAN', // Provided name
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'FontEnd Developer at DIGIWAX', // Provided position
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '', // No department provided
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: '', // No company name provided
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '1234 Elm Street, CO 80202', // Provided company address
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: getTemplateImages('companylogo_TEMPLATE_7_1'), // Provided logo
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: '', // No email provided
        shortLink: 'https://server.utags.co/dWHCPdvT',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '', // No phone number provided
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://www.digiwax.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://www.digiwax.com', // Provided website
        shortLink: 'https://server.utags.co/kTyECJxe',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '', // No CTA URL provided
        shortLink: 'https://server.utags.co/TUXcJmep',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: '', // No Facebook provided
        shortLink: 'https://server.utags.co/PvpOiyYt',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: '', // No Instagram provided
        shortLink: 'https://server.utags.co/mUDegLXR',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: '', // No LinkedIn provided
        shortLink: 'https://server.utags.co/qgOdRFdJ',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'signOff',
        type: 'company',
        label: 'SignOff',
        value: 'Kind regards,', // Provided sign off
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyPhone',
        type: 'company',
        label: 'Company Phone',
        value: '+1 (555) 123-9876', // Provided company phone
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyEmail',
        type: 'company',
        label: 'Company Email',
        value: 'dave@digiwax.com', // Provided company email
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
    ],
    design: {
      primaryBrandColor: '#583d7b', // Provided primary brand color
      font: 'Trebuchet MS, Helvetica, sans-serif', // Provided font
      theme: 'LIGHT',
      icons: null, // Set icons to null
      styles: {
        cta: {
          style: {
            type: 'colored', // Provided CTA style
            width: 200,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000', // Provided font style
            fontSize: 12,
            lineHeight: 1.09,
          },
        },
        headerFont: {
          style: {
            fontSize: 14, // Provided header font size
          },
        },
        icon: null, // Set icon to null
        socialIcon: {
          type: 'square', // Provided social icon style
          style: {
            size: 15, // Provided size
            color: '#583d7b',
          },
        },
        companyLogo: {
          style: {
            width: 108, // Provided logo width
          },
        },
        ctaBanner: {
          style: {
            width: 400, // Provided CTA banner width
          },
        },
        headshot: {
          style: {
            width: 141, // Provided headshot width
          },
        },
        signOff: {
          style: {
            fontSize: 20, // Provided sign off style
          },
        },
        separator: {
          style: {
            color: '#583d7b',
            width: 1, // Provided separator width
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 100, // Provided social banner width
          },
        },
      },
    },
    templateId: '1a935022-d5c7-4cb2-93bc-85b988b3b69d', // Provided template ID
    template: {
      id: '1a935022-d5c7-4cb2-93bc-85b988b3b69d',
      name: 'template 7', // Provided template name
      key: 'TEMPLATE_7', // Provided template key
      description: null,
      isActive: true,
      config: null,
      templateId: 7,
    },
    name: '7-1',
  },
];

export default Template7;
