import React from 'react';
import { get } from 'lodash';

function SignOff({ style = {}, tdProps = {}, signature = {}, data = {} }) {
  const { font } = get(signature, 'design.styles', {});
  const { signOff } = get(signature, 'design.styles', {});
  const { fontSize } = get(font, 'style', {});

  const textStyle = {
    fontSize: signOff?.style?.fontSize || fontSize || 20,
    color: signOff?.style?.color || signature?.design?.primaryBrandColor,
  };

  return (
    <td
      style={{
        fontWeight: 700,
        paddingTop: '10px',
        paddingBottom: '10px',
        margin: 0,
        ...textStyle,
        ...style,
      }}
      {...tdProps}
    >
      {data?.value}
    </td>
  );
}

export default SignOff;
