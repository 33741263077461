import Models from 'modules/AiHeadshot/components/Models';
import React from 'react'

const FormVersionTwo = ({ gender, selectedModels, setSelectedModels, model }) => (
    <div>
      <Models
        selectedModels={selectedModels || []}
        setSelectedModels={setSelectedModels}
        model={model}
        gender={gender}
      />
    </div>
  );
export default FormVersionTwo