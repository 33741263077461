/* eslint-disable import/no-cycle */
import { Loader2, Moon, Sun, Laptop, Smartphone } from 'lucide-react';
import React, { useState, useRef } from 'react';
import { useRouter } from 'hooks/router';
import { useFeatures } from 'hooks/user';
import {
  useSignatureId,
  useUpsertSignature,
  useSaveSignature,
} from 'hooks/signature';
import {
  TEXT_IDS,
  TEXT_IDS_ARRAY,
  TEXT_IDS_ARRAY_PRIMARY,
  SOCIAL_LINKS,
} from 'common/constants';
import { uploadAllImages } from 'common/utils';
import Button from 'components/Button';
import { useAppContext } from 'AppContext';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import SignatureSaveButton from 'components/SignatureSaveButton';
import { useWorkspaceId, useRedirectToWorkspace } from 'hooks/workspace';
import SaveSignatureDialog from 'components/SaveSignatureDialog';
import { templateColors } from 'components/TemplatesModel/utils/darkTemplateColors';
import { get } from 'lodash';
import SignaturePreview from './SignaturePreview';

function Signature() {
  const {
    state: { signature, baseImages, signatureIconsUploading },
    setHeadshotUpdateStatus,
    handleSubscriptionModal,
  } = useAppContext();

  const {
    navigate,
    location: { pathname },
  } = useRouter();
  const { signatureId } = useSignatureId();
  const { workspaceId } = useWorkspaceId();
  const [viewport, setViewport] = useState('desktop');
  const [uploadingSignature, setUploadingSignature] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { isUserUsingProFeatures } = useFeatures();
  const [upsertSignature] = useUpsertSignature();

  const { isSignatureChanged, isDesignStyleChanged, saveChanges } =
    useSaveSignature();
  const saveSignatureDialogRef = useRef();
  const { redirectToWorkspace } = useRedirectToWorkspace();

  // const handleDarkMode = (value) => {
  //   const isDark = value === 'true'; // Convert string value to boolean
  //   setIsDarkMode(isDark);

  //   const templateKey = signature?.template.templateId;
  //   const { darkModePrimaryColor, lightModePrimaryColor } = {
  //     darkModePrimaryColor: 'white',
  //     lightModePrimaryColor: 'black',
  //   };

  //   TEXT_IDS_ARRAY.forEach((id) => {
  //     const textId = TEXT_IDS[id];
  //     // eslint-disable-next-line no-undef
  //     const textElement = document.getElementById(textId);

  //     if (textElement) {
  //       textElement.style.color = isDark
  //         ? darkModePrimaryColor
  //         : lightModePrimaryColor;
  //     }
  //   });
  // };
  const handleDarkMode = (value) => {
    const isDark = value === 'true';
    setIsDarkMode(isDark);
    const templateKey = signature?.template?.templateId;

    const { font } = get(signature, 'design.styles', {});
    const { color } = get(font, 'style', {});
    const primaryBrandColor = get(
      signature,
      'design.primaryBrandColor',
      'black',
    );

    const { darkModePrimaryColor, lightModePrimaryColor } = {
      darkModePrimaryColor: 'white',
      lightModePrimaryColor: color || 'black',
    };

    TEXT_IDS_ARRAY.forEach((id) => {
      const textId = TEXT_IDS[id];
      // eslint-disable-next-line no-undef
      const textElement = document.getElementById(textId);
      if (textElement) {
        if (!isDark) {
          if (TEXT_IDS_ARRAY_PRIMARY.includes(id)) {
            textElement.style.color = primaryBrandColor;
          } else {
            const specificColor = templateColors[templateKey]?.[id];
            textElement.style.color = specificColor || lightModePrimaryColor;
          }
        } else {
          textElement.style.color = darkModePrimaryColor;
        }
      }
    });
    SOCIAL_LINKS.forEach(({ name }) => {
      const socialTextId = `${TEXT_IDS.SOCIALLINKWORD}-${name.toLowerCase()}`;
      // eslint-disable-next-line no-undef
      const socialElement = document.getElementById(socialTextId);

      if (socialElement) {
        if (!isDark) {
          socialElement.style.color = primaryBrandColor;
        } else {
          socialElement.style.color = darkModePrimaryColor;
        }
      }
    });
  };

  const getSignature = async () => {
    setUploadingSignature(true);
    const { id, ...restData } = signature;

    try {
      if (isDesignStyleChanged) {
        const response = await uploadAllImages(baseImages, signatureId);
        restData.design.icons = response;
      }

      await upsertSignature({
        variables: {
          data: restData,
          where: {
            signatureId,
            workspaceId,
          },
        },
      });
      setHeadshotUpdateStatus(false);
      setUploadingSignature(false);
      navigate(`/app/${workspaceId}/signature/${signatureId}/email-signature`);
    } catch (error) {
      const response = await uploadAllImages(baseImages, signatureId);
      restData.design.icons = response;
      await upsertSignature({
        variables: {
          data: restData,
          where: {
            signatureId,
            workspaceId,
          },
        },
      });
      setHeadshotUpdateStatus(false);
      setUploadingSignature(false);
      navigate(`/app/${workspaceId}/signature/${signatureId}/email-signature`);
    }
  };

  const handleCheckSaveChanges = () => {
    if (isSignatureChanged) {
      saveSignatureDialogRef?.current?.open();
    } else {
      redirectToWorkspace();
    }
  };

  const handleSaveChanges = () => {
    saveChanges();
    saveSignatureDialogRef?.current?.close();
    if (!isUserUsingProFeatures()) {
      redirectToWorkspace();
    }
  };

  return (
    // signature-preview-section
    <>
      <SaveSignatureDialog
        handleSaveChanges={handleSaveChanges}
        ref={saveSignatureDialogRef}
      />
      <div className="h-[calc(100dvh_-_71px)] bg-[#f5f5f5]">
        <div className="h-[60px] flex items-center justify-between p-4 lg:p-0 text-regular-base font-primary  ">
          <div className="flex items-center gap-4">
            {/* <h1 className="flex items-center font-primary text-h6 font-bold text-neutral-1000">
              <Button
                onClick={handleCheckSaveChanges}
                variant="text"
                className="px-0 py-0 mr-2"
              >
                <ArrowLeft />
              </Button>
              {signature?.name || ''}
            </h1> */}
          </div>
          <div className="flex gap-4">
            <SignatureSaveButton />
            {/* {templateData && (
            <TemplatesModel
              open={templateModal}
              setOpen={setTemplateModal}
              data={templateData}
              handleTemplateSelect={handleTemplateSelect}
            />
          )} */}
            <Button
              className="pl-[16px] pr-[16px] h-[40px]"
              onClick={() => {
                if (isUserUsingProFeatures()) {
                  handleSubscriptionModal(true);
                  return;
                }
                getSignature();
              }}
              disabled={signatureIconsUploading}
            >
              {uploadingSignature && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Get signature
            </Button>
            {/* <ProfileAvatar /> */}
          </div>
        </div>
        <div className="h-[102px] flex items-center justify-between p-4 pt-0">
          <div>
            <div className="font-primary text-h7 font-bold ml-6">
              Signature preview
            </div>
            <div className="text-secondary-500 mt-[8px] ml-6">
              This information will be visible on your email signature preview.
            </div>
          </div>

          <div className="flex gap-4">
            <div
              className="flex items-center cursor-pointer underline text-[#144CC7] font-primary font-bold"
              onClick={() => {
                navigate(`${pathname}?showTemplate=true&&isCreate=false`);
              }}
            >
              Change Template
            </div>

            {/* <div>
              <Tabs
                value={viewport}
                onValueChange={(value) => setViewport(value)}
                className="w-[88px]"
              >
                <TabsList className="px-1 rounded-md grid grid-cols-2 gap-1 ">
                  <TabsTrigger
                    value="desktop"
                    className="rounded-md border-0 text-[#7A7289] p-[5px] bg-[transparent] data-[state=active]:bg-primary-100 data-[state=active]:text-primary cursor-pointer"
                  >
                    <Laptop size={24} />
                  </TabsTrigger>
                  <TabsTrigger
                    value="mobile"
                    className="rounded-md border-0 text-[#7A7289] p-[5px] bg-[transparent] data-[state=active]:bg-primary-100 data-[state=active]:text-primary cursor-pointer"
                  >
                    <Smartphone size={24} />
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div> */}

            <div className="flex items-center">
              <Tabs defaultValue="false" onValueChange={handleDarkMode} asChild>
                <TabsList className="bg-primary-foreground px-1 rounded-full ">
                  <TabsTrigger
                    value="false"
                    className="rounded-full flex items-center justify-center border-0 p-[5px] bg-[transparent] data-[state=active]:bg-primary data-[state=active]:text-white-0 cursor-pointer"
                  >
                    <Sun size={20} />
                  </TabsTrigger>
                  <TabsTrigger
                    value="true"
                    className="rounded-full flex items-center justify-center border-0 p-[5px] bg-[transparent] data-[state=active]:bg-primary data-[state=active]:text-white-0 cursor-pointer"
                  >
                    <Moon size={20} />
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>
        </div>

        <div
          className="overflow-y-hidden"
          style={{ height: 'calc(100% - 225px)' }}
        >
          <div className=" flex justify-center mt-3 ml-8 h-full ">
            <div
              className={` overflow-hidden h-full transition-all duration-200 ease-linear ${
                isDarkMode ? 'bg-neutral-900' : 'bg-white-0'
              } ${
                viewport === 'mobile'
                  ? '   w-[325px] border-[1px] border-lightpink rounded-md'
                  : 'w-full  border-t-[1px] border-t-lightpink border-l-[1px] border-l-lightpink rounded-tl-[8px] border-tl-lightpink'
              }`}
            >
              <SignaturePreview isDarkMode={isDarkMode} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signature;
