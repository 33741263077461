/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_USER = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      name
      profileImage
      role
      industry
      isActive
      lastActiveOn
      earlyAccess
      onboardingStatus
      isCouponUsed
      planId
      groupId
      signatureLimit
      workspaces
      phone
      department
      position
      freeTrialStartedAt
      isPlanExpired
      activePlan {
        id
        name
        price
        allowedSignatures
        allowedTemplates
        features {
          key
          label
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($where: GetUserByIdInput!) {
    getUserById(where: $where) {
      id
      email
      firstName
      lastName
      name
      profileImage
      role
      isActive
      lastActiveOn
      earlyAccess
      onboardingStatus
      isCouponUsed
      planId
      groupId
      signatureLimit
      workspaces
      phone
      department
      position
      freeTrialStartedAt
      isPlanExpired
      activePlan {
        id
        name
        price
        allowedSignatures
        allowedTemplates
        features {
          key
          label
        }
      }
    }
  }
`;
export const GET_USER_ROLE = gql`
  query GetUserRoleForWorkspace($where: GetUserRoleForWorkspaceInput!) {
    getUserRoleForWorkspace(where: $where) {
      data {
        id
        name
      }
    }
  }
`;
