import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CONNECT_GOOGLE_MARKETPLACE_APP = gql`
  mutation ConnectGoogleMarketplaceApp($where: ConnectGoogleMarketplaceInput!) {
    connectGoogleMarketplaceApp(where: $where) {
      success
      message
      userLicense {
        enabled
        state
        editionId
        customerId
        applicationId
        id
        userId
      }
      customerLicense {
        kind
        id
        applicationId
        customerId
        state
        editions {
          editionId
          seatCount
        }
      }
    }
  }
`;

export const SET_EMAIL_SIGNATURES = gql`
  mutation SetEmailSignatures($where: SetEmailSignatureInput!) {
    setEmailSignatures(where: $where) {
      success
      message
      installedSignatureIds
    }
  }
`;

export const REMOVE_EMAIL_SIGNATURES = gql`
  mutation RemoveEmailSignatures($where: RemoveEmailSignatureInput!) {
    removeEmailSignatures(where: $where) {
      success
      message
    }
  }
`;

export const REMOVE_ACCESS_TOKEN = gql`
  mutation RemoveAccessToken($where: GetValidAccessTokenInput!) {
    removeAccessToken(where: $where) {
      success
      message
    }
  }
`;
