import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const UPDATE_PADDLE_SUBSCRIPTION_PLAN = gql`
  mutation UpdatePaddleSubscriptionPlan(
    $where: UpdatePaddleSubscriptionInput!
  ) {
    updatePaddleSubscriptionPlan(where: $where) {
      success
      message
      subscriptionId
    }
  }
`;
