import React, { useRef } from 'react';
import { useRouter } from 'hooks/router';
import { useWorkspaceId } from 'hooks/workspace';
import { Button } from 'components/ui/button';
import { LockKeyhole } from 'lucide-react';
import AlertDialog from 'components/AlertDialog';
import { cn } from 'lib/utils';
import { useActivateFreeTrialPlan } from 'hooks/plans';
import { useFreeTrial, useFeatures, useUserPermission } from 'hooks/user';
import { useAppContext } from 'AppContext';

const RestrictedContentCard = ({
  wrapperClassName,
  cardClassName = '',
  hideTrial = false,
}) => {
  const { handleSubscriptionModal } = useAppContext();
  const { navigate } = useRouter();
  const { workspaceId } = useWorkspaceId();
  const dialogRef = useRef(null);
  const [activateFreeTrialPlan] = useActivateFreeTrialPlan();
  const { isFreeTrialStarted } = useFreeTrial();
  const { isAppSumoUser } = useUserPermission();

  const handlePurchasePlan = () => {
    handleSubscriptionModal(false);
    if (isAppSumoUser) {
      // eslint-disable-next-line no-undef
      window.open(`https://appsumo.com/account/products/`, '_blank');
    } else navigate(`/app/${workspaceId}/user/subscriptions`);
  };

  const handleStartFreeTrial = () => {
    activateFreeTrialPlan();
    dialogRef.current.close();
  };

  const renderMessage = () => {
    if (!isFreeTrialStarted && !hideTrial && !isAppSumoUser) {
      return (
        <>
          <p className="mt-1 text-sm text-gray-600">
            This feature is not available as it requires a paid plan. You can
            purchase a plan or start a free trial to explore all premium
            features.
          </p>
        </>
      );
    }
    if (isFreeTrialStarted || hideTrial) {
      return (
        <p className="mt-1 text-sm text-gray-600">
          You are using some of the features of the paid plans. You can revert
          to a free version of your signature or check out our pricing.
        </p>
      );
    }

    if (isAppSumoUser) {
      return (
        <p className="mt-1 text-sm text-gray-600">
          This feature is not available in AppSumo License tier 1. You can
          upgrade your license by clicking the below button.
        </p>
      );
    }
  };
  return (
    <>
      <AlertDialog
        ref={dialogRef}
        title="Start TEAMS plan free trial for 7 days"
        message="You are about to start 7-day free trial to access signature features for your teams."
        handleOk={handleStartFreeTrial}
      />

      <div
        className={cn(
          'w-full h-[calc(100vh_-_71px)] flex justify-center items-center bg-primary-foreground',
          wrapperClassName,
        )}
      >
        <div
          className={cn(
            'max-w-xs p-6 bg-white rounded-lg text-center bg-white-0',
            cardClassName,
            {
              'shadow-md': !cardClassName.includes('no-shadow'),
            },
          )}
        >
          <LockKeyhole className="mx-auto h-8 w-8 text-primary" />

          <h2 className="mt-2 text-lg font-semibold">
            {!isAppSumoUser && 'This requires a paid plan!'}
            {isAppSumoUser && 'Upgrade to use this feature!'}
          </h2>

          {renderMessage()}

          <div className="mt-4 flex flex-col space-y-2">
            <Button
              onClick={handlePurchasePlan}
              variant="default"
              className="w-full"
            >
              {isAppSumoUser ? 'Change plan on AppSumo' : 'Purchase Plan'}
            </Button>

            {!isFreeTrialStarted && !hideTrial && !isAppSumoUser && (
              <Button
                onClick={() => {
                  dialogRef.current.open();
                }}
                variant="outline"
                className="w-full text-primary"
              >
                Start 7-day free trial
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RestrictedContentCard;
