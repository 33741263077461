import { getTemplateImages } from 'common/constants';

const Template24 = [
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_24', 'gif'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Jason Hancock',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Head of Marketing',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'AV Studios',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },

      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://avstudios.arch',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://avstudios.arch',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: null,
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#000000',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#000000',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 105,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#000000',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24',
  },
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_24_1'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Marcia Brown',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Corporate Lawyer',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'Brown & Associates',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },

      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'Marcia@brownassociates.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '(456) 789-0123',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: getTemplateImages('ctabanner_TEMPLATE_24_2'),
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: 'https://www.brownassociates.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#3C1211',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#3C1211',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 309,
            ctaborderRadius: 4,
          },
        },
        headshot: {
          style: {
            width: 102,
            borderRadius: 6,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#3C1211',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },

    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,

    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-1',
  },
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_24_2'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Kelvin Anderson',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Kelvin Anderson',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },

      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'Kelvin@andersonhealth.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '(456) 789-0123',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://Kelvinanderson.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: 'Schedule a consultation ',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: 'https://new.com',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: null,
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#45668E',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 212,
            borderRadius: 2,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.2,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#45668E',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 309,
          },
        },
        headshot: {
          style: {
            width: 175,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#45668E',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-2',
  },
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_24_3'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Emma Brooks',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Founder & CEO',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'InnovateX',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },

      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'emma@innovate.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: null,
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'twitter',
        type: 'company',
        label: 'TWITTER',
        value: 'https://www.twitter.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#57A199',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#57A199',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 309,
          },
        },
        headshot: {
          style: {
            width: 106,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#57A199',
            width: 5,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-3',
  },
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_24_4'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Evelyn Parker',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'PhD Candidate in Environmental Studies',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'University of California, Berkeley',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },

      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'Evelyn.parker@berkeley.edu',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: null,
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'socialBanners',
        value: [
          {
            imgUrl:
              'https://static.dev.sendsig.com/asset/linkedin-social-banner-icon-v2.png?variant=2',
            link: 'https://www.linkedin.com',
            platform: 'LinkedIn',
          },
        ],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: 'Kind regards,',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#3D6C9E',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 18,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            color: '#3D6C9E',
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 309,
          },
        },
        headshot: {
          style: {
            width: 80,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 14,
          },
        },
        separator: {
          style: {
            color: '#3D6C9E',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 126,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-4',
  },
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_24_5'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Jason Hancock',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'HR Manager',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'Zenith Technologies',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'olivia.t@zenithtech.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '(888) 234-5678',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://avstudios.arch',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: null,
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#5f9c1f',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 13,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'square',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            color: '#5f9c1f',
            size: 18,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 105,
            borderRadius: 5,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#5f9c1f',
            width: 2,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: '24-5',
  },
];

export default Template24;
