import { useLazyQuery, useMutation } from '@apollo/client';
import { useBrandContext } from 'context/BrandContext';
import { cloneAndRemoveTypename } from 'common/utils';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  GET_WORKSPACE_TEAMMATE_BY_BRAND_ID,
  GET_WORKSPACE_TEAMMATE_BY_ID,
  GET_WORKSPACE_TEAMNMATE_BY_USER_ID,
  GET_WORKSPACE_TEAMNMATE_BY_WORKSPACE_ID,
  REMOVE_TEAMMATE_FROM_GROUP,
  UPDATE_WORKSPACE_TEAMMATE_ROLE,
} from 'modules/Teammates/graphql/Queries';

/* eslint-disable import/prefer-default-export */

export const useGetWorkspaceTeammateById = () => {
  const [getWorkspaceTeammateById, states] = useLazyQuery(
    GET_WORKSPACE_TEAMMATE_BY_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {},
      onError() {
        // Handle error if necessary
        // window.location.href = '/app/';
      },
    },
  );

  return [getWorkspaceTeammateById, states];
};

export const useGetWorkspaceTeammateByBrandId = () => {
  const { setBrandwiseWorkspaceTeammate } = useBrandContext(); // Use the correct context and function

  const [getWorkspaceTeammateByBrandId, states] = useLazyQuery(
    GET_WORKSPACE_TEAMMATE_BY_BRAND_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const teammates = res?.getWorkspaceTeammateByBrandId?.data || [];
        setBrandwiseWorkspaceTeammate(teammates); // Update this line
      },
      onError() {
        // Handle error if necessary
        // window.location.href = '/app/';
      },
    },
  );

  return [getWorkspaceTeammateByBrandId, states];
};

export const useGetWorkspaceTeammateByWorkspaceId = () => {
  const { setWorkspaceTeammates } = useWorkspaceContext();

  const [getWorkspaceTeammateByWorkspaceId, states] = useLazyQuery(
    GET_WORKSPACE_TEAMNMATE_BY_WORKSPACE_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const teammates = res?.getWorkspaceTeammateByWorkspaceId?.data || [];
        setWorkspaceTeammates(cloneAndRemoveTypename(teammates));
      },
      onError() {
        // eslint-disable-next-line no-undef
        // window.location.href = '/app/';
      },
    },
  );

  return [getWorkspaceTeammateByWorkspaceId, states];
};

export const useGetWorkspaceTeammateByUserId = () => {
  const { setWorkspaceTeammates } = useWorkspaceContext();

  const [getWorkspaceTeammateByUserId, states] = useLazyQuery(
    GET_WORKSPACE_TEAMNMATE_BY_USER_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const teammates = res?.getWorkspaceTeammateByUserId?.data || [];
        setWorkspaceTeammates(teammates);
      },
      onError() {
        // eslint-disable-next-line no-undef
        // window.location.href = '/app/';
      },
    },
  );

  return [getWorkspaceTeammateByUserId, states];
};

export const useUpdateWorkspaceTeammateRole = () => {
  const [updateWorkspaceTeammateRole, states] = useMutation(
    UPDATE_WORKSPACE_TEAMMATE_ROLE,
    {
      onCompleted: (data) => {},
      onError: (err) => {},
    },
  );

  return [updateWorkspaceTeammateRole, states];
};

export const useTeammateGroup = () => {
  const {
    state: { groups, workspaceTeammates },
  } = useWorkspaceContext();

  const getTeammateGroup = (id) => {
    const teammate = workspaceTeammates.find(
      (teammate) => teammate.userId === id,
    );
    if (teammate) {
      const group = groups.find((group) => group.id === teammate.groupId);
      return group || null;
    }
    return null;
  };

  return { getTeammateGroup };
};

export const useRemoveTeammateFromGroup = () => {
  const {
    state: { workspaceTeammates },
    setWorkspaceTeammates,
  } = useWorkspaceContext();

  const [removeTeammateFromGroup, states] = useMutation(
    REMOVE_TEAMMATE_FROM_GROUP,
    {
      onCompleted: (res) => {
        const removedIds = res?.removeTeammateFromGroup?.data || [];

        // Update the workspaceTeammates by filtering out the removed teammates
        setWorkspaceTeammates(
          workspaceTeammates.filter(
            (teammate) => !removedIds.includes(teammate.id),
          ),
        );

        // // Show a success message
        // toast({
        //   description: message,
        //   status: 'success',
        // });
      },
      onError: (error) => {
        // Show an error message
        // toast({
        //   description: 'Failed to remove teammates from the group',
        //   status: 'error',
        // });
      },
    },
  );

  return [removeTeammateFromGroup, states];
};
