/* eslint-disable no-bitwise */
import React from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';

const formSchema = z.object({
  email: z
    .string()
    .email({
      message: 'Please enter a valid email address.',
    })
    .nonempty({ message: 'Email is required.' }),
});

const SendSignatureModal = ({ open, closeModal, onSignatureSend }) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { email: '' },
  });

  const onSubmit = (data) => {
    onSignatureSend(data?.email);
  };

  return (
    <Dialog open={open} onOpenChange={closeModal}>
      <DialogContent className="sm:max-w-[400px] max-w-sm">
        <div className="pt-2.5">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              form={form}
              className="mt-[8px]"
              initialValues={{ remember: true }}
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        className="bg-primary-foreground h-[23px] pr-[3px] rounded transition-colors duration-300 focus:border-primary focus:bg-primary-foreground hover:border hover:border-primary hover:bg-primary-foreground hover:shadow-custom"
                        placeholder="Enter email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                className="h-[40px] w-full mt-5"
                size="sm"
                // loading={signupLoading || socialLoading}
              >
                send
              </Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SendSignatureModal;
