import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_WORKSPACE_TEAMMATE_DETAILS,
  DELETE_WORKSPACE_TEAMMATE_DETAILS,
  UPDATE_WORKSPACE_TEAMMATE_DETAILS,
} from 'modules/WorkspaceTeammateDetails/graphql/Mutation';

import {
  GET_WORKSPACE_TEAMMATE_DETAILS,
  GET_WORKSPACE_TEAMMATE_DETAILS_LIST,
} from 'modules/WorkspaceTeammateDetails/graphql/Queries';

/* eslint-disable import/prefer-default-export */

export const useGetWorkspaceTeammateDetailsList = () => {
  const [getWorkspaceTeammateDetailsList] = useLazyQuery(
    GET_WORKSPACE_TEAMMATE_DETAILS_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {},
      onError: (error) => {
        // Handle error, e.g., navigate to a not-found page
        // window.location.href = '/app/';
      },
    },
  );

  return [getWorkspaceTeammateDetailsList];
};

export const useGetWorkspaceTeammateDetails = () => {
  const [getWorkspaceTeammateDetails] = useLazyQuery(
    GET_WORKSPACE_TEAMMATE_DETAILS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {},
      onError: (error) => {
        // Handle error, e.g., navigate to a not-found page
        // window.location.href = '/app/';
      },
    },
  );

  return [getWorkspaceTeammateDetails];
};

export const useCreateWorkspaceTeammateDetails = () => {
  const [createWorkspaceTeammateDetails, states] = useMutation(
    CREATE_WORKSPACE_TEAMMATE_DETAILS,
    {
      onCompleted: (data) => {},
      onError: (error) => {},
    },
  );

  return [createWorkspaceTeammateDetails, states];
};

export const useUpdateWorkspaceTeammateDetails = () => {
  const [updateWorkspaceTeammateDetails, states] = useMutation(
    UPDATE_WORKSPACE_TEAMMATE_DETAILS,
    {
      onCompleted: (data) => {},
      onError: (error) => {},
    },
  );

  return [updateWorkspaceTeammateDetails, states];
};

export const useDeleteWorkspaceTeammateDetails = () => {
  const [deleteWorkspaceTeammateDetails, states] = useMutation(
    DELETE_WORKSPACE_TEAMMATE_DETAILS,
    {
      onCompleted: (data) => {},
      onError: (error) => {},
    },
  );

  return [deleteWorkspaceTeammateDetails, states];
};
