/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleTwentySix({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const section2 =
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const section3 =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');

  return (
    <>
      <table
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
                paddingBottom: '2px',
              },
            })}
          </tr>
        )}
        <tr>
          <td
            aria-label="main-content"
            style={{
              paddingBottom: '8px',
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
              }}
            >
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    ref={mainContentRef}
                    style={{
                      paddingBottom: '10px',
                    }}
                  >
                    <tr>
                      <td
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 0,
                          lineHeight: '100%',
                          paddingRight: !section1 ? '80px' : '0px',
                          paddingTop: !section1 ? '20px' : '0px',
                        }}
                      >
                        <table
                          cellPadding="0px"
                          cellSpacing="0"
                          border="0"
                          style={{
                            borderCollapse: 'collapse',
                          }}
                        >
                          {!section2 && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0px"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    paddingBottom: '8px',
                                  }}
                                >
                                  {getFieldData(fields, 'name') && (
                                    <tr>
                                      {Name({
                                        signature,
                                        data: getFieldData(fields, 'name'),
                                        style: {
                                          fontFamily: 'inherit',
                                          whiteSpace: 'nowrap',
                                          paddingTop: '20px',
                                          paddingBottom: '1px',
                                          fontWeight: 0,
                                        },
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'position') && (
                                    <tr>
                                      {Position({
                                        signature,
                                        data: getFieldData(fields, 'position'),
                                        style: {
                                          fontFamily: 'inherit',
                                          whiteSpace: 'nowrap',
                                          paddingBottom: '10px',
                                          fontSize: '20px',
                                          color: '#00E571',
                                        },
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'department') && (
                                    <tr>
                                      {Department({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'department',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                          paddingBottom: '1px',
                                        },
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'companyName') && (
                                    <tr>
                                      {CompanyName({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'companyName',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                          paddingBottom: '1px',
                                        },
                                      })}
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                          )}

                          {getFieldData(fields, 'phone') && (
                            <tr>
                              {Phone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'phone'),
                                style: {
                                  paddingBottom: '0px',
                                  marginBottom: '1px',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyPhone') && (
                            <tr>
                              {CompanyPhone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyPhone'),
                                style: {
                                  paddingBottom: '0px',
                                  marginBottom: '1px',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'email') && (
                            <tr>
                              {Email({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'email'),
                                style: {
                                  paddingBottom: '0px',
                                  marginBottom: '1px',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyEmail') && (
                            <tr>
                              {CompanyEmail({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyEmail'),
                                style: {
                                  paddingBottom: '0px',
                                  marginBottom: '1px',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyAddress') && (
                            <tr>
                              {CompanyAddress({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyAddress'),
                                style: {
                                  paddingBottom: '0px',
                                  marginBottom: '1px',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'website') && (
                            <tr>
                              {Website({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'website'),
                                style: {
                                  color: '#1155CC',
                                  paddingBottom: '0px',
                                  marginBottom: '1px',
                                  textDecoration: 'underline',
                                },
                              })}
                            </tr>
                          )}
                          {!!socialFields?.length && (
                            <tr>
                              {SocialLinks({
                                signature,
                                showAwsIcons,
                                data: socialFields,
                                design,
                                style: { paddingBottom: '6px' },
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                      <td>
                        {!section1 && (
                          <table cellPadding="0" cellSpacing="0">
                            {getFieldData(fields, 'headshotUrl') && (
                              <tr align="center">
                                {HeadshotImage({
                                  data: getFieldData(fields, 'headshotUrl'),
                                  design,
                                })}
                              </tr>
                            )}

                            {getFieldData(fields, 'companyLogo') && (
                              <tr align="center">
                                {CompanyLogo({
                                  data: getFieldData(fields, 'companyLogo'),
                                  design,
                                })}
                              </tr>
                            )}
                          </table>
                        )}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* {getFieldData(fields, 'ctaUrl') &&
          getFieldData(fields, 'ctaTitle') && (
            <tr>
              {CallToAction({
                signature,
                showAwsIcons,
                design,
                data: {
                  ctaUrl: getFieldData(fields, 'ctaUrl'),
                  ctaTitle: getFieldData(fields, 'ctaTitle'),
                },
              })}
            </tr>
          )}
        {getFieldData(fields, 'ctaBannerImg') &&
          getFieldData(fields, 'ctaBannerUrl') && (
            <tr>
              {CallToActionBanner({
                data: {
                  ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                  ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                },
                design,
              })}
            </tr>
          )}
        {!!getFieldData(fields, 'socialBanners')?.value?.length && (
          <tr>
            {SocialBanners({
              data: getFieldData(fields, 'socialBanners'),
              design,
            })}
          </tr>
        )}
        {getFieldData(fields, 'footer') && (
          <tr>
            {Footer({
              data: getFieldData(fields, 'footer'),
              design,
            })}
          </tr>
        )} */}
            </table>
          </td>
        </tr>
        {/* <tr>
      <Branding hideBranding={hideBranding} />
    </tr> */}
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleTwentySix;
