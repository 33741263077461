import React from 'react';

const SignatureWarning = ({ width }) => (
  <div className="bg-yellow-5 text-yellow-6  border border-red-500 rounded-md w-max px-4 flex items-center justify-between m-4" >
    <span className="text-xs inline-block px-4 py-1 leading-none bg-yellow-4 rounded-full font-bold">Warning</span>
    <p className=" text-meta-s m-3">
      Your signature is wider than 600 pixels ({width}) and may not render nicely on mobile devices.</p>
  </div>
);

export default SignatureWarning;
