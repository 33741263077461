import { Card } from 'components/ui/card';
import React from 'react';

function CommonAnalyticsCard({ label, count, hidden, children }) {
  return (
    <div hidden={hidden}>
      <Card className="border-solid border border-gray-200 p-6 shadow-none flex items-center justify-between lg:justify-center">
        <div className="flex items-center justify-between lg:justify-center">
          <div className="flex items-center w-20  text-gray-700">
            {children}
          </div>
          <div className="w-auto">
            <div>
              <div className="text-gray-700 w-full text-lg ">{label}</div>
              <div className="text-16 text-381e72">{count}</div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default CommonAnalyticsCard;
