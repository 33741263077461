/* eslint-disable no-undef */
/* eslint-disable arrow-body-style */
import { Button } from 'components/ui/button';
import { ArrowRight, Check } from 'lucide-react';
import React, { useEffect} from 'react';
import male from 'assets/images/male-image.png';
import female from 'assets/images/female-image.png';
import { cn } from 'lib/utils';

const GenderSelection = ({ setStep, gender, setGender }) => {
  const data = [
    {
      image: male,
      label: 'Male',
      value: 'male',
    },
    {
      image: female,
      label: 'Female',
      value: 'female',
    },
  ];

  useEffect(() => {
    const genderLocal = localStorage.getItem('gender');
    if (genderLocal) {
      setGender(genderLocal);
    } else {
      localStorage.setItem('gender', 'male');
    }
  }, []);

  const handleSelect = (value) => {
    localStorage.setItem('gender', value);
    setGender(value);
  };

  return (
    <div className="w-full ">
      <div className="overflow-auto h-[calc(100dvh_-_172px)] relative w-full max-w-[1440px]  mx-auto  ">
        <div className="max-w-[700px] my-[48px]  mx-auto flex flex-col gap-[40px] sm:w-[343px]">
          <div>
            <div className="text-white-0 font-bold text-[30px] leading-[36px] font-primary text-center sm:text-[20px] sm:leading-[36px]">
              Step 1 : Select your gender type
            </div>
            <div className="text-[#AAAAAA] font-[500] text-[20px] leading-[36px] mt-[14px] font-primary text-center sm:text-[14px] sm:mt-0 sm:leading-[24px]">
              This information will help us to personalize your experience
            </div>
          </div>
          <div className="flex items-center justify-center gap-[20px]">
            {data.map(({ image, label, value }, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className={cn('flex flex-col items-center gap-3')}>
                  <div
                    className={cn(
                      'relative group rounded-[12px] cursor-pointer border-solid border-[transparent] overflow-hidden',
                      { 'border-solid border-primary': gender === value },
                    )}
                    data-selected="false"
                  >
                    <div className="rounded-[10px] p-[2px]">
                      <img
                        alt={`Preview of pose ${data.id}`}
                        className="h-[auto] w-full max-w-[160px] object-cover rounded-[8px] transition-all"
                        src={image}
                        style={{
                          objectFit: 'contain',
                        }}
                        onClick={() => {
                          handleSelect(value);
                        }}
                      />
                    </div>
                    {gender === value ? (
                      <div className="absolute bottom-4 right-4 h-6 w-6 flex items-center justify-center bg-primary text-[white] rounded-full z-10">
                        <Check className="h-5 w-5" color="#ffffff" />
                      </div>
                    ) : (
                      <div className="absolute bottom-4 right-4 h-5 w-5 flex items-center justify-center border-solid border-[1px] border-[#CCC2DC] text-[white] rounded-full z-10" />
                    )}
                  </div>
                  <div className="text-white-0 text-[24px] font-[600]">
                    {label}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className=" flex justify-center items-center  h-[100px]  border-solid border-0 border-t bg-[#141414] border-[#727272]">
        <div className="flex flex-col gap-3">
          <Button
            onClick={() => {
              setStep(2);
            }}
            // disabled={isDisabled}
            // {...(isDisabled ? { variant: 'ghost' } : {})}
            className="h-[44px] w-[164px]"
          >
            Next <ArrowRight size={20} className="ml-[6px]" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GenderSelection;
