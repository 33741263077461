/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroup($data: CreateGroupInput!) {
    createGroup(data: $data) {
      id
      name
      templateId
      workspaceId
    }
  }
`;

export const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateGroup(
    $data: UpdateGroupInput!
    $where: UpdateGroupWhereInput!
  ) {
    updateGroup(data: $data, where: $where) {
      id
      name
      templateId
      workspaceId
    }
  }
`;

export const DELETE_GROUP_MUTATION = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      success
      message
    }
  }
`;
