/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

export function useRouter() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  return { navigate, location, params };
}
