// eslint-disable-next-line import/prefer-default-export
export const darkModeStyle = (templateKey) => {
  if (templateKey === 'TEMPLATE_26') {
    return `
     body {
        background-color: #ffffff;
        color: #002d58;
      }
      #position-text-id {
        color: rgb(0, 229, 113);
      }
      @media (prefers-color-scheme: dark) {
        body {
          background-color: #1a1a1a;
          color: #ffffff;
        }
        #position-text-id {
        color: #ffffff;
        }
        #phonename-text-id,
        #emailname-text-id,
        #websitename-text-id,
        #addressname-text-id,
        #name-text-id {
          color: #00e571;
        }

      }
    `;
  }
  if (templateKey === 'TEMPLATE_27') {
    return `
     body {
        background-color: #ffffff;
        color: #002d58;
      }
      #position-text-id {
        color: rgb(0, 229, 113);
      }
      @media (prefers-color-scheme: dark) {
        body {
          background-color: #1a1a1a;
          color: #ffffff;
        }
        #position-text-id {
        color: #ffffff;
        }
        #phonename-text-id,
        #emailname-text-id,
        #websitename-text-id,
        #addressname-text-id,
        #name-text-id {
          color: #00e571;
        }

      }
    `;
  }
  return '';
};
