/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React from 'react';

import TeammateEdit from 'modules/TeammateEdit';

const TeammateMySignature = () => {
  return <TeammateEdit />;
};

export default TeammateMySignature;
