import React, { useState } from 'react';
import { Settings } from 'lucide-react';

const WorkspaceSetting = () => (
  <div>
    <div  className="flex justify-between">
      <Settings className="mr-2 h-5 w-5" />
      Settings
    </div>
  </div>
);

export default WorkspaceSetting;
