import { useAppContext } from 'AppContext';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/ui/button';
import { replace } from 'lodash';
import React, { useRef, useState } from 'react';
import { PROCCESS_HEADSHOT_IMAGE } from 'modules/Headshot/graphql/Mutations';
import { fileUpload, getLiveUrl } from 'common/utils';
import {
  GET_STYLES,
  HEAD_SHOT_SIGNED_URL,
} from 'modules/Headshot/graphql/Queries';
import client from 'apollo';
import { PreviewImageIcon, UploadSimpleIcon } from 'assets/svg';
import pngBg from 'assets/png/png-bg.png';
import { useUpdateHeadshotMutation } from 'hooks/headshot';
import { Alert, AlertDescription } from 'components/ui/alert';
import PreparingHeadshot from './PreparingHeadshot';
import Cropper from './Cropper';
import HeadshotEditorDialog from './HeadshotEditorDialog';

const ImageUploadWithCrop = ({
  createHeadShot,
  noImageUploadedTitle = '',
  removeHeadshot,
}) => {
  const {
    state: { headshot },
    setHeadshotUpdateStatus,
    setHeadshot,
  } = useAppContext();
  const { data: { styles: { data: stylesData } = {} } = {} } = useQuery(
    GET_STYLES,
    {
      variables: {
        pagination: { skip: 0, limit: 6 },
      },
      fetchPolicy: 'network-only',
    },
  );

  const { base64Image: _notUsed, ...restHeadshotData } = headshot?.config || {};
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [updateHeadshotMutation] = useUpdateHeadshotMutation();
  const cropperRef = useRef();
  const imageEditorRef = useRef(null);
  const [processHeadshot] = useMutation(PROCCESS_HEADSHOT_IMAGE, {
    onCompleted: async (res) => {
      // if (!headshot?.id) {
      const outputImage = getLiveUrl(res?.processHeadshotImage?.key);
      const response = await createHeadShot({
        variables: {
          data: {
            image: outputImage,
            config: {
              ...restHeadshotData,
              processedImageKey: res?.processHeadshotImage?.key,
            },
          },
        },
      });

      const { __typename, ...createdHeadshot } =
        response?.data?.createHeadshot?.data;
      const { __typename: notUsed, ...style } = stylesData?.[0];
      await updateHeadshotMutation({
        variables: {
          where: {
            id: createdHeadshot?.id,
          },
          data: {
            ...createdHeadshot,
            styleId: style?.id,
            style,
          },
        },
      });
      // }
      setHeadshotUpdateStatus(true);
    },
    onError: () => {},
  });

  const handleClear = async () => {
    setLoading(true);
    await removeHeadshot({
      variables: {
        where: {
          id: headshot?.id,
        },
      },
    });
    setLoading(false);
    setErrorMessage('');
  };

  const handleImageUpload = async (file = null) => {
    const fileName = replace(file?.name, new RegExp(' ', 'g'), '_');
    const res = await client?.query({
      query: HEAD_SHOT_SIGNED_URL,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          fileName,
        },
      },
    });
    const { signedUrl = '', key = '' } =
      await res?.data?.getHeadshotUploadSignedUrl;
    try {
      await fileUpload(signedUrl, file);
      await processHeadshot({
        variables: {
          where: {
            key,
          },
        },
      });
    } catch (error) {
      <Alert variant="destructive">
        <AlertDescription>{error?.message} </AlertDescription>
      </Alert>;
    }
  };

  const handleFinish = async (file) => {
    cropperRef?.current?.close();
    setLoading(true);
    if (file?.status === 'uploading') {
      setLoading(false);
      return;
    }
    await handleImageUpload(file);
    setLoading(false);
  };

  const draggerContent = (
    <div className="flex items-center  justify-center flex-col">
      <UploadSimpleIcon />
      <div className="text-medium-base font-primary  ">
        Drag and drop an image here to upload
      </div>
      <div className="text-medium-base font-primary ">
        or{' '}
        <span className="text-semantic-info ">
          <u>select a file</u>
        </span>
      </div>
    </div>
  );

  return headshot?.image && headshot?.id ? (
    // dragger-preview-file-section
    <>
      <div
        className="rounded border border-primary-100 bg-cover bg-no-repeat flex items-center flex-col"
        style={{
          backgroundImage: `url('${pngBg}')`,
          width: '100%',
          height: 'auto',
        }}
      >
        <img
          className=""
          height="180px"
          width="180px"
          src={headshot?.image}
          alt="Uploaded"
        />
        <div className="border-t border-t-primary-100 bg-white-0 w-full flex items-center justify-between py-[6px] px-[0px] rounded-b-[4px] h-[34px]">
          <Button
            variant="text"
            className="font-primary font-medium text-meta-xs pl-2"
            onClick={handleClear}
          >
            Clear
          </Button>
        </div>
      </div>
    </>
  ) : (
    <>
      {noImageUploadedTitle && (
        <p className="text-center">{noImageUploadedTitle}</p>
      )}
      {loading && (
        <Dialog open={loading} onOpenChange={null}>
          <DialogContent hideCloseButton className="h-[500px] max-w-2xl w-full">
            <div className="">
              <PreparingHeadshot
                className="!h-[100%] p-[0px] max-w-[100%] w-[auto]"
                headerFontSize={24}
                wrapperHeight="h-[100%]"
              />
            </div>
          </DialogContent>
        </Dialog>
      )}

      <Cropper
        ref={cropperRef}
        draggerContent={draggerContent}
        onOk={(file) => {
          handleFinish(file);
        }}
        baseDraggerClassname="h-[102px] bg-[rgba(0,0,0,0.02)]"
        customCropSize={{ aspect: 1 / 1, circularCrop: false }}
      />

      {errorMessage?.length > 0 && (
        <span className="text-red-2">{errorMessage}</span>
      )}
    </>
  );
};

export default ImageUploadWithCrop;
