import React from 'react'

const SignatureInfo = ({ title, value }) => (
  <div className='m-auto'>
    {title}
    <br />
    <span className='text-[28px] font-[700] text-black-90 leading-[34px]'>
      {value}
    </span>
  </div>
);
export default SignatureInfo