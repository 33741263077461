import React from 'react';
import { contentImages } from 'assets/content';

export const outlookcomContent = () => (
  <div className="p-4">
    <div className="text-center mb-6">
      <h1 className="text-2xl font-semibold">
        Set Up Your Signature - Outlook.com (Web Mail)
      </h1>
    </div>

    <div className="mt-6 steps">
      <ol className="list-none p-0 m-0 space-y-6">
        <li className="flex items-start space-x-4">
          <span>
            After signing in to your Outlook.com account, select the Settings
            icon located in the top right corner.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            In the settings sidebar, click on View All Outlook Settings at the
            bottom.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Go to the Mail section, then choose Compose and Reply in the
            settings menu.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Copy your email signature and paste it into the signature editor
            provided.
          </span>
        </li>
        <li className="text-center">
          <img
            src={contentImages['outlook-com-1']}
            alt="Attach Signature File"
            className="w-3/4 mx-auto border rounded-md shadow-lg"
          />
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Click Save to apply your changes and close the settings window.
          </span>
        </li>
        <li className="text-center">
          <img
            src={contentImages['outlook-com-2']}
            alt="Compose Email with Signature"
            className="w-3/4 mx-auto border rounded-md shadow-lg"
          />
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Now, you can create a new email and see your signature in action!
          </span>
        </li>
      </ol>
    </div>
  </div>
);

export default outlookcomContent;
