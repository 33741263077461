/* eslint-disable arrow-body-style */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from 'components/ui/dialog';
import { useWorkspaceId } from 'hooks/workspace';
import { useCreateGroup, useDeleteGroup, useUpdateGroup } from 'hooks/group';
import { toast } from 'hooks/use-toast';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import CreateForm from './components/CreateForm';
import EditForm from './components/EditForm';
import DeleteForm from './components/DeleteForm';

const ActionDialog = forwardRef((props, ref) => {
  const {
    state: { groups },
    setGroups,
  } = useWorkspaceContext();

  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(null);
  const [data, setData] = useState(null);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => {
      setIsOpen(false);
      setData(null);
    },
    onTypeChange: (t) => setType(t),
    onDataChange: (d) => setData(d),
  }));

  const { workspaceId } = useWorkspaceId();
  const [createGroup] = useCreateGroup();
  const [updateGroup] = useUpdateGroup();
  const [deleteGroup] = useDeleteGroup();

  const handleCreateGroup = async (values) => {
    const { name, templateId } = values;
    await createGroup({
      variables: {
        data: {
          name,
          templateId,
          workspaceId,
        },
      },
    });
    toast({
      closeicn: 'success',
      description: 'Create successfully',
    });
    ref?.current?.close();
  };

  const handleUpdateGroup = async (values) => {
    const { name } = values;
    await updateGroup({
      variables: {
        data: {
          name,
          workspaceId,
        },
        where: {
          id: data?.id,
        },
      },
    });
    toast({
      closeicn: 'success',
      description: 'Update successfully',
    });
    ref?.current?.close();
  };

  const handleDeleteGroup = async() => {
   const res = await deleteGroup({
      variables: { id: data?.id },
    });
    if(res.data){
      setGroups(groups.filter((group) => group.id !== data?.id));
      ref?.current?.close();
    }
  };

  const handleOnClick = (values) => {
    try {
      if (type === 'create') {
        handleCreateGroup(values);
      } else if (type === 'update') {
        handleUpdateGroup(values);
      }
    } catch (error) {
      // message?.error('Something went wrong');
      toast({
        closeicn: 'destructive',
        description: 'Something went wrong',
      });
    }
    ref?.current?.close();
  };

  const getDialogContent = () => {
    switch (type) {
      case 'create':
        return (
          <CreateForm
            data={data}
            onClose={() => ref?.current?.close()}
            handleOnClick={handleOnClick}
          />
        );
      case 'update':
        return (
          <EditForm
            data={data}
            onClose={() => ref?.current?.close()}
            handleOnClick={handleOnClick}
          />
        );
      case 'delete':
        return (
          <DeleteForm
            onClose={() => ref?.current?.close()}
            handleDeleteGroup={handleDeleteGroup}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(e) => {
        setIsOpen(e);
        setData(null);
      }}
    >
      {getDialogContent()}
    </Dialog>
  );
});

export default ActionDialog;
