import React from 'react';
import { Button } from 'components/ui/button';
import { useCreateLmCheckoutLink } from 'hooks/ai-headshot';
import { ArrowRight } from 'lucide-react';
import { CHECKOUT_TYPES } from 'common/constants';
import { usePosthogEvents } from 'hooks/posthog';
import ImageGrid from '../ImageGrid';

const ShowAllImages = ({
  prevImagesV1,
  prevImagesV2,
  getPrediction,
  BackButton,
}) => {
  const { url } = useCreateLmCheckoutLink(CHECKOUT_TYPES.CHECKOUT_LINK);
  const { capture } = usePosthogEvents();
  return (
    <div className="w-full">
      <div className="overflow-auto h-[calc(100dvh_-_172px)]  relative w-full  mx-auto  ">
        <div className="p-6 max-w-[1440px]">
          <div className="">{BackButton}</div>
          {!!prevImagesV2.length && (
            <>
              <h1 className="text-white-0">Creative</h1>
              <ImageGrid
                previousImages={prevImagesV2}
                getPrediction={getPrediction}
                warpperClassNames="justify-start"
              />
            </>
          )}
          {!!prevImagesV1.length && (
            <>
              <h1 className="text-white-0">Professional</h1>
              <ImageGrid
                previousImages={prevImagesV1}
                getPrediction={getPrediction}
                warpperClassNames="justify-start"
              />
            </>
          )}
        </div>
      </div>
      <div className=" flex  w-full  justify-center items-center  h-[100px]  border-solid border-0 border-t bg-[#141414] border-[#727272]">
        <Button
          onClick={() => capture('Buys more credits')}
          className="h-[44px]"
        >
          <a
            href={url}
            className="lemonsqueezy-button no-underline text-white-0 flex justify-center"
          >
            Buy more credits <ArrowRight size={20} className="ml-[6px]" />
          </a>
        </Button>
      </div>
    </div>
  );
};

export default ShowAllImages;
