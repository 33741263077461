/* eslint-disable import/prefer-default-export */
import Template1 from '../TemplatesData/Template1';
import Template2 from '../TemplatesData/Template2';
import Template3 from '../TemplatesData/Template3';
import Template4 from '../TemplatesData/Template4';
import Template5 from '../TemplatesData/Template5';
import Template6 from '../TemplatesData/Template6';
import Template7 from '../TemplatesData/Template7';
import Template8 from '../TemplatesData/Template8';
import Template9 from '../TemplatesData/Template9';
import Template10 from '../TemplatesData/Template10';
import Template11 from '../TemplatesData/Template11';
import Template12 from '../TemplatesData/Template12';
import Template13 from '../TemplatesData/Template13';
import Template14 from '../TemplatesData/Template14';
import Template15 from '../TemplatesData/Template15';
import Template16 from '../TemplatesData/Template16';
import Template17 from '../TemplatesData/Template17';
import Template18 from '../TemplatesData/Template18';
import Template19 from '../TemplatesData/Template19';
import Template20 from '../TemplatesData/Template20';
import Template21 from '../TemplatesData/Template21';
import Template23 from '../TemplatesData/Template23';
import Template22 from '../TemplatesData/Template22';
import Template24 from '../TemplatesData/Template24';
import Template25 from '../TemplatesData/Template25';
import Template26 from '../TemplatesData/Template26';
import Template27 from '../TemplatesData/Template27';
import Template30 from '../TemplatesData/Template30';
import Template32 from '../TemplatesData/Template32';
import Template33 from '../TemplatesData/Template33';
import Template34 from '../TemplatesData/Template34';

export const TEMPLATE_MODELS = [
  Template1,
  Template2,
  Template3,
  Template4,
  Template5,
  Template6,
  Template7,
  Template8,
  Template9,
  Template10,
  Template11,
  Template12,
  Template13,
  Template14,
  Template15,
  Template16,
  Template17,
  Template18,
  Template19,
  Template20,
  Template21,
  Template22,
  Template23,
  Template24,
  Template25,
  Template26,
  Template27,
  Template30,
  Template32,
  Template33,
  Template34,
].flatMap((d) => d);

export const CATEGORY_MODELS = [
  'All',
  'Minimal',
  'Creative',
  'Formal',
  'Text only',
  'With headshot',
  'Promotional',
].flatMap((d) => d);

// export const CATEGORY_MAP_MODELS = {
//   1: ['Creative', 'Formal'],
//   2: ['Creative', 'With headshot'],
//   3: ['Creative', 'With headshot'],
//   4: ['Formal', 'With headshot'],
//   5: ['Formal', 'With headshot'],
//   6: ['Formal', 'With headshot'],
//   7: ['Formal', 'With headshot'],
//   8: ['Formal', 'With headshot'],
//   9: ['Formal', 'With headshot'],
//   10: ['Creative', 'With headshot'],
//   13: ['Creative', 'With headshot'],
//   14: ['Minimal', 'With headshot'],
//   15: ['Minimal', 'With headshot'],
//   16: ['Minimal', 'With headshot'],
//   17: ['Text only'],
//   20: ['Creative', 'With headshot'],
//   21: ['Minimal', 'With headshot'],
//   22: ['Promotional', 'With headshot'],
//   23: ['Promotional', 'With headshot'],
//   24: ['Promotional', 'With headshot'],
//   25: ['Promotional', 'With headshot'],
//   30: ['Minimal', 'With headshot'],
//   32: ['Minimal', 'With headshot'],
//   33: ['Minimal', 'With headshot'],
//   34: ['Minimal', 'With headshot'],
// };

export const CATEGORY_MAP_MODELS = {
  1: {
    1: ['Creative', 'Formal'],
  },
  2: {
    2: ['Creative', 'With headshot'],
    '2-1': ['Creative', 'With headshot'],
    '2-2': ['Text only'],
    '2-3': ['Minimal', 'With headshot'],
    '2-4': ['Formal', 'With headshot'],
    '2-5': ['Minimal', 'With headshot'],
  },
  3: {
    3: ['Creative', 'With headshot'],
    '3-1': ['Text only'],
    '3-2': ['Creative'],
    '3-3': ['Formal', 'With headshot'],
    '3-4': ['Formal'],
    '3-5': ['Formal', 'With headshot'],
  },
  4: {
    4: ['Formal', 'With headshot'],
    '4-1': ['Promotional'],
  },
  5: {
    5: ['Formal', 'With headshot'],
    '5-1': ['Formal'],
    '5-2': ['Formal', 'With headshot', 'Promotional'],
    '5-3': ['Promotional'],
    '5-4': ['Formal', 'With headshot'],
  },
  6: {
    6: ['Formal', 'With headshot'],
    '6-1': ['Formal', 'With headshot'],
    '6-2': ['Formal', 'With headshot'],
    '6-3': ['With headshot'],
    '6-4': ['Text only'],
  },
  7: {
    7: ['Formal', 'With headshot'],
    '7-1': ['Formal'],
  },
  8: {
    8: ['Formal', 'With headshot'],
    '8-1': ['Formal', 'With headshot'],
    '8-2': ['Formal', 'With headshot'],
  },
  9: {
    9: ['Formal'],
  },
  10: {
    10: ['Creative', 'With headshot'],
  },
  11: {
    11: ['Creative', 'With headshot'],
    '11-1': ['Creative', 'With headshot'],
    '11-2': ['Creative', 'With headshot'],
    '11-3': ['Creative', 'With headshot'],
    '11-4': ['Promotional'],
  },
  12: {
    12: ['Formal'],
  },
  13: {
    13: ['Creative', 'With headshot'],
    '13-1': ['Formal'],
    '13-2': ['Formal'],
  },
  14: {
    14: ['Minimal'],
    '14-1': ['Promotional'],
  },
  15: {
    15: ['Minimal', 'With headshot'],
  },
  16: {
    16: ['Minimal', 'With headshot'],
  },
  17: {
    17: ['Text only'],
    '17-1': ['Minimal'],
  },
  18: {
    18: ['Minimal'],
    '18-1': ['Minimal'],
  },
  19: {
    19: ['Minimal'],
  },
  20: {
    20: ['Creative', 'With headshot'],
    '20-1': ['Creative', 'With headshot'],
  },
  21: {
    21: ['Minimal', 'With headshot'],
    '21-1': ['Text only'],
  },
  22: {
    22: ['Promotional'],
    '22-1': ['With headshot'],
    '22-2': ['Promotional'],
    '22-3': ['With headshot'],
  },
  23: {
    23: ['With headshot', 'Promotional'],
  },
  24: {
    24: ['Creative'],
    '24-1': ['Promotional'],
    '24-2': ['With headshot'],
    '24-3': ['With headshot'],
    '24-4': ['With headshot'],
    '24-5': ['With headshot'],
  },
  25: {
    25: ['Minimal'],
  },
  26: {
    26: ['Creative'],
  },
  30: {
    30: ['Minimal', 'With headshot'],
  },
  32: {
    32: ['Minimal', 'With headshot'],
  },
  33: {
    33: ['Creative'],
    '33-1': ['With headshot'],
    '33-2': ['With headshot'],
  },
  34: {
    34: ['Minimal', 'With headshot'],
  },
};
