import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_AI_HEADSHOT_MUTATION = gql`
  mutation CreateAIHeadshot($data: CreateAIHeadshotInput!) {
    createAIHeadshot(data: $data) {
      data {
        id
        userId
        step
        isPaid
        image
        previousImages
        credits
        type
        status
      }
    }
  }
`;
