/* eslint-disable import/prefer-default-export */
import thunderbirdImage1 from './thunderbird/t1.png';
import thunderbirdImage2 from './thunderbird/t2.png';
import thunderbirdImage3 from './thunderbird/t3.png';
import outlookComImage1 from './outlookcom/o1.png';
import outlookComImage2 from './outlookcom/o2.png';
import outlookImage1 from './outlook/m1.png';
import outlookImage2 from './outlook/m2.png';
import outlookImage3 from './outlook/m3.png';
import outlookImage4 from './outlook/m4.png';
import outlookImage5 from './outlook/m5.png';
import outlookImage6 from './outlook/m6.png';
import outlookIconA1 from './outlook/a1.png';
import outlookIconA2 from './outlook/a2.png';
import outlookIconA3 from './outlook/a3.png';
import outlookIconA4 from './outlook/a4.png';

export const contentImages = {
  'thunderbird-1': thunderbirdImage1,
  'thunderbird-2': thunderbirdImage2,
  'thunderbird-3': thunderbirdImage3,
  'outlook-com-1': outlookComImage1,
  'outlook-com-2': outlookComImage2,
  'outlook-1': outlookImage1,
  'outlook-2': outlookImage2,
  'outlook-3': outlookImage3,
  'outlook-4': outlookImage4,
  'outlook-5': outlookImage5,
  'outlook-6': outlookImage6,
  'outlook-a1': outlookIconA1,
  'outlook-a2': outlookIconA2,
  'outlook-a3': outlookIconA3,
  'outlook-a4': outlookIconA4,
};
