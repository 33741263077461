import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { useCreateTeammate } from 'hooks/teammate';
import { useWorkspaceId } from 'hooks/workspace';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import { LoaderCircle } from 'lucide-react';

const SelectUsersDialog = forwardRef(({ users, onClose }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { workspaceId } = useWorkspaceId();
  const [createTeammate] = useCreateTeammate();
  const [searchQuery, setSearchQuery] = useState('');
  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();

  const form = useForm({
    defaultValues: {
      selectedUsers: [],
    },
  });

  const watch = form.watch();

  const { control, setValue, getValues } = form;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const fetchWorkspaceTeammates = async () => {
    await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
  };

  const handleUserSelect = (userId) => {
    const selectedUsers = getValues('selectedUsers');
    if (selectedUsers.includes(userId)) {
      setValue(
        'selectedUsers',
        selectedUsers.filter((id) => id !== userId),
      );
    } else {
      setValue('selectedUsers', [...selectedUsers, userId]);
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery) ||
      user.email.toLowerCase().includes(searchQuery),
  );

  const handleSelectAll = (isChecked) => {
    if (!isChecked) {
      setValue('selectedUsers', []);
    } else {
      const filteredUserIds = filteredUsers.map((user) => user.id);
      setValue('selectedUsers', filteredUserIds);
    }
  };

  const isAllSelected =
    filteredUsers.length > 0 &&
    filteredUsers.every((user) => getValues('selectedUsers').includes(user.id));

  const handleSubmit = async (values) => {
    setLoading(true);
    const teammates = users.filter((u) => values.selectedUsers.includes(u.id));

    // Prepare the selected users data for bulk creation

    const usersData = teammates.map((user) => ({
      data: {
        email: user.email,
        name: user.name,
        department: user.department,
        position: user.position,
        profileImage: user.profileImage,
        // Add other necessary fields here
      },
      where: { workspaceId }, // Assuming `workspaceId` is available in your context
    }));

    try {
      // Execute the createTeammate mutation for each user in parallel using Promise.all
      const res = await Promise.all(
        usersData.map((userData) =>
          createTeammate({
            variables: userData,
          }),
        ),
      );

      // Extract the created teammates data from the response
      const createdTeammates = res.map((result) =>
        get(result, 'data.createTeammate.data', null),
      );
      if (createdTeammates.length) {
        // Update the teammates list or perform any additional logic
        setIsOpen(false);
        if (typeof onClose === 'function') {
          onClose();
        }
        fetchWorkspaceTeammates();
        form.reset();
        setLoading(false);
      }

      return createdTeammates;
    } catch (error) {
      // Handle the error if necessary
    }
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent hideCloseButton className="rounded-[6px] max-w-md">
        <Form {...form}>
          <h1 className="m-0">Select Teammates</h1>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-3"
          >
            <FormField
              control={control}
              name="search"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Search</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        placeholder="Search teammates"
                        onChange={handleSearchChange}
                        value={searchQuery}
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="flex items-center space-x-2 mb-2 mt-3">
              <Checkbox
                onCheckedChange={handleSelectAll}
                checked={isAllSelected}
              />
              <span>Select all</span>
            </div>

            <div className="relative space-y-2 h-[200px] overflow-auto ">
              {filteredUsers.map((user) => (
                <FormField
                  key={user.id}
                  control={control}
                  name="selectedUsers"
                  render={() => (
                    <FormItem className="flex items-center space-x-2">
                      <FormControl>
                        <Checkbox
                          className="mt-2"
                          checked={getValues('selectedUsers').includes(user.id)}
                          onCheckedChange={() => handleUserSelect(user.id)}
                        />
                      </FormControl>
                      <div className="flex items-center space-x-2">
                        {user.profileImage ? (
                          <img
                            src={user.profileImage}
                            alt={user.name}
                            className="w-8 h-8 rounded-full"
                          />
                        ) : (
                          <div className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full">
                            <span className="text-sm font-medium text-gray-600">
                              {user.name
                                .split(' ')
                                .map((part) => part[0])
                                .join('')}
                            </span>
                          </div>
                        )}
                        <div>
                          <div className="font-medium">{user.name}</div>
                          <div className="text-sm text-gray-500 lowercase">
                            {user.email}
                          </div>
                        </div>
                      </div>
                    </FormItem>
                  )}
                />
              ))}
            </div>

            <div className="w-full">
              <Button
                disabled={loading || watch?.selectedUsers?.length === 0}
                type="submit"
                className="w-full"
                size="sm"
              >
                Add Teammates{' '}
                {loading && (
                  <LoaderCircle className="ml-2 w-8 h-8 text-gray-1 animate-spin" />
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

export default SelectUsersDialog;
