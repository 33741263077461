/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { aiHeadshotRoutes } from 'routes';
import { useGetRoutes } from 'helpers/useGetRoutes';
import { useRouter } from 'hooks/router';
import { useFeatureChecks } from 'hooks/brand';
import { useBrandContext } from 'context/BrandContext';
import DarkBg from '../../assets/svg/darkbg.svg';
import Header from './Header';

const AiHeadshotLayout = () => {
  const { getRoutes } = useGetRoutes();
  const { navigate } = useRouter();
  const { isAIHeadshotsAllowed } = useFeatureChecks();
  const {
    state: { brandwiseWorkspaceTeammate },
  } = useBrandContext();
  const workspaceId = brandwiseWorkspaceTeammate?.[0]?.workspaceId;

  useEffect(() => {
    localStorage.removeItem('journey');
  }, []);

  useEffect(() => {
    if (!isAIHeadshotsAllowed && workspaceId) {
      navigate(`/app/${workspaceId}/user/profile`);
    }
  }, [isAIHeadshotsAllowed]);

  return (
    <div
      style={{
        backgroundImage: `url(${DarkBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Header />
      <div className="h-[calc(100dvh_-_71px)] overflow-auto">
        <Routes>
          {getRoutes(aiHeadshotRoutes)}
          <Route path="*" element={<Navigate to="/app/ai-headshot" />} />
        </Routes>
      </div>
    </div>
  );
};

export default AiHeadshotLayout;
