/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';

export const useDebounceEffect = (callback, delay, deps) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [...(deps || []), delay]);
};
