import React from 'react';

const BeforeAfterCard = ({ before = '', after = '' }) => (
  <div className="  w-full my-[20px]">
    <div className="  w-full  grid grid-cols-2 gap-4 items-center justify-between">
      <div className=" w-full flex flex-col items-center  ">
        <span className="mb-2 text-[28px] text-[#381E72]  font-semibold">
          BEFORE
        </span>
        <div className="p-[17px]  rounded-[28px]  shadow-template">
          <img
            alt="Before"
            className="rounded-[28px] max-w-[200px] w-full border-[2px] "
            src={before}
            style={{
              aspectRatio: '2/3',
              objectFit: 'cover',
            }}
          />
        </div>
      </div>
      <div className="w-full flex flex-col items-center gap-2">
        <span className="mb-2 text-[28px] text-[#381E72]  font-semibold">
          AFTER
        </span>
        <div className=" p-[17px] rounded-[28px] shadow-template">
          <img
            alt="After"
            className=" max-w-[200px] w-full rounded-[28px] border-[2px]"
            src={after}
            style={{
              aspectRatio: '2/3',
              objectFit: 'cover',
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default BeforeAfterCard;
