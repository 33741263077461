import React from 'react';
import { contentImages } from 'assets/content';

export const ThunderbirdContent = () => (
  <div className="p-4">
    <div className="text-center mb-6">
      <h1 className="text-2xl font-semibold">
        Set Up Your Signature - Mozilla Thunderbird (Windows)
      </h1>
    </div>

    <div className="mt-6 steps">
      <ol className="list-none p-0 m-0 space-y-6">
        <li className="flex items-start space-x-4">
          <span>
            Download your email signature as an HTML file from the provided
            link.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Open Mozilla Thunderbird. Click the Menu Icon in the top-right
            corner, go to Options, then select Account Settings.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            In the Account Settings window, check the box for "Attach the
            signature from a file instead (text, HTML, or image)" and upload the
            HTML signature file.
          </span>
        </li>
        <li className="text-center">
          <img
            src={contentImages['thunderbird-1']}
            alt="Attach Signature File"
            className="w-3/4 mx-auto border rounded-md shadow-lg"
          />
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Click OK at the bottom-right to save. When you compose a new email,
            you'll see your signature included!
          </span>
        </li>
        <li className="text-center">
          <img
            src={contentImages['thunderbird-2']}
            alt="Compose Email with Signature"
            className="w-3/4 mx-auto border rounded-md shadow-lg"
          />
          <span className="text-sm mt-2 block text-gray-600">
            Note: Thunderbird may display red borders around elements in your
            email signature while composing. These borders won’t appear in the
            recipient's email.
          </span>
        </li>
        <li className="text-center">
          <img
            src={contentImages['thunderbird-3']}
            alt="Compose Email with Signature"
            className="w-3/4 mx-auto border rounded-md shadow-lg"
          />
        </li>
      </ol>
    </div>
  </div>
);

export default ThunderbirdContent;
