import React from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';

const presetColors = [
  { primary: '#4c4c4c', 'primary-foreground': '#e0e0e2' },
  { primary: '#3db88b', 'primary-foreground': '#f0f9f6' },  // Green
  { primary: '#4466ff', 'primary-foreground': '#f0f3ff' },  // Blue
  { primary: '#e16b16', 'primary-foreground': '#fdf3ec' },  // Orange
  { primary: '#ee5e99', 'primary-foreground': '#fef2f7' },  // Pink
  { primary: '#aa8d80', 'primary-foreground': '#f8f6f5' },  // Brown
  { primary: '#0f9d9f', 'primary-foreground': '#e7f5f5' },  // Teal
  { primary: '#7f56d9', 'primary-foreground': 'rgb(243, 237, 255)' },  // Purpel
];

const ThemeSelect = ({
  value = null,
  presetColor = presetColors,
  colorPickerWrapperClassName = '',
  ...rest
}) => {
  const { onChange } = rest;

  return (
    <div className="w-full text-left">
      <div className={cn('flex gap-2', colorPickerWrapperClassName)}>
        <div className="flex gap-2">
          {presetColor.map(({ primary, 'primary-foreground': foreground }) => (
            <Button
              className={cn(
                'shadow-sm h-6 px-3',
                value?.color === primary ? 'ring-2 ring-offset-2 ring-primary' : '',
              )}
              type="button"
              key={primary}
              onClick={() =>
                onChange({ color: primary, foregroundColor: foreground })
              }
              style={{ backgroundColor: primary }}
            >
              {/* Optionally, you can display the foreground color or any other indicator */}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThemeSelect;
