/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Separator } from 'components/ui/separator';
import { Slider } from 'components/ui/slider';
import { Label } from 'components/ui/label';
import React, { useEffect, useRef } from 'react';
import { useAppContext } from 'AppContext';
import { PRIMARY_COLOR, FEATURE_KEYS } from 'common/constants';
import ColorPicker from 'components/ColorPicker';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import {
  useGetSignature,
  useSaveSignature,
  useSignatureId,
} from 'hooks/signature';
import Button from 'components/Button';
import { useWorkspaceId } from 'hooks/workspace';
import { useUserPermission } from 'hooks/user';

const designSchema = z.object({
  font: z.string().default('Arial, Helvetica, sans-serif'),
  themeColor: z.string().default(PRIMARY_COLOR),
  iconType: z.string().default('rounded'),
  iconSize: z.number().min(11).max(30).default(20),
  socialIconType: z.string().default('rounded'),
  socialIconSize: z.number().min(11).max(30).default(20),
  fontSize: z.number().min(11).max(18).default(14),
  headerFontSize: z.number().min(11).max(18).default(13),
  lineHeight: z.number().min(0.8).max(2).default(1.5),
  separatorWidth: z.number().min(1).max(10).default(2),
  footerWidth: z.number().min(1).max(10).default(100),
  textColor: z.string().default('#000000'),
  separatorColor: z.string().default(PRIMARY_COLOR),
  separatorType: z.string().default('solid'),
  footerColor: z.string().default('#000000'),
  footerFontSize: z.number().min(11).max(18).default(9),
  socialIconColor: z.string().default(PRIMARY_COLOR),
});

const Fonts = [
  { value: 'Arial, Helvetica, sans-serif', label: 'Arial' },
  { value: 'Comic Sans MS , cursive, sans-serif', label: 'Comic Sans MS' },
  { value: 'Courier new, courier, monospace', label: 'Courier New' },
  { value: 'Georgia, serif', label: 'Georgia' },
  { value: 'Lucida Console, Monaco, monospace', label: 'Lucida Console' },
  { value: 'Tahoma,Geneva,Sans-serif', label: 'Tahoma' },
  { value: 'Times New Roman, Times, serif', label: 'Times New Roman' },
  { value: 'Trebuchet MS, Helvetica, sans-serif', label: 'Trebuchet MS' },
  { value: 'Verdana, Geneva, sans-serif', label: 'Verdana' },
];

function DesignControl() {
  const {
    state: { signature },
    setSignature,
  } = useAppContext();
  const { workspaceId } = useWorkspaceId();

  const form = useForm({
    resolver: zodResolver(designSchema),
    values: {
      font: signature?.design?.font || 'Arial, Helvetica, sans-serif',
      themeColor: signature?.design?.primaryBrandColor || PRIMARY_COLOR,
      iconType: signature?.design?.styles?.icon?.type || 'rounded',
      iconSize: parseFloat(signature?.design?.styles?.icon?.style?.size) || 20,
      socialIconType: signature?.design?.styles?.socialIcon?.type || 'rounded',
      socialIconSize:
        parseFloat(signature?.design?.styles?.socialIcon?.style?.size) || 20,
      fontSize:
        parseFloat(signature?.design?.styles?.font?.style?.fontSize) || 14,
      headerFontSize:
        parseFloat(signature?.design?.styles?.headerFont?.style?.fontSize) ||
        parseFloat(signature?.design?.styles?.font?.style?.headerfontsize) ||
        13,
      lineHeight:
        parseFloat(signature?.design?.styles?.font?.style?.lineHeight) || 1.5,
      separatorWidth:
        parseFloat(signature?.design?.styles?.separator?.style?.width) || 1,
      footerWidth:
        parseFloat(signature?.design?.styles?.footer?.style?.width) || 500,
      textColor: signature?.design?.styles?.font?.style?.color || '#000000',
      separatorColor:
        signature?.design?.styles?.separator?.style?.color || PRIMARY_COLOR,
      separatorType:
        signature?.design?.styles?.separator?.style?.type || 'solid',
      footerColor: signature?.design?.styles?.footer?.style?.color || '#000000',
      footerFontSize:
        parseFloat(signature?.design?.styles?.footer?.style?.fontSize) || 9,
      socialIconColor:
        signature?.design?.styles?.socialIcon?.style?.color || PRIMARY_COLOR,
    },
  });

  const { isDesignStyleChanged } = useSaveSignature();
  const [getSignature] = useGetSignature();
  const { signatureId } = useSignatureId();
  const { isFeaturesAllowed } = useUserPermission();
  const previousValuesRef = useRef({});

  const watchFields = form.watch();

  const handleFormChange = () => {
    const {
      font,
      themeColor,
      iconType,
      iconSize,
      socialIconType,
      socialIconSize,
      fontSize,
      headerFontSize,
      lineHeight,
      separatorWidth,
      separatorColor,
      separatorType,
      footerWidth,
      textColor,
      footerColor,
      footerFontSize,
      socialIconColor,
    } = form.getValues();

    const updatedStyles = {
      icon: { type: iconType, style: { size: iconSize } },
      socialIcon: {
        type: socialIconType,
        style: { size: socialIconSize, color: socialIconColor },
      },
      font: {
        style: {
          fontSize,
          lineHeight,
          color: textColor,
        },
      },
      headerFont: {
        style: {
          fontSize: headerFontSize,
        },
      },
      separator: {
        style: {
          width: separatorWidth,
          color: separatorColor,
          type: separatorType,
        },
      },
      footer: {
        style: {
          width: footerWidth,
          color: footerColor,
          fontSize: footerFontSize,
        },
      },
    };

    const newSignature = {
      ...signature,
      design: {
        ...signature?.design,
        primaryBrandColor: themeColor,
        font,
        styles: { ...(signature?.design?.styles ?? {}), ...updatedStyles },
      },
    };

    if (
      JSON.stringify(previousValuesRef.current) !== JSON.stringify(newSignature)
    ) {
      setSignature(newSignature);
      previousValuesRef.current = newSignature;
    }
  };

  useEffect(() => {
    const subscription = form.watch(() => handleFormChange());
    return () => subscription.unsubscribe();
  }, [form, signature]);

  const resetToDefault = () => {
    getSignature({
      variables: {
        where: { signatureId: signatureId || signature?.id || '', workspaceId },
      },
    });
  };

  return (
    <div className="text-neutral-1000">
      <header className=" px-[16px] h-[102px] flex items-center">
        <h1 className="font-primary text-h6 font-bold">Design</h1>
      </header>

      <Form
        onValueChange={(value) => {
          console.log('value', value);
        }}
        {...form}
      >
        <div className="px-4">
          <div className="bg-white-0 mb-4 rounded p-0 px-2">
            <FormField
              control={form.control}
              name="themeColor"
              render={({ field }) => (
                <FormItem className="m-0 flex items-center">
                  <div className="whitespace-nowrap text-[14px] mb-0">
                    Theme color
                  </div>
                  <FormControl>
                    <ColorPicker
                      defaultValue={
                        signature?.design?.primaryBrandColor || PRIMARY_COLOR
                      }
                      value={field.value}
                      colorPickerWrapperClassName="justify-end mb-2"
                      onChange={(value) => {
                        const color = `#${value.toHex().slice(0, 6)}`;
                        form.setValue('themeColor', color);
                        form.setValue('socialIconColor', color);
                        form.setValue('separatorColor', color);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="bg-white-0 mb-4 rounded pt-1 pb-4 px-2">
            <FormField
              name="iconType"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="leading-[36px] text-[14px]">Icon Type</div>
                  <FormControl>
                    <RadioGroup
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="none" id="none" />
                        <Label htmlFor="none">None</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="rounded" id="rounded" />
                        <Label htmlFor="rounded">Rounded</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="square" id="square" />
                        <Label htmlFor="square">Square</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="letter" id="letter" />
                        <Label htmlFor="letter">Letter</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="word" id="word" />
                        <Label htmlFor="word">Word</Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* {(watchFields.iconType === 'rounded' ||
              watchFields.iconType === 'square') && (
              <>
                <Separator className="mt-4 mb-4 bg-primary-foreground" />
                <FormField
                  name="iconSize"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="m-0">
                      <div className="mb-2 flex justify-between text-medium-base font-primary">
                        <span>Icon size</span>

                        <span>{field.value}px</span>
                      </div>
                      <FormControl>
                        <Slider
                          {...field}
                          min={14}
                          max={22}
                          value={[field.value]}
                          onValueChange={([value]) => field.onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )} */}
          </div>

          <div className="bg-white-0 mb-4 rounded pt-1 pb-4 px-2">
            <FormField
              name="socialIconType"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="leading-[36px] text-[14px]">
                    Social Media Icon Type
                  </div>
                  <FormControl>
                    <RadioGroup
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="rounded"
                          id="rounded-socialIconType"
                        />
                        <Label htmlFor="rounded-socialIconType">Rounded</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="square"
                          id="square-socialIconType"
                        />
                        <Label htmlFor="square-socialIconType">Square</Label>
                      </div>
                      {/* <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="letter"
                          id="letter-socialIconType"
                        />
                        <Label htmlFor="letter-socialIconType">Letter</Label>
                      </div> */}
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="word" id="word-socialIconType" />
                        <Label htmlFor="word-socialIconType">Word</Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Separator className="mt-4 mb-4 bg-primary-foreground" />
            <FormField
              name="socialIconSize"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Social Media Icon size</span>
                    <span>{field.value}px</span>
                  </div>
                  <FormControl>
                    <Slider
                      {...field}
                      min={14}
                      max={22}
                      step={1}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="mt-4 mb-4 bg-primary-foreground" />
            <FormField
              name="socialIconColor"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0 flex items-center">
                  <div className="whitespace-nowrap text-[14px]">
                    Background Color
                  </div>
                  <FormControl>
                    <ColorPicker
                      defaultValue={
                        signature?.design?.styles?.socialIcon?.style?.color ||
                        PRIMARY_COLOR
                      }
                      colorPickerWrapperClassName="justify-end mb-2"
                      color={field.value}
                      value={field.value}
                      onChange={(value) =>
                        field.onChange(`#${value.toHex().slice(0, 6)}`)
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="bg-white-0 mb-4 rounded pt-1 pb-1 px-2">
            <div className="bg-white-0 rounded mb-4 pb-1">
              <FormField
                name="font"
                control={form.control}
                render={({ field }) => (
                  <FormItem className="mb-0 h-full  items-center justify-between">
                    {/* <div className="mr-2">Font</div> */}
                    <div className="leading-[36px] text-[14px]">Font</div>
                    <FormControl>
                      <Select
                        value={field.value}
                        onValueChange={(val) => field.onChange(val)}
                        className="bg-white-0"
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select Your Font" />
                        </SelectTrigger>
                        <SelectContent>
                          {Fonts.map((item) => (
                            <SelectItem
                              value={item.value}
                              className="mb-0 h-full w-full"
                              key={item.label}
                            >
                              {item.label}
                              {!isFeaturesAllowed(FEATURE_KEYS.CUSTOM_FONT) &&
                                item.value !==
                                  'Arial, Helvetica, sans-serif' && (
                                  <svg
                                    fill="#d3a40c"
                                    height={16}
                                    width={16}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    className="ml-2"
                                  >
                                    <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
                                  </svg>
                                )}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              name="fontSize"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>
                      Font Size{' '}
                      {!isFeaturesAllowed(FEATURE_KEYS.CUSTOM_FONT) && (
                        <svg
                          fill="#d3a40c"
                          height={16}
                          width={16}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          className="ml-2"
                        >
                          <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
                        </svg>
                      )}
                    </span>

                    <span>{field.value}px</span>
                  </div>
                  <FormControl>
                    <Slider
                      {...field}
                      min={11}
                      max={18}
                      step={1}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="mt-4 mb-4 bg-primary-foreground" />
            <FormField
              name="headerFontSize"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>
                      Name Font Size{' '}
                      {!isFeaturesAllowed(FEATURE_KEYS.CUSTOM_FONT) && (
                        <svg
                          fill="#d3a40c"
                          height={16}
                          width={16}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          className="ml-2"
                        >
                          <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
                        </svg>
                      )}
                    </span>
                    <span>{field.value}px</span>
                  </div>
                  <FormControl>
                    <Slider
                      min={11}
                      max={20}
                      step={1}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="mt-4 mb-4 bg-primary-foreground" />
            <FormField
              name="lineHeight"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Line Height</span>
                    <span>{field.value}</span>
                  </div>
                  <FormControl>
                    <Slider
                      {...field}
                      min={0.8}
                      max={2.5}
                      step={0.01}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Separator className="mt-4 mb-3 bg-primary-foreground" />
            <FormField
              name="textColor"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0 flex items-center">
                  <div className="whitespace-nowrap text-[14px]">
                    Text Color
                    {!isFeaturesAllowed(FEATURE_KEYS.CUSTOM_FONT) && (
                      <svg
                        fill="#d3a40c"
                        height={16}
                        width={16}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        className="ml-2"
                      >
                        <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
                      </svg>
                    )}
                  </div>
                  <FormControl>
                    <ColorPicker
                      defaultValue={
                        signature?.design?.styles?.font?.style?.color ||
                        '#000000'
                      }
                      colorPickerWrapperClassName="justify-end mb-2"
                      color={field.value}
                      onChange={(value) =>
                        field.onChange(`#${value.toHex().slice(0, 6)}`)
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="bg-white-0 mb-4 rounded pt-1 pb-4 px-2">
            <FormField
              name="separatorType"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="leading-[36px] text-[14px]">
                    Separator Type
                  </div>
                  <FormControl>
                    <RadioGroup
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="none" id="none-separatorType" />
                        <Label htmlFor="none-separatorType">None</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="solid"
                          id="solid-separatorType"
                        />
                        <Label htmlFor="solid-separatorType">Solid</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="dashed"
                          id="dashed-separatorType"
                        />
                        <Label htmlFor="dashed-separatorType">Dashed</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="double"
                          id="double-separatorType"
                        />
                        <Label htmlFor="double-separatorType">Double</Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Separator className="mt-4 mb-3 bg-primary-foreground" />

            {/* Separator Width */}
            <FormField
              name="separatorWidth"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Separator Width</span>
                    <span>{field.value}px</span>
                  </div>
                  <FormControl>
                    <Slider
                      {...field}
                      min={1}
                      max={10}
                      step={1}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Separator className="mt-4 mb-3 bg-primary-foreground" />

            {/* Separator Color */}
            <FormField
              name="separatorColor"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0 flex items-center">
                  <div className="whitespace-nowrap text-[14px]">
                    Separator Color
                  </div>
                  <FormControl>
                    <ColorPicker
                      defaultValue={
                        signature?.design?.styles?.separator?.style?.color ||
                        PRIMARY_COLOR
                      }
                      colorPickerWrapperClassName="justify-end mb-2"
                      color={field.value}
                      value={field.value}
                      onChange={(value) =>
                        field.onChange(`#${value.toHex().slice(0, 6)}`)
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="bg-white-0 mb-4 rounded pt-1 pb-4 px-2">
            <FormField
              name="footerWidth"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    {/* <span>Footer Width</span> */}
                    <div className="leading-[36px] text-[14px]">
                      Footer Width
                    </div>
                    <div className="leading-[36px] text-[14px]">
                      {field.value}px
                    </div>
                  </div>
                  <FormControl>
                    <Slider
                      {...field}
                      min={100}
                      max={600}
                      step={1}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="mt-4 mb-3 bg-primary-foreground" />

            {/* Footer Color */}
            <FormField
              name="footerColor"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0 flex items-center">
                  <div className="whitespace-nowrap text-[14px]">
                    Footer Color
                  </div>
                  <FormControl>
                    <ColorPicker
                      defaultValue={
                        signature?.design?.styles?.footer?.style?.color ||
                        '#000000'
                      }
                      colorPickerWrapperClassName="justify-end mb-2"
                      color={field.value}
                      onChange={(value) =>
                        field.onChange(`#${value.toHex().slice(0, 6)}`)
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* <FormField
              name="footerFontSize"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Footer Font Size</span>
                    <span>{field.value}px</span>
                  </div>
                  <FormControl>
                    <Slider
                      {...field}
                      min={9}
                      max={15}
                      step={1}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            /> */}
          </div>
          <Button
            variant="outline"
            className="w-[50%]"
            disabled={!isDesignStyleChanged}
            onClick={resetToDefault}
          >
            Reset to Default
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default DesignControl;
