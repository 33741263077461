import React from 'react';

const YahooContent = () => (
  <div className="p-4">
    <div className="text-center mb-6">
      <h1 className="text-2xl font-semibold">
        Set Up Your Signature – Yahoo (Web Mail)
      </h1>
    </div>

    <div className="bg-gray-100 border border-gray-200 p-4 rounded-md mb-6 text-gray-800">
      <strong>WARNING:</strong> Unfortunately, Yahoo Mail makes on-the-fly
      adjustments to the images in your signature, which is out of our control.
      This can cause rendering issues in certain email clients if your images
      are being scaled down from their natural size. We've noticed that the
      following images are being scaled down:
      <ul className="list-disc pl-5 mt-2">
        <li>Profile Image (291px → 50px)</li>
        <li>Logo (600px → 100px)</li>
        <li>Call to Action Image (1172px → 521px)</li>
      </ul>
      <p className="mt-2">
        While the signature may look fine in the Yahoo Mail preview, recipients
        using Microsoft email clients such as Outlook 2019 may see images at
        their full size (example below). Please test your signature by sending
        it to a recipient who is using a Windows mail client. If the issue
        occurs, you can resolve it by uploading your images at the exact size
        they should appear in the signature (without scaling them down). A free
        online service like Pixlr can help you resize images appropriately.
      </p>
      <p className="mt-2">
        <strong>IMPORTANT:</strong> Once resized, your images will render
        correctly in Windows email clients; however, they may appear slightly
        blurry on tablets, phones, and other high-resolution screens. If you
        prefer not to compromise, we recommend using a different email client.
      </p>
    </div>

    <div className="mt-6 steps">
      <ol className="list-none p-0 m-0 space-y-6">
        <li className="flex items-start space-x-4">
          <span>
            Once logged into your Yahoo account, click the Settings Cog in the
            top right corner.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            With the dropdown open, click <strong>More Settings</strong>.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Click <strong>Writing Email</strong> from the settings options.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Click the <strong>+</strong> button at the bottom of the middle
            column.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Copy and paste your new email signature into the signature editor.
          </span>
        </li>
      </ol>

      <div className="bg-gray-100 border border-gray-200 p-4 rounded-md mt-6 text-gray-800">
        <strong>WARNING:</strong> If your signature does not appear in the
        preview on the right, move the text cursor in the signature editor to
        the top of the pane and press the enter key.
      </div>

      <ol className="list-none p-0 m-0 mt-6 space-y-6">
        <li className="flex items-start space-x-4">
          <span>
            Your signature should save automatically. Close the settings page,
            compose a new email, and enjoy your new signature!
          </span>
        </li>
      </ol>
    </div>
  </div>
);

export default YahooContent;
