import { Progress } from 'components/ui/progress';
import { Card, CardTitle } from 'components/ui/card';
import { MapPinIcon } from 'assets/svg';
import { progressBarPercent } from 'common/utils';
import CommonAnalyticsCard from 'components/CommonAnalyticsCard';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { WorldMap } from 'react-svg-worldmap';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';

function Region({
  hideCard,
  hideGraph,
  hideTable,
  analyticsData,
  getLinksLoading,
  analyticsLinksData,
  color,
}) {
  const countriesData = get(analyticsData, 'countries', {});
  const count = get(countriesData, 'count', null);
  const { count: tableCount, country: mapData, totalCountryCount } =
    analyticsLinksData?.countries || {};

  const CountryData = useMemo(() => {
    if (mapData) {
      return mapData?.map(({ countryCode = '', count: CountryCount = '' }) => ({
        country: countryCode,
        value: CountryCount,
      }));
    }
    return [];
  }, [mapData]);

  return (
    <div>
      <CommonAnalyticsCard label="Region" count={count} hidden={hideCard}>
        <MapPinIcon stroke={color} className="min-w-7" />
      </CommonAnalyticsCard>

      <div hidden={hideGraph}>
        <Card loading={getLinksLoading} className="shadow-none">
          <CardTitle className="font-primary text-h7 text-neutral-1000">
            Region
          </CardTitle>

          <div className="flex justify-center">
            {color && <WorldMap
              color={color}
              value-suffix="people"
              size="lg"
              data={CountryData}
            />}
          </div>
        </Card>
      </div>

      <div hidden={hideTable}>
        <Card loading={getLinksLoading} className="overflow-y-hidden ">
          <CardTitle className="font-primary text-h7 text-neutral-1000">
            Region: {tableCount}
          </CardTitle>

          <div className="w-full min-h-[200px]">
            <Table border="0px" scroll={{ y: 240 }} loading={false}>
              <TableHeader className="bg-[#f0f0f0] text-[#000000] font-bold">
                <TableRow>
                  <TableHead className="w-[30%] text-[#000000] font-bold">
                    Region
                  </TableHead>
                  <TableHead className="w-[25%] text-center text-[#000000] font-bold">
                    Visit
                  </TableHead>
                  <TableHead className="text-[#000000] font-bold">
                    Visit %
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {mapData?.map((items) => (
                  <TableRow key={items}>
                    <TableCell className="w-[30%]">{items?.country}</TableCell>
                    <TableCell className="w-[25%] text-center">
                      {items?.count}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Progress
                          value={progressBarPercent(
                            items?.count,
                            totalCountryCount,
                          )}
                          // size="small"
                          // status="normal"
                          // strokeColor={{
                          //   '0%': '#381e72',
                          //   '50%': '#7f56d9',
                          //   '100%': '#ba9df8',
                          // }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Region;
