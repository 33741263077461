import { Button as BaseButton } from 'components/ui/button';
import React from 'react';
import { cn } from 'lib/utils';
import classNames from 'classnames';

function Button(props) {
  const { children } = props;

  return (
    <BaseButton
      className={cn('bg-primary', classNames)}
      type="primary"
      {...props}
    >
      {children}
    </BaseButton>
  );
}

export default Button;
