/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
import { Button } from 'components/ui/button';
import { getLiveUrl } from 'common/utils';
import { useCreateLmCheckoutLink } from 'hooks/ai-headshot';
import React, { useEffect, useState } from 'react';
import { AI_MODELS, CHECKOUT_TYPES } from 'common/constants';
import {
  V1_MODELS,
  V2_MODELS,
  V2_PACKAGE_MODELS,
} from 'modules/AiHeadshot/constants';
import { Progress } from 'components/ui/progress';
import { usePosthogEvents } from 'hooks/posthog';
import { Download, Share2 } from 'lucide-react';
import { useAppContext } from 'AppContext';
import LoaderComponent from 'components/LoaderComponent';
import { cn } from 'lib/utils';
import ShareModal from '../ImageGrid/ImageCard/ShareModal';
import { delay } from '../HeadshotGenerator/HeadshotGenerator';

const AiHeadshotPreview = ({
  setStep,
  resultComponent,
  preview,
  image,
  topResult,
  handleProccessModel,
  status,
  fetchIntialHeadshots,
}) => {
  const {
    state: { aiHeadshot },
  } = useAppContext();
  const [open, setOpen] = useState(false);
  const [hiddenPics, setHiddenPics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(13);

  const { url } = useCreateLmCheckoutLink(CHECKOUT_TYPES.OVERLAY_LINK);
  const { capture } = usePosthogEvents();

  const processModelsOnSuccess = async () => {
    const allModels = [...V1_MODELS, ...V2_MODELS];
    const prevGender = allModels?.find(
      (model) => model?.prompt_set === aiHeadshot?.image?.prompt_set,
    )?.gender;
    const prevModel = aiHeadshot?.image?.model;

    const genderLocal = prevGender || localStorage.getItem('gender');
    const modelLocal = prevModel || localStorage.getItem('model');
    const versionModel =
      modelLocal === AI_MODELS.v1 ? V1_MODELS : V2_PACKAGE_MODELS;
    const modelBasedOnGender = versionModel.filter(
      (m) => m.gender === genderLocal,
    );
    let hiddenPicsRes = [];
    if (modelLocal === AI_MODELS.v1) {
      hiddenPicsRes = [
        modelBasedOnGender?.[2],
        modelBasedOnGender?.[5],
        modelBasedOnGender?.[7],
      ];
    } else {
      hiddenPicsRes = [...modelBasedOnGender];
    }
    setLoading(true);
    window.createLemonSqueezy();
    LemonSqueezy.Url.Close();
    setProgress(30);
    await handleProccessModel(hiddenPicsRes);
    setProgress(99);
    await delay(2000);
    setLoading(false);
    await fetchIntialHeadshots();
    setStep(4);
  };

  const genderLocal = localStorage.getItem('gender') ?? 'male';
  const modelLocal = localStorage.getItem('model') ?? AI_MODELS.v1;

  useEffect(() => {
    const versionModel =
      modelLocal === AI_MODELS.v1 ? V1_MODELS : V2_PACKAGE_MODELS;
    const modelBasedOnGender = versionModel.filter(
      (m) => m.gender === genderLocal,
    );
    let hiddenPicsRes = [];
    if (modelLocal === AI_MODELS.v1) {
      hiddenPicsRes = [
        modelBasedOnGender?.[2],
        modelBasedOnGender?.[5],
        modelBasedOnGender?.[7],
      ];
    } else {
      hiddenPicsRes = [...modelBasedOnGender];
    }

    setHiddenPics(hiddenPicsRes);
  }, [modelLocal, genderLocal]);

  useEffect(() => {
    window.createLemonSqueezy();
    LemonSqueezy.Setup({
      eventHandler: (event) => {
        // eslint-disable-next-line no-console
        console.log('event', event);
        if (event.event === 'Checkout.Success') {
          processModelsOnSuccess();
          capture('Buys a package plan');
        }
        // Do whatever you want with this event data
      },
    });
  }, []);

  const prevModel = aiHeadshot?.image?.model;

  useEffect(() => {
    const modelLocal = prevModel || localStorage.getItem('model');
    if (modelLocal) {
      localStorage.setItem('model', modelLocal);
    }
  }, [prevModel]);

  const handleDownload = async (url) => {
    const imageUrl = getLiveUrl(url);
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `ai-headshot-${new Date().getTime()}.jpeg`;
    capture('Downloads free photo');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const previousImages = aiHeadshot?.previousImages ?? [];

  const isPendingImages = previousImages?.some(
    (image) => image.status === 'pending',
  );

  if (loading) {
    return (
      <div className="w-full overflow-hidden h-[750px] bg-[transparent] rounded-lg shadow-lg  flex items-center justify-center">
        <div className="flex flex-col gap-4   w-full items-center justify-center py-2">
          <p className="text-white-0 text-center text-[32px] font-[700] sm:text-[24px] ">
            Generating the best headshot for you! This may take a few minutes
            ...
          </p>
          <Progress value={progress} className="w-[60%] sm:h-[10px]" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center flex-col w-full">
      <div className="overflow-auto text-center h-[calc(100dvh_-_172px)]  relative w-full max-w-[1440px]  mx-auto  ">
        <div className="flex items-center flex-col py-[24px]">
          <div className="relative max-w-[209px] h-auto leading-[12px]">
            {topResult}
            <div className=" absolute  left-[180px] top-[100px] h-[44px] max-w-[271px] flex flex-col justify-center items-center gap-[16px]">
              <Button
                variant="outline"
                disabled={status !== 'completed'}
                onClick={() => {
                  capture('Shares free photo');
                  setOpen(true);
                }}
              >
                PREVIEW
              </Button>
              <Button
                disabled={status !== 'completed'}
                onClick={() => handleDownload(image)}
              >
                DOWNLOAD
              </Button>
            </div>
          </div>

          <div className="bg-black p-6 sm:p-[20px] rounded-lg shadow-lg text-center">
            <h1 className="text-white-0 text-[30px] sm:text-[20px] font-bold my-2 sm:my-0">
              Buy professional photos in different styles
            </h1>
            <p className="text-[#AAAAAA] text-[20px] md:text-[14px]  my-2 sm:my-0">
              Get 4 photos in different styles and professional background.
            </p>
          </div>

          {isPendingImages && <LoaderComponent />}
          <div
            className={cn(
              'w-full gap-2 mt-6 sm:mt-0 h-auto flex flex-wrap items-center justify-center',
              { 'opacity-0 h-[0px] overflow-hidden': isPendingImages },
            )}
          >
            {resultComponent}
            {!!hiddenPics.length &&
              hiddenPics?.map((image) => (
                <img
                  key={image.id}
                  src={image.exampleImage}
                  alt={image.prediction_key}
                  className="w-full max-w-[150px] blur-sm "
                  style={{
                    aspectRatio: modelLocal === AI_MODELS.v1 ? '1/1' : '2/3',
                    objectFit: 'contain',
                  }}
                />
              ))}
          </div>
        </div>
      </div>
      <ShareModal
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        url={image}
        input={preview}
      />
      <div className=" flex  w-full  justify-center items-center  h-[100px]  border-solid border-0 border-t bg-[#141414] border-[#727272]">
        <Button
          className=""
          onClick={() => {
            capture('Clicks on DOWNLOAD ALL package buy button');
          }}
        >
          <a
            href={url}
            className="lemonsqueezy-button no-underline text-white-0 bg-[transparent] text-[16px] sm:text-[14px]"
          >
            BUY ALL PHOTOS
          </a>
        </Button>
      </div>
    </div>
  );
};

export default AiHeadshotPreview;
