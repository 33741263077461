/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
import * as React from 'react';

import { cn } from 'lib/utils';

const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        // 'flex rounded-md border w-full border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        'rounded focus:border-primary px-3 py-2 text-[14px] bg-background w-[calc(100%-30px)] border-solid border-1 border-gray-0  hover:border-primary focus:outline-none &>span]:line-clamp-1 ring-offset-background placeholder:text-muted-foreground  focus:ring-2 focus:ring-ring focus:ring-offset-1 disabled:cursor-not-allowed',

        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
