import { useLazyQuery } from '@apollo/client';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { GET_ALL_ROLES } from 'modules/Teammates/graphql/Queries';

/* eslint-disable import/prefer-default-export */
export const useGetAllRoles = () => {
  const { setRoles } = useWorkspaceContext();
  const [getAllRoles, states] = useLazyQuery(GET_ALL_ROLES, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setRoles(res?.getAllRoles?.data ?? []);
    },
    onError() {
      // eslint-disable-next-line no-undef
    },
  });

  return [getAllRoles, states];
};
