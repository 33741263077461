/* eslint-disable import/prefer-default-export */
import React from 'react';
import TemplateStyleOne from '../Templates/TemplateStyleOne';
import TemplateStyleTwo from '../Templates/TemplateStyleTwo';
import TemplateStyleThree from '../Templates/TemplateStyleThree';
import TemplateStyleFour from '../Templates/TemplateStyleFour';
import TemplateStyleFive from '../Templates/TemplateStyleFive';
import TemplateStyleSix from '../Templates/TemplateStyleSix';
import TemplateStyleSeven from '../Templates/TemplateStyleSeven';
import TemplateStyleEight from '../Templates/TemplateStyleEight';
import TemplateStyleNine from '../Templates/TemplateStyleNine';
import TemplateStyleTen from '../Templates/TemplateStyleTen';
import TemplateStyleEleven from '../Templates/TemplateStyleEleven';
import TemplateStyleTwelve from '../Templates/TemplateStyleTwelve';
import TemplateStyleThirteen from '../Templates/TemplateStyleThirteen';
import TemplateStyleFourteen from '../Templates/TemplateStyleFourteen';
import TemplateStyleFifthteen from '../Templates/TemplateStyleFifthteen';
import TemplateStyleSixteen from '../Templates/TemplateStyleSixteen';
import TemplateStyleSeventeen from '../Templates/TemplateStyleSeventeen';
import TemplateStyleEighteen from '../Templates/TemplateStyleEighteen';
import TemplateStyleNineteen from '../Templates/TemplateStyleNineteen';
import TemplateStyleTwenty from '../Templates/TemplateStyleTwenty';
import TemplateStyleTwentyOne from '../Templates/TemplateStyleTwentyOne';
import TemplateStyleTwentyTwo from '../Templates/TemplateStyleTwentyTwo';
import TemplateStyleTwentyThree from '../Templates/TemplateStyleTwentyThree';
import TemplateStyleTwentyFour from '../Templates/TemplateStyleTwentyFour';
import TemplateStyleTwentyFive from '../Templates/TemplateStyleTwentyFive';
import TemplateStyleTwentySix from '../Templates/TemplateStyleTwentySix';
import TemplateStyleTwentySeven from '../Templates/TemplateStyleTwentySeven';
import TemplateStyleThirty from '../Templates/TemplateStyleThirty';
import TemplateStyleThirtyTwo from '../Templates/TemplateStyleThirtyTwo';
import TemplateStyleThirtyThree from '../Templates/TemplateStyleThirtyThree';
import TemplateStyleThirtyFour from '../Templates/TemplateStyleThirtyFour';

const templateComponents = {
  TEMPLATE_1: TemplateStyleOne,
  TEMPLATE_2: TemplateStyleTwo,
  TEMPLATE_3: TemplateStyleThree,
  TEMPLATE_4: TemplateStyleFour,
  TEMPLATE_5: TemplateStyleFive,
  TEMPLATE_6: TemplateStyleSix,
  TEMPLATE_7: TemplateStyleSeven,
  TEMPLATE_8: TemplateStyleEight,
  TEMPLATE_9: TemplateStyleNine,
  TEMPLATE_10: TemplateStyleTen,
  TEMPLATE_11: TemplateStyleEleven,
  TEMPLATE_12: TemplateStyleTwelve,
  TEMPLATE_13: TemplateStyleThirteen,
  TEMPLATE_14: TemplateStyleFourteen,
  TEMPLATE_15: TemplateStyleFifthteen,
  TEMPLATE_16: TemplateStyleSixteen,
  TEMPLATE_17: TemplateStyleSeventeen,
  TEMPLATE_18: TemplateStyleEighteen,
  TEMPLATE_19: TemplateStyleNineteen,
  TEMPLATE_20: TemplateStyleTwenty,
  TEMPLATE_21: TemplateStyleTwentyOne,
  TEMPLATE_22: TemplateStyleTwentyTwo,
  TEMPLATE_23: TemplateStyleTwentyThree,
  TEMPLATE_24: TemplateStyleTwentyFour,
  TEMPLATE_25: TemplateStyleTwentyFive,
  TEMPLATE_26: TemplateStyleTwentySix,
  TEMPLATE_27: TemplateStyleTwentySeven,
  TEMPLATE_30: TemplateStyleThirty,
  TEMPLATE_32: TemplateStyleThirtyTwo,
  TEMPLATE_33: TemplateStyleThirtyThree,
  TEMPLATE_34: TemplateStyleThirtyFour,
};

export const dynamicTemplate = ({ templateKey }) => {
  const TemplateComponent = templateComponents?.[templateKey] ?? null;
  return TemplateComponent;
};
