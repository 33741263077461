import React, { useState } from 'react';
import Dragger from 'components/Dragger';
import { Button } from 'components/ui/button';
import { ArrowRight, ChevronLeft } from 'lucide-react';
import { UploadIconSVG } from 'assets/svg';
import { usePosthogEvents } from 'hooks/posthog';
import UploadGuide from '../UploadGuide';

const ImageUpload = ({ image, setImage, setStep }) => {
  const [files, setFiles] = useState([]);
  const { capture } = usePosthogEvents();
  const isDisabled = !image;

  const uploadGuide = (
    <div className="py-4">
      <div className=" flex items-center flex-col gap-[24px] px-[56px] py-[20px] ">
        <UploadIconSVG />
        <div className="text-[#AAAAAA] font-[400] text-[18px] leading-[28px] font-primary sm:text-[14px]">
          Click or drag a photo to upload
        </div>
      </div>
      <Button size="sm" className="mt-[20px]">
        Upload image
      </Button>
    </div>
  );

  const dragActiveClass = (
    <div className=" flex items-center flex-col gap-5  max-w-[600px]  w-full h-full py-[32px]">
      <UploadIconSVG />
      <div className="text-[#AAAAAA] font-[400] text-[18px] leading-[28px] font-primary">
        Drop the files here ...
      </div>
    </div>
  );

  return (
    <div className="w-full ">
      <div className=" overflow-auto h-[calc(100dvh_-_172px)] relative w-full max-w-[1440px]  mx-auto">
        <div className="py-[24px] ">
          <div className="w-full align-middle">
            <div className="relative">
              <div
                className="absolute sm:relative sm:w-full sm:mb-4 left-0 text-white-0 leading-[16px] font-[500] text-[16px] flex items-center cursor-pointer justify-start"
                onClick={() => setStep(1)}
              >
                <ChevronLeft size={20} className="mr-2" />
                back
              </div>
              <div className="text-white-0 font-bold text-[30px]  font-primary text-center sm:text-[20px] mx-2">
                Step 2 : Upload your best photo
              </div>
            </div>

            <div className="text-[#AAAAAA] font-[500] text-[20px]  mt-[8px] font-primary text-center sm:text-[14px] sm:mt-0 ">
              Choose front photo with clear facial expressions
            </div>

            <Dragger
              files={files}
              setFiles={setFiles}
              setStep={setStep}
              setImage={(img) => {
                capture('Uploads a photo');
                setImage(img);
              }}
              dragActiveClass={dragActiveClass}
              DraggerClass="bg-primary-foreground w-[max-content]  text-gray-400 font-bold bg-[transparent] border-none hover:border-none hover:bg-[#4A4061] transition ease-in-out delay-1 "
              wrapperClassName="bg-[transparent] mt-[10px] flex justify-center items-center"
              placeholder={uploadGuide}
              image={image}
            />
          </div>
          <div className="w-full flex justify-center">
            <div className="w-[fit-content] mx-2">
              <UploadGuide />
            </div>
          </div>
        </div>
      </div>

      <div className=" flex justify-center items-center  h-[100px]  border-solid border-0 border-t bg-[#141414] border-[#727272]">
        <div className="flex flex-col gap-3">
          <Button
            onClick={() => {
              setStep(3);
            }}
            disabled={isDisabled}
            {...(isDisabled ? { variant: 'ghost' } : {})}
            className="h-[44px] w-[164px]"
          >
            Continue <ArrowRight size={20} className="ml-[6px]" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
