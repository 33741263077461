/* eslint-disable arrow-body-style */
import { useTheme } from 'hooks/theme';
import React from 'react';

const WorkspaceTemplateSidebar = ({ activeTab, setActiveTab, items }) => {
  const { color, foregroundColor } = useTheme();
  return (
    <div
      style={{
        width: 100,
        backgroundColor: '#fff',
        borderRight: '1px solid #f0f0f0',
      }}
    >
      {items.map(({ icon: Icon, label, key }) => {
        const onClick = () => {
          setActiveTab(key);
        };

        const isActive = activeTab === key;

        return (
          <div
            style={{
              padding: '10px',
              cursor: 'pointer',
              ...(isActive
                ? {
                    backgroundColor: foregroundColor,
                    borderLeft: `4px solid ${color}`,
                  }
                : {}),
            }}
            key={key}
            onClick={onClick}
          >
            <div>
              <center style={{ marginBottom: '8px' }}>
                <Icon fill={isActive ? color : '#7a7289'} />
              </center>
              <center>
                <div style={{ color: isActive ? color : '#7a7289' }}>
                  {label}
                </div>
              </center>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WorkspaceTemplateSidebar;
