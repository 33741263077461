import { gql } from '@apollo/client';

export const HEAD_SHOT_SIGNED_URL = gql`
  query GetHeadshotUploadSignedUrl($data: GetHeadshotUploadSignedUrlInput!) {
    getHeadshotUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;

export const HADSHOT_QUERY = gql`
  query Headshot($where: HeadshotUniqueWhereInput!) {
    headshot(where: $where) {
      id
      image
      config
      styleId
      style {
        id
        name
        image
        key
        description
        isActive
      }
    }
  }
`;

export const GET_STYLES = gql`
  query Styles($pagination: Pagination) {
    styles(pagination: $pagination) {
      data {
        id
        name
        image
        key
        description
        isActive
      }
      count
    }
  }
`;
