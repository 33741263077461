import { useAppContext } from 'AppContext';
import { Dialog, DialogContent } from 'components/ui/dialog';
import React from 'react';
import RestrictedContentCard from './RestrictedContentCard';

function SubscriptionModal() {
  const {
    state: { showSubscriptionModal },
    handleSubscriptionModal,
  } = useAppContext();

  return (
    <div>
      {showSubscriptionModal ? (
        <Dialog
          open={showSubscriptionModal}
          onOpenChange={() => {
            handleSubscriptionModal(false);
          }}
        >
          <DialogContent hideCloseButton className=" w-[auto]">
            {/* <SubscriptionPlans /> */}
            <RestrictedContentCard
              hideTrial
              wrapperClassName="h-[max-content]"
              cardClassName="no-shadow"
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
}

export default SubscriptionModal;
