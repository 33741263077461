import Button from 'components/Button';
import { useRouter } from 'hooks/router';
import React from 'react';
import { get } from 'lodash';
import { useFetchWorkspaces } from 'hooks/workspace';

const Main = () => {
  const { navigate } = useRouter();
  const [fetchWorkspaces] = useFetchWorkspaces();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-primary-foreground">
      <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
      <h2 className="text-2xl font-semibold text-gray-600 mb-4">
        Page Not Found
      </h2>
      <p className="text-lg text-gray-500 mb-8">
        Sorry, the page you're looking for doesn't exist.
      </p>
      <Button
        onClick={async () => {
          const res = await fetchWorkspaces();
          const [workspace] = get(res, 'data.getWorkspaces.data', []);

          const workspaceId = workspace?.id;
          navigate(`/app/workspace/${workspaceId}/assets`);
        }}
      >
        Go to Homepage
      </Button>
    </div>
  );
};

export default Main;
