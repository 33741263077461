/* eslint-disable react/no-array-index-key */
import { useAppContext } from 'AppContext';
import { AI_MODELS } from 'common/constants';
import { isImgUrl } from 'common/utils';
import LoaderComponent from 'components/LoaderComponent';
import { get } from 'lodash';
import { Check, Lock } from 'lucide-react';
import {
  V1_MODELS,
  V2_MODELS,
} from 'modules/AiHeadshot/constants';
import React, { useState } from 'react';

export const handleImgagesUrl = async (id) => {
  const url = `https://static.dev.sendsig.com/pose-images/${id}.jpeg`;
  const res = await isImgUrl(url);
  if (res) {
    return url;
  }
  return null;
};

const MODELS = {
  [AI_MODELS.v1]: V1_MODELS,
  [AI_MODELS.v2]: V2_MODELS,
};

const Models = ({
  selectedModels,
  setSelectedModels,
  model = AI_MODELS.v1,
  gender,
}) => {
  const {
    state: { aiHeadshot },
  } = useAppContext();

  const isPaid = get(aiHeadshot, 'isPaid', false);

  const [loading, setLoading] = useState(false);

  const models = MODELS[model] || [];

  function isModelSelected(data) {
    return selectedModels?.some((model) => model?.id === data?.id);
  }

  const handleSelect = (data) => {
    if (isModelSelected(data)) {
      return setSelectedModels([]);
    }
    setSelectedModels([data]);
  };

  return (
    <div>
      <h3 className="font-medium text-center text-darkblue mb-2">
        Select your style
      </h3>
      {loading ? (
        <div className="flex gap-4 overflow-x-auto max-w-[800px] w-full items-center justify-center py-2 max-h-[400px]">
          <LoaderComponent classNames="w-full h-full" />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-4 sm:grid-cols-2 gap-3">
            {models
              .filter((m) => m.gender === gender)
              .map((data, index) => (
                <div key={index} className="">
                  <div
                    className="relative group rounded-[6px] cursor-pointer"
                    data-selected="false"
                  >
                    <div className="rounded-[10px] bg-red-500">
                      <img
                        alt={`Preview of pose ${data.id}`}
                        className="h-full w-full max-w-[162px] object-cover rounded-[8px] transition-all"
                        src={data.exampleImage}
                        style={{
                          objectFit: 'contain',
                          aspectRatio: model === AI_MODELS.v1 ? '1/1' : '2/3',
                        }}
                        onClick={() => {
                          if (data.shouldLock && !isPaid) return;
                          handleSelect(data);
                        }}
                      />
                      {data.shouldLock && !isPaid && (
                        <div className="absolute inset-0 bg-[black] bg-opacity-50 flex items-center justify-center rounded-[8px] group-hover:bg-opacity-75 transition-all">
                          <Lock className="h-5 w-5" color="#ffffff" />
                        </div>
                      )}
                    </div>
                    {isModelSelected(data) && (
                      <div className="absolute top-[-4px] left-[-4px] border-2 rounded-[10px] border-solid border-primary  w-[calc(100%_+_4px)] h-[calc(100%_+_1px)] z-10">
                        <div className="absolute bottom-4 right-4 h-6 w-6 flex items-center justify-center bg-primary text-[white] rounded-full z-10">
                          <Check className="h-5 w-5" color="#ffffff" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Models;
