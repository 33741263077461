import React, { useEffect } from 'react';
import { useRedirect } from 'hooks/auth';
import LoaderComponent from 'components/LoaderComponent';

const Redirect = () => {
  const { redirectUser } = useRedirect();

  useEffect(() => {
    redirectUser();
  }, []);

  return <LoaderComponent />;
};

export default Redirect;
