import React, { useEffect, useMemo, useState } from 'react';
import LoaderComponent from 'components/LoaderComponent';
import { cn } from 'lib/utils';
import { Progress } from '@radix-ui/react-progress';
import AiHeadshotPreview from '../AiHeadshotPreview';
import ImageGrid from '../ImageGrid';

const Results = ({
  previousImages = [],
  getPrediction,
  setStep,
  handleProccessModel,
  fetchIntialHeadshots,
}) => {
  const [dotCount, setDotCount] = useState(0);

  // Check if there are pending images
  const isPendingImages = useMemo(
    () => previousImages.some((image) => image.status === 'pending'),
    [previousImages],
  );

  // Clone of previousImages for rendering purposes
  const previousImagesClone = useMemo(
    () => [...previousImages],
    [previousImages],
  );

  // Dot animation for pending state
  useEffect(() => {
    if (isPendingImages) {
      const interval = setInterval(() => {
        setDotCount((prevCount) => (prevCount + 1) % 4);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [isPendingImages]);

  const dots = '.'.repeat(dotCount);

  return (
    <div className="w-full">
      {isPendingImages && (
        <div className="w-full overflow-hidden h-[750px] bg-[transparent] rounded-lg shadow-lg  flex items-center justify-center">
          <div className="flex flex-col gap-4 px-5  w-full items-center justify-center py-2">
            <p className=" text-white-0 text-[32px] font-[700] sm:text-[24px]">
              Generating the best headshot for you! This may take a few minutes{' '}
              <span className="w-[30px] inline-block">{dots}</span>
            </p>
          </div>
        </div>
      )}
      <div
        className={cn({
          'opacity-0 h-[0px] overflow-hidden': isPendingImages,
        })}
      >
        <AiHeadshotPreview
          setStep={setStep}
          preview={previousImages?.slice?.(-1)?.[0]?.input}
          status={previousImages?.slice?.(-1)?.[0]?.status}
          image={previousImages?.slice?.(-1)?.[0]?.url}
          handleProccessModel={handleProccessModel}
          fetchIntialHeadshots={fetchIntialHeadshots}
          topResult={
            <div>
              {previousImages?.slice(-1)?.length && (
                <ImageGrid
                  previousImages={previousImages?.slice(-1)}
                  getPrediction={getPrediction}
                  warpperClassNames="flex justify-center flex-wrap"
                  removeoverlay
                  isSolo
                />
              )}
            </div>
          }
          resultComponent={
            <div>
              {previousImagesClone?.slice(-1)?.length && (
                <ImageGrid
                  previousImages={previousImages?.slice(-1)}
                  getPrediction={getPrediction}
                  warpperClassNames="flex justify-center flex-wrap"
                  removeoverlay
                  isSolo
                />
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Results;
