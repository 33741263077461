import React, { useEffect } from 'react';

import { useGetUser, useGetUserSignatureDetails } from 'hooks/user';
import UserSignatureInfo from 'modules/Profile/components/UserSignatureInfo';
import WorkspaceSignatureTable from 'modules/Profile/components/WorkspaceSignatureTable';

function Account() {
  const { userWorkspaces } = useGetUserSignatureDetails();
  const [getUser] = useGetUser();

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="bg-primary-foreground h-[calc(100vh_-_71px)] ">
      <div className="p-[32px]">
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[40px] flex justify-center">
          Account
        </div>
        <div className="items-start  flex justify-center gap-[25px]">
          <div className="w-full max-w-[1174px] rounded-[8px] p-6 bg-white-0">
            <UserSignatureInfo />
            {userWorkspaces && (
              <WorkspaceSignatureTable data={userWorkspaces} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
