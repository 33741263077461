import React from 'react';
import { get } from 'lodash';
import { TEXT_IDS } from 'common/constants';

function CompanyName({ style = {}, tdProps = {}, signature = {}, data }) {
  const { font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});
  // const defaultTextColor = color;
  const textStyle = {
    fontSize,
    lineHeight,
    color,
  };

  return (
    <td
      id={TEXT_IDS.COMPANY}
      style={{
        fontStyle: 'normal',
        fontWeight: 400,
        paddingBottom: '2px',
        ...textStyle,
        ...style,
      }}
      {...tdProps}
    >
      {data?.value}
    </td>
  );
}

export default CompanyName;
