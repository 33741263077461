import React from 'react';
import { useFreeTrial } from 'hooks/user';
import { Label } from 'components/ui/label';
import SubscriptionPlans from './components/SubscriptionPlans';
import FreeToTeamPlan from './components/SubscriptionPlans/components/FreeToTeamPlan';

function SubscriptionPage() {
  // Get the active plan ID
  // List of LTD plans
  const { isPlanExpired } = useFreeTrial();

  return (
    <div className="bg-primary-foreground overflow-y-visible max-h-[max-content] h-full">
      <div className="p-[32px]">
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[15px] flex justify-center">
          <div className="mr-4">Plans</div>
        </div>
        {isPlanExpired && (
          <div>
            <div className="flex justify-center mb-6">
              <Label>Your Plan has expired</Label>
            </div>
            <FreeToTeamPlan />
          </div>
        )}
        {/* {isPlanFree && <FreeToTeamPlan />} */}
        {!isPlanExpired && <SubscriptionPlans />}
      </div>
    </div>
  );
}

export default SubscriptionPage;
