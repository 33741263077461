/* eslint-disable no-nested-ternary */
import { socialImages } from 'assets/social-images';
import { TEXT_IDS } from 'common/constants';
import WithImageConversion from 'common/helpers/htmlToImage';
import { handleUrlRedirection, removeHttpPrefix } from 'common/utils';
import { get } from 'lodash';
import React from 'react';

const WebsiteTypeWrapper = ({
  type,
  styles,
  iconDimensions,
  children,
  signature,
  showAwsIcons,
}) => {
  const { fontSize } = styles;
  const child = {
    letter: 'W.',
    word: 'Website.',
    rounded: children,
    square: children,
    none: '',
  };

  const updatedStyle = {
    letter: {
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: '100%',
      maxWidth: '100%',
      fontSize,
    },
    word: {
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: '100%',
      maxWidth: '100%',
      fontSize,
    },
    rounded: { borderRadius: '50%', ...iconDimensions },
    square: { borderRadius: '5px', ...iconDimensions },
    none: { display: 'none' },
  };

  const content = ['letter', 'word'].includes(type) ? (
    <span
      id={TEXT_IDS.WEBSITEWORD}
      style={{
        ...styles,
        ...(updatedStyle[type] || {}),
        margin: '0px',
        padding: '0px',
      }}
    >
      {child[type] || ''}
    </span>
  ) : (
    <p
      style={{
        ...styles,
        ...(updatedStyle[type] || {}),
        margin: '0px',
        padding: '0px',
      }}
    >
      {child[type] || ''}
    </p>
  );

  // Only wrap WithImageConversion for 'rounded' and 'square' types
  if (['rounded', 'square'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key="website"
        fileName="website"
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};
function Website({
  design,
  signature = {},
  style = {},
  tdProps = {},
  data = {},
  showAwsIcons = false,
}) {
  const { icon, font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});
  const type = get(icon, 'type', 'rounded');
  const size = fontSize + 4;

  const textStyle = {
    fontSize,
    lineHeight:
      type === 'letter' || type === 'word' || type === 'none' ? lineHeight : '',
    color: style?.color || color,
  };
  const link =
    data?.utagEnabled && data?.shortLink
      ? data?.shortLink
      : handleUrlRedirection(data?.value);

  return (
    <td
      style={{
        ...style,
        alignItems: 'center',
        paddingBottom: style?.paddingBottom || '6px',
        paddingTop: style?.paddingTop || '0px',
        paddingRight: style?.paddingRight || '0px',
        paddingLeft: style?.paddingLeft || '0px',
      }}
      {...tdProps}
    >
      <span
        style={{
          verticalAlign: 'middle',
        }}
      >
        <WebsiteTypeWrapper
          signature={signature}
          showAwsIcons={showAwsIcons}
          type={type}
          iconDimensions={{ height: size, width: size }}
          styles={{
            height: type === 'letter' || type === 'word' ? 'auto' : '16px',
            width: '16px',
            fontSize,
            backgroundColor: design?.primaryBrandColor,
          }}
        >
          <img
            src={socialImages['links-circle']}
            alt="website"
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        </WebsiteTypeWrapper>
      </span>
      <a
        id={TEXT_IDS.WEBSITE}
        href={link}
        style={{
          ...textStyle,
          textAlign: 'left',
          textDecoration: 'none',
          paddingLeft: type === 'none' ? '0px' : '4px',
          // whiteSpace: 'nowrap',
          verticalAlign: 'middle',
        }}
      >
        {removeHttpPrefix(data.value)}
      </a>
    </td>
  );
}

export default Website;
