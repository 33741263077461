// components/HelpButton.js
import React, { useState } from 'react';
import { HelpCircle } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from 'components/ui/button';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'components/ui/command';
import Divider from 'components/TemplatesModel/Templates/components/Divider';

const Helper = () => {
  const [open, setOpen] = useState(false);

  // Function to open the email client
  const handleContactUs = () => {
    window.open(
      'mailto:support@syncsignature.com?subject=Subscription%20Inquiry',
      '_blank',
    );
  };

  // Function to open external links
  const openExternalLink = (url) => {
    window.open(url, '_blank');
  };

  const items = [
    {
      key: 'helpArticles',
      label: 'Help Articles',
      onClick: () => openExternalLink('https://syncsignature.com/docs/'),
    },
    {
      key: 'scheduleCall',
      label: 'Schedule a Call',
      onClick: () =>
        openExternalLink('https://calendly.com/syncsignature/30min'),
    },
    {
      key: 'support',
      label: 'Email to Support Team',
      onClick: handleContactUs,
    },
  ];

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="text"
          className="flex items-center gap-x-2 cursor-pointer mb-3 py-5 px-7"
        >
          <HelpCircle size={18} stroke="#7a7289" />
          <span className="text-lg font-medium" style={{ color: '#7a7289' }}>
            Get Support
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[204px] rounded-lg border border-gray-200 p-0">
        <Command className="text-sm">
          <CommandList>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem key={item.key} onSelect={item.onClick}>
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
          <Divider />
          <CommandSeparator />
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default Helper;
