import { Eye } from 'lucide-react';
import { EyeCloseIcon } from 'assets/svg';
import { Input } from 'components/ui/input';
import React, { useState } from 'react';

const Password = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className='relative' >
      <Input {...props} type={showPassword ? 'text' : 'password'} />
      <span
        variant="text"
        onClick={() => setShowPassword(!showPassword)}
        className="cursor-pointer absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-500"
      >
        {showPassword ? <Eye /> : <EyeCloseIcon />}
      </span>
    </div>
  );
};

export default Password;
