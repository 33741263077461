import { useMutation } from '@apollo/client';
import { toast } from 'hooks/use-toast';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React, { useRef, useState } from 'react';
import SignupCover from 'assets/images/signup-cover.png';
import { useRouter } from 'hooks/router';
import { JOURNEYS, REGEX } from 'common/constants';
import { Loader2, ChevronRight } from 'lucide-react';
import darkLogo from 'assets/png/logo-dark.png';
import aiSignup from 'assets/images/ai-headhsot-signup.jpg';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import { useUserJourney } from 'hooks/auth';
import { Password } from 'components/Inputs';
import { useBrandContext } from 'context/BrandContext';
import { RESET_PASSWORD } from './graphql/Mutations';

const formSchema = z.object({
  newPassword: z.string().min(1, { message: 'Password is required.' }),
  confirmPassword: z
    .string()
    .min(1, { message: 'Confirm password is required.' }),
});

const ResetPassword = () => {
  const {
    state: { brand },
  } = useBrandContext();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { password: '' },
  });
  const { location, navigate } = useRouter();
  const matchWithNewPassword = useRef();
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const { journey } = useUserJourney();

  const [resetPassword, { loading: resetLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted() {
        setIsPasswordChange(true);
      },
    },
  );
  const params = new URLSearchParams(location?.search);
  const token = params?.get('token');
  const uid = params?.get('uid');

  const onFinish = async ({ newPassword, confirmPassword }) => {
    try {
      if (newPassword === confirmPassword) {
        await resetPassword({
          variables: {
            data: {
              password: newPassword,
              token,
              uid,
            },
          },
        });
      } else {
        // message.error('New & confirm password does not match');
        toast({
          closeicn: 'destructive',
          description: 'New & confirm password does not match',
        });
        matchWithNewPassword?.current?.focus({
          cursor: 'all',
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="flex min-h-screen h-full max-h-screen">
      <div
        className={cn(
          'flex flex-col justify-center w-full bg-primary-foreground p-[16px] overflow-auto',
          { 'bg-[#0A0B0C]': journey === JOURNEYS.AI_HEADSHOT },
        )}
      >
        <div className="w-full max-w-[600px] mx-[auto] flex flex-col items-center">
          {!isPasswordChange ? (
            <>
              <img
                src={
                  journey === JOURNEYS.AI_HEADSHOT
                    ? darkLogo
                    : brand?.logo_url || '/logo.svg'
                }
                height={40}
                width={200}
                alt="logo"
              />
              <h1
                level={1}
                className="text-primary font-primary  text-[32px] font-semibold leading-[36px] mt-8 mb-0"
              >
                Change password
              </h1>
              <p className="text-secondary-500 text-[14px] font-medium leading-[25px] mb-9 mt-2">
                Reset your password and regain access to {brand?.name}.
              </p>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onFinish)}
                  form={form}
                  className="space-y-[27px] w-full"
                >
                  <FormField
                    control={form.control}
                    name="newPassword"
                    render={({ field }) => (
                      <FormItem className=" font-medium  text-[12px]">
                        <FormLabel
                          className={
                            (cn(' text-darkblue'),
                            {
                              ' text-white-0 ':
                                journey === JOURNEYS.AI_HEADSHOT,
                            })
                          }
                        >
                          New Password
                        </FormLabel>
                        <FormControl>
                          <Password
                            type="password"
                            className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1  hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground  hover:shadow-custom py-1"
                            placeholder="Enter new password"
                            maxLength={16}
                            disabled={resetLoading}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="confirmPassword"
                    render={({ field }) => (
                      <FormItem className=" font-medium  text-[12px]">
                        <FormLabel
                          className={
                            (cn(' text-darkblue'),
                            {
                              ' text-white-0 ':
                                journey === JOURNEYS.AI_HEADSHOT,
                            })
                          }
                        >
                          Confirm Password
                        </FormLabel>
                        <FormControl>
                          <Password
                            type="password"
                            ref={matchWithNewPassword}
                            className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1  hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground  hover:shadow-custom py-1"
                            placeholder="Enter confirm password"
                            disabled={resetLoading}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Button htmlType="submit" className="h-[40px] w-full mt-2 ">
                    {resetLoading && (
                      <Loader2 className="animate-spin mr-2 h-5 w-5 " />
                    )}

                    <div className="flex items-center space-x-1">
                      <span> Change password</span>
                      <ChevronRight width="20" height="20" />
                    </div>
                  </Button>
                </form>
              </Form>
            </>
          ) : (
            <>
              <div>
                <img src="/logo.svg" height={40} width={200} alt="logo" />
              </div>
              <h1
                level={1}
                className="text-primary font-primary  text-[32px] font-semibold leading-[36px] mb-0"
              >
                Password Changed
              </h1>
              <p className="text-secondary-500 text-[14px] font-medium leading-[25px] mb-7 mt-2">
                Your password has been changed successfully.
              </p>
              <Button
                onClick={() => navigate('/auth/login')}
                htmlType="submit"
                className="h-[40px] w-full font-primary text-button-s mt-2"
              >
                <div className="flex items-center text-white-0">
                  Login <ChevronRight />
                </div>
              </Button>
            </>
          )}
        </div>
      </div>
      {/* <div
        style={{
          backgroundImage: `url(${
            journey === JOURNEYS.AI_HEADSHOT ? aiSignup : SignupCover
          })`,
        }}
        className="lg:hidden flex w-1/2 min-h-full gap-2.5 shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center"
      /> */}
    </div>
  );
};

export default ResetPassword;
