import React, { useMemo, useRef } from 'react';
import { get } from 'lodash';
import { useAppContext } from 'AppContext';
import { cn } from 'lib/utils';
import HeadShotImage from '../HeadShotImage';

function HeadShot({
  type,
  id = '',
  image = '',
  currentColor = '',
  rounded = false,
  clickable = false,
  height = 215,
  width = 215,
  data = null,
  uploadOnSelect,
  modalType,
}) {
  const {
    state: { signature, headshot },
    setHeadshotUpdateStatus,
    setToggleSidebar,
  } = useAppContext();
  const headShotRef = useRef();
  // const { headshotId } = signature || {};
  const headshotId = get(signature, 'headshotId', {});
  // const [updateHeadshotMutation] = useUpdateHeadshotMutation();
  const selectHeadShot = async () => {
    if (!clickable) return;
    if (uploadOnSelect) {
      await uploadOnSelect(headShotRef);
    }
  };

  const activeHeadshot = useMemo(
    () =>
      clickable && headshot?.style?.id === data?.id ? 'shadow-headshot' : '',
    [headshot, headshotId],
  );
  return (
    <div className={cn(activeHeadshot, { 'rounded-[50%]': rounded })}>
      <div
        ref={headShotRef}
        className={`headshot relative ${`h-[${height}px] w-[${width}px]`}  ${type} ${rounded ? 'rounded-[50%]' : ''
          } ${clickable ? 'cursor-pointer' : ''}`}
        id={id}
        style={{
          background:
            type === 'STYLE_4' || type === 'STYLE_6' ? 'white' : currentColor,
          ...(rounded ? { borderRadius: '50%' } : {}),
        }}
        onClick={() => {
          setToggleSidebar(false);
          if (activeHeadshot) {
            setHeadshotUpdateStatus(false);
            return;
          }
          selectHeadShot();
        }}
      >
        <HeadShotImage
          imageUrl={image}
          height={height}
          width={width}
          currentColor={currentColor}
          grayScale={headshot?.config?.grayScale ? 0 : 1}
          type={type}
          modalType={modalType}
        />
      </div>
    </div>
  );
}

export default HeadShot;
