/* eslint-disable no-undef */
import { useAppContext } from 'AppContext';
import { useGetRoutes } from 'helpers/useGetRoutes';
import { useFeatureChecks } from 'hooks/brand';
import { useRouter } from 'hooks/router';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { authRoutes } from 'routes';

const AuthLayout = () => {
  const { getRoutes } = useGetRoutes();
  const [searchParams] = useSearchParams();
  const { isRegistrationAllowed } = useFeatureChecks();
  const {
    location: { pathname },
    navigate,
  } = useRouter();

  const journey = searchParams.get('r');
  const code = searchParams.get('code');
  const { getToken } = useAppContext();
  const idToken = getToken();

  useEffect(() => {
    if (journey) {
      localStorage.setItem('journey', journey);
    } else if (pathname.includes('login') || pathname.includes('signup')) {
      localStorage.removeItem('journey');
    }
  }, [journey]);

  useEffect(() => {
    if (pathname.includes('signup') && !isRegistrationAllowed) {
      navigate('/auth/login');
    }
  }, [pathname]);

  if (idToken) {
    if (code) {
      return <Navigate to={`/app/app-sumo/${code}`} replace />;
    }
    return <Navigate to="/app/" replace />;
  }
  return (
    <Routes>
      {getRoutes(authRoutes)}
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};

export default AuthLayout;
