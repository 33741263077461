/* eslint-disable import/prefer-default-export */

import { usePostHog } from 'posthog-js/react';

export const usePosthogEvents = () => {
  const posthog = usePostHog();
  const capture = (event) => {
    posthog?.capture(event);
  };

  return { capture };
};
