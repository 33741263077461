/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { useWorkspaceId } from 'hooks/workspace';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'components/ui/select';
import {
  useGetWorkspaceTeammateByWorkspaceId,
  useUpdateWorkspaceTeammateRole,
} from 'hooks/workspace-teammate';
import { toast } from 'hooks/use-toast';
import { parseInt } from 'lodash';

const roleData = [
  {
    id: 1,
    name: 'OWNER',
  },
  {
    id: 2,
    name: 'MANAGER',
  },
  {
    id: 3,
    name: 'TEAMMATE',
  },
  {
    id: 4,
    name: 'EDITOR',
  },
];

const RoleChangeForm = ({ data, onClose, loading }) => {
  const [updateWorkspaceTeammateRole] = useUpdateWorkspaceTeammateRole();
  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();
  const { workspaceId } = useWorkspaceId();
  const [selectedRole, setSelectedRole] = useState(
    roleData.find((role) => role.id === parseInt(data.roleId)),
  );

  const onRoleChangeSubmit = async () => {
    const res = await updateWorkspaceTeammateRole({
      variables: {
        where: {
          id: data?.id,
        },
        data: {
          roleId: selectedRole.id,
        },
      },
    });
    if (!res) {
      return toast({
        closeicn: 'destructive',
        description: 'Unable to update Teammate Role',
      });
    }
    await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
    onClose();
  };

  return (
    <DialogContent
      className="sm:max-w-[425px] rounded-[6px] max-w-sm"
      hideCloseButton
    >
      <div>
        <DialogHeader className="text-left">
          <DialogTitle className="text-lg font-bold text-gray-900 my-0">
            Change Role
          </DialogTitle>
          <DialogDescription className="text-sm text-gray-500">
            Select a new role for the teammate.
          </DialogDescription>
        </DialogHeader>
        <div className="my-4">
          <Select
            onValueChange={(value) =>
              setSelectedRole(
                roleData.find((role) => role.id === parseInt(value)),
              )
            }
          >
            <SelectTrigger className="w-full p-2 border rounded-md bg-gray-100 text-left">
              {selectedRole ? selectedRole.name : 'Select Role'}
            </SelectTrigger>
            <SelectContent>
              {roleData
                ?.filter(({ id }) => [2, 3].includes(id))
                .map((role) => (
                  <SelectItem key={role.id} value={role.id.toString()}>
                    {role.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex w-full">
          <div className="flex justify-end gap-3 w-1/2 ml-auto">
            <Button onClick={() => onClose()} variant="outline" size="sm">
              Cancel
            </Button>
            <Button
              disabled={loading || !selectedRole}
              type="submit"
              onClick={onRoleChangeSubmit}
              size="sm"
            >
              Change Role
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
};

export default RoleChangeForm;
