import React, { useState, useEffect } from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import { useRouter } from 'hooks/router';
import { useAppContext } from 'AppContext';
import { useUpdateUserWithAppSumo } from 'hooks/user';
import LoaderComponent from 'components/LoaderComponent';

const SumoRedemption = ({ wrapperClassName, cardClassName = '' }) => {
  const {
    state: { currentUser },
  } = useAppContext();
  const {
    params: { code },
    navigate,
  } = useRouter();
  const userId = currentUser?.id;
  const [updateUserWithAppSumo] = useUpdateUserWithAppSumo();
  const [activationStatus, setActivationStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleActivate = async () => {
    if (!userId || !code) {
      setActivationStatus('Missing user ID or activation code.');
      return;
    }

    try {
      const result = await updateUserWithAppSumo({
        variables: {
          where: { id: userId },
          data: { code },
        },
      });

      if (result.data?.updateUserWithAppSumo?.message) {
        setActivationStatus(result.data.updateUserWithAppSumo.message);
        navigate('/app');
      }
    } catch (error) {
      setActivationStatus('Failed to activate license. Please try again.');
    }
  };

  const renderMessage = () => {
    if (!activationStatus) return null;

    return (
      <p
        className={cn('mt-2 text-sm', {
          'text-green-600': activationStatus.includes('success'),
          'text-red-600':
            activationStatus.includes('Failed') ||
            activationStatus.includes('Missing'),
        })}
      >
        {activationStatus}
      </p>
    );
  };

  if (isLoading) {
    return (
      <div className="w-full h-[calc(100vh)] flex justify-center items-center bg-primary-foreground">
        <LoaderComponent circleClassName="text-gray-500" />
      </div>
    );
  }

  return (
    <div
      className={cn(
        'w-full h-[calc(100vh)] flex justify-center items-center bg-primary-foreground',
        wrapperClassName,
      )}
    >
      <div
        className={cn(
          'max-w-xs p-6 bg-white rounded-lg text-center bg-white-0',
          cardClassName,
          {
            'shadow-md': !cardClassName.includes('no-shadow'),
          },
        )}
      >
        <h2 className="mt-2 text-lg font-semibold">Activate AppSumo License</h2>
        {renderMessage()}
        <p>
          You are about to activate your AppSumo license on this account. This
          action can not be reverted.
        </p>

        <div className="mt-4 flex flex-col space-y-2">
          <Button
            variant="default"
            className="w-full mb-3"
            onClick={handleActivate}
            disabled={!userId || !code}
          >
            Activate
          </Button>
          <Button
            variant="link"
            className="w-full text-blue-600 underline p-0 h-[auto] "
            onClick={() => navigate('/app/logout')}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SumoRedemption;
