import React from 'react';

function BackgroundSvg({ color, height, width }) {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 385 385"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2270_38441"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="384"
        height="384"
      >
        <rect x="0" y="0" width="384" height="384" fill="#7f56d9" />
      </mask>
      <g mask="url(#mask0_2270_38441)">
        <rect
          x="340.422"
          y="16.0581"
          width="20.3484"
          height="428.074"
          transform="rotate(45 340.422 16.0581)"
          fill={color}
        />
        <rect
          x="214.363"
          y="142.12"
          width="20.3484"
          height="428.074"
          transform="rotate(135 214.363 142.12)"
          fill={color}
        />
        <rect
          x="241.402"
          y="115.08"
          width="20.3484"
          height="428.074"
          transform="rotate(135 241.402 115.08)"
          fill={color}
        />
        <rect
          x="268.441"
          y="88.0405"
          width="20.3484"
          height="428.074"
          transform="rotate(135 268.441 88.0405)"
          fill={color}
        />
        <rect
          x="295.48"
          y="61.0005"
          width="20.3484"
          height="428.074"
          transform="rotate(135 295.48 61.0005)"
          fill={color}
        />
        <rect
          x="322.52"
          y="33.9614"
          width="20.3484"
          height="428.074"
          transform="rotate(135 322.52 33.9614)"
          fill={color}
        />
        <rect
          x="349.559"
          y="6.92139"
          width="20.3484"
          height="428.074"
          transform="rotate(135 349.559 6.92139)"
          fill={color}
        />
        <rect
          x="367.461"
          y="43.0972"
          width="20.3484"
          height="428.074"
          transform="rotate(45 367.461 43.0972)"
          fill={color}
        />
        <rect
          x="187.324"
          y="169.159"
          width="20.3484"
          height="428.074"
          transform="rotate(135 187.324 169.159)"
          fill={color}
        />
        <rect
          x="367.461"
          y="43.0972"
          width="20.3484"
          height="428.074"
          transform="rotate(45 367.461 43.0972)"
          fill={color}
        />
        <rect
          x="187.324"
          y="169.159"
          width="20.3484"
          height="428.074"
          transform="rotate(135 187.324 169.159)"
          fill={color}
        />
        <rect
          x="394.508"
          y="70.1372"
          width="20.3484"
          height="428.074"
          transform="rotate(45 394.508 70.1372)"
          fill={color}
        />
        <rect
          x="160.281"
          y="196.199"
          width="20.3484"
          height="428.074"
          transform="rotate(135 160.281 196.199)"
          fill={color}
        />
        <rect
          x="421.547"
          y="97.1812"
          width="20.3484"
          height="428.074"
          transform="rotate(45 421.547 97.1812)"
          fill={color}
        />
        <rect
          x="133.238"
          y="223.238"
          width="20.3484"
          height="428.074"
          transform="rotate(135 133.238 223.238)"
          fill={color}
        />
        <rect
          x="448.586"
          y="124.22"
          width="20.3484"
          height="428.074"
          transform="rotate(45 448.586 124.22)"
          fill={color}
        />
        <rect
          x="106.199"
          y="250.278"
          width="20.3484"
          height="428.074"
          transform="rotate(135 106.199 250.278)"
          fill={color}
        />
        <rect
          x="475.625"
          y="151.264"
          width="20.3484"
          height="428.074"
          transform="rotate(45 475.625 151.264)"
          fill={color}
        />
        <rect
          x="79.1602"
          y="277.322"
          width="20.3484"
          height="428.074"
          transform="rotate(135 79.1602 277.322)"
          fill={color}
        />
        <rect
          x="502.668"
          y="178.304"
          width="20.3484"
          height="428.074"
          transform="rotate(45 502.668 178.304)"
          fill={color}
        />
        <rect
          x="52.1172"
          y="304.361"
          width="20.3484"
          height="428.074"
          transform="rotate(135 52.1172 304.361)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default BackgroundSvg;
