import Signature from 'modules/Signature';
import React from 'react';

const Information = () => (
  <>
    <Signature />
  </>
);

export default Information;
