import { Progress } from 'components/ui/progress';
import { Card, CardTitle } from 'components/ui/card';
import { DesktopIcon } from 'assets/svg';
import { progressBarPercent } from 'common/utils';
import CommonAnalyticsCard from 'components/CommonAnalyticsCard';
import { get } from 'lodash';
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';

function Device({
  hideCard,
  hideTable,
  analyticsData,
  analyticsLinksData,
  getLinksLoading = false,
  color,
}) {
  const devicesData = get(analyticsData, 'devices', {});
  const count = get(devicesData, 'count', null);
  const { count: tableCount, devices: devicesTableData, totalDeviceCount } =
    analyticsLinksData?.devices || {};
  return (
    <div>
      <CommonAnalyticsCard label="Device" count={count} hidden={hideCard}>
        <DesktopIcon stroke={color} className="min-w-7" />
      </CommonAnalyticsCard>
      <div hidden={hideTable}>
        <Card loading={getLinksLoading} className="overflow-y-hidden">
          <CardTitle className="font-primary text-h7 text-neutral-1000">
            Device: {tableCount}
          </CardTitle>
          {/* <Table
            dataSource={devicesTableData}
            columns={columns}
            pagination={{
              pageSize: 3,
              y: 250,
            }}
            loading={false}
          /> */}
          <div className="w-full min-h-[200px]">
            <Table border="0px" scroll={{ y: 250 }} loading={false}>
              <TableHeader className="bg-[#f0f0f0] text-[#000000] font-bold">
                <TableRow>
                  <TableHead className="w-[30%] text-[#000000] font-bold">
                    Device
                  </TableHead>
                  <TableHead className="w-[25%] text-center text-[#000000] font-bold">
                    Visit
                  </TableHead>
                  <TableHead className="text-[#000000] font-bold">
                    Visit %
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {devicesTableData?.map((items) => (
                  <TableRow key={items}>
                    <TableCell className="w-[30%]">{items?.name}</TableCell>
                    <TableCell className="w-[25%] text-center">
                      {items?.count}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Progress
                          value={progressBarPercent(
                            items?.count,
                            totalDeviceCount,
                          )}
                          // size="small"
                          // status="normal"
                          // strokeColor={{
                          //   '0%': '#381e72',
                          //   '50%': '#7f56d9',
                          //   '100%': '#ba9df8',
                          // }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Device;
