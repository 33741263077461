import React from 'react';
import BackgroundSvg from '../BackgroundSvg';
import BackgroundSquareSvg from '../BackgroundSquareSvg';

const Normal = ({ imageUrl, width = 200, height = 200, grayScale = 0 }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="grayscaleFilter" colorInterpolationFilters="sRGB">
        <feColorMatrix type="saturate" values={grayScale} />
      </filter>
    </defs>
    <image
      href={imageUrl}
      x="0"
      y="0"
      width={width}
      height={height}
      filter="url(#grayscaleFilter)"
    />
  </svg>
);

const Three = ({
  imageUrl,
  width = 200,
  height = 200,
  currentColor = 'black',
  grayScale = 0,
}) => {
  const dynamicStrokeWidth = Math.min(width, height) * 0.05; // Adjust stroke width based on dimensions
  const centerX = width / 2;
  const centerY = height / 2;
  const radius = Math.min(width, height) / 2 - dynamicStrokeWidth / 2;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle
          cx={centerX}
          cy={centerY}
          r={radius}
          stroke={currentColor}
          strokeWidth={dynamicStrokeWidth}
          fill="white"
        />
        <defs>
          <filter id="grayscaleFilter" colorInterpolationFilters="sRGB">
            <feColorMatrix type="saturate" values={grayScale} />
          </filter>
        </defs>
        <image
          href={imageUrl}
          x="0"
          y="0"
          width={width}
          height={height}
          filter="url(#grayscaleFilter)"
        />
      </g>
    </svg>
  );
};

const Four = ({
  imageUrl,
  width = 200,
  height = 200,
  currentColor = 'black',
  grayScale = 0,
}) => {
  const dynamicStrokeWidth = Math.min(width, height) * 0.175; // Adjust stroke width based on dimensions
  const centerX = width / 2;
  const centerY = height / 2;
  const radius = Math.min(width, height) / 2 - dynamicStrokeWidth;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle
          cx={centerX}
          cy={centerY}
          r={radius}
          stroke="white"
          strokeWidth="0px"
          fill={currentColor}
        />
        <defs>
          <filter id="grayscaleFilter" colorInterpolationFilters="sRGB">
            <feColorMatrix type="saturate" values={grayScale} />
          </filter>
        </defs>
        <image
          href={imageUrl}
          x="0"
          y="0"
          width={width}
          height={height}
          filter="url(#grayscaleFilter)"
        />
      </g>
    </svg>
  );
};

const Five = ({ imageUrl, width = 200, height = 200, grayScale = 0 }) => (
  <svg
    fill="red"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="gradientBg" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset="0%"
          style={{ stopColor: 'rgba(0, 0, 0, 0.397)', stopOpacity: 1 }}
        />
        <stop
          offset="50%"
          style={{ stopColor: 'rgba(255, 255, 255, 0)', stopOpacity: 1 }}
        />
        <stop
          offset="100%"
          style={{ stopColor: 'rgba(0, 0, 0, 0.397)', stopOpacity: 1 }}
        />
      </linearGradient>
    </defs>
    <defs>
      <filter id="grayscaleFilter" colorInterpolationFilters="sRGB">
        <feColorMatrix type="saturate" values={grayScale} />
      </filter>
    </defs>
    <rect width={width} height={height} fill="url(#gradientBg)" />
    <image
      href={imageUrl}
      x="0"
      y="0"
      width={width}
      height={height}
      filter="url(#grayscaleFilter)"
    />
  </svg>
);

const Two = ({ imageUrl, width = 200, height = 200, grayScale = 0 }) => {
  const radius = ((width + height) / 2) * 0.025; // 0.5 factor due to average
  const uuid = Math.random().toString(36).substring(2, 15);
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id={`combinedFilter-${uuid}`} colorInterpolationFilters="sRGB">
          <feColorMatrix
            in="SourceGraphic"
            type="saturate"
            values={grayScale}
            result="grayscaleResult"
          />
          <feMorphology
            in="grayscaleResult"
            result="expandedOutline"
            operator="dilate"
            radius={radius}
          />
          <feFlood floodColor="white" result="color" />
          <feComposite
            in="color"
            in2="expandedOutline"
            operator="in"
            result="outline"
            radius={radius}
          />
          <feMerge>
            <feMergeNode in="outline" />
            <feMergeNode in="grayscaleResult" />{' '}
          </feMerge>
        </filter>
      </defs>

      <image
        href={imageUrl}
        x="0"
        y="0"
        width={width}
        height={height}
        filter={`url(#combinedFilter-${uuid})`}
      />
    </svg>
  );
};

const Six = ({
  imageUrl,
  width = 200,
  height = 200,
  currentColor = 'black',
  grayScale = 0,
  modalType = "round",
}) => (
  <svg
    width={width}
    height={height}
    fill={currentColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      {modalType === "round" && <BackgroundSvg color={currentColor} height={height} width={width} />}
      {modalType === "square" && <BackgroundSquareSvg color={currentColor} height={height} width={width} />}
    </g>
    <defs>
      <filter id="grayscaleFilter" colorInterpolationFilters="sRGB">
        <feColorMatrix type="saturate" values={grayScale} />
      </filter>
    </defs>
    <image
      href={imageUrl}
      x="0"
      y="0"
      height={height}
      width={width}
      filter="url(#grayscaleFilter)"
    />
  </svg>
);

const getHeadshot = (props) => {
  const { type } = props;

  switch (type) {
    case 'STYLE_1':
      return <Normal {...props} />;
    case 'STYLE_2':
      return <Two {...props} />;
    case 'STYLE_3':
      return <Three {...props} />;
    case 'STYLE_4':
      return <Four {...props} />;
    case 'STYLE_5':
      return <Five {...props} />;
    case 'STYLE_6':
      return <Six {...props} />;
    default:
      return <Normal {...props} />;
  }
};

const HeadShotImage = (props) => getHeadshot(props);

export default HeadShotImage;
