/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleThirtyTwo({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';

  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );
  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const section3 =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  return (
    <>
      <table
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td
            aria-label="main-content"
            style={{
              marginBottom: '8px',
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
              }}
            >
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    ref={mainContentRef}
                  >
                    <tr>
                      {!section1 && (
                        <td
                          style={{
                            verticalAlign: 'top',
                            paddingBottom: '8px',
                            paddingRight: '14px',
                          }}
                        >
                          <table
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                              fontSize: 'inherit',
                            }}
                          >
                            {getFieldData(fields, 'headshotUrl') && (
                              <tr align="center">
                                {HeadshotImage({
                                  data: getFieldData(fields, 'headshotUrl'),
                                  design,
                                })}
                              </tr>
                            )}

                            <tr>
                              {getFieldData(fields, 'companyLogo') &&
                                CompanyLogo({
                                  data: getFieldData(fields, 'companyLogo'),
                                  design,
                                  style: {
                                    textAlign: 'center',
                                    paddingTop: '10px',
                                  },
                                })}
                            </tr>
                          </table>
                        </td>
                      )}
                      <td>
                        <table
                          cellPadding="0px"
                          cellSpacing="0"
                          border="0"
                          style={{
                            borderCollapse: 'collapse',
                          }}
                        >
                          <tr>
                            {getFieldData(fields, 'name') &&
                              Name({
                                signature,
                                data: getFieldData(fields, 'name'),
                                style: {
                                  fontWeight: 700,
                                  color: design?.primaryBrandColor,
                                  fontFamily: 'inherit',
                                },
                              })}
                          </tr>
                          <tr>
                            {getFieldData(fields, 'position') &&
                              Position({
                                signature,
                                data: getFieldData(fields, 'position'),
                                style: {
                                  fontFamily: 'inherit',
                                },
                              })}
                          </tr>
                          <tr>
                            <td>
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                style={{
                                  fontSize: 'inherit',
                                  paddingBottom: '6px',
                                }}
                              >
                                <tr>
                                  {getFieldData(fields, 'department') &&
                                    Department({
                                      signature,
                                      data: getFieldData(fields, 'department'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingRight: '8px',
                                      },
                                    })}

                                  {getFieldData(fields, 'companyName') &&
                                    CompanyName({
                                      signature,
                                      data: getFieldData(fields, 'companyName'),
                                      style: {
                                        fontFamily: 'inherit',
                                      },
                                    })}
                                </tr>
                              </table>
                            </td>
                          </tr>
                          {!section3 && (
                            <tr>
                              <Divider signature={signature} design={design} />
                            </tr>
                          )}
                          <tr>
                            <td>
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                style={{
                                  borderCollapse: 'collapse',
                                }}
                              >
                                <tr>
                                  {getFieldData(fields, 'phone') &&
                                    Phone({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'phone'),
                                    })}
                                  {getFieldData(fields, 'email') &&
                                    Email({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'email'),
                                    })}
                                </tr>
                                {(getFieldData(fields, 'companyPhone') ||
                                  getFieldData(fields, 'companyEmail')) && (
                                  <tr>
                                    {getFieldData(fields, 'companyPhone') &&
                                      CompanyPhone({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyPhone',
                                        ),
                                        style: {
                                          paddingRight: '8px',
                                        },
                                      })}
                                    {getFieldData(fields, 'companyEmail') &&
                                      CompanyEmail({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyEmail',
                                        ),
                                        style: {},
                                      })}
                                  </tr>
                                )}
                              </table>
                            </td>
                          </tr>
                          {getFieldData(fields, 'website') && (
                            <tr>
                              {Website({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'website'),
                              })}
                            </tr>
                          )}

                          {getFieldData(fields, 'companyAddress') && (
                            <tr>
                              {CompanyAddress({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyAddress'),
                              })}
                            </tr>
                          )}
                          {!!socialFields?.length && (
                            <tr>
                              {SocialLinks({
                                signature,
                                showAwsIcons,
                                data: socialFields,
                                design,
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              {/* {getFieldData(fields, 'ctaUrl') &&
          getFieldData(fields, 'ctaTitle') && (
            <tr>
              {CallToAction({
                signature,
                showAwsIcons,
                design,
                data: {
                  ctaUrl: getFieldData(fields, 'ctaUrl'),
                  ctaTitle: getFieldData(fields, 'ctaTitle'),
                },
                style: {
                  paddingBottom: '8px',
                },
              })}
            </tr>
          )}

        {getFieldData(fields, 'ctaBannerImg') &&
          getFieldData(fields, 'ctaBannerUrl') && (
            <tr>
              {CallToActionBanner({
                data: {
                  ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                  ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                },
                design,
                style: { textAlign: 'left' },
              })}
            </tr>
          )}
        {!!getFieldData(fields, 'socialBanners')?.value?.length && (
          <tr>
            {SocialBanners({
              data: getFieldData(fields, 'socialBanners'),
              design,
            })}
          </tr>
        )}
        {getFieldData(fields, 'footer') && (
          <tr>
            {Footer({
              data: getFieldData(fields, 'footer'),
              design,
            })}
          </tr>
        )} */}
            </table>
          </td>
        </tr>
        {/* <tr>
      <Branding hideBranding={hideBranding} />
    </tr> */}
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleThirtyTwo;
