import React from 'react';
import GmailContent from './gmail';
import OutlookContent from './outlook';
import AppleMailContent from './appleMail';
import YahooContent from './yahoo';
import ThunderbirdContentAlias from './thunderbird';
import OutlookcomContent from './outlookcom';

// eslint-disable-next-line import/prefer-default-export
export const signatureContents = [
  {
    id: 'gmail',
    content: <GmailContent />,
  },
  {
    id: 'outlook',
    content: <OutlookContent />,
  },
  {
    id: 'applemail',
    content: <AppleMailContent />,
  },
  {
    id: 'yahoo',
    content: <YahooContent />,
  },
  {
    id: 'thunderbird',
    content: <ThunderbirdContentAlias />,
  },
  {
    id: 'outlookcom',
    content: <OutlookcomContent />,
  },
];
