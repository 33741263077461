import { generate, green, presetPalettes, red } from '@ant-design/colors';
import { ColorPicker as BaseColorPicker, Space, theme } from 'antd';
import React, { useEffect, useRef } from 'react';
import { cn } from 'lib/utils';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Input } from 'components/ui/input';

const presetColors = [
  '#D5D5D5',
  '#B4B4B4',
  '#EE4D37',
  '#F08D32',
  '#F8CA0B',
  '#06C270',
  '#144CC7',
];

const hexColorPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
const formSchema = z.object({
  colorInput: z
    .string()
    .nonempty({ message: 'Please enter color code!' }) // Ensures the field is not empty
    .regex(hexColorPattern, 'Invalid hex color code!'), // Validates the format of the color code
});

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map(([label, colors]) => ({
    label,
    colors,
  }));

const ColorPicker = ({
  presetColor = presetColors,
  isPresetShow = false,
  isInputShow = false,
  hideApplyButton = false,
  colorPickerWrapperClassName = '',
  ...rest
}) => {
  const popupContainerRef = useRef(null);
  const { token } = theme.useToken();
  const { onChange, value } = rest;
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
  });
  const handleApplyClick = (values) => {
    onChange(values.colorInput);
  };
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { colorInput: value },
  });

  useEffect(() => {
    form.setValue('colorInput', value);
  }, [value]);
  return (
    <div ref={popupContainerRef} className="w-full text-left">
      {/* style={{ textAlign: 'left' }} */}
      <div
        className={cn('flex gap-2', colorPickerWrapperClassName, {
          'mb-4': isPresetShow,
        })}
      >
        <BaseColorPicker
          getPopupContainer={() => popupContainerRef.current}
          presets={presets}
          placement="left"
          showText
          trigger="hover"
          onChangeComplete={(val) => {
            const hexColor = val?.toHex()?.slice(0, 6);
            form.setValue('colorInput', `#${hexColor}`);
          }}
          {...rest}
        />
        {isPresetShow && (
          <div className="flex gap-2 ">
            {presetColor.map((color) => (
              <Button
                className="shadow-sm h-6 px-3"
                type="button"
                key={color}
                onClick={() => {
                  onChange(color);
                  form.setValue('colorInput', color);
                }}
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
        )}
        {isInputShow && (
          <div>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(handleApplyClick)}
                form={form}
                initialValues={{ remember: true }}
              >
                <Space.Compact>
                  <FormField
                    control={form.control}
                    name="colorInput"
                    render={({ field }) => (
                      <FormItem
                        // rules={[
                        //   { required: true, message: 'Please enter color code!' },
                        //   {
                        //     pattern: hexColorPattern,
                        //     message: 'Invalid hex color code!',
                        //   },
                        // ]}
                        className="mb-0"
                      >
                        <FormControl>
                          <Input className="rounded-none py-0 h-6" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {!hideApplyButton && (
                    <Button type="submit" className="h-6 rounded-none">
                      Apply
                    </Button>
                  )}
                </Space.Compact>
              </form>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};
export default ColorPicker;
