import { useAppContext } from 'AppContext';
import { ClipboardIcon } from 'assets/svg';
import {
  cloneAndRemoveTypename,
  handleCopy,
  uploadAllImages,
} from 'common/utils';
import LoaderComponent from 'components/LoaderComponent';
import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { useSendSignature, useUpsertSignature } from 'hooks/signature';
import { useWorkspaceId } from 'hooks/workspace';
import { get } from 'lodash';
import { delay } from 'modules/AiHeadshot/components/HeadshotGenerator/HeadshotGenerator';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  useGetRoleForWorkspace,
  useUserPermission,
  useFeatures,
} from 'hooks/user';
import { FEATURE_KEYS, ROLES } from 'common/constants';
import SignatureLinkButtons from 'modules/EmailSignature/components/SignatureLinkButtons';

const sendSignatureSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
});

const TeammateSignaturePriview = ({
  signature,
  setSignature,
  isDirty,
  showSendSignature = false,
  onClose,
}) => {
  const {
    state: { baseImages },
  } = useAppContext();

  const [loading, setLoading] = useState(false);
  const { workspaceId } = useWorkspaceId();
  const { isUserUsingProFeatures } = useFeatures();
  const FinalTemplate = dynamicTemplate({
    templateKey: signature?.template?.key,
  });
  const contentRef = useRef();
  const [upsertSignature] = useUpsertSignature();
  const { isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();

  const getSignature = async () => {
    const { id: sigId, ...restData } = cloneAndRemoveTypename(signature);

    const response = await upsertSignature({
      variables: {
        data: restData,
        where: {
          signatureId: sigId ?? null,
          workspaceId,
        },
      },
    });

    const id = get(response, 'data.upsertSignature.id', null);
    if (id) {
      const responseIcons = await uploadAllImages(baseImages, id);
      restData.design.icons = responseIcons;
      await upsertSignature({
        variables: {
          data: restData,
          where: {
            signatureId: sigId ?? null,
            workspaceId,
          },
        },
      });
      setSignature(restData);
    }
  };

  const initlalEmail =
    signature?.fields?.find((f) => f?.name === 'email')?.value || '';

  const form = useForm({
    resolver: zodResolver(sendSignatureSchema),
    defaultValues: {
      email: initlalEmail,
    },
  });

  useEffect(() => {
    if (initlalEmail) {
      form.reset({ email: initlalEmail });
    }
  }, [initlalEmail]);

  const [sendSignature] = useSendSignature();
  const handleSendSignature = (values) => {
    const { email } = values;
    sendSignature({
      variables: {
        data: {
          email,
          html: contentRef?.current?.innerHTML,
        },
      },
    });
    if (onClose) {
      onClose();
    }
  };
  const handleCopySignature = async () => {
    setLoading(true);
    // await getSignature();
    await delay(1000);
    handleCopy(contentRef);
    setLoading(false);
  };
  const hideBranding =
    isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
    role === ROLES.TEAMMATE ||
    role === ROLES.MANAGER;

  return (
    <div className="pt-4 px-4 max-h-[500px] overflow-y-auto">
      {FinalTemplate && (
        <div>
          <div className="border border-solid border-gray-200 rounded-lg p-6">
            <div ref={contentRef}>
              <FinalTemplate
                signature={signature}
                showAwsIcons
                hideBranding={hideBranding}
              />
            </div>
          </div>
          {isDirty && (
            <div className="text-[red] py-2">
              Save your changes to copy signature
            </div>
          )}
          {!showSendSignature ? (
            <>
              <div className="flex justify-center items-center">
                <Button
                  disabled={loading || isDirty || isUserUsingProFeatures()}
                  label="Copy Signature"
                  icon={<ClipboardIcon />}
                  className="h-[54px] mt-4 "
                  onClick={handleCopySignature}
                >
                  Copy Signature{' '}
                  {loading && (
                    <LoaderComponent circleClassName="ml-2 w-7 h-7 text-gray-500" />
                  )}
                </Button>
              </div>
              <div className="flex gap-x-[28px] w-full justify-center mt-3">
                <SignatureLinkButtons showLabel={false} />
              </div>
            </>
          ) : (
            <div className="mt-4">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSendSignature)}>
                  <div className="grid grid-cols-[1fr_auto] gap-[18px]">
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              id="email"
                              placeholder="Enter you email"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Button
                      // disabled={signatureLoading}
                      className="h-[34px]"
                      type="submit"
                    >
                      Send
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TeammateSignaturePriview;
