import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd'; // Import DatePicker from antd
import dayjs from 'dayjs';
import {
  useGetCurrentWorkspace,
  useGetWorkspaceAnalytics,
  useToggleUtagsForWorkspace,
  useWorkspaceId,
} from 'hooks/workspace';
import { Switch } from 'components/ui/switch';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { isEmpty } from 'lodash';
import { Skeleton } from 'components/ui/skeleton';
import Analytics from './components/Analytics';

const { RangePicker } = DatePicker;

export function SkeletonCard({ hideFooter = false }) {
  return (
    <div className=" h-full flex flex-col space-y-3">
      <Skeleton className="h-full w-full rounded-xl" />
      {!hideFooter && (
        <div className="space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-full" />
        </div>
      )}
    </div>
  );
}

const WorkspaceAnalytics = () => {
  const {
    state: { workspaceAnalytics: analyticsData },
  } = useWorkspaceContext();
  const { workspace } = useGetCurrentWorkspace();

  const [getWorkspaceAnalytics, { loading: analyticsLoading }] =
    useGetWorkspaceAnalytics();
  const { workspaceId } = useWorkspaceId();

  const [toggleUtagsForWorkspace, { loading, error, data }] =
    useToggleUtagsForWorkspace();

  // Set default date range to last week
  const defaultStartDate = dayjs().startOf('day');
  const defaultEndDate = dayjs().subtract(7, 'days').endOf('day');

  const [dateRange, setDateRange] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);

  // Date range presets
  const rangePresets = [
    { label: 'Last 7 Days', value: [dayjs().subtract(6, 'd'), dayjs()] },
    { label: 'Last 15 Days', value: [dayjs().subtract(14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().subtract(29, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().subtract(89, 'd'), dayjs()] },
    { label: 'Last 180 Days', value: [dayjs().subtract(179, 'd'), dayjs()] },
    { label: 'Last 360 Days', value: [dayjs().subtract(359, 'd'), dayjs()] },
  ];

  const fetchWorkspaceAnalytics = async (filter = {}) => {
    await getWorkspaceAnalytics({
      variables: {
        where: {
          workspaceId,
        },
        filter,
      },
    });
  };

  // Fetch initial data with default date range
  useEffect(() => {
    fetchWorkspaceAnalytics({
      startDate: defaultStartDate.startOf('day').toISOString(),
      endDate: defaultEndDate.endOf('day').toISOString(),
    });
  }, [workspaceId]);

  // Handle date range change
  const handleRangeChange = (dates) => {
    setDateRange(dates);
    const filter = dates
      ? {
          startDate: dates[0].startOf('day').toISOString(),
          endDate: dates[1].endOf('day').toISOString(),
        }
      : {};
    fetchWorkspaceAnalytics(filter);
  };

  const handleToggle = (checked) => {
    toggleUtagsForWorkspace({
      variables: {
        where: {
          workspaceId,
          utagEnabled: checked,
        },
      },
    });
  };

  const utagEnabled = workspace?.utagEnabled;

  const DataPicker = (
    <div className="mt-4">
      <RangePicker
        className="w-full"
        presets={rangePresets}
        onChange={handleRangeChange}
        value={dateRange}
        placement="bottomRight"
      />
    </div>
  );

  if (analyticsLoading || isEmpty(analyticsData)) {
    return (
      <div className="bg-primary-foreground h-[calc(100vh_-_200px)] px-[24px] py-[64px] gap-10">
        <div className="text-center mb-8">
          <h3 className="text-lg font-semibold text-gray-800">
            We’re crunching the numbers!
          </h3>
          <p className="text-sm text-gray-600">
            Hang tight, your data will be ready shortly.
          </p>
        </div>
        <div className="grid grid-cols-4 h-[80px] gap-6 mb-4">
          <SkeletonCard hideFooter />
          <SkeletonCard hideFooter />
          <SkeletonCard hideFooter />
          <SkeletonCard hideFooter />
        </div>
        <div className="grid grid-cols-2 h-[400px] gap-6">
          <SkeletonCard />
          <SkeletonCard />
        </div>
      </div>
    );
  }

  return (
    <div className="px-6 bg-primary-foreground overflow-auto h-[calc(100vh_-_71px)]">
      {!utagEnabled && (
        <>
          <h1 className="text-2xl font-bold m-0 pt-2">Workspace Analytics</h1>
          <p>This is where you will see analytics for your workspace.</p>

          {/* Toggle Utag Enabled */}
          <div className="flex items-center mt-4">
            <span className="mr-2 text-lg">Enable workspace analytics:</span>
            <Switch
              onCheckedChange={handleToggle}
              checked={workspace?.utagEnabled}
            />
          </div>

          {/* Date Range Picker */}
          {loading && <p className="mt-2 text-gray-600">Toggling Utag...</p>}
          {data && (
            <p className="mt-2 text-green-600">
              {data?.toggleUtagsForWorkspace?.message}
            </p>
          )}
        </>
      )}

      {/* Analytics content */}
      {analyticsData && utagEnabled && (
        <Analytics analyticsData={analyticsData} DataPicker={DataPicker} />
      )}
    </div>
  );
};

export default WorkspaceAnalytics;
