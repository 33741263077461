import { Progress } from 'components/ui/progress';
import { Card, CardTitle } from 'components/ui/card';
import { GlobeIcon } from 'assets/svg';
import { progressBarPercent } from 'common/utils';
import CommonAnalyticsCard from 'components/CommonAnalyticsCard';
import { get } from 'lodash';
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';

function Browser({
  hideCard,
  hideTable,
  analyticsData,
  getLinksLoading = false,
  analyticsLinksData,
  color,
}) {
  const browserData = get(analyticsData, 'browsers', null);
  const count = get(browserData, 'count', null);
  const tableCount = get(analyticsLinksData, 'browsers.count', null);
  const browser = get(analyticsLinksData, 'browsers.browser', []);
  const totalBrowserCount = get(
    analyticsLinksData,
    'browsers.totalBrowserCount',
    null,
  );

  return (
    <div>
      <CommonAnalyticsCard label="Browser" count={count} hidden={hideCard}>
        <GlobeIcon stroke={color} className="min-w-7" />
      </CommonAnalyticsCard>
      <div hidden={hideTable}>
        <Card loading={getLinksLoading} className="h-100 overflow-y-hidden">
          <CardTitle className="font-primary text-h7 text-neutral-1000">
            Browser: {tableCount}
          </CardTitle>
          <div className="w-full min-h-[200px]">
            <Table border="0px" scroll={{ y: 240 }} loading={false}>
              <TableHeader className="bg-[#f0f0f0] text-[#000000] font-bold">
                <TableRow>
                  <TableHead className="w-[30%] text-[#000000] font-bold">
                    Browser
                  </TableHead>
                  <TableHead className="w-[25%] text-center text-[#000000] font-bold">
                    Visit
                  </TableHead>
                  <TableHead className="text-[#000000] font-bold">
                    Visit %
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {browser?.map((items) => (
                  <TableRow key={items}>
                    <TableCell className="w-[30%]">{items?.browser}</TableCell>
                    <TableCell className="w-[25%] text-center">
                      {items?.count}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Progress
                          value={progressBarPercent(
                            items?.count,
                            totalBrowserCount,
                          )}
                          // size="small"
                          // status="normal"
                          // strokeColor={{
                          //   '0%': '#381e72',
                          //   '50%': '#7f56d9',
                          //   '100%': '#ba9df8',
                          // }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Browser;
