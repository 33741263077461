import { gql } from '@apollo/client';

export const GET_BRANDS = gql`
  query GetBrands {
    getAllBrands {
      data {
        id
        url
        logoUrl
        name
        supportEmail
        urlStatus
        userId
        featuresAllowed
        config
        favicon
      }
    }
  }
`;

export const GET_BRAND_BY_ID = gql`
  query GetBrandById($where: BrandWhereInput!) {
    getBrandById(where: $where) {
      data {
        id
        url
        logoUrl
        name
        supportEmail
        urlStatus
        userId
        featuresAllowed
        config
        favicon
      }
    }
  }
`;

export const GET_BRAND_BY_URL = gql`
  query GetBrandByUrl($where: BrandWhereUrlInput!) {
    getBrandByUrl(where: $where) {
      data {
        id
        url
        logoUrl
        name
        supportEmail
        urlStatus
        userId
        featuresAllowed
        config
        favicon
      }
    }
  }
`;

export const GET_BRAND_LOGO_SIGNED_URL = gql`
  query GetBrandLogoUploadSignedUrl($data: GetBrandLogoUploadSignedUrlInput!) {
    getBrandLogoUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

export const GET_FAVICON_SIGNED_URL = gql`
  query GetFaviconUploadSignedUrl($data: GetFaviconUploadSignedUrlInput!) {
    getFaviconUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;
