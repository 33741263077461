import { gql } from '@apollo/client';

const ONBOARDING = gql`
  mutation UpsertSignature($data: CreateSignatureInput!) {
    upsertSignature(data: $data) {
      information {
        personalInformation {
          name
          position
        }
        businessInformation {
          companyName
          companyLogo
        }
      }
      design {
        primaryBrandColor
      }
    }
  }
`;

export const ADD_INDUSTRY = gql`
  mutation AddIndustry($data: BravoIndustryInput!) {
    addIndustry(data: $data) {
      industry
    }
  }
`;

export default ONBOARDING;
