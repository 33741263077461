import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation LoginUser($data: LoginUserInput!) {
    loginUser(data: $data) {
      message
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        role
        isActive
        lastActiveOn
        earlyAccess
        groupId
        onboardingStatus
        isCouponUsed
        signatureLimit
        phone
        position
        department
        activePlan {
          id
          name
          price
          allowedSignatures
          allowedTemplates
          features {
            key
            label
          }
        }
      }
      redirectSlug
      accessToken
      refreshToken
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation Logout {
    logout {
      message
    }
  }
`;

export const SIGNUP = gql`
  mutation SignUp($data: SignUpInput!) {
    signUp(data: $data) {
      message
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        role
        groupId
        isActive
        lastActiveOn
        earlyAccess
        onboardingStatus
        signatureLimit
        isCouponUsed
        phone
        position
        department
        activePlan {
          id
          name
          price
          allowedSignatures
          allowedTemplates
          features {
            key
            label
          }
        }
      }
      redirectSlug
      accessToken
      refreshToken
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation UpdatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      message
    }
  }
`;

export const CONFIRM_INVITATION = gql`
  mutation ConfirmInvitation($data: ConfirmInvitationInput!) {
    confirmInvitation(data: $data) {
      message
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        role
        groupId
        isActive
        lastActiveOn
        earlyAccess
        onboardingStatus
        isCouponUsed
        signatureLimit
        phone
        position
        department
        activePlan {
          id
          name
          price
          allowedSignatures
          allowedTemplates
          features {
            key
            label
          }
        }
      }
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      user {
        id
        email
        firstName
        name
        lastName
        profileImage
        role
        isActive
        lastActiveOn
      }
      accessToken
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        role
        groupId
        industry
        isActive
        lastActiveOn
        earlyAccess
        onboardingStatus
        isCouponUsed
        signatureLimit
        phone
        position
        department
        emailClient
        usedFor
        userType
        companySize
        activePlan {
          id
          name
          price
          allowedSignatures
          allowedTemplates
          features {
            key
            label
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_WITH_APPSUMO = gql`
  mutation UpdateUserWithAppSumo(
    $where: UpdateUserWithAppSumoInput!
    $data: AppSumoCodeInput!
  ) {
    updateUserWithAppSumo(where: $where, data: $data) {
      message
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        role
        groupId
        industry
        isActive
        lastActiveOn
        earlyAccess
        onboardingStatus
        isCouponUsed
        signatureLimit
        phone
        position
        department
        emailClient
        usedFor
        userType
        companySize
        activePlan {
          id
          name
          price
          allowedSignatures
          allowedTemplates
          features {
            key
            label
          }
        }
      }
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      success
      message
    }
  }
`;
