import { useEffect, useState } from 'react';

const colorDifferenceThreshold = 100;
const colorDistance = (r, g, b, r1, g1, b1) =>
  Math.sqrt((r - r1) ** 2 + (g - g1) ** 2 + (b - b1) ** 2);
const useImageColorExtractor = (imageUrl) => {
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const extractColors = async () => {
      // eslint-disable-next-line no-undef
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Enable CORS to access image data
      img.src = imageUrl;
      img.onload = () => {
        // eslint-disable-next-line no-undef
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, img.width, img.height).data;

        const uniqueColors = new Set();
        for (let i = 0; i < imageData.length; i += 4) {
          const r = imageData[i];
          const g = imageData[i + 1];
          const b = imageData[i + 2];
          const a = imageData[i + 3];
          if (a > 0) {
            // Exclude transparent pixels
            const color = `rgba(${r}, ${g}, ${b}, ${a})`;

            let isDifferent = true;
            // eslint-disable-next-line no-restricted-syntax
            for (const existingColor of uniqueColors) {
              const [r1, g1, b1] = existingColor
                ?.substring(5, existingColor.length - 1)
                ?.split(',')
                ?.map(parseFloat) || []
              if (
                colorDistance(r, g, b, r1, g1, b1) < colorDifferenceThreshold
              ) {
                isDifferent = false;
                break;
              }
            }

            if (isDifferent) {
              uniqueColors.add(color);
            }
          }
        }
        setColors(Array.from(uniqueColors));
      };
    };

    if (imageUrl) {
      extractColors();
    }
  }, [imageUrl]);

  return { colors, setColors };
};

export default useImageColorExtractor;
