/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_GROUPS = gql`
  query GetGroups($workspaceId: ID!) {
    getGroups(workspaceId: $workspaceId) {
      data {
        id
        name
        templateId
        workspaceId
      }
    }
  }
`;


export const GET_GROUP_BY_ID = gql`
  query GetGroupById($id: ID!) {
    getGroupById(id: $id) {
      data {
        id
        name
        templateId
        workspaceId
      }
    }
  }
`;