import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';
import EditWorkspaceDialog from 'components/WorkspaceSwitcher/components/EditWorkspaceDialog';
import { useRouter } from 'hooks/router';
import { Pencil, Trash } from 'lucide-react';
import { useFetchWorkspaces, useDeleteWorkspace } from 'hooks/workspace';
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel,
} from 'components/ui/alert-dialog';
import Pagination from './components/Pagination';

const WorkspaceSignatureTable = () => {
  const { navigate } = useRouter();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [fetchWorkspaces, { data, loading, error }] = useFetchWorkspaces();
  const [deleteWorkspace] = useDeleteWorkspace();
  const [workspaces, setWorkspaces] = useState([]);

  useEffect(() => {
    // Fetch all workspaces initially
    fetchWorkspaces({ variables: {} }).then((response) => {
      setWorkspaces(response.data.getWorkspaces.data || []);
    });
  }, []);

  const handleWorkspaceClick = (id) => {
    navigate(`/app/workspace/${id}/assets`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteWorkspace = (id) => {
    deleteWorkspace({
      variables: { where: { id } },
    })
      .then(() => {
        const updatedWorkspaces = workspaces?.filter(
          (workspace) => workspace.id !== id,
        );

        setWorkspaces(updatedWorkspaces);
        const [ws] = updatedWorkspaces;
        navigate(`/app/${ws?.id}/user/account`);
      })
      .catch((err) => {
        // console.error('Error deleting workspace:', err);
      });
  };

  const editWorkspaceDialogRef = useRef();
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Calculate the workspaces to display on the current page
  const offset = (currentPage - 1) * itemsPerPage;
  const currentWorkspaces = workspaces.slice(offset, offset + itemsPerPage);
  const totalCount = workspaces.length;

  return (
    <>
      <EditWorkspaceDialog
        setWorkspaces={setWorkspaces}
        workspaces={workspaces}
        ref={editWorkspaceDialogRef}
      />

      <Table>
        <TableHeader>
          <TableRow className="max-w-[1110px]">
            <TableHead>Workspace name</TableHead>
            <TableHead>Signature limit</TableHead>
            <TableHead>Used signatures</TableHead>
            <TableHead>Remaining signatures</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {currentWorkspaces.map((item) => (
            <TableRow key={item.id}>
              <TableCell
                className="font-medium cursor-pointer"
                onClick={() => handleWorkspaceClick(item.id)}
              >
                {item.name}
              </TableCell>
              <TableCell>{item.signatureLimit}</TableCell>
              <TableCell>{item.signatureUsed}</TableCell>
              <TableCell>{item.signatureLimit - item.signatureUsed}</TableCell>
              <TableCell className="flex gap-3">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    editWorkspaceDialogRef.current.open();
                    editWorkspaceDialogRef.current.onDataChange(item);
                  }}
                >
                  <Pencil size={16} />
                </span>
                {workspaces.length > 1 && (
                  <AlertDialog className="w-[auto]">
                    <AlertDialogTrigger asChild>
                      <span className="cursor-pointer text-red-500">
                        <Trash size={16} />
                      </span>
                    </AlertDialogTrigger>
                    <AlertDialogContent className="w-[300px]">
                      <AlertDialogHeader>
                        <AlertDialogTitle className="m-0">
                          Confirm Deletion
                        </AlertDialogTitle>
                        <AlertDialogDescription className="mt-0">
                          Are you sure you want to delete this workspace? This
                          action cannot be undone.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <div className="flex justify-end items-center gap-2 mt-3">
                        <AlertDialogCancel className="w-[auto] m-0">
                          Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                          onClick={() => handleDeleteWorkspace(item.id)}
                        >
                          Delete
                        </AlertDialogAction>
                      </div>
                    </AlertDialogContent>
                  </AlertDialog>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(totalCount / itemsPerPage)}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default WorkspaceSignatureTable;
