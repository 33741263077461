/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useAppContext } from 'AppContext';
import { get } from 'lodash';
import { formatDateTime } from 'common/utils';
import {
  useGetPaddleManagementUrl,
  useGetRecurringPlans,
  useSubscriptionDetails,
} from 'hooks/plans';
import {
  useTotalSignatureWorkspace,
  useFetchWorkspaces,
} from 'hooks/workspace';
import { useGetUser } from 'hooks/user';
import LoaderComponent from 'components/LoaderComponent';
import { Card, CardContent, CardFooter } from 'components/ui/card';
import { AlertCircle, CalendarDays, CreditCard } from 'lucide-react';
import { Button } from 'components/ui/button';

function BillingPage() {
  const {
    state: { currentUser, subscriptionDetails, recurringPlans },
  } = useAppContext();
  const [loading, setLoading] = useState(false);

  const [getSubscriptionDetails] = useSubscriptionDetails();
  const [getRecurringPlans] = useGetRecurringPlans();
  const allPaddlePrices = recurringPlans?.flatMap((plan) => plan.paddlePrices);

  const activePlan = allPaddlePrices?.find(
    (price) => price.id === subscriptionDetails?.priceId,
  );
  const signatureLimit = get(currentUser, 'signatureLimit', null);
  const [getUser] = useGetUser();

  const [getPaddleManagementUrl, { error }] = useGetPaddleManagementUrl();

  const getBillingDetails = () => {
    if (!subscriptionDetails) return null;

    const { billingPeriod, nextBilledAt } = subscriptionDetails;

    return {
      billingPeriod,
      nextBilledAt,
    };
  };
  const billingDetails = getBillingDetails();
  const [fetchWorkspaces] = useFetchWorkspaces();
  const { totalSignatureUsed } = useTotalSignatureWorkspace();
  useEffect(() => {
    if (currentUser?.id) {
      getSubscriptionDetails({
        variables: {
          where: { userId: currentUser?.id },
        },
      });
    }
  }, [currentUser?.id]);

  const fetchData = async () => {
    setLoading(true);
    await getRecurringPlans();
    await fetchWorkspaces();
    await getUser();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubscriptionAction = async (type, getPaddleManagementUrl) => {
    try {
      const result = await getPaddleManagementUrl();

      const url =
        type === 'cancel'
          ? result?.data?.getPaddleManagementUrl?.cancelUrl
          : result?.data?.getPaddleManagementUrl?.updateUrl;

      if (url) {
        window.open(url, '_blank');
      } else {
        // console.error(`Failed to fetch ${type} subscription URL`);
      }
    } catch (err) {
      // console.error(`Error fetching ${type} subscription URL`, err);
    }
  };

  if (loading) {
    return (
      <LoaderComponent
        circleClassName="mt-20 w-7 h-7 text-gray-500"
        setWidth="100%"
        classNames="w-full h-full relative"
      />
    );
  }

  return (
    <div className="bg-primary-foreground h-[calc(100vh_-_71px)] flex justify-center items-start">
      <div className="p-[32px] w-full max-w-xl">
        {/* Billing Header */}
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[40px] flex justify-center">
          <div className="mr-4">Billing</div>
        </div>

        {/* Active Plan Block */}
        {currentUser?.activePlan?.name && (
          <div className="bg-white-0 shadow-lg p-6 rounded-md flex justify-between items-center mb-8">
            <div className="flex flex-col items-center">
              <div className="text-h6 text-primary">Active Plan</div>
              <div className="text-regular-l">
                {[6]?.includes(currentUser?.planId)
                  ? activePlan?.description
                  : currentUser?.activePlan?.name?.split('_')?.join(' ')}
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-h6 text-primary">Signature Limit</div>
              <div className="text-regular-l">{signatureLimit}</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-h6 text-primary">Signature Used</div>
              <div className="text-regular-l">{totalSignatureUsed}</div>
            </div>
          </div>
        )}

        <div className="flex justify-center">
          {billingDetails ? (
            <Card className="w-full max-w-sm">
              <div className="text-h6 text-primary p-6 pb-3">
                Subscription Details
              </div>
              <CardContent className="grid gap-4">
                <div className="flex items-center space-x-4">
                  <CalendarDays className="h-5 w-5 text-muted-foreground" />
                  <div className="space-y-1">
                    <p className="text-sm font-medium leading-none">
                      Subscription Period
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {formatDateTime(billingDetails.billingPeriod?.starts_at)}{' '}
                      - {formatDateTime(billingDetails.nextBilledAt)}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <AlertCircle className="h-5 w-5 text-muted-foreground" />
                  <div className="space-y-1">
                    <p className="text-sm font-medium leading-none">
                      Next Billing Date
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {formatDateTime(billingDetails.nextBilledAt)}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <CreditCard className="h-5 w-5 text-muted-foreground" />
                  <div className="space-y-1">
                    <p className="text-sm font-medium leading-none">
                      Subscription Amount
                    </p>
                    <p className="text-sm text-muted-foreground">
                      $
                      {activePlan?.unit_price?.amount
                        ? (activePlan.unit_price.amount / 100).toFixed(2)
                        : '0.00'}{' '}
                      / year
                    </p>
                  </div>
                </div>
              </CardContent>
              <CardFooter>
                {/* <Button
                  variant="outline"
                  onClick={() =>
                    handleSubscriptionAction('cancel', getPaddleManagementUrl)
                  }
                  className=" border-[red] text-[red] hover:text-[red]"
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Cancel'}
                </Button> */}
                <Button
                  className="w-full py-3 bg-primary text-white-0"
                  onClick={() =>
                    handleSubscriptionAction('update', getPaddleManagementUrl)
                  }
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Manage'}
                </Button>
              </CardFooter>
            </Card>
          ) : (
            <div className="w-full max-w-sm bg-white-0 p-6 rounded-md shadow-md text-center">
              <p className="text-primary">No subscription details available.</p>
            </div>
          )}
        </div>

        {/* Cancel Subscription Button */}
        {/* Uncomment if you want to add this later */}
        {/* <div className="flex flex-col items-center mt-8 space-y-4">
          <Button
            onClick={() =>
              handleSubscriptionAction('cancel', getPaddleManagementUrl)
            }
            className="bg-[red] text-white py-2 px-4 rounded-md"
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Cancel Subscription'}
          </Button>
          <Button
            onClick={() =>
              handleSubscriptionAction('update', getPaddleManagementUrl)
            }
            className="bg-blue-600 text-white py-2 px-4 rounded-md"
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Manage Subscription'}
          </Button>
        </div> */}
      </div>
    </div>
  );
}

export default BillingPage;
