import React, { useRef } from 'react';
import { useAppContext } from 'AppContext';
import { useRouter } from 'hooks/router';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES, TEXT_IDS } from 'common/constants';
import Button from 'components/Button';
import { useSaveSignature } from 'hooks/signature';
import SaveSignatureDialog from 'components/SaveSignatureDialog';

function Branding({ hideBranding = false }) {
  const {
    state: { showBranding, showBrandingText },
    handleSubscriptionModal,
  } = useAppContext();
  const {
    location: { search },
  } = useRouter();
  const params = new URLSearchParams(search);
  const { isSignatureChanged, saveChanges } = useSaveSignature();
  const showTemplate = params.get('showTemplate');
  const saveSignatureRef = useRef();
  const { isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();

  const handleBrandingButton = () => {
    if (isSignatureChanged) {
      saveSignatureRef.current.open();
    } else {
      handleSubscriptionModal(true);
    }
  };

  const handleSaveChanges = () => {
    saveChanges();
    saveSignatureRef.current.close();
    handleSubscriptionModal(true);
  };

  if (hideBranding) {
    return <></>;
  }

  if (!showTemplate) {
    return (
      <>
        <SaveSignatureDialog
          handleSaveChanges={handleSaveChanges}
          ref={saveSignatureRef}
        />
        <table
          style={{
            fontSize: '10px',
            borderTopColor: 'rgb(238, 238, 238)',
            // marginTop: '8px',
          }}
        >
          {showBrandingText && (
            <tr>
              {/* <td>
            <img
              src="/logo32.png"
              height={16}
              width={16}
              alt="logo"
              style={{ display: 'block', margin: 'auto' }}
            />
          </td> */}
              <td id={TEXT_IDS.BRANDING}>
                <p className="mb-2 mt-0">
                  Created with
                  <a
                    href="https://syncsignature.com/?r=watermark"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: '#7F56D9',
                      fontWeight: '800',

                      textDecoration: 'none',
                      paddingBottom: '2px',
                      paddingLeft: '3px',
                    }}
                  >
                    SyncSignature
                  </a>
                </p>
              </td>
            </tr>
          )}
          {showBranding && (
            <tr>
              <td colSpan="2" style={{}}>
                <Button onClick={handleBrandingButton}>Remove Branding</Button>
              </td>
            </tr>
          )}
        </table>
      </>
    );
  }
}

export default Branding;
