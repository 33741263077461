import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import { AlertTriangle, Loader2 } from 'lucide-react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import ImageUploadPreview from 'components/ImageUploadPreview';
import Cropper from 'components/Cropper';
import { Alert, AlertDescription } from 'components/ui/alert';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Button } from 'components/ui/button';
import { Slider } from 'components/ui/slider';
import { useGetCtaBannerSignedUrl } from 'hooks/cta';
import { IMAGE_TYPE } from 'common/constants';
import {
  fileUpload,
  getLiveUrl,
  resizeImage,
  getLiveUrlforCTABanner,
} from 'common/utils';

const gifBannerIds = [5]; // List of IDs where the extension is 'gif'

const preDefinedBanners = Array.from({ length: 17 }, (_, index) => ({
  id: index + 1,
  name: `cta_banner_${index + 1}`,
  image: getLiveUrlforCTABanner(
    `ctabanner_TEMPLATE_${index + 1}`,
    gifBannerIds.includes(index + 1) ? 'gif' : 'jpg',
  ),
}));

const CtaBannerDialog = forwardRef(
  ({ signature, form, watch, ctaBannerImgFields }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(1.3);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (preDefinedBanners && preDefinedBanners.length > 0) {
        setLoading(false);
      }
    }, []);

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }));

    const [getCtaBannerSignUrl] = useGetCtaBannerSignedUrl();

    const generateSignUrl = async (variables) => {
      const res = await getCtaBannerSignUrl({
        variables: {
          data: {
            ...variables,
          },
        },
      });
      return res?.data?.getCtaBannerUploadSignedUrl;
    };

    const handleFileUpload = async (files, field) => {
      const file = files?.[0];
      if (file) {
        const isGif = file.type === IMAGE_TYPE.gif;
        let uploadFile = file;
        if (!isGif) {
          uploadFile = await resizeImage(file, 200);
        }
        const { name } = uploadFile || {};
        const ext = name.substring(name.lastIndexOf('.') + 1);
        const filename = name.split('.').slice(0, -1).join('.');
        const newFilename = `${filename}.${ext}`;
        const res = await generateSignUrl({
          fileName: newFilename,
          id: signature?.id,
          type: 'signature',
        });
        if (res) {
          const { signedUrl, key } = res;

          try {
            await fileUpload(signedUrl, file);
            const liveUrl = getLiveUrl(key);
            form.setValue(field.name, liveUrl);
            form.setValue('ctaBannerUrl', 'https://yourwebsite.com');
          } catch (error) {
            return false;
          }
        }
      }
    };

    const handleSetSelectedBanner = (banner) => {
      setSelectedBanner(banner?.id);
      form.setValue('ctaBannerImg', banner?.image);
      form.setValue('ctaBannerUrl', 'https://yourwebsite.com');
    };

    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-5xl p-8">
          <h2 className="text-lg font-semibold mb-4">
            Choose your banner from the gallery
          </h2>
          <div className="grid grid-cols-2 gap-6">
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader2 className="animate-spin mr-2 h-5 w-5 " />
              </div>
            ) : (
              <div className="space-y-4">
                <div className="space-y-4 p-4 overflow-y-auto h-[calc(100vh-400px)]">
                  {preDefinedBanners.map((banner) => (
                    <img
                      key={banner.id}
                      src={banner.image}
                      alt={banner.name}
                      className={`cursor-pointer rounded-lg border-2 w-full h-auto ${
                        selectedBanner === banner.id
                          ? 'shadow-signature'
                          : 'border-transparent'
                      }`}
                      onClick={() => handleSetSelectedBanner(banner)}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="space-y-4 pt-4 px-4 flex flex-col justify-between">
              <Form {...form}>
                {ctaBannerImgFields?.map((field) => (
                  <FormField
                    key={field.name}
                    control={form.control}
                    name={field.name}
                    render={({ field: formField }) => (
                      <FormItem>
                        <FormControl>
                          {watch.ctaBannerImg ? (
                            <ImageUploadPreview
                              className="rounded-lg mb-4 h-[300px]"
                              previewImageClassName="max-w-[80%]"
                              onChange={() => {
                                form.setValue(field.name, '');
                              }}
                              imageUrl={watch.ctaBannerImg}
                            />
                          ) : (
                            <Cropper
                              onOk={(file) => {
                                handleFileUpload([file], formField);
                              }}
                              showAspectbuttons
                              allowGif
                              cropperProps={{ aspect: aspectRatio }}
                              baseDraggerClassname="mb-4 h-[300px]"
                            />
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
              </Form>
              <div className="flex justify-end gap-2 mt-6">
                <Button
                  variant="outline"
                  className="w-[max-content]"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
                <Button onClick={() => setIsOpen(false)}>Apply</Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);

export default CtaBannerDialog;
