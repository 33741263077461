import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_STEP_MUTATION = gql`
  mutation UpdateStep($data: UpdateStepInput!) {
    updateStep(data: $data) {
      data {
        id
        userId
        step
        isPaid
        image
        previousImages
        credits
        type
        status
      }
    }
  }
`;
