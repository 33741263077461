/* eslint-disable no-undef */
import { initializePaddle } from '@paddle/paddle-js';
import { useWorkspaceId } from 'hooks/workspace';
import { delay } from 'modules/AiHeadshot/components/HeadshotGenerator/HeadshotGenerator';

export const predefinedValues = [
  5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100, 101,
];

export const handleContactUs = () => {
  window.open(
    'mailto:support@syncsignature.com?subject=Subscription%20Inquiry',
  );
};

export const useInitializePaddleClient = () => {
  const { workspaceId } = useWorkspaceId();
  const initializePaddleClient = async (setPaddle) => {
    const paddleClientToken = process.env.REACT_APP_PADDLE_CLIENT_TOKEN;

    initializePaddle({
      environment:
        process.env.REACT_APP_ENV === 'production' ? 'production' : 'sandbox',
      token: paddleClientToken,
      eventCallback: async (data) => {
        if (data.name === 'checkout.completed') {
          await delay(3000);
          window.location.href = `/app/success`;
        }
      },
    }).then(setPaddle);
  };
  return { initializePaddleClient };
};
