import React from 'react';
import { Result } from 'components/ui/result';

const MaintenancePage = () => (
  <div className="flex justify-center items-center  bg-primary-foreground   h-full">
    <Result
      title={
        <p className="text-xl font-bold">
          We are still working on this part. <br />
          Please review back soon!
        </p>
      }
      className="max-h-72 max-w-64"
    />
  </div>
);
export default MaintenancePage;
