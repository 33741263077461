import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CHECKOUT_TYPES } from 'common/constants';
import { UPDATE_STEP_MUTATION } from 'modules/AiHeadshot/graphql/Mutations';
import {
  CREATE_LM_CHECKOUT_LINK,
  GET_PREDICTION,
  GET_USER_AI_HEADSHOTS,
} from 'modules/AiHeadshot/graphql/Queries';
import { CREATE_AI_HEADSHOT_MUTATION } from 'modules/Assets/graphql/Mutations';

/* eslint-disable import/prefer-default-export */
export const useCreateAIHeadshotMutation = () => {
  // Execute the mutation
  const [createAIHeadshotMutation] = useMutation(CREATE_AI_HEADSHOT_MUTATION, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: () => {},
  });

  return [createAIHeadshotMutation];
};
export const useUpdateStepMutation = () => {
  // Execute the mutation
  const [updateStep] = useMutation(UPDATE_STEP_MUTATION, {
    onCompleted: () => {},
    onError: () => {},
  });

  return [updateStep];
};

export const useCreateLmCheckoutLink = (
  type = CHECKOUT_TYPES.CHECKOUT_LINK,
) => {
  const { data } = useQuery(CREATE_LM_CHECKOUT_LINK, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        type,
      },
    },
  });

  return { url: data?.createLmCheckoutLink?.url };
};

export const useGetUserAIHeadshots = () => {
  const [getUserAIHeadshots, state] = useLazyQuery(GET_USER_AI_HEADSHOTS, {
    fetchPolicy: 'network-only',
  });

  return [getUserAIHeadshots, state];
};
export const useGetPrediction = () => {
  const [getPrediction, state] = useLazyQuery(GET_PREDICTION, {
    fetchPolicy: 'network-only',
  });

  return [getPrediction, state];
};
