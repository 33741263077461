import React from 'react';
import { contentImages } from 'assets/content';

const OutlookContent = () => (
  <div className="p-4">
    <div className="text-center mb-6">
      <h1 className="text-2xl font-semibold">
        Set Up Your Signature - Outlook
      </h1>
    </div>

    <div className="text-center mb-6">
      <h2 className="text-2xl font-semibold">
        Set Up Your Signature - Outlook for Desktop (Windows)
      </h2>
    </div>

    <div className="mt-6 steps">
      <ol className="list-disc p-0 m-0 space-y-6">
        <li className=" items-start space-x-4">
          <span>Using Copy and Paste Method</span>
        </li>
        <li className=" items-start space-x-4">
          <span>
            Follow these steps to copy and paste your signature text into the
            Outlook desktop app:
          </span>
        </li>
        <li className=" items-start space-x-4">
          <span>
            Go to <strong>View all Outlook settings</strong> in Outlook.
          </span>
        </li>
        <li className=" items-start space-x-4">
          <span>
            Find <strong>Email</strong> and click on{' '}
            <strong>Compose & Reply</strong>.
          </span>
        </li>
        <li className=" items-start space-x-4">
          <span>Copy your email signature.</span>
        </li>
        <li className=" items-start space-x-4">
          <span>Paste your signature text into the Edit Signature box.</span>
        </li>
        <li className="items-start space-x-4">
          Outlook desktop uses the Microsoft Word rendering engine for emails,
          which may cause variations in signature appearance.
          <ul className="mt-2 space-y-1 list-disc pl-6">
            <li>
              Expect differences in spacing, font size, and image quality.
            </li>
            <li>
              Links in your signature may show with a blue underline for
              recipients, regardless of your settings.
            </li>
          </ul>
          <p className="mt-2">
            For a more consistent look, consider using an email client that
            supports HTML rendering, such as <strong>Outlook.com</strong>.
          </p>
        </li>
        <li className="items-start space-x-4">
          <span>
            Click <strong>Save</strong> to apply your signature and close the
            settings.
          </span>
        </li>
        <li className="items-start space-x-4">
          <span>Compose a new email to see your signature in action!</span>
        </li>
      </ol>
    </div>

    <div className="text-center mb-6">
      <h2 className="text-2xl font-semibold">
        Set Up Your Signature - Outlook for Desktop (Mac)
      </h2>
    </div>

    <div className="mt-6 steps">
      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>
            iMac users can add signatures in the Outlook for desktop app via the
            copy and paste method.
          </span>
        </li>
        <li className="items-start space-x-4">
          <span>
            Select ‘Outlook’ on the top left corner of your screen, then
            ‘Preferences.’
          </span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-1']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>

      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>Next, Click on ‘Signatures.’</span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-2']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>

      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>
            Then, Click on ‘+’ to create a new email signature for Mac and then
            select ‘Edit.’
          </span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-3']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>

      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>Finally, Paste your copied signature and save.</span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-4']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>

      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>
            Verify your signature before using it in emails sent via Apple Mac
            or iOS devices.
          </span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-5']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>

      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>
            Select ‘Choose default signature’ to make sure that your newly added
            email signature for Mac is set as the default signature for every
            email you send.
          </span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-6']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>

      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>
            If you want to set your email signature on a case-by-case basis,
            click on ‘Signature’ and select the desired one each time.
          </span>
        </li>
        <li className="items-start space-x-4">
          <span>
            <strong>IMPORTANT:</strong> HTML signatures have faced challenges
            with the Outlook iOS app. While they were once reliable, our recent
            evaluation indicates that your signature’s email and phone number
            links may be removed, causing them to appear blue for some
            recipients (depending on their email client). If achieving a
            “pixel-perfect” email signature is crucial, consider using an
            alternative email app on your device, such as the iOS Mail app. 📧📱
          </span>
        </li>
      </ol>
    </div>

    <div className="text-center mb-6">
      <h2 className="text-2xl font-semibold">
        Set Up Your Signature - Outlook for Desktop (Android)
      </h2>
    </div>

    <div className="mt-6 steps">
      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>
            To give your messages a professional touch, you can add an email
            signature in the Outlook mobile app.
          </span>
        </li>
        <li className="items-start space-x-4">
          <span>
            Open the Outlook app and click on the Outlook Profile icon.
          </span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-a1']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>

      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>
            Click on the Gear ⚙️ icon at the bottom-left corner of the column.
          </span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-a2']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>

      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>Next, click on your account and then tap on Signature.</span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-a3']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>

      <ol className="list-disc p-0 m-0 space-y-6">
        <li className="items-start space-x-4">
          <span>
            Finally, in the opened text editor add text, links or email
            signature created with syncsignature and then tap on ✓ symbol.
          </span>
        </li>
      </ol>

      <div className="text-center">
        <img
          src={contentImages['outlook-a4']}
          alt="Attach Signature File"
          className="w-1/2 mx-auto border rounded-md shadow-lg"
        />
      </div>
    </div>

    <div className="text-center mb-6">
      <h2 className="text-2xl font-semibold">
        Set Up Your Signature - Outlook for iphone
      </h2>
    </div>

    <div className="mt-6 steps">
      <ol className="list-disc p-0 m-0 space-y-6">
        <li className=" items-start space-x-4">
          <span>
            Open the Outlook mobile app for iPhone and go to Settings.
          </span>
        </li>
        <li className=" items-start space-x-4">
          <span>Tap on your profile icon and then tap Signature.</span>
        </li>
        <li className=" items-start space-x-4">
          <span>
            Next, Enter your desired email signature text in the box provided.
            You can also tap the email Signature Image button to add an image to
            your signature (this is optional).
          </span>
        </li>
        <li className=" items-start space-x-4">
          <span>
            Once you’re happy with the preview of your professional email
            signature, tap Save.
          </span>
        </li>
      </ol>
    </div>
  </div>
);

export default OutlookContent;
