import { useAppContext } from 'AppContext';
import { map } from 'lodash';
import React from 'react';
import HeadShot from './components/Headshot';

const HeadshotList = ({
  colorPicked = '',
  rounded = false,
  height = 215,
  width = 215,
  afterLogin = false,
  clickable = false,
  stylesData = {},
  headshotData = null,
  handleSelect,
}) => {
  const {
    state: { headshot },
  } = useAppContext();
  return (
    <div className=" flex flex-col gap-[20px] justify-center w-full">
      <div
        className={`flex flex-wrap ${
          afterLogin ? 'gap-x-[24px]' : 'gap-x-[60px]'
        }  ${'gap-y-[32px] 2xl:gap-y-[20px] 2xl:mx-0 2xl:my-auto 2xl:gap-x-[30px]'} justify-center`}
      >
        {map(stylesData, (data) => (
          <div className="flex flex-col" key={data?.id}>
            <HeadShot
              image={headshot?.image}
              type={data?.key}
              id={data?.key}
              currentColor={colorPicked}
              rounded={rounded}
              height={height}
              width={width}
              clickable={clickable}
              data={data}
              uploadOnSelect={handleSelect}
              headshotData={headshotData}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeadshotList;
