import { useLazyQuery, useMutation } from '@apollo/client';
import { FEATURE_KEYS } from 'common/constants';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  CREATE_WORKSPACE_TEMPLATE,
  DELETE_WORKSPACE_TEMPLATE,
  UPDATE_SIGNATURE_BY_WORKSPACE_TEMPLATE,
  UPDATE_WORKSPACE_TEMPLATE,
} from 'modules/Templates/graphql/Mutations';
import {
  GET_WORKSPACE_TEMPLATE,
  GET_WORKSPACE_TEMPLATE_LISTING,
  GET_WORKSPACE_TEMPLATE_LOGO_SIGN_URL,
} from 'modules/Templates/graphql/Queries';
import { useAppContext } from 'AppContext';
import { useUserPermission } from './user';
import { useGetTemplates } from './template';

/* eslint-disable import/prefer-default-export */
export const useFetchWorkspaceTemplates = () => {
  const { setWorkspaceTemplates } = useWorkspaceContext();
  const [fetchWorkspaceTemplates, states] = useLazyQuery(
    GET_WORKSPACE_TEMPLATE_LISTING,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setWorkspaceTemplates(res?.getAllWorkspaceTemplate?.data ?? []);
      },
      onError() {
        // eslint-disable-next-line no-undef
        // window.location.href = '/app/';
      },
    },
  );
  return [fetchWorkspaceTemplates, states];
};
export const useFetchWorkspaceTemplate = () => {
  const [fetchWorkspaceTemplate, states] = useLazyQuery(
    GET_WORKSPACE_TEMPLATE,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {},
      onError() {},
    },
  );
  return [fetchWorkspaceTemplate, states];
};

export const useDeleteWorkspaceTemplate = () => {
  const [deleteWorkspaceTemplate] = useMutation(DELETE_WORKSPACE_TEMPLATE, {
    onCompleted: () => {},
    onError: () => {},
  });
  return [deleteWorkspaceTemplate];
};
export const useUpdateWorkspaceTemplate = () => {
  const {
    state: { workspaceTemplates },
    setWorkspaceTemplates,
  } = useWorkspaceContext();

  const [updateWorkspaceTemplate] = useMutation(UPDATE_WORKSPACE_TEMPLATE, {
    onCompleted: (res) => {
      const updatedTemplate = res?.updateWorkspaceTemplate?.data;
      if (updatedTemplate) {
        setWorkspaceTemplates(
          workspaceTemplates.map((template) =>
            template.id === updatedTemplate.id ? updatedTemplate : template,
          ),
        );
      }
    },
    onError: () => {},
  });

  return [updateWorkspaceTemplate];
};

/* eslint-disable import/prefer-default-export */
export const useUpdateSignatureByWorkspaceTemplate = () => {
  const [updateSignatureByWorkspaceTemplate, { loading, error }] = useMutation(
    UPDATE_SIGNATURE_BY_WORKSPACE_TEMPLATE,
    {
      onCompleted: (res) => {},
      onError: (err) => {},
    },
  );

  return [updateSignatureByWorkspaceTemplate, { loading, error }];
};

export const useCreateWorkspaceTemplate = () => {
  const {
    state: { workspaceTemplates },
    setWorkspaceTemplates,
  } = useWorkspaceContext();

  const [createWorkspaceTemplate] = useMutation(CREATE_WORKSPACE_TEMPLATE, {
    onCompleted: (res) => {
      setWorkspaceTemplates(
        [...workspaceTemplates, res?.createWorkspaceTemplate?.data] ?? [],
      );
    },
    onError: () => {},
  });

  return [createWorkspaceTemplate];
};

export const useGetWorkspaceTemplateCompanyLogoSignedUrl = () => {
  const [getWorkspaceTemplateSignUrl] = useLazyQuery(
    GET_WORKSPACE_TEMPLATE_LOGO_SIGN_URL,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: () => {},
    },
  );

  return [getWorkspaceTemplateSignUrl];
};

export const useWorkspaceTemplateFeatures = ({
  workspaceTemplate: signature,
}) => {
  const { data: templatesData } = useGetTemplates();
  const { isFeaturesAllowed, isTemplatesAllowed } = useUserPermission();

  const getFeaturesUsedByUser = () => {
    const features = [];
    const hasNonEmptyCTAFields = signature?.fields?.some(
      (f) =>
        ['ctaTitle', 'ctaUrl', 'ctaBannerImg', 'ctaBannerUrl']?.includes(
          f?.name,
        ) && f?.value?.trim() !== '',
    );
    if (hasNonEmptyCTAFields) {
      features.push(FEATURE_KEYS.CALL_TO_ACTION);
    }
    return features;
  };

  const isUserUsingProFeatures = () => {
    const features = getFeaturesUsedByUser();
    return !isFeaturesAllowed(...features) || !isTemplatesAllowed;
  };

  const handleRevertToFree = () => {
    const updatedFields = signature?.fields?.map((field) => {
      if (
        field.name === 'ctaTitle' ||
        field.name === 'ctaUrl' ||
        field.name === 'ctaBannerImg' ||
        field.name === 'ctaBannerUrl'
      ) {
        return {
          ...field,
          value: '',
        };
      }
      return field;
    });

    const data = {
      ...signature,
      design: {
        ...(templatesData?.[0]?.design || {}),
        primaryBrandColor: signature?.design?.primaryBrandColor,
      },
      fields: updatedFields,
      template: templatesData?.[0]?.template,
      templateId: templatesData?.[0]?.templateId,
    };
    // const { id, ...restData } = data;
    return data;
  };

  return { isUserUsingProFeatures, handleRevertToFree };
};
