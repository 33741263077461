/* eslint-disable no-restricted-syntax */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { useRouter } from 'hooks/router';
import { MagicWandIcon, MegaPhoneIcon, NoteBookIcon } from 'assets/svg';
import { isEqual, omit } from 'lodash';

import { useWorkspaceId } from 'hooks/workspace';
import { cloneAndRemoveTypename, uploadAllImages } from 'common/utils';
import LoaderComponent from 'components/LoaderComponent';
import { cn } from 'lib/utils';
import { useAppContext } from 'AppContext';
import { toast } from 'hooks/use-toast';
import {
  useCreateWorkspaceTemplate,
  useUpdateWorkspaceTemplate,
} from 'hooks/workspace-template';
import WorkspaceTemplateDesign from '../TemplateDialog/components/WorkspaceTemplateDesign';
import WorkspaceTemplateCallToAction from '../TemplateDialog/components/WorkspaceTemplateCallToAction';
import WorkspaceTemplateInformation from '../TemplateDialog/components/WorkspaceTemplateInformation';
import WorkspaceTemplateSidebar from '../TemplateDialog/components/WorkspaceTemplateSidebar';
import WorkspaceTempatePreview from '../TemplateDialog/components/WorkspaceTempatePreview';

const items = [
  // {
  //   key: 'headshot',
  //   icon: UserCircleIcon,
  //   label: 'Headshot',
  //   disabled: false,
  // },
  {
    key: 'information',
    icon: NoteBookIcon,
    label: 'Information',
    disabled: false,
  },
  {
    key: 'design',
    icon: MagicWandIcon,
    label: 'Design',
    disabled: false,
  },
  {
    key: 'cta',
    icon: MegaPhoneIcon,
    label: 'Call to action',
    disabled: false,
  },
];

const CreateTemplateDialog = forwardRef((props, ref) => {
  const {
    state: { baseImages },
  } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [workspaceTemplate, setWorkspaceTemplate] = useState(null);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => {
      setWorkspaceTemplate(null);
      setIsOpen(false);
    },
    onDataChange: (d) => setWorkspaceTemplate(d),
  }));
  const [createWorkspaceTemplate] = useCreateWorkspaceTemplate();
  const [updateWorkspaceTemplate] = useUpdateWorkspaceTemplate();

  const [activeTab, setActiveTab] = useState('information');

  const { workspaceId } = useWorkspaceId();

  const [loading, setLoading] = useState(false);

  const handleSetWorkspaceTemplate = (data) => setWorkspaceTemplate(data);

  const renderActiveComponent = () => {
    switch (activeTab) {
      case 'design':
        return (
          <WorkspaceTemplateDesign
            workspaceTemplate={workspaceTemplate}
            handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
          />
        );
      case 'cta':
        return (
          <WorkspaceTemplateCallToAction
            handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
            workspaceTemplate={workspaceTemplate}
          />
        );
      case 'information':
      default:
        return (
          <WorkspaceTemplateInformation
            handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
            workspaceTemplate={workspaceTemplate}
          />
        );
    }
  };

  const handleSaveWorkspaceTemplate = async () => {
    try {
      // Clone the workspace template and remove unnecessary fields
      const formatedWorkspaceTemplate = omit(
        cloneAndRemoveTypename(workspaceTemplate),
        ['id', 'workspaceId'],
      );

      const res = await createWorkspaceTemplate({
        variables: {
          data: formatedWorkspaceTemplate,
          where: { workspaceId },
        },
      });

      const workspaceTemplateId = res?.data?.createWorkspaceTemplate?.data?.id;
      if (workspaceTemplateId) {
        const response = await uploadAllImages(baseImages, workspaceTemplateId);
        formatedWorkspaceTemplate.design.icons = response;
        await updateWorkspaceTemplate({
          variables: {
            data: formatedWorkspaceTemplate,
            where: {
              templateId: workspaceTemplateId,
              workspaceId,
            },
          },
        });
      }
      // Show success or error message
      if (res?.data) {
        toast({ closeicn: 'success', description: 'Created successfully' });
      } else {
        toast({
          closeicn: 'destructive',
          description: 'Error creating template',
        });
      }

      // Close the ref modal
      ref.current.close();
    } catch (error) {
      toast({
        closeicn: 'destructive',
        description: 'Failed to save the workspace template',
      });
    }
  };

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen} onOpenChange={() => ref?.current?.close()}>
          <DialogContent
            className={cn(
              'rounded-[6px] max-w-[1360px] max-h-[calc(100vh_-_100px)] h-full grid grid-cols-[100px_400px_1fr] m-0 p-0 border-0 gap-0',
              { 'grid-cols-[100px_1fr]': loading },
            )}
            hideCloseButton
          >
            <div className="overflow-hidden  bg-white-0 rounded-[inherit]">
              <WorkspaceTemplateSidebar
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                items={items}
              />
            </div>
            <>
              {loading ? (
                <LoaderComponent />
              ) : (
                <>
                  <div className=" w-full h-full overflow-auto bg-primary-foreground">
                    {workspaceTemplate && renderActiveComponent()}
                  </div>
                  <div className="bg-[#f5f5f5] rounded-[inherit]">
                    {workspaceTemplate && (
                      <WorkspaceTempatePreview
                        isWorkspaceTemplateChanged
                        handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
                        workspaceTemplate={workspaceTemplate}
                        handleSaveWorkspaceTemplate={
                          handleSaveWorkspaceTemplate
                        }
                      />
                    )}
                  </div>
                </>
              )}{' '}
            </>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
});

export default CreateTemplateDialog;
