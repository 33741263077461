import React from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { signatureContents } from './content/signatureContent';

const getContentById = (id) => {
  const contentObj = signatureContents.find((item) => item.id === id);
  return contentObj ? contentObj.content : <p>Content not found.</p>;
};

const SendSignatureModal = ({ open, closeModal, content }) => (
  <Dialog open={open} onOpenChange={closeModal}>
    <DialogContent
      className="sm:max-w-[400px] max-w-4xl max-h-screen overflow-y-auto"
      hideCloseButton
    >
      <div className="pt-2.5">
        <div className="pt-4">{getContentById(content)}</div>
      </div>
    </DialogContent>
  </Dialog>
);

export default SendSignatureModal;
