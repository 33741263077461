import { Loader2 } from 'lucide-react';
import { useSaveSignature } from 'hooks/signature';
import React from 'react';
import { Button } from 'components/ui/button';

const SignatureSaveButton = () => {
  const { saveChanges, loading, isSignatureChanged } = useSaveSignature();

  return (
    <div className="mr-0 pr-0">
      <Button
        className="pl-[16px] pr-[16px] h-[40px]"
        variant={!isSignatureChanged ? 'ghost' : 'outline'}
        disabled={!isSignatureChanged}
        onClick={async () => {
          saveChanges();
        }}
      >
        {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
        Save
      </Button>
    </div>
  );
};

export default SignatureSaveButton;
