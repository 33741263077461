import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Button } from 'components/ui/button';
import { Switch } from 'components/ui/switch'; // Import Switch component
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useCreateWorkspace } from 'hooks/workspace';
import { get } from 'lodash';
import { useRouter } from 'hooks/router';

const schema = z.object({
  name: z.string().min(1, 'Workspace name is required'),
  signatureLimit: z
    .string()
    .min(1, 'Signature limit is required')
    .regex(/^\d+$/, 'Signature limit must be a number'),
  utagEnabled: z.boolean(), // Add utagEnabled field to the schema
});

const CreateWorkspaceDialog = forwardRef((_, ref) => {
  const { navigate } = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const [createWorkspace] = useCreateWorkspace();

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      signatureLimit: '',
      utagEnabled: false, // Set default value for utagEnabled
    },
  });

  const { handleSubmit, control, watch, setValue } = form;

  // Watch the utagEnabled value
  const utagEnabled = watch('utagEnabled');

  const handleContinue = async (values = {}) => {
    const res = await createWorkspace({
      variables: {
        data: {
          ...values,
          signatureLimit: parseInt(values.signatureLimit, 10),
        },
      },
    });

    const createWorkspaceId = get(res, 'data.createWorkspace.data.id', null);
    if (createWorkspaceId) {
      navigate(`/app/workspace/${createWorkspaceId}/assets`);
    }
    setIsOpen(false);
  };

  const onSubmit = (data) => {
    handleContinue(data);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="rounded-[6px] max-w-[600px]" hideCloseButton>
        <DialogHeader>
          <DialogTitle>Add Workspace</DialogTitle>
          <DialogDescription>
            Add a new team to manage products and customers.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4 py-2 pb-4">
              <FormField
                name="name"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="name">Workspace Name</FormLabel>
                    <FormControl>
                      <Input
                        id="name"
                        className="h-[23px] py-1"
                        placeholder="My Workspace"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="signatureLimit"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="signatureLimit">
                      Signature Limit
                    </FormLabel>
                    <FormControl>
                      <Input
                        id="signatureLimit"
                        className="h-[23px] py-1"
                        placeholder="Signature Limit"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="utagEnabled"
                control={control}
                render={({ field }) => (
                  <FormItem className="flex items-center gap-3 ">
                    <FormLabel>Enable workspace analytics</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={(checked) =>
                          setValue('utagEnabled', checked)
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <div className="flex w-full justify-end gap-3">
                <Button
                  type="button"
                  className="w-[max-content]"
                  onClick={() => setIsOpen(false)}
                  variant="outline"
                  size="sm"
                >
                  Cancel
                </Button>
                <Button type="submit" size="sm">
                  Continue
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

export default CreateWorkspaceDialog;
