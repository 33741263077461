import { Progress } from 'components/ui/progress';
import { Card, CardTitle } from 'components/ui/card';
import { GearIcon } from 'assets/svg';
import { progressBarPercent } from 'common/utils';
import CommonAnalyticsCard from 'components/CommonAnalyticsCard';
import { get } from 'lodash';
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';

function OperatingSystem({
  hideCard,
  hideTable,
  analyticsData,
  getLinksLoading = false,
  analyticsLinksData,
  color,
}) {
  const osData = get(analyticsData, 'operatingSystems', {});

  const count = get(osData, 'count', null);
  const { count: tableCount, operatingSystem = [], totalOsCount } =
    analyticsLinksData?.operatingSystems || {};

  return (
    <div>
      <CommonAnalyticsCard label="OS" count={count} hidden={hideCard}>
        <GearIcon stroke={color} className="min-w-7" />
      </CommonAnalyticsCard>
      <div hidden={hideTable}>
        <Card loading={getLinksLoading} className="h-100 overflow-y-hidden">
          <CardTitle className="font-primary text-h7 text-neutral-1000">
            Operating System: {tableCount}
          </CardTitle>
          <div className="w-full min-h-[200px]">
            <Table border="0px" scroll={{ y: 240 }} loading={false}>
              <TableHeader className="bg-[#f0f0f0] text-[#000000] font-bold">
                <TableRow>
                  <TableHead className="w-[30%] text-[#000000] font-bold">
                    OS
                  </TableHead>
                  <TableHead className="w-[25%] text-center text-[#000000] font-bold">
                    Visit
                  </TableHead>
                  <TableHead className="text-[#000000] font-bold">
                    Visit %
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {operatingSystem?.map((items) => (
                  <TableRow key={items}>
                    <TableCell className="w-[30%]">{items?.os}</TableCell>
                    <TableCell className="w-[25%] text-center">
                      {items?.count}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Progress
                          value={progressBarPercent(items?.count, totalOsCount)}
                          // size="small"
                          // status="normal"
                          // strokeColor={{
                          //   '0%': '#381e72',
                          //   '50%': '#7f56d9',
                          //   '100%': '#ba9df8',
                          // }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default OperatingSystem;
