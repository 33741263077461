import { gql } from '@apollo/client';

export const GET_WORKSPACE_TEAMMATE_DETAILS_LIST = gql`
  query GetWorkspaceTeammateDetailsList {
    getWorkspaceTeammateDetailsList {
      id
      name
      position
      email
      department
      phone
      profileImage
      sendInviteFlag
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_WORKSPACE_TEAMMATE_DETAILS = gql`
  query GetWorkspaceTeammateDetails(
    $where: WorkspaceTeammateDetailsWhereInput!
  ) {
    getWorkspaceTeammateDetails(where: $where) {
      id
      name
      position
      email
      department
      phone
      profileImage
      sendInviteFlag
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
