/* eslint-disable no-undef */
/* eslint-disable no-unreachable */
/* eslint-disable no-console */
import { useAppContext } from 'AppContext';
import { useMutation } from '@apollo/client';
import { ChevronRight } from 'lucide-react';
import { toast } from 'hooks/use-toast';
import { get, isString, map, partition, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ClearableInput } from 'components/Inputs';
import {
  DEFAULT_DESIGN_STYLE,
  DISPLAY_MODE,
  FONT_FAMILY,
  SOCIAL_LINKS,
  USER,
  onboardingStatus,
} from 'common/constants';

import { fileUpload, resizeImage, URL_REGEX } from 'common/utils';
import { useRouter } from 'hooks/router';
import { useGetSignatureList, useUpsertSignature } from 'hooks/signature';
import { UPDATE_USER } from 'modules/Auth/graphql/Mutations';
import client from 'apollo';
import { GET_SIGN_URL } from 'modules/Signature/graphql/Queries';
import { Input } from 'components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import ColorPicker from 'components/ColorPicker';
import Button from 'components/Button';
import useImageColorExtractor from 'common/helpers/useImageColorExtractor';
import { signatureFieldUpdate } from 'lib/utils';
import BaseDragger from 'components/BaseDragger';
import ImageUploadPreview from 'components/ImageUploadPreview';
import { useWorkspaceId } from 'hooks/workspace';
import { useGetTemplates } from 'hooks/template';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { useBrandContext } from 'context/BrandContext';
import { useUpdateWorkspaceTeammateDetails } from 'hooks/workspace-teammate-details';
import OnboardingSignaturePreview from 'components/OnboardingSignaturePreview';
import SideCoverHeadshot from 'assets/images/side-cover.png';
import { ToggleGroup, ToggleGroupItem } from 'components/ui/toggle-group';
import keyBy from 'lodash/keyBy';
import { socialImages } from 'assets/social-images';
import { ADD_INDUSTRY } from './graphql/Mutation';
import { SIGNATURE_FIELDS, industryOptions } from './constants';

const Onboarding = () => {
  const {
    state: { signature, loading, currentUser },
    setSignature,
    setUserLocalData,
  } = useAppContext();

  const {
    state: { brand },
  } = useBrandContext();
  const {
    state: { workspaceTeammates, groups },
  } = useWorkspaceContext();
  const { workspaceId } = useWorkspaceId();
  const [updateWorkspaceTeammateDetails, { loading: submitLoading }] =
    useUpdateWorkspaceTeammateDetails();

  const workspaceTeammate =
    workspaceTeammates?.find(
      (wt) =>
        wt.workspaceId === workspaceId &&
        wt.userId === currentUser?.id &&
        wt.brandId === brand?.id,
    ) ?? null;
  const group = groups?.find((g) => g.name === 'Default Group') ?? null;
  // eslint-disable-next-line no-undef
  const getUser = JSON?.parse(localStorage?.getItem(USER));

  const { navigate } = useRouter();

  const { data } = useGetTemplates();
  const { templateId, template } = data[0];

  const fieldsMap = keyBy(SIGNATURE_FIELDS, 'name');

  const socialFields = SOCIAL_LINKS.map((s) => ({
    name: s.name,
    link: '',
    type: 'company',
    label: s.name.toUpperCase(),
    value: '',
    shortLink: '',
    isVariable: false,
    utagEnabled: true,
    variableValue: null,
  }))
    .map((s) => ({ ...s, ...fieldsMap[s.name] }))
    .slice(0, 5);

  const socialValues = socialFields
    .filter((f) => !!f.value)
    .map((field) => field.name);
  const [socialNames, setSocialNames] = useState(socialValues);

  const SOCIAL_FIELDS_NAMES = SOCIAL_LINKS.map((s) => s.name);

  const socialFieldsSchema = socialFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: z.string().nullable(),
    }),
    {},
  );

  const socialFieldValues = socialFields?.reduce((acc, field) => {
    acc[field.name] = field.value || '';
    return acc;
  }, {});

  const formInitial = {
    // set user name for google login / signup initial set in signature context when refresh get from local storage
    name: getUser?.name || '',
    position: '',
    companyName: 'ACME. INC',
    companyLogo: '',
    industry: '',
    ...socialFieldValues,
  };

  const formSchema = z.object({
    name: z.string().trim().nonempty({ message: 'Please enter your name!' }),
    position: z
      .string()
      .trim()
      .nonempty({ message: 'Please enter your position!' }),
    companyName: z.string().optional(),
    companyLogo: z.any().optional(),
    industry: z.any().optional(),
    ...(socialFieldsSchema ?? {}),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    values: formInitial,
  });
  const watch = form.watch();
  const {
    formState: { errors },
  } = form; // Destructure errors from formState

  const { colors, setColors } = useImageColorExtractor(
    watch?.companyLogo?.preview,
  );
  const DEFAULT_VALUE_MAP = {
    name: 'Olivia Bolt',
    position: 'CEO',
    companyName: 'ACME. INC',
    companyLogo: watch?.companyLogo?.preview || '',
    email: getUser?.email || '',
    headshotUrl: localStorage.getItem('headshotUrl') || '',
  };

  const getSignaturePreview = () => {
    const values = form.getValues();

    const updatedFields = [
      ...(signature?.fields?.length ? signature?.fields : SIGNATURE_FIELDS),
    ].map((field) => {
      let value;
      console.log('field.name]', field.name);

      // Special case for companyLogo
      if (field.name === 'companyLogo') {
        value = values.companyLogo?.preview || DEFAULT_VALUE_MAP.companyLogo;
      } else if (field.name === 'companyName') {
        value = values.companyName || '';
      } else {
        // Fallback for other fields
        value =
          values[field.name] ||
          DEFAULT_VALUE_MAP[field.name] ||
          field?.value ||
          '';
      }

      return {
        ...field,
        value,
      };
    });

    const newSignature = {
      ...signature,
      templateId,
      template,
      fields: updatedFields,
    };
    return newSignature;
  };
  const handleFormChange = () => {
    const values = form.getValues();
    const currentSocialFields = SOCIAL_FIELDS_NAMES.filter((f) => values[f]);
    const updatedFields = [
      ...(signature?.fields?.length ? signature?.fields : SIGNATURE_FIELDS),
    ].map((field) => ({
      ...field,
      value: values[field.name] ?? field?.value,
    }));

    const [allSocialFields, normalFields] = partition(
      [...updatedFields, ...socialFields],
      (field) => SOCIAL_FIELDS_NAMES.includes(field.name),
    );
    const updatedSocialFields = uniqBy(allSocialFields, 'name')
      .filter((f) => currentSocialFields.includes(f.name))
      .map((field) => ({
        ...field,
        value: values[field.name] ?? field?.value,
      }));

    const fields = [...normalFields, ...updatedSocialFields];

    const newSignature = {
      ...signature,
      templateId,
      template,
      fields,
    };
    setSignature(newSignature);
  };

  useEffect(() => {
    const subscription = form.watch(() => handleFormChange());
    return () => subscription.unsubscribe();
  }, [form, signature]);

  const [setOnboard, { loading: setOnboardLoading }] = useUpsertSignature();

  const [updateUser] = useMutation(UPDATE_USER, {
    // update user onboard journey to in user data and local storage
    onCompleted(res) {
      setUserLocalData(res?.updateUser?.data);
    },
    onError() {},
  });

  const [addIndustry] = useMutation(ADD_INDUSTRY);
  const [getSignatureListing] = useGetSignatureList();
  const handleImageUpload = async (files = null, signatureId = '') => {
    const file = await resizeImage(files?.[0], 200);
    if (!file) return;
    const { name } = file?.[0] || {};
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    const filename = name?.split('.')?.slice(0, -1)?.join('.');
    const newFilename = `${filename}.${ext}`;
    const res = await client?.query({
      query: GET_SIGN_URL,
      variables: {
        data: {
          fileName: newFilename,
          signatureId,
        },
      },
    });

    if (res) {
      const { signedUrl, key } = await res?.data?.getCompanyLogoUploadSignedUrl;
      try {
        await fileUpload(signedUrl, file);
        return { key };
      } catch (error) {
        // message?.error('Something went wrong');
        toast({
          closeicn: 'destructive',
          description: 'Something went wrong',
        });
      }
    }
  };

  const handleYourInformation = async (values) => {
    const { industry = '', ...formValues } = values;
    const list = await getSignatureListing({
      variables: { where: { workspaceId } },
    });

    let signatureId;

    if (list.data.signatures.count === 0) {
      const signatureResponse = await setOnboard({
        variables: {
          data: {
            ...signature,
            templateId,
            fields: SIGNATURE_FIELDS,
            groupId: group?.id,
          },
          where: {
            workspaceId,
          },
        },
      });

      signatureId = get(signatureResponse, 'data.upsertSignature.id', null);
    } else {
      signatureId = get(list, 'data.signatures.data[0].id', null);
    }

    const { key } =
      (values.companyLogo &&
        (await handleImageUpload([values.companyLogo], signatureId))) ||
      {};

    const outputLogoImage =
      key && `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${key}`;

    const updatedFields = signatureFieldUpdate(signature?.fields, [
      { name: 'name', value: formValues?.name },
      { name: 'position', value: formValues?.position },
      { name: 'companyName', value: formValues?.companyName ?? '' },
      { name: 'email', value: getUser?.email },
      { name: 'companyLogo', value: outputLogoImage ?? '' },
      { name: 'headshotUrl', value: DEFAULT_VALUE_MAP.headshotUrl ?? '' },
    ]);

    const userData = {
      name: formValues?.name,
      position: formValues?.position,
    };

    const primaryBrandColor = signature?.design?.primaryBrandColor;

    const data = {
      design: {
        ...signature?.design,
        theme: DISPLAY_MODE?.LIGHT,
        font: FONT_FAMILY,
        styles: {
          ...DEFAULT_DESIGN_STYLE,
          separator: {
            ...DEFAULT_DESIGN_STYLE?.separator,
            style: {
              ...DEFAULT_DESIGN_STYLE?.separator?.style,
              color: primaryBrandColor,
            },
          },
          socialIcon: {
            ...DEFAULT_DESIGN_STYLE?.socialIcon,
            style: {
              ...DEFAULT_DESIGN_STYLE?.socialIcon?.style,
              color: primaryBrandColor,
            },
          },
        },
        icons: [],
      },
      fields: updatedFields,
      templateId,
      template,
      groupId: group?.id,
    };

    const res = await setOnboard({
      variables: {
        data,
        where: {
          signatureId,
          workspaceId,
        },
      },
    });
    if (workspaceTeammate?.workspaceTeammateDetailsId) {
      await updateWorkspaceTeammateDetails({
        variables: {
          data: { ...userData },
          where: { id: workspaceTeammate.id },
        },
      });
    }

    setSignature({
      ...data,
      information: data?.information,
      design: data?.design,
    });
    addIndustry({
      variables: {
        data: { industry },
      },
    });
    if (res) {
      updateUser({
        variables: {
          data: {
            onboardingStatus: onboardingStatus?.INFORMATION_ADDED,
            ...userData,
            industry,
          },
        },
      });

      if (res?.data?.upsertSignature?.id) {
        console.log('DEFAULT_VALUE_MAP', DEFAULT_VALUE_MAP);
        if (DEFAULT_VALUE_MAP.headshotUrl) {
          navigate(
            `/app/${workspaceId}/onboarding/info/${res?.data?.upsertSignature?.id}`,
          );
        } else {
          navigate(
            `/app/${workspaceId}/onboarding/professional-headshot/${res?.data?.upsertSignature?.id}`,
          );
        }

        localStorage.removeItem('headshotUrl');
      }
    }
  };

  return (
    <div className="min-h-screen h-full max-h-screen grid grid-cols-2 lg:grid-cols-1 ">
      <div className="flex flex-col justify-center  bg-primary-foreground p-[16px]  overflow-auto">
        <div className="w-full max-w-[600px] lg:max-w-[none] mx-auto ">
          <img src="/logo.svg" height={40} width={200} alt="logo" />
          <h1
            level={1}
            className="text-primary font-primary my-6 text-[32px] font-semibold leading-[36px]"
          >
            Your Information
          </h1>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleYourInformation)}
              // initialValues={formInitial}
              // form={form}
              className="space-y-[27px]"
              disabled={loading || setOnboardLoading}
            >
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem
                      className="text-medium-base font-primary "
                      // rules={[{ ...required, message: 'Please enter your name!' }]}
                    >
                      <FormLabel>Your name</FormLabel>
                      <FormControl>
                        <Input
                          className="bg-[transparent] h-[21px] rounded transition duration-300 focus-within hover:border-1 hover:border-primary hover:bg-primary-foreground hover:shadow-custom
                          py-1"
                          placeholder="Name"
                          disabled={loading || setOnboardLoading}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="position"
                  render={({ field }) => (
                    <FormItem className="text-medium-base font-primary ">
                      <FormLabel>Position</FormLabel>
                      <FormControl>
                        <Input
                          className=" py-1 bg-[transparent] h-[21px] rounded transition duration-300 focus-within hover:border-1 hover:border-primary hover:bg-primary-foreground hover:shadow-custom"
                          placeholder="Position"
                          disabled={loading || setOnboardLoading}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="companyName"
                  render={({ field }) => (
                    <FormItem className="text-medium-base font-primary ">
                      <FormLabel>Company name</FormLabel>
                      <FormControl>
                        <Input
                          className="py-1 bg-[transparent] h-[21px] rounded transition duration-300 focus-within hover:border-1 hover:border-primary hover:bg-primary-foreground hover:shadow-custom"
                          placeholder="Company name"
                          disabled={loading || setOnboardLoading}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="industry"
                  render={({ field }) => (
                    <FormItem className="text-medium-base font-primary ">
                      <FormLabel>Industry</FormLabel>
                      <FormControl>
                        <Select
                          asChild
                          className=" rounded h-[21px] w-full transition duration-300 focus-within hover:border-1 hover:shadow-custom"
                          onValueChange={(val) => {
                            form.setValue('industry', val);
                          }}
                        >
                          <SelectTrigger className="w-full bg-[transparent] hover:border-primary hover:bg-primary-foreground h-[33px]">
                            <SelectValue placeholder="Industry" />
                          </SelectTrigger>
                          <SelectContent>
                            {industryOptions.map((option) => (
                              <SelectItem
                                value={option.value}
                                key={option.value}
                              >
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="companyLogo"
                render={() => (
                  <FormItem>
                    <FormLabel>Company logo (optional)</FormLabel>
                    <FormControl>
                      {watch.companyLogo ? (
                        <ImageUploadPreview
                          text="Clear"
                          onChange={() => {
                            form.setValue('companyLogo', '');
                            setColors([]);
                          }}
                          imageUrl={watch.companyLogo.preview}
                        />
                      ) : (
                        <BaseDragger
                          handleChange={(files) =>
                            form.setValue('companyLogo', files?.[0])
                          }
                          className="max-w-full mb-2 h-[150px] p-0"
                        >
                          Drag & drop or click to upload company logo
                        </BaseDragger>
                      )}
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div>
                <FormLabel>Social links</FormLabel>
                <div className="mt-2">
                  <ToggleGroup
                    variant="outline"
                    type="multiple"
                    value={socialNames}
                    className="flex gap-2 flex-wrap justify-start"
                    onValueChange={(values) => {
                      setSocialNames(values);
                      values.forEach((value) => {
                        form.setValue(value, 'https://website.com');
                      });
                      SOCIAL_FIELDS_NAMES.forEach((name) => {
                        if (!values.includes(name)) {
                          form.setValue(name, '');
                        }
                      });
                    }}
                  >
                    {socialFields.map((field) => {
                      const iconKey = `${field.name}-circle`;
                      const IconImage = socialImages[iconKey];

                      return (
                        <ToggleGroupItem
                          key={field.name}
                          value={field.name}
                          className="flex items-center bg-white-0 gap-2 p-4 border-1 border-solid border-white-0 rounded-full cursor-pointer transition-colors duration-200 data-[state=on]:border-primary data-[state=on]:border-1 data-[state=on]:border-solid data-[state=on]:bg-transparent"
                        >
                          {IconImage ? (
                            <img
                              src={IconImage}
                              alt={field.label}
                              width={20}
                              height={20}
                              className="filter brightness-0" // Add this class
                            />
                          ) : (
                            field.icon && <field.icon size={20} />
                          )}
                          <span className=" capitalize">
                            {field.label?.toLowerCase()}
                          </span>
                        </ToggleGroupItem>
                      );
                    })}
                  </ToggleGroup>
                </div>
              </div>

              <div className="font-semibold !mb-[-20px] !mt-[20px]">
                Theme color
              </div>
              <ColorPicker
                presetColor={colors?.slice(0, 10)}
                isPresetShow
                hideApplyButton
                colorPickerWrapperClassName="flex items-center"
                onChange={(value) => {
                  const color = isString(value) ? value : `#${value?.toHex()}`;
                  const updatedDesign = {
                    ...signature?.design,
                    primaryBrandColor: color,
                    styles: {
                      ...signature?.design?.styles,
                      separator: {
                        ...signature?.design?.styles?.separator,
                        style: {
                          ...signature?.design?.styles?.separator?.style,
                          color,
                        },
                      },
                      socialIcon: {
                        ...signature?.design?.styles?.socialIcon,
                        style: {
                          ...signature?.design?.styles?.socialIcon?.style,
                          color,
                        },
                      },
                    },
                  };

                  const newSignature = {
                    ...signature,
                    design: updatedDesign,
                  };

                  setSignature(newSignature);
                }}
                // value={primaryColor}
              />
              <Button
                type="submit"
                className="h-[40px] w-full mt-[6px]"
                loading={setOnboardLoading || loading}
              >
                <div className="flex items-center space-x-1">
                  <span>Next</span>
                  <ChevronRight width="20" height="20" />
                </div>
              </Button>
            </form>
          </Form>
        </div>
      </div>
      <div
        className="flex items-center justify-center lg:hidden h-full min-h-screen gap-[10px] shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center md:w-0 md:h-full"
        style={{ backgroundImage: `url(${SideCoverHeadshot})` }}
      >
        <OnboardingSignaturePreview signature={getSignaturePreview()} />
      </div>
      {/* <div className='bg-[#E2D7F8] flex items-center justify-center'>
        <OnboardingSignaturePreview signature={signature} />
      </div> */}
    </div>
  );
};

export default Onboarding;
