import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_CLIENT_IMAGE_SIGNED_URL = gql`
  query GetClientImageSignedUrl($data: UploadClientImageInput!) {
    getClientImageSignedUrl(data: $data) {
      url
    }
  }
`;
