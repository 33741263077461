import { useLazyQuery, useMutation } from '@apollo/client';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  CREATE_GROUP_MUTATION,
  DELETE_GROUP_MUTATION,
  UPDATE_GROUP_MUTATION,
} from 'modules/Groups/graphql/Mutations';
import { GET_GROUPS, GET_GROUP_BY_ID } from 'modules/Groups/graphql/Queries';

/* eslint-disable import/prefer-default-export */
export const useCreateGroup = () => {
  const {
    state: { groups },
    setGroups,
  } = useWorkspaceContext();
  const [createGroup] = useMutation(CREATE_GROUP_MUTATION, {
    onCompleted: (res) => {
      setGroups([...groups, res?.createGroup]);
    },
    onError: () => {},
  });

  return [createGroup];
};
export const useUpdateGroup = () => {
  const {
    state: { groups },
    setGroups,
  } = useWorkspaceContext();
  const [updateGroup] = useMutation(UPDATE_GROUP_MUTATION, {
    onCompleted: (res) => {
      const updatedGroup = res?.updateGroup;
      setGroups(
        groups.map((group) =>
          group.id === updatedGroup.id ? updatedGroup : group,
        ),
      );
    },
    onError: () => {},
  });

  return [updateGroup];
};
export const useGetGroup = () => {
  const { setGroups } = useWorkspaceContext();
  const [getGroups, states] = useLazyQuery(GET_GROUPS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setGroups(res?.getGroups?.data ?? []);
    },
    onError() {
      // eslint-disable-next-line no-undef
      window.location.href = '/app/';
    },
  });

  return [getGroups, states];
};

export const useGetGroupById = () => {
  const [getGroupById, states] = useLazyQuery(GET_GROUP_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted(res) {},
    onError() {
      // eslint-disable-next-line no-undef
      // window.location.href = '/app/';
    },
  });

  return [getGroupById, states];
};

export const useDeleteGroup = () => {
  const [deleteGroup] = useMutation(DELETE_GROUP_MUTATION, {
    onCompleted: () => {},
    onError: () => {},
  });

  return [deleteGroup];
};
