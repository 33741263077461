/* eslint-disable arrow-body-style */

import ProfileAvatar from 'components/ProfileAvatar';
import { useRedirectToWorkspace } from 'hooks/workspace';
import React from 'react';
import Logo from 'components/Logo';

const Header = () => {
  const { redirectToWorkspace } = useRedirectToWorkspace();
  return (
    <nav className="bg-[#FFFBFE] flex justify-between h-[70px] border-solid border-0 border-b-1 border-[#dddddd]">
      <div className="flex items-center pl-[16px]">
        <Logo onClick={redirectToWorkspace} />
      </div>
      <div className="flex items-center pr-[16px]">
        <ProfileAvatar />
      </div>
    </nav>
  );
};

export default Header;
