import Button from 'components/Button';
import { Input } from 'components/ui/input';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useState } from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const [inputPage, setInputPage] = useState(currentPage);

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value === '' || /^[0-9\b]+$/.test(value)) {
      setInputPage(value);
    }
  };

  const handlePageJump = () => {
    const page = Math.max(1, Math.min(totalPages, parseInt(inputPage, 10)));
    onPageChange(page);
  };

  return (
    <div className="flex items-center justify-center space-x-2 mt-4">
      <Button
        className={`px-3 py-2 rounded h-[32px] ${currentPage === 1 ? 'bg-gray-200' : 'bg-primary'}`}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeft />
      </Button>
      <Input
        type="text"
        readOnly
        value={currentPage}
        onChange={handleInputChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handlePageJump();
          }
        }}
        className="w-12 text-center rounded"
      />
      <Button
        className={`px-3 py-2 rounded h-[32px] ${currentPage === totalPages ? 'bg-gray-200' : 'bg-primary'}`}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRight />
      </Button>
    </div>
  );
};

export default Pagination;
