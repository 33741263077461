import { DownloadIcon } from 'assets/svg';
import { Button } from 'components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { toPng } from 'html-to-image';
import React, { useState } from 'react';
import { useToast } from 'hooks/use-toast';

function DownloadHeadshot({ type }) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();

  //  HeadShot Dimensions
  const HEADSHOT_DIMENSIONS = [
    {
      name: 'Whatsapp',
      dimension: { height: 500, width: 500 },
    },
    {
      name: 'Twitter',
      dimension: { height: 400, width: 400 },
    },
    {
      name: 'Linkedin',
      dimension: { height: 400, width: 400 },
    },
    {
      name: 'Instagram',
      dimension: { height: 320, width: 320 },
    },
    {
      name: 'Large',
      dimension: { height: 800, width: 800 },
    },
  ];

  const returnPngImage = async (target, canvasHeight, canvasWidth) => {
    setOpen(false);
    // eslint-disable-next-line no-undef
    const node = document?.getElementById(target);
    if (node) {
      try {
        const dataUrl = await toPng(node, {
          quality: 1,
          pixelRatio: 1,
          canvasHeight: canvasHeight || node?.offsetHeight,
          canvasWidth: canvasWidth || node?.offsetWidth,
          cacheBust: true,
          includeQueryParams: true,
          fontEmbedCSS: false,
          imagePlaceholder: 'download-headshot-image',
        });
        // eslint-disable-next-line no-undef
        const link = document?.createElement('a');
        link.download = `headshot-${new Date()?.getTime()}.png`;
        link.href = dataUrl;
        link?.click();
      } catch (error) {
        // message?.error('Error generating png image!');
        toast({
          closeicn: 'destructive',
          description:
            'Error in image. Please re-upload your photo to download!',
        });
      }
    }
  };

  const handleMenuItemClick = (media) => {
    returnPngImage(
      `${type}`,
      media?.dimension?.height,
      media?.dimension?.width,
    );
  };

  const menu = (
    <div className="">
      {HEADSHOT_DIMENSIONS.map((media) => (
        <div key={media?.name}>
          <Button
            className="border-none w-full text-black bg-white-0 hover:bg-gray-200 hover:cursor-pointer "
            onClick={() => handleMenuItemClick(media)}
          >
            {media?.name} {media?.dimension?.height} X {media?.dimension?.width}
          </Button>
        </div>
      ))}
    </div>
  );

  return (
    <Popover open={open} className="  ">
      <PopoverTrigger className="absolute bottom-2.5 right-2.5 rounded-full z-10 bg-primary border-0 p-2">
        <DownloadIcon
          onClick={() => setOpen(true)}
          className="h-[32px] w-[32px]"
        />
      </PopoverTrigger>
      <PopoverContent
        onPointerDownOutside={() => setOpen(false)}
        className="w-full p-0 "
      >
        {menu}
      </PopoverContent>
    </Popover>
  );
}

export default DownloadHeadshot;
