import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  workspaces: [],
  groups: [],
  teammates: [],
  workspaceTemplates: [],
  roles: [],
  workspaceTeammates: [],
  googleWorkspaceUsers: [], // Added to initial state
  isGoogleWorkspaceConnected: false, // Added to initial state
  workspaceAnalytics: {},
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_WORKSPACES':
      return { ...state, workspaces: action?.data };
    case 'SET_GROUPS':
      return { ...state, groups: action?.data };
    case 'SET_TEAMMATES':
      return { ...state, teammates: action?.data };
    case 'SET_WORKSPACE_TEMPLATES':
      return { ...state, workspaceTemplates: action?.data };
    case 'SET_ROLES':
      return { ...state, roles: action?.data };
    case 'SET_WORKSPACE_TEAMMATES':
      return { ...state, workspaceTeammates: action?.data };
    case 'SET_GOOGLE_WORKSPACE_USERS': // Added new case for googleWorkspaceUsers
      return { ...state, googleWorkspaceUsers: action?.data };
    case 'SET_IS_GOOGLE_WORKSPACE_CONNECTED': // Added new case for googleWorkspaceUsers
      return { ...state, isGoogleWorkspaceConnected: action?.data };
    case 'SET_WORKSPACE_ANALYTICS': // New case for setting workspace analytics
      return { ...state, workspaceAnalytics: action?.data };
    default:
      return { ...state };
  }
};

const WorkspaceContext = createContext({
  state: initialState,
  dispatch: () => {},
});

const WorkspaceContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setWorkspaces = (data) => {
    dispatch({ type: 'SET_WORKSPACES', data });
  };
  const setGroups = (data) => {
    dispatch({ type: 'SET_GROUPS', data });
  };
  const setTeammates = (data) => {
    dispatch({ type: 'SET_TEAMMATES', data });
  };
  const setWorkspaceTemplates = (data) => {
    dispatch({ type: 'SET_WORKSPACE_TEMPLATES', data });
  };
  const setRoles = (data) => {
    dispatch({ type: 'SET_ROLES', data });
  };
  const setWorkspaceTeammates = (data) => {
    // Renamed function to setWorkspaceTeammates
    dispatch({ type: 'SET_WORKSPACE_TEAMMATES', data });
  };
  const setGoogleWorkspaceUsers = (data) => {
    // Added function to set googleWorkspaceUsers
    dispatch({ type: 'SET_GOOGLE_WORKSPACE_USERS', data });
  };
  const setIsGoogleWorkspaceConnected = (data) => {
    // Added function to set googleWorkspaceUsers
    dispatch({ type: 'SET_IS_GOOGLE_WORKSPACE_CONNECTED', data });
  };

  const setWorkspaceAnalytics = (data) => {
    dispatch({ type: 'SET_WORKSPACE_ANALYTICS', data });
  };

  const value = {
    state,
    dispatch,
    setWorkspaces,
    setGroups,
    setTeammates,
    setWorkspaceTemplates,
    setRoles,
    setWorkspaceTeammates,
    setGoogleWorkspaceUsers, // Added to context value
    setIsGoogleWorkspaceConnected,
    setWorkspaceAnalytics,
  };

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
};

const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error(
      'useWorkspaceContext must be used within a WorkspaceContextProvider',
    );
  }
  return context;
};

export { WorkspaceContext, useWorkspaceContext, WorkspaceContextProvider };
