import React from 'react';
import { SubscriptionIcon, UserCircleIcon, Whiltelabel } from 'assets/svg';
import { ReceiptText } from 'lucide-react';
import { useRouter } from 'hooks/router';
import { useTheme } from 'hooks/theme';
import { useGetRoutes } from 'helpers/useGetRoutes';
import { userRoutes } from 'routes';
import { useWorkspaceId } from 'hooks/workspace';

const itemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  position: 'relative',
};

const getTabContentMap = (workspaceId) => ({
  ACCOUNT: {
    key: 'Account',
    path: `/app/${workspaceId}/user/account`,
    icon: UserCircleIcon,
    label: 'Account',
  },
  PROFILE: {
    key: 'Profile',
    path: `/app/${workspaceId}/user/profile`,
    icon: UserCircleIcon,
    label: 'Profile',
  },
  SUBSCRIPTIONS: {
    key: 'plan',
    path: `/app/${workspaceId}/user/subscriptions`,
    icon: SubscriptionIcon,
    label: 'Plans',
  },
  BILLING: {
    key: 'billing',
    path: `/app/${workspaceId}/user/billing`,
    icon: ReceiptText,
    label: 'Billing',
  },
  WHITELABEL: {
    key: 'Whitelabel',
    path: `/app/${workspaceId}/user/whitelabel`,
    icon: Whiltelabel,
    label: 'WhiteLabel',
  },
});

const UserSideBar = () => {
  const {
    location: { pathname },
    navigate,
  } = useRouter();

  const { color, foregroundColor } = useTheme();
  const { workspaceId } = useWorkspaceId();

  const { getRoutesList } = useGetRoutes();
  const routes = getRoutesList(userRoutes);
  const TAB_CONTENT_MAP = getTabContentMap(workspaceId);

  const getTabItem = (key) => {
    const route = routes.find((r) => r.name === key);
    if (route) {
      return [
        {
          ...route,
          ...TAB_CONTENT_MAP[key],
        },
      ];
    }
    return [];
  };

  const items = [
    ...getTabItem('ACCOUNT'),
    ...getTabItem('PROFILE'),
    ...getTabItem('SUBSCRIPTIONS'),
    ...getTabItem('BILLING'),
    ...getTabItem('WHITELABEL'),
  ];

  const isActiveTab = (path) => pathname.includes(path);

  return (
    <div
      className="h-[calc(100vh_-_71px)] flex flex-col"
      collapsible
      theme="light"
      trigger={null}
      width={277}
    >
      {items.map(({ icon: Icon, path, label }) => {
        const onClick = () => {
          navigate(path);
        };

        const isActive = isActiveTab(path);

        return (
          <div
            className="h-[70px]"
            style={{
              ...itemStyle,
              ...(isActive
                ? {
                    backgroundColor: foregroundColor,
                    borderLeft: `4px solid ${color}`,
                  }
                : {}),
            }}
            key={path}
            onClick={onClick}
          >
            <div
              className="flex items-center w-full "
              style={{ marginLeft: isActive ? '30px' : '35px' }}
            >
              <Icon
                fill={isActive ? color : '#7a7289'}
                className="h-[30px] w-[30px] mr-3"
              />
              <div
                className="text-[18px]"
                style={{ color: isActive ? color : '#7a7289' }}
              >
                {label}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserSideBar;
