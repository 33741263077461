/* eslint-disable import/prefer-default-export */
import { cn } from 'lib/utils';
import React from 'react';

function Skeleton({ className, ...props }) {
  return (
    <div
      className={cn('custom-pulse rounded-md bg-white-0', className)}
      {...props}
    />
  );
}

export { Skeleton };
