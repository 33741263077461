import React from 'react';

const GmailContent = () => (
  <div className="p-4">
    <div className="text-center mb-6">
      <h1 className="text-2xl font-semibold">Set Up Your Signature - Gmail</h1>
    </div>
    <div className="bg-gray-100 border border-gray-200 p-4 rounded-md text-yellow-800 mb-6">
      <strong>WARNING:</strong> Gmail may prevent you from installing signatures
      over 10,000 characters. Our templates require all characters to ensure
      compatibility across email clients. If you see "The signature is too long.
      Please try a shorter signature" error, try reducing information or using a
      simpler template.
    </div>

    <div className="mt-6 steps">
      <ol className="list-none p-0 m-0 space-y-6">
        <li className="flex items-start space-x-4">
          <span>
            Once logged into your Gmail account, click the Settings icon in the
            top-right corner.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>Select "See all settings" option.</span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Scroll down to the Signature section and click "Create new".
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Enter your new signature name into the modal and click "Create".
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>Copy and paste your signature into the signature editor.</span>
        </li>
      </ol>
    </div>

    <div className="mt-6 bg-gray-100 border border-gray-200 p-4 rounded-md text-yellow-800 mb-6">
      <strong>WARNING:</strong> There is currently a bug in Chrome that causes
      signature links to appear underlined after copy/pasting. To avoid this,
      use another browser like{' '}
      <a
        href="https://www.mozilla.org/firefox/new/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 underline"
      >
        Mozilla Firefox
      </a>{' '}
      for setup. You can switch back to Chrome afterward.
    </div>

    <div className="mt-6 steps">
      <ol className="list-none p-0 m-0 space-y-6">
        <li className="flex items-start space-x-4">
          <span>
            Select your new signature in the "For New Emails Use" dropdown.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            IMPORTANT: The Gmail iOS app will use this setting to determine
            which signature to use when sending email.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Ensure you scroll to the bottom of the page and click "Save
            Changes".
          </span>
        </li>
      </ol>
    </div>

    <div className="mt-6">
      <p>Compose a new email and enjoy your new signature!</p>
    </div>
  </div>
);

export default GmailContent;
