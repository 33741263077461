/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Card } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import { useAppContext } from 'AppContext';
import { handleContactUs } from '../utils';

export const PLAN_STATE = {
  ACTIVE: 'ACTIVE',
  UPDATE: 'UPDATE',
  UPGRADE: 'UPGRADE',
  CONTACT: 'CONTACT',
};

export const PLAN_LABLE = {
  ACTIVE: 'Active plan',
  UPDATE: 'Update plan',
  UPGRADE: 'Upgrade plan',
  CONTACT: 'Contact us',
};

const SoloPlans = ({
  plans,
  subscriptionDetails,
  openCheckout,
  initialUserCount,
}) => {
  const {
    state: { currentUser },
  } = useAppContext();

  const allPlans = plans.flatMap((plan) => plan.paddlePrices);

  const isActivePlan = (planPriceId) => {
    if (subscriptionDetails?.priceId) {
      return planPriceId === subscriptionDetails?.priceId;
    }
    return false;
  };

  const getPlanState = (allPlans, signatureLimit, activePriceId) => {
    if (
      Number(initialUserCount) > Number(signatureLimit) ||
      [11, 12, 13].includes(Number(currentUser?.planId))
    )
      return PLAN_STATE.CONTACT;
    if (!allPlans?.length) return PLAN_STATE.UPDATE;
    if (!activePriceId) return PLAN_STATE.UPDATE;
    const activePlan = allPlans.find((price) => price.id === activePriceId);
    if (!['PROFESSIONAL', 'SOLO'].includes(activePlan?.description))
      return PLAN_STATE.CONTACT;
    const mininumSignatureLimit = Number(
      activePlan?.custom_data?.signature_limit,
    );

    if (mininumSignatureLimit < Number(signatureLimit)) {
      return PLAN_STATE.UPGRADE;
    }
    if (mininumSignatureLimit === Number(signatureLimit)) {
      return PLAN_STATE.ACTIVE;
    }
    if (mininumSignatureLimit > Number(signatureLimit)) {
      return PLAN_STATE.CONTACT;
    }
    return PLAN_STATE.UPDATE;
  };

  const renderPlanButton = (price, planId) => {
    const state = getPlanState(
      allPlans,
      price?.custom_data?.signature_limit,
      subscriptionDetails?.priceId,
    );
    if (state === PLAN_STATE.ACTIVE) {
      return (
        <Button
          onClick={() => openCheckout(price.id, planId)}
          className="w-full py-3 bg-primary text-white-0 rounded-xl"
          disabled
        >
          Active Plan
        </Button>
      );
    }
    if (state === PLAN_STATE.UPDATE) {
      return (
        <Button
          onClick={() => openCheckout(price.id, planId)}
          className="w-full py-3 bg-primary text-white-0 rounded-xl"
        >
          Update Plan
        </Button>
      );
    }
    if (state === PLAN_STATE.UPGRADE) {
      return (
        <Button
          onClick={() => openCheckout(price.id, planId)}
          className="w-full py-3 bg-primary text-white-0 rounded-xl"
        >
          Upgrade Plan
        </Button>
      );
    }
    if (state === PLAN_STATE.CONTACT) {
      return (
        <Button
          onClick={handleContactUs}
          className="w-full py-3 bg-primary text-white-0 rounded-xl"
        >
          Contact us
        </Button>
      );
    }
    return (
      <Button
        onClick={handleContactUs}
        className="w-full py-3 bg-primary text-white-0 rounded-xl"
      >
        Contact Us
      </Button>
    );
  };
  const renderPlanCard = (plan, price) => {
    const isActive = isActivePlan(price.id);
    const monthlyPrice = price?.custom_data?.per_month || 0;
    const description = price?.description;
    const filteredFeatures =
      description === 'SOLO'
        ? plan.features.filter((feature) => feature.key !== 'TEAM_FEATURE')
        : plan.features;

    return (
      <Card
        bordered
        key={price.id}
        className={cn('p-4 shadow-md rounded-xl', {
          'border-primary': isActive, // Highlight the active plan
          'border-gray-300': !isActive,
        })}
      >
        <div>
          <h1 className="text-2xl font-semibold mb-4">{price.description}</h1>

          <div className="text-[20px] leading-none font-bold text-primary-1000 mb-1">
            ${monthlyPrice}{' '}
            <span className="text-[18px] font-normal">/ mo</span>
          </div>
          <div className="text-[13px] text-[#676767] mb-5">
            ${price.unit_price.amount / 100} billed yearly
          </div>

          {renderPlanButton(price, plan.id)}
          <ul className="mb-4 space-y-2">
            {price.custom_data?.signature_limit && (
              <li className="text-black-0">
                {price.custom_data.signature_limit}{' '}
                {description === 'SOLO' ? 'Pro Signature' : 'Pro Signatures'}
              </li>
            )}
            {filteredFeatures.map((feature, idx) => (
              <li key={idx} className="text-black-0">
                {feature.label}
              </li>
            ))}
          </ul>
        </div>
      </Card>
    );
  };

  const renderSoloPlans = () =>
    plans
      .filter((plan) => plan.name === 'SOLO')
      .flatMap((plan) =>
        plan.paddlePrices.map((price) => renderPlanCard(plan, price)),
      );

  return <div className="gap-12 grid grid-cols-2">{renderSoloPlans()}</div>;
};

export default SoloPlans;
