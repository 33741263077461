/* eslint-disable global-require */
/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */

import React, { useState, useMemo, useEffect } from 'react';

import { Monitor, Globe2, Chrome, Smartphone } from 'lucide-react';
import { Card, CardContent, CardTitle } from 'components/ui/card';
import { WorldMap } from 'react-svg-worldmap';
import { useTheme } from 'hooks/theme';
import countries from 'i18n-iso-countries';
import Chart from 'react-google-charts';
import { startCase } from 'lodash';
import { Empty } from 'components/ui/empty';
import { options } from 'modules/Analytics/components/Clicks';

const Analytics = ({ analyticsData, DataPicker = '' }) => {
  const { color } = useTheme();
  const [selectedSignature, setSelectedSignature] = useState();
  // Object.keys(analyticsData)[0],
  useEffect(() => {
    if (analyticsData) {
      setSelectedSignature(Object.keys(analyticsData)[0]);
    }
  }, []);

  const cumulativeData = useMemo(() => {
    try {
      const totalClicks = Object.values(analyticsData).reduce(
        (sum, data) => sum + (data.totalClicks || 0),
        0,
      );

      const allCountries = new Set();
      const allBrowsers = new Set();
      const allOs = new Set();
      let totalDevices = 0;
      const deviceCounts = {};
      const browserCounts = {};
      const osCounts = {};
      const countryCounts = {};
      const monthWiseClicks = {};

      Object.values(analyticsData).forEach((data) => {
        data.countries?.forEach(({ country, count }) => {
          allCountries.add(country);
          countryCounts[country] = (countryCounts[country] ?? 0) + (count || 0);
        });

        data.totalBrowsers?.forEach((browser) => {
          allBrowsers.add(browser);
          browserCounts[browser] = (browserCounts[browser] || 0) + 1;
        });

        data.totalOs?.forEach((os) => {
          allOs.add(os);
          osCounts[os] = (osCounts[os] || 0) + 1;
        });

        totalDevices += data.devices?.totalDeviceCount || 0;

        data.devices?.devices?.forEach((device) => {
          deviceCounts[device.name] =
            (deviceCounts[device.name] || 0) + (device.count || 0);
        });

        data.monthWiseClicks?.forEach(({ month, year, clicks }) => {
          const key = `${year}-${month}`;
          monthWiseClicks[key] = (monthWiseClicks[key] || 0) + (clicks || 0);
        });
      });

      return {
        totalClicks,
        totalCountries: allCountries.size,
        totalBrowsers: allBrowsers.size,
        totalOs: allOs.size,
        totalDevices,
        deviceCounts,
        browserCounts,
        osCounts,
        countryCounts,
        monthWiseClicks: Object.entries(monthWiseClicks)
          .map(([key, clicks]) => {
            const [year, month] = key.split('-');
            return {
              year: parseInt(year, 10),
              month: parseInt(month, 10),
              clicks,
            };
          })
          .sort((a, b) => a.year - b.year || a.month - b.month),
      };
    } catch (error) {
      // Return a default structure if an error occurs
      return {
        totalClicks: 0,
        totalCountries: 0,
        totalBrowsers: 0,
        totalOs: 0,
        totalDevices: 0,
        deviceCounts: {},
        browserCounts: {},
        osCounts: {},
        countryCounts: {},
        monthWiseClicks: [],
      };
    }
  }, [analyticsData]);
  // const selectedData = analyticsData[selectedSignature];

  const workspaceStats = [
    { title: 'Total Clicks', value: cumulativeData.totalClicks, icon: Monitor },
    {
      title: 'Total Regions',
      value: cumulativeData.totalCountries,
      icon: Globe2,
    },
    {
      title: 'Total Browsers',
      value: cumulativeData.totalBrowsers,
      icon: Chrome,
    },
    {
      title: 'Total Devices',
      value: Object.keys(cumulativeData.deviceCounts).length,
      icon: Smartphone,
    },
  ];

  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

  const generateCountryCodeMap = () => {
    const countryCodeMap = {};
    const countryNames = countries.getNames('en');

    Object.entries(countryNames).forEach(([alpha2Code, name]) => {
      countryCodeMap[name] = alpha2Code;
      // Add lowercase version
      countryCodeMap[name.toLowerCase()] = alpha2Code;
      // Add version without "the" if it starts with "the"
      if (name.toLowerCase().startsWith('the ')) {
        countryCodeMap[name.slice(4)] = alpha2Code;
      }
    });

    // Add special cases
    const specialCases = {
      'United Kingdom of Great Britain and Northern Ireland': 'GB',
      UK: 'GB',
      'United Kingdom': 'GB',
      'Great Britain': 'GB',
      America: 'US',
      'United States of America': 'US',
      USA: 'US',
      'Republic of Korea': 'KR',
      'South Korea': 'KR',
      "Democratic People's Republic of Korea": 'KP',
      'North Korea': 'KP',
      Russia: 'RU',
      'Russian Federation': 'RU',
      'Korea, Republic of': 'KR',
    };

    Object.entries(specialCases).forEach(([name, code]) => {
      countryCodeMap[name] = code;
      countryCodeMap[name.toLowerCase()] = code;
    });

    return countryCodeMap;
  };

  const countryCodeMap = useMemo(() => generateCountryCodeMap(), []);

  // const countriesData =

  const countryData = Object.entries(cumulativeData?.countryCounts || {}).map(
    ([country, count]) => ({
      country: countryCodeMap[country],
      countryCode: countryCodeMap[country] || '',
      value: count,
    }),
  );

  const graphData = [
    ['Month', 'Clicks'],
    ...(cumulativeData?.monthWiseClicks || []).map(
      ({ month, year, clicks }) => [
        `${startCase(new Date(year, month - 1).toLocaleString('default', { month: 'long' }))}`,
        clicks,
      ],
    ),
  ];

  return (
    <div className="space-y-8 ">
      {/* Workspace Analytics Section */}
      <section className="space-y-6">
        <div className="flex justify-between">
          <h1 className="text-2xl font-bold m-0 pt-2">Workspace Analytics</h1>
          {DataPicker}
        </div>

        <div className="grid grid-cols-4 gap-4">
          {workspaceStats.map((stat, index) => (
            <Card key={index} className="bg-white-0">
              <CardContent className="flex gap-3 p-4">
                <stat.icon className="h-5 w-5 text-muted-foreground shrink-0" />
                <div className="">
                  <p className="text-xs font-medium text-muted-foreground m-0">
                    {stat.title}
                  </p>
                  <p className="text-lg font-semibold mt-3 mb-0">
                    {stat.value}
                  </p>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      {/* Teammate Analytics Section */}
      <section className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <div className=" w-full">
            <Card className="shadow-none">
              <CardTitle className="font-primary text-h7 text-neutral-1000">
                Region
              </CardTitle>

              <div className="flex justify-center">
                {color && countryData && (
                  <WorldMap
                    color={color}
                    value-suffix="people"
                    size="lg"
                    data={countryData}
                  />
                )}
              </div>
            </Card>
          </div>

          <div className="rounded-lg">
            <Card className="shadow-none rounded-lg">
              <CardTitle className="font-primary text-h7 text-neutral-1000">
                Clicks
              </CardTitle>
              <div className="flex justify-center">
                {graphData?.length > 1 ? (
                  <div className='p-4 pb-0'>
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="350px"
                      data={graphData}
                      options={options}
                    />
                  </div>
                ) : (
                  <Empty className="my-[101px]" />
                )}
              </div>
            </Card>
          </div>
        </div>
      </section>
      <p className='text-[12px] text-[red] !mt-0 py-3'>*Kindly note: The data shown here updates every hour to ensure accuracy and reliability.</p>
      </div>
  );
};

export default Analytics;
