import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ERROR_PAGE_SUBTITLE, ERROR_PAGE_TITLE } from 'common/constants';

const MyFallbackComponent = ({ error, componentStack }) => (
  <div className="flex flex-col items-center justify-center h-full">
    <div className="text-center mb-8">
      <h2 className="text-2xl font-bold">{ERROR_PAGE_TITLE}</h2>
      <p>{ERROR_PAGE_SUBTITLE}</p>
    </div>
    <div className="flex flex-col items-center">
      <div className="error-info mb-4">
        <h4 className="text-lg font-bold">Error:</h4>
        <p>{error && error.message && error.message.toString()}</p>
      </div>
      <div className="error-info">
        <h4 className="text-lg font-bold">Stacktrace:</h4>
        <p> <CloseCircleOutlined/> Your {componentStack}</p>
      </div>
    </div>
  </div>
);

export default MyFallbackComponent;


// const MyFallbackComponent = ({ error, componentStack }) => (
//   <Result
//     status="error"
//     title={ERROR_PAGE_TITLE}
//     subTitle={ERROR_PAGE_SUBTITLE}
//   >
//     <div className="desc">
//       <Paragraph>
//         <Typography.Title level={4}> Error:</Typography.Title>
//       </Paragraph>
//       <Paragraph>
//         <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
//         {error?.message?.toString()}
//       </Paragraph>
//       <Paragraph>
//         <Typography.Title level={4}> Stacktrace:</Typography.Title>
//       </Paragraph>
//       <Paragraph>
//         <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
//         {componentStack}
//       </Paragraph>
//     </div>
//   </Result>
// );

// export default MyFallbackComponent;