import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPSERT_SIGNATURE = gql`
  mutation UpsertSignature(
    $data: CreateSignatureInput!
    $where: SignatureUniqueWhereInput
  ) {
    upsertSignature(data: $data, where: $where) {
      id
      workspaceId
      installation
      fields
      information {
        personalInformation {
          name
          position
          department
        }
        businessInformation {
          companyName
          companyAddress
          companyLogo
          logoWidth
        }
        contactInformation {
          email
          phone
          website
          websiteShortLink
          emailShortLink
        }
        socialLinks
        shortSocialLinks
        footer
      }
      design {
        primaryBrandColor
        font
        theme
        icons
        styles
      }
      cta {
        title
        url
      }
      templateId
      headshotId
      headshotUrl
      headshot {
        id
        image
        config
        styleId
        style {
          id
          name
          image
          key
          description
          isActive
        }
      }
      template {
        id
        name
        image
        key
        description
        isActive
        config
        templateId
      }
      name
    }
  }
`;

export const REMOVE_SIGNATURE = gql`
  mutation RemoveSignature($where: SignatureUniqueWhereInput!) {
    removeSignature(where: $where) {
      id
      name
      fields
      information {
        personalInformation {
          name
          position
          department
        }
        businessInformation {
          companyName
          companyAddress
          companyLogo
          logoWidth
        }
        contactInformation {
          email
          phone
          website
          websiteShortLink
          emailShortLink
        }
        socialLinks
        shortSocialLinks
        footer
      }
      design {
        primaryBrandColor
        font
        theme
        styles
        icons
      }
      cta {
        title
        url
        shortUrl
      }
      templateId
      headshotId
      headshotUrl
      template {
        id
        name
        image
        key
        description
        isActive
        config
        templateId
      }
    }
  }
`;

export const SEND_SIGNATURE = gql`
  mutation SendSignature($data: SendSignatureInput!) {
    sendSignature(data: $data) {
      __typename
    }
  }
`;
