import { AI_MODELS } from 'common/constants'
import { getLiveUrl } from 'common/utils'

const uuid = () => Math.random().toString(36).substring(2, 15)

export const getModalImages = name =>
  `https://static.dev.sendsig.com/pose-images/${name}.jpeg`

const V1_MODELS_MEN = [
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a male img, wearing suit with tie, background is office, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'male',
    exampleImage: getModalImages('v1-male-1'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a male img, wearing business wear, background is library, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'male',
    exampleImage: getModalImages('v1-male-2'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a male img, wearing formal shirt, background is cafe, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'male',
    exampleImage: getModalImages('v1-male-3'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a male img, wearing blazer, background is park, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'male',
    exampleImage: getModalImages('v1-male-4'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a male img, wearing business wear, background is office, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'male',
    exampleImage: getModalImages('v1-male-5'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a male img, wearing suit with tie, background is library, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'male',
    exampleImage: getModalImages('v1-male-6'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a male img, wearing blazer, background is cafe, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'male',
    exampleImage: getModalImages('v1-male-7'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a male img, wearing formal shirt, background is park, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'male',
    exampleImage: getModalImages('v1-male-8'),
    shouldLock: true,
  },
]

const V1_MODELS_WOMEN = [
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a female img, wearing suit with tie, background is office, 50mm portrait photography, natural lighting photography, shallow focus',

    gender: 'female',
    exampleImage: getModalImages('v1-female-1'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a female img, wearing business wear, background is library, 50mm portrait photography, natural lighting photography, shallow focus',

    gender: 'female',
    exampleImage: getModalImages('v1-female-2'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a female img, wearing formal shirt, background is cafe, 50mm portrait photography, natural lighting photography, shallow focus',

    gender: 'female',
    exampleImage: getModalImages('v1-female-3'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a female img, wearing blazer, background is park, 50mm portrait photography, natural lighting photography, shallow focus',

    gender: 'female',
    exampleImage: getModalImages('v1-female-4'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a female img, wearing business wear, background is office, 50mm portrait photography, natural lighting photography, shallow focus',

    gender: 'female',
    exampleImage: getModalImages('v1-female-5'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a female img, wearing suit with tie, background is library, 50mm portrait photography, natural lighting photography, shallow focus',

    gender: 'female',
    exampleImage: getModalImages('v1-female-6'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a female img, wearing blazer, background is cafe, 50mm portrait photography, natural lighting photography, shallow focus',

    gender: 'female',
    exampleImage: getModalImages('v1-female-7'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a female img, wearing formal shirt, background is park, 50mm portrait photography, natural lighting photography, shallow focus',

    gender: 'female',
    exampleImage: getModalImages('v1-female-8'),
    shouldLock: true,
  },
]

const V2_MODELS_MEN = [
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_91',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_91'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_93',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_93'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_2_10_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage: getModalImages('CEO_2_10_male'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_92',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_92'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_133',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_133'),
    shouldLock: true,
  },

  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: '90s_trend_2_male',
    prompt_set: 'photoaistudio_90s_trend',
    gender: 'male',
    exampleImage: getModalImages('90s_trend_2_male'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'architectural_mastermind_20_male',
    prompt_set: 'photoaistudio_architectural_mastermind',
    gender: 'male',
    exampleImage: getModalImages('architectural_mastermind_20_male'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_125',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_125'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_82',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_82'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_83',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('  professional_headshot_83'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_84',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_84'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_2_31_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage: getModalImages('CEO_2_31_male'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_86',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_86'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_89',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_89'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_95',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_95'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_90',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_90'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_87',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_87'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_10_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage: getModalImages('CEO_1_10_male'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_1_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage: getModalImages('CEO_4_1_male'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_19_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffb95-fa71-47d3-aa69-830c0286a57d.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_26_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffb99-ded9-4407-af5b-63edb9eb86e1.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_30_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffb9b-d43c-4d56-a302-b9f6daca8ccb.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_31_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffb9d-c8b1-4b2e-9ad2-201d85f83287.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_2_17_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffba3-b86a-4c49-88dd-2051aa21b9bd.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_2_31_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffba9-9504-49a2-af21-661a258858f9.png',
    shouldLock: true,
  },

  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_6_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbab-9fd3-4110-900d-c09d971cb390.png',
    shouldLock: true,
  },

  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_19_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbb3-805e-47b5-844f-aed921c5b1ec.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_2_19_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffba5-adaa-4658-ba5c-6a999c346bf8.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_31_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbb9-62a0-4484-8a2a-31168f2fca7d.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_1_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbbb-542a-4eef-8de4-c6e016ab3c98.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_11_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbc3-3928-4d1c-904f-789e3ca0b74d.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_24_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbc7-1dfc-4712-94a1-a2248597a6c7.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_5_11_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbcb-2d67-44c3-b249-98d03bb012a4.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_5_24_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbcd-203b-4f4c-8d5c-6873b20f1184.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_5_27_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbcf-1f87-4603-9b91-6f6b8810f2a4.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_6_11_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbd3-0473-4a16-8c48-99c9907ddd6a.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_7_5_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbda-ece1-4107-8eaa-38a20d374151.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_2_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbe2-d60b-4ffa-8668-1e2628a6db37.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_22_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbe4-d873-40f0-a676-bc04d3f62682.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_2_24_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbe6-c533-4494-9ec2-8d5d9646cb7a.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_5_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbea-ad97-4970-b626-3af0c367962f.png',
    shouldLock: true,
  },
]

const V2_MODELS_WOMEN = [
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_10_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage: getModalImages('CEO_1_10_female'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_7_14_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage: getModalImages('CEO_7_14_female'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_7_1_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage: getModalImages('CEO_7_1_female'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_6_23_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage: getModalImages('CEO_6_23_female'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_377',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'female',
    exampleImage: getModalImages('professional_headshot_377'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: '90s_trend_2_female',
    prompt_set: 'photoaistudio_90s_trend',
    gender: 'female',
    exampleImage: getModalImages('90s_trend_2_female'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'architectural_mastermind_20_female',
    prompt_set: 'photoaistudio_architectural_mastermind',
    gender: 'female',
    exampleImage: getModalImages('architectural_mastermind_20_female'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'yearbook_328',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'female',
    exampleImage: getModalImages('yearbook_328'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_432',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'female',
    exampleImage: getModalImages('professional_headshot_432'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_433',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'female',
    exampleImage: getModalImages('professional_headshot_433'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_434',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'female',
    exampleImage: getModalImages('professional_headshot_434'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_390',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'female',
    exampleImage: getModalImages('professional_headshot_390'),
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_10_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffb8e-200f-4764-b033-7a3d12da131c.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_18_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffb94-0b67-49a7-aa48-29a18b209f13.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_23_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffb97-eb6c-4600-8c6b-b283612a6523.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_2_11_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffba1-b662-43d9-886c-e25707445e9b.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_2_26_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffba7-9ed1-405e-89f6-f167d9e09dc2.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_10_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbad-9673-4d2e-997e-bd05c3282252.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_11_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbaf-9197-4525-aa09-f96c173c223a.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_14_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbb1-8d07-4fca-a7ae-5fa5cd1a711f.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_19_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbb5-7870-49af-88a5-512ab6ae9ada.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_20_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbb7-6e3f-4352-b6ac-67de146a79f5.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_3_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbbd-494e-4d4b-8914-3338841e7af7.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_8_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbbf-490b-4970-ad5a-84539e66cc7e.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_10_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbc1-43ba-43f6-8273-3bd97ad89c84.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_29_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbc9-1f15-47ef-a437-4f12dc53a8f6.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_5_28_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbd1-1997-4130-8c58-d07499200aab.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_6_23_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbd5-0da7-4c74-ab5d-8fbb3efec8da.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_6_26_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbd7-0eee-49a2-b490-0acf0230c2df.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_7_1_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbd9-002b-4315-ab1c-085ab7c3f511.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_7_14_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbdc-e21f-4fec-9157-4f4c41d41c12.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_7_16_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbde-e317-4069-92ad-f2cdafbb539d.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_7_17_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbe0-e2b7-4d07-a789-c95f79cc3750.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_21_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbe8-ba4c-48fa-bcc1-7ea385701d54.png',
    shouldLock: true,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_4_6_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbec-a4ce-4fe3-92fb-dc78742dfe7f.png',
    shouldLock: true,
  },
]

export const V1_MODELS = [...V1_MODELS_MEN, ...V1_MODELS_WOMEN]

export const V2_MODELS = [...V2_MODELS_MEN, ...V2_MODELS_WOMEN]

export const FIRST_TIME_MODEL_V1 = [
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a male img, wearing formal shirt, background is office, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'male',
    exampleImage: getModalImages('v1-male-1'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v1,
    prediction_key: null,
    prompt_set:
      'Headshot portrait of a female img, wearing formal shirt, background is office, 50mm portrait photography, natural lighting photography, shallow focus',
    gender: 'female',
    exampleImage: getModalImages('v1-female-1'),
    shouldLock: false,
  },
]

export const FIRST_TIME_MODEL_V2 = [
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'dating_47',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('dating_47'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'landmark_selfie_1_female',
    prompt_set: 'photoaistudio_landmark',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_landmark/9b56d627-9cce-4b59-9899-66ae4ea4886d.png',
    shouldLock: false,
  },
]

export const V2_PACKAGE_MODELS = [
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'professional headshot_89',
    prompt_set: 'photoaistudio_cyberrealistic_lcm',
    gender: 'male',
    exampleImage: getModalImages('professional_headshot_89'),
    shouldLock: false,
  },

  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_1_10_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage: getModalImages('CEO_1_10_male'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_2_31_male',
    prompt_set: 'photoaistudio_CEO',
    gender: 'male',
    exampleImage: getModalImages('CEO_2_31_male'),
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_5_28_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbd1-1997-4130-8c58-d07499200aab.png',
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_6_23_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbd5-0da7-4c74-ab5d-8fbb3efec8da.png',
    shouldLock: false,
  },
  {
    id: uuid(),
    model: AI_MODELS.v2,
    prediction_key: 'CEO_3_19_female',
    prompt_set: 'photoaistudio_CEO',
    gender: 'female',
    exampleImage:
      'https://api.edsdev.ca/storage/images/prompt_demo/photoaistudio_II/photoaistudio_CEO/9b2ffbb5-7870-49af-88a5-512ab6ae9ada.png',
    shouldLock: false,
  },
]
