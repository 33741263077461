/* eslint-disable arrow-body-style */
import { cn } from 'lib/utils';
import { LoaderCircle } from 'lucide-react';
import React from 'react';

const LoaderComponent = ({
  text = '',
  classNames = '',
  circleClassName = '',
}) => {
  return (
    <div
      className={cn(
        'fixed inset-0 flex justify-center items-center bg-white/80',
        'h-screen w-screen',
        classNames,
      )}
    >
      <LoaderCircle
        className={cn(
          'w-10 h-10 text-primary-foreground animate-spin',
          circleClassName,
        )}
      />
      {text && <p className="text-lg text-blue-600 ml-4">{text}</p>}
    </div>
  );
};

export default LoaderComponent;
