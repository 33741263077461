/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { DialogContent, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';

// Zod validation schema
const groupSchema = z.object({
  name: z.string().min(1, 'Group name is required'),
});

const EditForm = ({ data, onClose, handleOnClick }) => {
  const form = useForm({
    resolver: zodResolver(groupSchema),
    defaultValues: {
      name: data?.name ?? '',
    },
  });

  useEffect(() => {
    if (data) {
      form.reset({ name: data.name });
    }
  }, [data, form]);

  return (
    <DialogContent
      className="rounded-[6px] max-w-md px-[21px] py-[22px]"
      hideCloseButton
    >
      <DialogHeader>
        <DialogTitle>Edit Group</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnClick)}>
          <div className="grid gap-[18px]">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-lg">Group Name</FormLabel>
                  <FormControl>
                    <Input
                      id="name"
                      placeholder="Enter Group Name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-3 ">
              <Button
                type="button"
                className="w-[max-content]"
                onClick={() => onClose()}
                variant="outline"
                size="sm"
              >
                Cancel
              </Button>
              <Button type="submit" size="sm">
                Update
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </DialogContent>
  );
};

export default EditForm;
