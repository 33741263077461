import React from 'react';
import { useGetUserSignatureDetails } from 'hooks/user';
import SignatureInfo from './SignatureInfo';

const UserSignatureInfo = () => {
  const { userSignatureLimit, userAssignedSignature, userUnassignedSignature } =
    useGetUserSignatureDetails();
  return (
    <div className="h-[109px]  max-w-[1110px] border-solid border-1 border-gray-200 text-[16px] font-[500] text-[#958DA5] flex justify-start items-start leading-[20px] gap-2">
      <SignatureInfo title="User signature limit" value={userSignatureLimit} />
      <SignatureInfo
        title="Assigned signatures"
        value={userAssignedSignature}
      />
      <SignatureInfo
        title="Unassigned signatures"
        value={userUnassignedSignature}
      />
    </div>
  );
};

export default UserSignatureInfo;
