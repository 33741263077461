import { ApolloProvider } from '@apollo/client';
import client from 'apollo';
import { AppContextProvider } from 'AppContext';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { BrowserRouter as Router } from 'react-router-dom';

const useComponentToHtml = () => {
  const convertToHtml = (Component, props = {}) =>
    // Render the component to HTML string with necessary context providers
    ReactDOMServer.renderToString(
      <ApolloProvider client={client}>
        <Router>
          <AppContextProvider>
            <Component {...props} />
          </AppContextProvider>
        </Router>
      </ApolloProvider>,
    );
  return { convertToHtml };
};

export default useComponentToHtml;
