/* eslint-disable no-nested-ternary */
import { useMutation } from '@apollo/client';
import { ChevronRight, Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import SignupCover from 'assets/images/signup-cover.png';
import {
  EMAIL_PLACEHOLDER,
  JOURNEYS,
  MINUTES,
  SECONDS,
} from 'common/constants';
import { Input } from 'components/ui/input';
import Timer from 'components/Timer';
import darkLogo from 'assets/png/logo-dark.png';
import aiSignup from 'assets/images/ai-headhsot-signup.jpg';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import { useUserJourney } from 'hooks/auth';
import { useGetBrandDetails } from 'hooks/brand';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const formSchema = z.object({
  email: z
    .string()
    .email({
      message: 'Please enter a valid email address.',
    })
    .nonempty({ message: 'Email is required.' }),
});

const ForgetPassword = () => {
  const { logoUrl, name } = useGetBrandDetails();
  const [seconds, setSeconds] = useState(SECONDS);
  const [minutes, setMinutes] = useState(MINUTES);
  const [timerComplete, setTimerComplete] = useState(false);
  const [resendLink, setResendLink] = useState(false);
  const [mailId, setMailId] = useState('');

  const { journey } = useUserJourney();

  const [ResetRequest, { loading: resetLoading }] = useMutation(
    FORGOT_PASSWORD,
    {
      onCompleted() {
        setTimerComplete(true);
      },
      onError() {}, // Always write this method for error handling in all mutation.
    },
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { email: '' },
  });
  const onFinish = async (values) => {
    setMailId(values?.email);

    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
      };
      await ResetRequest({
        variables: { data: { ...formValues } },
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="flex min-h-screen h-full max-h-screen">
      <div
        className={cn(
          'flex flex-col justify-center w-full bg-primary-foreground p-[16px] overflow-auto',
          { 'bg-[#0A0B0C]': journey === JOURNEYS.AI_HEADSHOT },
        )}
      >
        <div className="w-full max-w-[600px] mx-[auto] flex flex-col items-center">
          <img
            src={
              journey === JOURNEYS.AI_HEADSHOT
                ? darkLogo
                : logoUrl || '/logo.svg'
            }
            height={40}
            width="auto"
            alt="logo"
          />
          <h1 className="text-primary font-primary text-[32px] font-semibold leading-[36px] mt-8 mb-0">
            {!timerComplete
              ? 'Forgot password'
              : resendLink
                ? 'Reset password'
                : 'Email sent'}
          </h1>
          <p className="text-secondary-500 text-[14px] font-medium leading-[25px] mb-9 w-full flex items-center justify-center mt-2">
            {!timerComplete
              ? 'We will send you a link to reset your password on your registered email address.'
              : resendLink
                ? `Send an email on ${mailId} to reset your password`
                : `Password reset link sent to ${mailId}`}
          </p>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onFinish)}
              form={form}
              initialValues={{ remember: true }}
              className="space-y-[25px] w-full"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className=" font-medium  text-[12px]">
                    <FormLabel
                      className={
                        (cn(' text-darkblue'),
                        {
                          ' text-white-0 ': journey === JOURNEYS.AI_HEADSHOT,
                        })
                      }
                    >
                      Email
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1  hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground  hover:shadow-custom py-1"
                        placeholder={EMAIL_PLACEHOLDER}
                        disabled={resetLoading}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                htmlType="submit"
                className="h-[40px] w-full font-primary text-button-s mt-2 flex justify-center items-center text-white-0"
                disabled={timerComplete}
              >
                {resetLoading && (
                  <Loader2 className="animate-spin mr-2 h-5 w-5 " />
                )}
                <div className="text-white-0 flex items-center">
                  {!timerComplete ? (
                    `${resendLink ? 'Resend link' : 'Send link'}`
                  ) : (
                    <div className="flex justify-center items-center  ">
                      <span className="mr-2 flex items-center text">
                        Resend link in <ChevronRight />
                      </span>
                      <Timer
                        seconds={seconds}
                        minutes={minutes}
                        setMinutes={setMinutes}
                        setSeconds={setSeconds}
                        setTimerComplete={setTimerComplete}
                        setResendLink={setResendLink}
                      />
                    </div>
                  )}
                </div>
              </Button>
            </form>
          </Form>
        </div>
      </div>
      {/* <div
        style={{
          backgroundImage: `url(${
            journey === JOURNEYS.AI_HEADSHOT ? aiSignup : SignupCover
          })`,
        }}
        className="lg:hidden flex w-1/2 min-h-full gap-2.5 shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center"
      /> */}
    </div>
  );
};

export default ForgetPassword;
