import * as React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from 'lib/utils';
import { Error500 } from 'assets/svg';

const resultVariants = cva(
  'inline-flex items-center rounded border px-2.5 py-0.5 text-xs  transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-none bg-primary-foreground hover:bg-primary-foreground',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

function Result({ className, variant, icon, title, ...props }) {
  return (
    <div className={cn(resultVariants({ variant }), className)} {...props}>
      <div>
        <div>
          <Error500 />
        </div>
        <div className="text-black-88 text-24 leading-[1.3333] my-2 text-center">
          {title}
        </div>
      </div>
    </div>
  );
}

export { Result, resultVariants };
