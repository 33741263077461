/* eslint-disable arrow-body-style */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useAppContext } from 'AppContext';

// Define validation schema with zod
const emailSchema = z.object({
  email: z.string().email('Invalid email address').min(1, 'Email is required'),
});

const GoogleConnectDialog = forwardRef(
  ({ handleDataOnSubmit, connecting }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const { state: currentUser } = useAppContext();

    const form = useForm({
      resolver: zodResolver(emailSchema),
      defaultValues: {
        email: currentUser?.currentUser?.email,
      },
    });
    // Expose open and close functions to the parent using `useImperativeHandle`
    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => {
        form.reset();
        setIsOpen(false);
      },
    }));

    const {
      handleSubmit,
      control,
      formState: { errors },
    } = form;

    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="rounded-[6px] max-w-md">
          <Form {...form}>
            <form onSubmit={handleSubmit(handleDataOnSubmit)}>
              <DialogHeader>
                <DialogTitle>Connect to Google Workspace</DialogTitle>
              </DialogHeader>
              <div className="flex items-center space-x-2">
                <FormField
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormLabel>Email Address</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          id="email"
                          placeholder="Enter Google Workspace Admin Email"
                          className="h-[30px]"
                        />
                      </FormControl>
                      <FormMessage>{errors.email?.message}</FormMessage>
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex justify-end gap-3 pt-4">
                <Button
                  type="button"
                  className="w-[max-content]"
                  onClick={() => setIsOpen(false)}
                  variant="outline"
                  size="sm"
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={connecting} size="sm">
                  {connecting ? 'Connecting...' : 'Submit'}
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    );
  },
);

export default GoogleConnectDialog;
