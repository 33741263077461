import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from 'lib/utils';

const Switch = React.forwardRef(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      // "overflow-hidden rounded-full h-full transition duration-200 ease-in-out cursor-pointer text-gray-0",
      'peer bg-primary inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full  border-0 focus-visible:outline-none  disabled:cursor-not-allowed disabled:bg-gray-1 data-[state=checked]:bg-primary data-[state=unchecked]:bg-gray-1',
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block  h-[calc(100%_-_4px)] w-[auto] aspect-square rounded-full bg-white-0 ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0',
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

// eslint-disable-next-line import/prefer-default-export
export { Switch };
