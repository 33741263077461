/* eslint-disable arrow-body-style */
import React from 'react';
import Signatures from './components/Signatures';

const Assets = () => {
  return (
    <div className="bg-primary-foreground w-full h-[calc(100vh_-_71px)] overflow-auto">
      <div className="p-[24px] ">
        <Signatures />
      </div>
    </div>
  );
};

export default Assets;
