import React from 'react';

const AppleMailContent = () => (
  <div className="p-4">
    <div className="text-center mb-6">
      <h1 className="text-2xl font-semibold">
        Set Up Your Signature - Apple Mail (Mac OS)
      </h1>
    </div>

    <div className="mt-6 steps">
      <ol className="list-none p-0 m-0 space-y-6">
        <li className="flex items-start space-x-4">
          <span>
            With the Mail application open, click <strong>Mail</strong> and then{' '}
            <strong>Preferences</strong> in the top left corner of your screen.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            From the preferences pop-up, click the <strong>Signatures</strong>{' '}
            tab.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Click the <strong>+</strong> button at the bottom of the middle
            column.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Copy and paste your new email signature into the right-hand column
            and close the pop-up window to save.
          </span>
        </li>
        <div className="bg-gray-100 border border-gray-200 p-4 rounded-md text-yellow-800 mb-4">
          <strong>IMPORTANT:</strong> Ensure that "Always match my default
          message font" is unchecked; otherwise, your signature will appear in
          plain text.
        </div>
        <div className="bg-gray-100 border border-gray-200 p-4 rounded-md text-yellow-800 mb-4">
          <strong>IMPORTANT:</strong> Still seeing a plain text version? Try
          toggling the "Place signature above quoted text" checkbox and pasting
          in your signature again.
        </div>
        <li className="flex items-start space-x-4">
          <span>
            Select your new signature in the <strong>Choose Signature</strong>{' '}
            field near the bottom of the pop-up.
          </span>
        </li>
        <li className="flex items-start space-x-4">
          <span>
            Close the Preferences pop-up, compose a new email, and enjoy your
            new signature!
          </span>
        </li>
        <div className="bg-gray-100 border border-gray-200 p-4 rounded-md text-yellow-800 mb-4">
          <strong>IMPORTANT:</strong> Images not appearing when you compose an
          email? Go to your Privacy settings and ensure that{' '}
          <strong>Block All Remote Content</strong> is unchecked.
        </div>
      </ol>
    </div>
  </div>
);

export default AppleMailContent;
