import { gql } from '@apollo/client';

export const CREATE_LM_CHECKOUT_LINK = gql`
  query CreateLmCheckoutLink($data: CheckoutInput!) {
    createLmCheckoutLink(data: $data) {
      url
    }
  }
`;

export const GET_USER_AI_HEADSHOTS = gql`
  query GetUserAIHeadshots {
    getUserAIHeadshots {
      data {
        id
        userId
        step
        isPaid
        image
        previousImages
        credits
        type
        status
      }
    }
  }
`;

export const GET_PREDICTION = gql`
  query GetPrediction($data: PredictionInput!) {
    getPrediction(data: $data) {
      data {
        id
        userId
        credits
        image
        previousImages
        type
        status
      }
    }
  }
`;
