import React from 'react';

import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';

const SignatureTemplate = ({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) => {
  const TemplateComponent = dynamicTemplate({
    templateKey: signature?.template?.key,
  });

  return TemplateComponent ? (
    <TemplateComponent
      signature={signature}
      showAwsIcons={showAwsIcons}
      hideBranding={hideBranding}
    />
  ) : (
    ''
  );
};

export default SignatureTemplate;
