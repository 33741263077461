/* eslint-disable import/prefer-default-export */
const { useLazyQuery } = require('@apollo/client');
const {
  GET_CTA_BANNER_SIGN_URL,
} = require('modules/Signature/graphql/Queries');

export const useGetCtaBannerSignedUrl = () => {
  const [getCtaBannerSignUrl] = useLazyQuery(GET_CTA_BANNER_SIGN_URL, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {},
    onError: (err) => {},
  });

  return [getCtaBannerSignUrl];
};
