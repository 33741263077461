/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */

import {
  useCreateAIHeadshotMutation,
  useGetPrediction,
  useGetUserAIHeadshots,
  useUpdateStepMutation,
} from 'hooks/ai-headshot';
import React, { useEffect, useState } from 'react';
import { get, partition } from 'lodash';
import { useAppContext } from 'AppContext';

import LoaderComponent from 'components/LoaderComponent';
import { AI_MODELS } from 'common/constants';
import { ChevronLeft } from 'lucide-react';
import HeadshotGenerator from './components/HeadshotGenerator';
import ImageUpload from './components/ImageUpload';
import {
  INPUTS,
  V1_INPUT,
  delay,
} from './components/HeadshotGenerator/HeadshotGenerator';
import Results from './components/Results';
import ShowAllImages from './components/ShowAllImages';
import GenderSelection from './components/GenderSelection';

const AiHeadshot = () => {
  const {
    state: { aiHeadshot, step },
    setAiHeadshot,
    setStep,
  } = useAppContext();
  const versionMap = {
    v1: AI_MODELS.v1,
    v2: AI_MODELS.v2,
  };
  const reversedVersionMap = {};
  Object.keys(versionMap).forEach((key) => {
    reversedVersionMap[versionMap[key]] = key;
  });
  const [gender, setGender] = useState('male');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateStep] = useUpdateStepMutation();
  const [createAIHeadshotMutation] = useCreateAIHeadshotMutation();
  const [getUserAIHeadshots] = useGetUserAIHeadshots();
  const [getPrediction] = useGetPrediction();
  const handleStepChange = (step) => {
    if (step > 3 && aiHeadshot?.id) {
      updateStep({ variables: { data: { step, id: aiHeadshot?.id } } });
    }
    setStep(step);
  };

  const fetchIntialHeadshots = async () => {
    const response = await getUserAIHeadshots();
    const [aiHeadshotRes] = get(response, 'data.getUserAIHeadshots.data', [
      null,
    ]);

    setAiHeadshot(aiHeadshotRes);
  };

  const fetchInitalData = async () => {
    setLoading(true);
    await fetchIntialHeadshots();
    setLoading(false);
  };

  useEffect(() => {
    if (!aiHeadshot?.isPaid) {
      setStep(aiHeadshot?.step ?? 1);
    }
  }, [aiHeadshot?.isPaid]);

  useEffect(() => {
    fetchInitalData();
  }, []);

  const generateAiHeadshot = async (modelData) => {
    try {
      const { model } = modelData;

      const inputImages = {
        [AI_MODELS.v1]: {
          ...V1_INPUT,
          pose_image: image ?? aiHeadshot?.image?.input,
          main_face_image: image ?? aiHeadshot?.image?.input,
          prompt: modelData.prompt_set,
        },
        [AI_MODELS.v2]: {
          main_face_image: image ?? aiHeadshot?.image?.input,
        },
      };

      const configs = {
        [AI_MODELS.v1]: {
          category: 'photo manipulation',
          model: AI_MODELS.v1,
        },
        [AI_MODELS.v2]: {
          prediction_key: modelData.prediction_key,
          prompt_set: modelData.prompt_set,
          model: AI_MODELS.v2,
        },
      };

      const initialInput = INPUTS[model] ?? {};
      const initalInputImages = inputImages[model] || {};

      const inputData = {
        ...initialInput,
        ...initalInputImages,
      };

      const res = await createAIHeadshotMutation({
        variables: {
          data: {
            input: inputData,
            config: configs[model],
          },
        },
      });

      const data = get(res, 'data.createAIHeadshot.data', null);
      if (!data) {
        throw new Error('No data received from AI headshot generation');
      }

      if (data?.previousImages) {
        setAiHeadshot({
          ...aiHeadshot,
          ...data,
          previousImages: data?.previousImages,
        });
      }

      return data;
    } catch (error) {
      console.error(
        `Error generating AI headshot for model ${modelData.model}:`,
        error,
      );
      throw error;
    }
  };

  const handleProccessModel = async (models) => {
    try {
      const promises = models.map((model) => generateAiHeadshot(model));
      const results = await Promise.all(promises);
      return results;
    } catch (error) {
      console.error('Error processing models:', error);
      throw error;
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh_-_71px)] bg-[transparent]  overflow-hidden ">
        <LoaderComponent />
      </div>
    );
  }

  const previousImages = get(aiHeadshot, 'previousImages', []);

  const BackButton = (
    <div className=" z-30 text-white-0">
      <div
        className=" text-white-0 leading-[16px] font-[500] text-[16px] flex items-center cursor-pointer justify-start"
        onClick={() => setStep(1)}
      >
        <ChevronLeft size={20} className="mr-2" />
        Back to editor
      </div>
    </div>
  );

  const [prevImagesV1, prevImagesV2] = partition(
    previousImages,
    (image) => image.model === AI_MODELS.v1,
  );

  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <GenderSelection
            gender={gender}
            setGender={setGender}
            setStep={handleStepChange}
          />
        );
      case 2:
        return (
          <ImageUpload
            image={image}
            setImage={setImage}
            setStep={handleStepChange}
          />
        );
      case 3:
        return (
          <HeadshotGenerator
            gender={gender}
            handleProccessModel={handleProccessModel}
            setStep={handleStepChange}
            image={image}
          />
        );

      case 4:
        return aiHeadshot?.isPaid ? (
          <ShowAllImages
            BackButton={BackButton}
            prevImagesV1={prevImagesV1}
            prevImagesV2={prevImagesV2}
            getPrediction={getPrediction}
          />
        ) : (
          <Results
            handleProccessModel={async (addedModels) => {
              await handleProccessModel(addedModels);
              await fetchIntialHeadshots();
            }}
            previousImages={previousImages}
            getPrediction={getPrediction}
            setStep={handleStepChange}
            fetchIntialHeadshots={fetchIntialHeadshots}
          />
        );

      case 5:
        return (
          <ShowAllImages
            BackButton={BackButton}
            prevImagesV1={prevImagesV1}
            prevImagesV2={prevImagesV2}
            getPrediction={getPrediction}
          />
        );
      default:
        <ImageUpload setImage={setImage} setStep={handleStepChange} />;
    }
  };

  return (
    <div className="flex items-center justify-center h-full overflow-auto bg-[transparent] relative   ">
      <div
        id="sasasasasasaa"
        className=" w-[100%] flex flex-col items-center  justify-center  bg-[transparent]"
      >
        {getComponent()}
      </div>
    </div>
  );
};

export default AiHeadshot;
