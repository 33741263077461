import React from 'react';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from 'react-share';
import { ArrowRight } from 'lucide-react';
import { Dialog, DialogContent, DialogOverlay } from 'components/ui/dialog';
import { getLiveUrl } from 'common/utils';
import Aiheadshotimg from 'assets/images/Aiheadshot-bg.jpg';
import BeforeAfterCard from './BeforeAfterCard';

const ShareButtons = [
  {
    id: 1,
    Component: EmailShareButton,
    Icon: EmailIcon,
    shareProps: {
      body:'I just created my free AI headshot using SyncSignature. Create yours today for free!',
      subject:'Free AI Headshot by SyncSignature',
      url:'https://app.syncsignature.com/auth/signup?r=ai-headshot',
    },
    iconProps: {
      style: {
        backgroundColor: '#F2F1EB',
      },
    },
  },
  {
    id: 2,
    Component: FacebookShareButton,
    Icon: FacebookIcon,
    shareProps: {
      quote:'I just created my free AI headshot using SyncSignature. Create yours today for free!',
      url:'https://app.syncsignature.com/auth/signup?r=ai-headshot',
    },
    iconProps: {
      style: {
        backgroundColor: '#E7F3FF',
      },
    },
  },
  {
    id: 3,
    Component: TwitterShareButton,
    Icon: TwitterIcon,
    shareProps: {
      title:'I just created my free AI headshot using SyncSignature. Create yours today for free!',
      url:'https://app.syncsignature.com/auth/signup?r=ai-headshot',
    },
    iconProps: {
      style: {
        backgroundColor: '#E7F3FF',
      },
    },
  },
  {
    id: 4,
    Component: WhatsappShareButton,
    Icon: WhatsappIcon,
    shareProps: {
      url:'https://app.syncsignature.com/auth/signup?r=ai-headshot',
    },
    iconProps: {
      style: {
        backgroundColor: '#E0FBE2',
      },
    },
  },
  {
    id: 5,
    Component: LinkedinShareButton,
    Icon: LinkedinIcon,
    shareProps: {
      title:'Free AI Headshot by SyncSignature',
      summary:'I just created my free AI headshot using SyncSignature. Create yours today for free!',
      source:'https://app.syncsignature.com/auth/signup?r=ai-headshot',
      url:'https://app.syncsignature.com/auth/signup?r=ai-headshot',
    },
    iconProps: {
      style: {
        backgroundColor: '#E7F3FF',
      },
    },
  },
];

const ShareModal = ({ open, setOpen, onClose, url, input }) => (
  <Dialog open={open} onOpenChange={setOpen}>
    <DialogOverlay className="bg-[black]/80" />
    <DialogContent
      // className="p-4 gap-0 rounded-md"
      style={{
        backgroundImage: `url(${Aiheadshotimg})`,
      }}
      className=" flex p-0 items-center max-w-lg justify-center gap-0 rounded-md  shrink-0  bg-cover bg-no-repeat bg-center"
      hideCloseButton
    >
      <div className="p-6 flex items-center justify-center flex-col">
        <div className="mt-[20px]">
          <img src="../logo.svg" height={40} width={200} alt="logo" />
        </div>
        <BeforeAfterCard before={input} after={getLiveUrl(url)} />
        <div className="text-[28px] sm:text-[16px] text-[#381E72] font-semibold flex items-center justify-center">
          Create your own headshot
          <ArrowRight className="ml-2 size-[48px] sm:size-[20px]" />
        </div>
        <div className="flex justify-center gap-2 mt-[40px]">
          {ShareButtons.map(({ Component, Icon, id, iconProps ,shareProps}, i) => (
            <Component
              key={id}
              title="test title"
              // hashtags={['testhash']}
              // related={['related']}
              // url={getLiveUrl(url)}
              {...shareProps}
            >
              <Icon
                size={32}
                className="flex items-center justify-center rounded p-[15px] sm:p-[5px]"
                {...iconProps}
                round
              />
            </Component>
          ))}
        </div>
      </div>
    </DialogContent>
  </Dialog>
);

export default ShareModal;
