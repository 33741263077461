import { gql } from '@apollo/client';

export const UPSERT_BRAND_MUTATION = gql`
  mutation UpsertBrand($data: BrandInput!) {
    upsertBrand(data: $data) {
      data {
        id
        url
        logoUrl
        name
        supportEmail
        urlStatus
        userId
        featuresAllowed
        config
        favicon
      }
      created
    }
  }
`;

export const UPDATE_BRAND_MUTATION = gql`
  mutation UpdateBrand($data: BrandInput!, $where: BrandWhereInput!) {
    updateBrand(data: $data, where: $where) {
      data {
        id
        url
        logoUrl
        name
        supportEmail
        urlStatus
        userId
        featuresAllowed
        config
        favicon
      }
    }
  }
`;

export const DELETE_BRAND_MUTATION = gql`
  mutation DeleteBrand($id: ID!) {
    deleteBrand(id: $id) {
      success
      message
    }
  }
`;
