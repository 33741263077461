/* eslint-disable no-undef */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAppContext } from 'AppContext';
import { cloneAndRemoveTypename } from 'common/utils';
import { UPDATE_PADDLE_SUBSCRIPTION_PLAN } from 'modules/SubscriptionPage/graphql/Mutations';
import {
  ACTIVATE_FREE_TRIAL_PLAN,
  GET_LM_LTD_PLAN_CHECKOUT_LINK,
  GET_PADDLE_CHECKOUT_TRANSACTION_ID,
  GET_PADDLE_MANAGEMENT_URL,
  GET_RECURRING_PLANS,
  GET_SUBSCRIPTION_DETAILS,
} from 'modules/SubscriptionPage/graphql/Queries';
import { useState } from 'react';
import { useWorkspaceId } from './workspace';

// eslint-disable-next-line import/prefer-default-export
export const useGetLmLtdPlanCheckoutLink = () => {
  const [getCheckoutLink, { data, loading, error }] = useLazyQuery(
    GET_LM_LTD_PLAN_CHECKOUT_LINK,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: () => {},
    },
  );

  return [getCheckoutLink, { data, loading, error }];
};

export const useGetPaddleCheckoutTransactionId = () => {
  const [getPaddleCheckoutTransactionId, { data, loading, error }] =
    useLazyQuery(GET_PADDLE_CHECKOUT_TRANSACTION_ID, {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: () => {},
    });

  return [getPaddleCheckoutTransactionId, { data, loading, error }];
};

export const useGetRecurringPlans = () => {
  const { setRecurringPlans } = useAppContext();

  const [getRecurringPlans, { data, loading, error }] = useLazyQuery(
    GET_RECURRING_PLANS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setRecurringPlans(cloneAndRemoveTypename(data.getRecurringPlans));
      },
      onError: () => {},
    },
  );

  return [getRecurringPlans, { data, loading, error }];
};

export const useUpdatePaddleSubscriptionPlan = () => {
  const [updatePaddleSubscriptionPlan, { data, loading, error }] = useMutation(
    UPDATE_PADDLE_SUBSCRIPTION_PLAN,
    {
      onCompleted: (data) => {
        // Add any logic that you want to execute when the mutation is successful
      },
      onError: (error) => {
        // Add any logic to handle the error
      },
    },
  );

  return [updatePaddleSubscriptionPlan, { data, loading, error }];
};

export const useGetPaddleManagementUrl = () => {
  // Lazy query for fetching the cancel subscription URL based on the user ID
  const [getPaddleManagementUrl, { data, loading, error }] = useLazyQuery(
    GET_PADDLE_MANAGEMENT_URL,
    {
      fetchPolicy: 'network-only', // Always fetch fresh data from the server
      onCompleted: (data) => {},
      onError: (error) => {},
    },
  );

  // This returns the query trigger function and the result states
  return [
    getPaddleManagementUrl, // Function to trigger the query
    { data, loading, error },
  ];
};

export const useSubscriptionDetails = () => {
  const { setSubscriptionDetails } = useAppContext();

  const [getSubscriptionDetails, states] = useLazyQuery(
    GET_SUBSCRIPTION_DETAILS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setSubscriptionDetails(
          cloneAndRemoveTypename(data.getSubscriptionDetails),
        );
      },
      onError: (error) => {},
    },
  );

  return [getSubscriptionDetails, states];
};

export const useActivateFreeTrialPlan = () => {
  const { setCurrentUser } = useAppContext();
  const { workspaceId } = useWorkspaceId();
  const [activateFreeTrialPlan, { data, loading, error }] = useMutation(
    ACTIVATE_FREE_TRIAL_PLAN,
    {
      onCompleted: (data) => {
        setCurrentUser(data?.activateFreeTrialPlan?.data);
        // window.location.reload();
        window.location.href = `/app/workspace/${workspaceId}/teammates`;
        // console.log('Free trial plan activated successfully:', data);
      },
      onError: (error) => {
        // console.error('Error activating free trial plan:', error);
      },
    },
  );

  // Returning the mutation function, data, loading, and error states
  return [activateFreeTrialPlan, { data, loading, error }];
};
