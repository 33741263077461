import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const DELETE_WORKSPACE_TEMPLATE = gql`
  mutation DeleteWorkspaceTemplate($where: DeleteWorkspaceTemplateWhereInput!) {
    deleteWorkspaceTemplate(where: $where) {
      success
      message
    }
  }
`;

export const UPDATE_SIGNATURE_BY_WORKSPACE_TEMPLATE = gql`
  mutation UpdateSignatureByWorkspaceTemplate(
    $where: UpdateWorkspaceTemplateWhereInput!
  ) {
    updateSignatureByWorkspaceTemplate(where: $where) {
      success
      message
    }
  }
`;

export const UPDATE_WORKSPACE_TEMPLATE = gql`
  mutation UpdateWorkspaceTemplate(
    $data: CreateWorkspaceTemplateInput!
    $where: UpdateWorkspaceTemplateWhereInput
  ) {
    updateWorkspaceTemplate(data: $data, where: $where) {
      data {
        id
        name
        fields
        design {
          primaryBrandColor
          font
          theme
          icons
          styles
        }
        cta {
          title
          url
          shortUrl
        }
        templateId
        headshotId
        headshot {
          id
          image
          config
          styleId
          style {
            id
            name
            image
            key
            description
            isActive
          }
          base64Image
          createdAt
          updatedAt
        }
        template {
          id
          name
          image
          key
          description
          isActive
          config
          templateId
        }
        workspaceId
      }
    }
  }
`;
export const CREATE_WORKSPACE_TEMPLATE = gql`
  mutation CreateWorkspaceTemplate(
    $data: CreateWorkspaceTemplateInput!
    $where: WorkspaceTemplateUniqueWhereInput
  ) {
    createWorkspaceTemplate(data: $data, where: $where) {
      data {
        id
        name
        fields
        design {
          primaryBrandColor
          font
          theme
          icons
          styles
        }
        cta {
          title
          url
          shortUrl
        }
        templateId
        headshotId
        headshotUrl
        headshot {
          id
          image
          config
          styleId
          style {
            id
            name
            image
            key
            description
            isActive
          }
          base64Image
          createdAt
          updatedAt
        }
        template {
          id
          name
          image
          key
          description
          isActive
          config
          templateId
        }
        workspaceId
      }
    }
  }
`;
