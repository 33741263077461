/* eslint-disable no-console */
import { UserOutlined } from '@ant-design/icons';
import { useAppContext } from 'AppContext';
import { SignOutIcon } from 'assets/svg';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { getUserInitials } from 'common/utils';
import { useRouter } from 'hooks/router';
import { get } from 'lodash';
import React from 'react';

const UserProfile = ({ extraItems = [] }) => {
  const {
    state: { currentUser },
  } = useAppContext();
  const name = get(currentUser, 'name', null);
  const { navigate } = useRouter();
  const userProfile = getUserInitials(name);

  const handleSignOut = () => {
    navigate('/app/logout');
  };

  const items = [
    ...extraItems,
    {
      key: 'signOut',
      label: (
        <div className="w-64 cursor-pointer">
          <div className="flex justify-between pointer-events-none ">
            <div>Sign Out</div>
            <div>
              <SignOutIcon />
            </div>
          </div>
        </div>
      ),
      onClick: handleSignOut,
    },
  ];
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="h-[40px] w-10 flex items-center justify-center bg-primary-foreground rounded cursor-pointer">
          {userProfile ?? <UserOutlined />}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {items.map((item) => (
          <DropdownMenuItem key={item?.key} onClick={item.onClick}>
            {item?.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserProfile;
