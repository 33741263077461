import React from 'react';
import { Zap } from 'lucide-react';
import AiHeadshotButton from 'components/AiHeadshotButton';
import { useGetBrandDetails, useFeatureChecks } from 'hooks/brand';
import HeadshotControl from '../HeadshotControl';
import Headshot from '../Headshot';

const MobileHeadshot = () => {
  const { isAIHeadshotsAllowed } = useFeatureChecks();

  return (
    <div>
      <HeadshotControl />
      {isAIHeadshotsAllowed && (
        <div className="w-full flex justify-center mt-[20px]">
          {/* <AiHeadshotButton className="flex justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]">
            Try AI Headshot
            <Zap size={16} className="ml-[6px] fill-white-0" />
          </AiHeadshotButton> */}
        </div>
      )}
      <Headshot />
    </div>
  );
};
export default MobileHeadshot;
