import React, { useRef } from 'react';

import { useWorkspaceContext } from 'context/WorkspaceContext';
import AddActionCard from 'components/AddActionCard';
import { useRouter } from 'hooks/router';
import GroupCards from './components/GroupCards';
import ActionDialog from './components/ActionDialog';

const Groups = () => {
  const {
    state: { groups },
  } = useWorkspaceContext();
  const { navigate } = useRouter();

  const actionDialogRef = useRef();

  const actions = {
    handleEdit: (record) => {
      actionDialogRef.current.onTypeChange('update');
      actionDialogRef.current.onDataChange(record);
      actionDialogRef?.current?.open();
    },
    handleDelete: (record) => {
      actionDialogRef.current.onTypeChange('delete');
      actionDialogRef.current.onDataChange(record);
      actionDialogRef?.current?.open();
    },
    handleCardClick: (record) => {
      navigate(`/app/workspace/${record.workspaceId}/groups/${record.id}`);
    },
  };

  return (
    <>
      <ActionDialog ref={actionDialogRef} />
      <div className="bg-primary-foreground  p-[24px]">
        <div className="bg-white-0 w-full h-[calc(100vh_-_120px)] overflow-auto relative">
          <div className="bg-white-0 p-[16px] rounded-[8px] ">
            <div className=" flex flex-wrap gap-2 ">
              <AddActionCard
                className="h-[130px] w-[200px]"
                handleOnClick={() => {
                  actionDialogRef.current.onTypeChange('create');
                  actionDialogRef?.current?.open();
                }}
              />
              {groups.map((data) => (
                <GroupCards
                  key={data.id}
                  t
                  name={data.name}
                  actions={actions}
                  data={data}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Groups;
