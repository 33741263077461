import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from 'components/ui/dialog';
import { cloneAndRemoveTypename } from 'common/utils';
import { get, omit } from 'lodash';
import {
  DEFAULT_DESIGN_STYLE,
  DISPLAY_MODE,
  DUMMY_DATA,
  FONT_FAMILY,
  PRIMARY_COLOR,
} from 'common/constants';
import { SIGNATURE_FIELDS } from 'modules/Onboarding/constants';
import { useDeleteSignature, useUpsertSignature } from 'hooks/signature';
import { useWorkspaceId } from 'hooks/workspace';
import { useRouter } from 'hooks/router';
import { toast } from 'hooks/use-toast';
import { useCreateHeadshot } from 'hooks/headshot';
import { useAppContext } from 'AppContext';
import CreateForm from './components/CreateForm';
import UpdateForm from './components/UpdateForm';
import DeleteForm from './components/DeleteForm';
import DuplicateForm from './components/DuplicateForm';

const SignatureActionModal = forwardRef(
  ({ listingData, setListingData }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState(null);
    const [data, setData] = useState(null);

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
      onTypeChange: (t) => setType(t),
      onDataChange: (d) => setData(d),
    }));

    const [upsertSignature] = useUpsertSignature();
    const [deleteSignature] = useDeleteSignature();
    const { workspaceId } = useWorkspaceId();
    const [createHeadshot] = useCreateHeadshot();
    const { navigate } = useRouter();
    const {
      state: { templates: templateData },
    } = useAppContext();

    const handleCreate = async (signatureName) => {
      // dummy date to create new signature
      const newData = {
        information: {
          personalInformation: {
            ...DUMMY_DATA?.personalInformation,
          },
          businessInformation: {
            companyName: DUMMY_DATA?.businessInformation?.companyName,
            logoWidth: 80,
            companyAddress: DUMMY_DATA?.businessInformation?.companyAddress,
          },
          contactInformation: {
            ...DUMMY_DATA?.contactInformation,
          },
          socialLinks: DUMMY_DATA?.socialLinks,
        },
        design: {
          primaryBrandColor: PRIMARY_COLOR,
          theme: DISPLAY_MODE?.LIGHT,
          styles: DEFAULT_DESIGN_STYLE,
          font: FONT_FAMILY,
          icons: [],
        },
        fields: SIGNATURE_FIELDS,
        name: signatureName,
        templateId: templateData?.[0]?.id,
      };

      const response = await upsertSignature({
        variables: { data: newData, where: { workspaceId } },
      });
      const id = get(response, 'data.upsertSignature.id', null);
      if (id) {
        navigate(
          `/app/${workspaceId}/signature/${id}/information?showTemplate=true&&isCreate=true`,
        );
      }
    };

    const handleDelete = async () => {
      try {
        const res = await deleteSignature({
          variables: {
            where: {
              signatureId: data?.id,
              workspaceId,
            },
          },
        });
        if (res?.data) {
          toast({
            closeicn: 'success',
            description: 'Delete successfully',
          });
        }
        if (res) {
          const listDataClone = [...listingData];
          const index = listDataClone.findIndex(
            (item) => item.id === res?.data?.removeSignature?.id,
          );
          listDataClone.splice(index, 1);
          setListingData(listDataClone);
        }
        setIsOpen(false);
      } catch (error) {
        // message.error('Failed to save changes.');
        toast({
          closeicn: 'destructive',
          description: 'Failed to save changes.',
        });
      }
    };

    const handleUpdate = async (updatedName) => {
      const isSignatureNameUnique = listingData.every(
        (signature) => signature.name !== updatedName,
      );

      if (isSignatureNameUnique) {
        const { id, ...signature } = data;
        const signatureData = {
          ...signature,
          name: updatedName,
        };
        const formatedData = cloneAndRemoveTypename(signatureData);
        const res = await upsertSignature({
          variables: {
            data: formatedData,
            where: {
              signatureId: id,
              workspaceId,
            },
          },
        });
        const response = get(res, 'data.upsertSignature', null);
        if (response) {
          toast({
            closeicn: 'success',
            description: 'Update successfully',
          });
        }
        const listDataClone = [...listingData];
        const findSignatureTobeUpdated = listDataClone.find(
          (item) => item.id === response?.id,
        );
        findSignatureTobeUpdated.name = response?.name;
        setListingData(listDataClone);
        setIsOpen(false);
      } else {
        toast({
          closeicn: 'destructive',
          description: 'Signature name must be unique at the time of update.',
        });
      }
    };

    const getCloneSignatureName = (name) => {
      const parts = name.split('_');
      const lastPart = parts.pop();
      const startName = parts.join('_');
      const numberSuffix = parseInt(lastPart, 10);

      if (Number(numberSuffix)) {
        const newName = `${startName}_${numberSuffix + 1}`;
        const findIndexOfName = listingData.findIndex(
          (s) => s.name === newName,
        );
        if (findIndexOfName === -1) {
          return `${startName}_${numberSuffix + 1}`;
        }
      }
      return `${name}_1`;
    };

    const handleDuplicate = async () => {
      const {
        id,
        updatedAt,
        createdAt,
        name,
        headshotId,
        headshot,
        ...restdata
      } = cloneAndRemoveTypename(data);

      let headshotResId = headshotId;
      if (headshotResId) {
        const cloneHeadshot = omit(headshot, ['id']) || {};

        const headshotRes = await createHeadshot({
          variables: {
            data: cloneHeadshot,
          },
        });

        headshotResId = get(headshotRes, 'data.createHeadshot.data.id');
      }

      const signaturedata = {
        ...restdata,
        headshotId: headshotResId,
        name: getCloneSignatureName(name),
      };

      const res = await upsertSignature({
        variables: { data: signaturedata, where: { workspaceId } },
      });
      const response = get(res, 'data.upsertSignature', null);
      if (response) {
        const listDataClone = [...listingData, response];
        setListingData(listDataClone);
        toast({
          closeicn: 'success',
          description: 'Duplicate successfully',
        });
      }
      // else
      // {
      //   toast({
      //     closeicn: 'destructive',
      //     description: 'Failed to save changes.',
      //   });
      // }
    };

    const handleOnClick = (values) => {
      try {
        if (type === 'create') {
          handleCreate(values);
        } else if (type === 'update') {
          handleUpdate(values);
        } else if (type === 'delete') {
          handleDelete();
        } else if (type === 'duplicate') {
          handleDuplicate();
        }
      } catch (error) {
        toast({
          closeicn: 'destructive',
          description: 'Something went wrong',
        });
      }
      ref?.current?.close();
    };

    const getDialogContent = () => {
      switch (type) {
        case 'create':
          return (
            <CreateForm
              handleOnClick={handleOnClick}
              onClose={() => setIsOpen(false)}
            />
          );
        case 'update':
          return (
            <UpdateForm
              handleOnClick={handleOnClick}
              data={data}
              onClose={() => setIsOpen(false)}
            />
          );
        case 'delete':
          return (
            <DeleteForm
              handleOnClick={handleOnClick}
              onClose={() => setIsOpen(false)}
            />
          );
        case 'duplicate':
          return (
            <DuplicateForm
              handleOnClick={handleOnClick}
              onClose={() => setIsOpen(false)}
            />
          );
        default:
          return null;
      }
    };

    return (
      <>
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          {getDialogContent()}
        </Dialog>
      </>
    );
  },
);

export default SignatureActionModal;
