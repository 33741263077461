import { handleUrlRedirection } from 'common/utils';
import { get } from 'lodash';
import React from 'react';
import { FONT_FAMILY } from 'common/constants';

function CallToActionBanner({
  data,
  style = {},
  toProps = {},
  design = {},
  signature = {},
}) {
  const { ctaBannerUrl, ctaBannerImg } = data;
  const width = get(design, 'styles.ctaBanner.style.width', 250);
  const ctaborderRadius = get(
    design,
    'styles.ctaBanner.style.ctaborderRadius',
    0,
  );

  const link = ctaBannerUrl?.utagEnabled
    ? ctaBannerUrl?.shortLink || handleUrlRedirection(ctaBannerUrl?.value)
    : handleUrlRedirection(ctaBannerUrl?.value);

  const font = get(signature, 'design.font', FONT_FAMILY);

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tr>
        <td
          style={{
            ...style,
            textAlign: 'left',
            paddingBottom: '16px',
          }}
          {...toProps}
        >
          <a
            aria-label="link"
            style={{
              color: 'transparent',
              textDecoration: 'none',
            }}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={ctaBannerImg?.value}
              alt="brand-logo"
              width={width}
              title="ctabanner"
              style={{
                border: '0px',
                lineHeight: '100%',
                outline: 'none',
                textDecoration: 'none',
                display: 'block',
                borderRadius: `${ctaborderRadius}px`,
              }}
            />
          </a>
        </td>
      </tr>
    </table>
  );
}

export default CallToActionBanner;
