import { useAppContext } from 'AppContext';
import history from 'historyData';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
} from 'components/ui/dialog';

export default function RouterPrompt(props) {
  const { dispatch } = useAppContext();
  const {
    openPrompt = false,
    description = 'It looks like you have been editing something. If you leave before saving, your changes will be lost.',
    showCustomModal = false,
  } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const currentLocation = useLocation();

  const unblockRef = useRef();

  useEffect(() => {
    if (openPrompt) {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = function () {
        return true;
      };
    }

    unblockRef.current = history?.block((location) => {
      if (openPrompt && location?.pathname !== currentLocation?.pathname) {
        setCurrentPath(location?.pathname);
        if (showCustomModal) {
          setShowPrompt(true);
        }
        return false;
      }
      return true;
    });
    return () => {
      unblockRef?.current();
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPrompt]);

  const handleOK = () => {
    if (unblockRef) {
      unblockRef?.current();
    }
    setShowPrompt(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    history?.push(currentPath);
  };

  const handleCancel = () => {
    setShowPrompt(false);
  };

  return showPrompt ? (
    <Dialog
      open={showPrompt}
      onOpenChange={handleCancel}
    >
      <DialogContent className="sm:max-w-[425px] max-w-sm" >
        <div className="pt-2.5">
          {description}
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
}
