import React, { useRef } from 'react';
import {
  ChartBarIcon,
  Logo,
  MagicWandIcon,
  MegaPhoneIcon,
  NoteBookIcon,
  UserCircleIcon,
} from 'assets/svg';
import { useRouter } from 'hooks/router';
import { FEATURE_KEYS } from 'common/constants';
import { useFeatures, useUserPermission } from 'hooks/user';
import { useRedirectToWorkspace, useWorkspaceId } from 'hooks/workspace';
import SaveSignatureDialog from 'components/SaveSignatureDialog';
import { useSaveSignature } from 'hooks/signature';
import { useTheme } from 'hooks/theme';

const itemStyle = {
  height: '85px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  position: 'relative',
  backgroundColor: '#ffffff',
};

const SideBar = () => {
  const {
    location: { pathname },
    navigate,
    params,
  } = useRouter();
  const { color,foregroundColor } = useTheme()
  const { isFeaturesAllowed } = useUserPermission();
  const { isSignatureChanged, saveChanges } = useSaveSignature();
  const signatureId = params?.id;
  const { workspaceId } = useWorkspaceId();
  const { redirectToWorkspace } = useRedirectToWorkspace();
  const { isUserUsingProFeatures } = useFeatures();
  const saveSignatureDialogRef = useRef();

  const items = [
    // {
    //   key: 'dashboard',
    //   path: '/dashboard',
    //   icon: Logo,
    //   label: '',
    //   disabled: false,
    //   showCrown: false,
    // },
    {
      key: 'headshot',
      path: '/headshot',
      icon: UserCircleIcon,
      label: 'Headshot',
      disabled: false,
      showCrown: false,
    },
    // {
    //   key: 'templates',
    //   path: '/templates',
    //   icon: FourSquareIcon,
    //   label: 'Templates',
    //   disabled: false,
    //   showCrown: false,
    // },
    {
      key: 'information',
      path: '/information',
      icon: NoteBookIcon,
      label: 'Information',
      disabled: false,
      showCrown: false,
    },
    {
      key: 'design',
      path: '/design',
      icon: MagicWandIcon,
      label: 'Design',
      disabled: false,
      showCrown: false,
    },
    {
      key: 'cta',
      path: '/cta',
      icon: MegaPhoneIcon,
      label: 'Call to action',
      disabled: false,
      showCrown: !isFeaturesAllowed(FEATURE_KEYS.CALL_TO_ACTION),
    },
    {
      key: 'analytics',
      path: '/analytics',
      icon: ChartBarIcon,
      label: 'Analytics',
      disabled: false,
      showCrown: !isFeaturesAllowed(FEATURE_KEYS.SIGNATURE_ANALYTICS),
    },
  ];

  const isActiveTab = (path) => pathname.includes(path);

  const handleSaveChanges = () => {
    saveChanges();
    saveSignatureDialogRef?.current?.close();
    if (!isUserUsingProFeatures()) {
      redirectToWorkspace();
    }
  };

  return (
    <>
      <SaveSignatureDialog
        handleSaveChanges={handleSaveChanges}
        ref={saveSignatureDialogRef}
      />
      <div
        className="overflow-hidden h-[calc(100vh_-_71px)]"
        collapsible
        theme="light"
        trigger={null}
        style={{}}
      >
        {items.map(({ icon: Icon, path, label, key, showCrown }) => {
          const onClick = () => {
            if (key === 'dashboard') {
              if (isSignatureChanged) {
                saveSignatureDialogRef?.current?.open();
              } else {
                redirectToWorkspace();
              }
              return;
            }
            const pathToRedirect = `/app/${workspaceId}/signature/${signatureId}${path}`;
            navigate(pathToRedirect);
          };

          const isActive = isActiveTab(path);

          return (
            <div
              style={{
                ...itemStyle,
                ...(isActive
                  ? {
                      backgroundColor: foregroundColor,
                      borderLeft: `4px solid ${color}`,
                    }
                  : {}),
              }}

              key={path}
              onClick={onClick}
            >
              <div>
                <span
                  style={{
                    position: 'absolute',
                    right: '4px',
                    top: '1px',
                  }}
                >
                  {showCrown && (
                    <svg
                      fill="#d3a40c"
                      height={16}
                      width={16}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
                    </svg>
                  )}
                </span>

                <center style={{ marginBottom: '8px' }}>
                  <Icon fill={isActive ? color : '#7a7289'} />
                </center>
                <center>
                  <div style={{ color: isActive ? color : '#7a7289' }}>
                    {label}
                  </div>
                </center>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SideBar;
