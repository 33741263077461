/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_WORKSPACES = gql`
  query GetWorkspaces {
    getWorkspaces {
      data {
        id
        name
        signatureLimit
        signatureUsed
        workspaceDomain
        utagEnabled
      }
    }
  }
`;

export const GET_WORKSPACE_BY_ID = gql`
  query GetWorkspaceById($where: GetWorkspaceByIdInput!) {
    getWorkspaceById(where: $where) {
      data {
        id
        name
        ownerId
        signatureLimit
        signatureUsed
        workspaceDomain
        utagEnabled
      }
    }
  }
`;

export const GET_WORKSPACE_ANALYTICS = gql`
  query GetWorkspaceAnalytics(
    $where: GetWorkspaceAnalyticsWhereInput!
    $filter: AnalyticsFilter
  ) {
    getWorkspaceAnalytics(where: $where, filter: $filter) {
      data
    }
  }
`;

export const TOGGLE_UTAGS_FOR_WORKSPACE_MUTATION = gql`
  mutation toggleUtagsForWorkspace($where: ToggleUtagsInput!) {
    toggleUtagsForWorkspace(where: $where) {
      success
      message
    }
  }
`;

export const DELETE_WORKSPACE_MUTATION = gql`
  mutation DeleteWorkspace($where: DeleteWorkspaceInput!) {
    deleteWorkspace(where: $where) {
      success
      message
    }
  }
`;
