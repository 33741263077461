/* eslint-disable arrow-body-style */
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import React from 'react';

const BulkDeleteDialog = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className="sm:max-w-[425px] rounded-[6px] max-w-sm"
        hideCloseButton
      >
        <DialogHeader className="text-left">
          <DialogTitle className="text-lg font-bold text-gray-900 my-1">Confirm</DialogTitle>
          <DialogDescription className="text-sm text-gray-500">
            Are you sure you want to continue?
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full">
          <div className="flex justify-end gap-3 w-1/2 ml-auto">
            <Button
              variant="outlinefill"
              onClick={() => onClose()}
              size="sm"
            >
              Cancel
            </Button>
            <Button
              variant="outlinefill"
              type="submit"
              onClick={() => onSubmit()}
              size="sm"
            >
              Submit
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BulkDeleteDialog;
