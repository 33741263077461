import { ChevronRight } from 'lucide-react';
import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { get, isString } from 'lodash';
import { useAppContext } from 'AppContext';
import { useRouter } from 'hooks/router';
import { PRIMARY_COLOR } from 'common/constants';
import { GET_STYLES } from 'modules/Headshot/graphql/Queries';
import LoginModal from 'components/LoginModal';
import HeadshotList from 'components/HeadshotList';
import ColorPicker from 'components/ColorPicker';
import Button from 'components/Button';

const PublicHeadshotEditor = () => {
  const {
    state: { headshot },
    setHeadshot,
  } = useAppContext();

  const { navigate } = useRouter();
  const [primaryColor] = useState(PRIMARY_COLOR);
  const [open, setOpen] = useState(false);

  const { data: { styles: { data: stylesData } = {} } = {} } = useQuery(
    GET_STYLES,
    {
      variables: {
        pagination: { skip: 0, limit: 6 },
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (!headshot?.id) {
      navigate('/public-headshot');
    }
  }, [headshot?.id]);

  const handleSelect = async (ref) => {
    const { __typename, ...selectedStyle } = stylesData?.find(
      (d) => d.key === ref.current.id,
    );

    setHeadshot({
      ...headshot,
      style: {
        ...headshot?.style,
        ...selectedStyle,
      },
      styleId: selectedStyle?.id,
    });
  };

  return (
    <div className="min-h-screen h-full max-h-screen">
      {open && <LoginModal open={open} setOpen={setOpen} />}
      <div className="h-screen w-full bg-primary-foreground flex-col table">
        <div className="py-40 px-24 md:p-24 sm:py-12 sm:px-6 bg-primary-foreground flex-col table-cell">
          <section className="headshot-preview-section flex items-center justify-center mb-[60px]">
            <div className="max-w-[850px] w-full">
              <HeadshotList
                colorPicked={
                  headshot?.config?.['background-color'] || primaryColor
                }
                svgColor="white"
                rounded
                stylesData={stylesData}
                clickable
                handleSelect={handleSelect}
              />
            </div>
          </section>

          <section className="headshot-control-panel flex justify-center gap-4">
            <div className="flex justify-center gap-1">
              <ColorPicker
                hideApplyButton
                value={headshot?.config?.['background-color'] || primaryColor}
                onChange={(value) => {
                  const color = isString(value) ? value : `#${value?.toHex()}`;

                  setHeadshot({
                    ...headshot,
                    config: {
                      ...headshot?.config,
                      'background-color': color,
                    },
                  });
                }}
                className="signature-color-picker"
                isPresetShow
              />
            </div>
          </section>
          <section className="flex justify-center">
            <Button onClick={() => setOpen(true)}>
              <div className="flex items-center justify-center space-x-1 w-[200px]">
                <span>Download</span>
                <ChevronRight width="20" height="20" />
              </div>
            </Button>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PublicHeadshotEditor;
