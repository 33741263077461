import { useAppContext } from 'AppContext';
import { useMutation } from '@apollo/client';
import { useRouter } from 'hooks/router';
import React, { useEffect } from 'react';
import LoaderComponent from 'components/LoaderComponent';
import { LOGOUT_USER } from './graphql/Mutations';

const Logout = () => {
  const { dispatch } = useAppContext();
  const { navigate } = useRouter();
  const [logout, { loading, error, data }] = useMutation(LOGOUT_USER, {
    onCompleted() {
      // eslint-disable-next-line no-undef
      localStorage.clear();
    },
    onError() {
      // eslint-disable-next-line no-undef
      localStorage.clear();
    },
  });

  useEffect(() => {
    logout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent setWidth="100%" />;

  if (error) {
    if (error?.graphQLErrors?.length) {
      const newErrors = error?.graphQLErrors?.[0];
      // eslint-disable-next-line no-console
      console?.log('newErrors => ', newErrors);
      dispatch({ type: 'LOGOUT' });
      // eslint-disable-next-line no-undef
      navigate('/auth/login');
      return null;
    }

    navigate(-1);
    return null;
  }

  if (data) {
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    window.location = '/auth/login';
    return null;
  }

  return null;
};

export default Logout;
