import { DatePicker } from 'antd';
import { Card, CardTitle } from 'components/ui/card';
import dayjs from 'dayjs';
import React from 'react';
// import { DatePickerWithRange } from 'components/ui/date-range-picker';
import { useSignatureId } from 'hooks/signature';
import { useTheme } from 'hooks/theme';
import Clicks from './Clicks';
import Region from './Region';
import Browser from './Browser';
import Device from './Device';
import OperatingSystem from './OperatingSystem';

const { RangePicker } = DatePicker;

function Overview({
  analyticsData,
  getAnalyticsFolder,
  getAnalyticsLinks,
  analyticsLinks,
  loading = false,
  dateRange = [],
  setDateRange,
}) {
  const { signatureId } = useSignatureId();
  const { color } = useTheme()

  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs()?.add(-6, 'd'), dayjs()],
    },
    {
      label: 'Last 15 Days',
      value: [dayjs()?.add(-14, 'd'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs()?.add(-29, 'd'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs()?.add(-89, 'd'), dayjs()],
    },
  ];

  const onRangeChange = (dates) => {
    setDateRange([dates?.[0]?.startOf('day'), dates?.[1]?.endOf('day')]);
    getAnalyticsLinks({
      variables: {
        where: {
          refId: analyticsLinks,
        },
        ...(dates && {
          filter: {
            startDate: dates?.[0]?.startOf('day'),
            endDate: dates?.[1]?.endOf('day'),
          },
        }),
      },
    });
    getAnalyticsFolder({
      variables: {
        where: {
          signatureId,
        },
        filter: {
          startDate: dates?.[0]?.startOf('day'),
          endDate: dates?.[1]?.endOf('day'),
        },
      },
    });
  };
  return (
    <div className="p-0">
      <Card loading={loading} className="shadow-none">
        <CardTitle className="px-6  justify-end">
          <div className="flex items-center justify-between">
            <div className="flex items-center  font-primary text-h6 font-bold text-neutral-1000">
              Statistics
            </div>
            <div className="pb-3">
              <RangePicker
                className="w-full"
                presets={rangePresets}
                onChange={onRangeChange}
                placement="bottomRight"
                value={dateRange}
              />
            </div>
          </div>
        </CardTitle>
        <div className="grid grid-cols-5 p-4 gap-6">
          <Clicks color={color} hideGraph analyticsData={analyticsData} />
          <Region color={color} hideGraph hideTable analyticsData={analyticsData} />
          <Browser color={color} hideTable analyticsData={analyticsData} />
          <Device color={color} hideTable analyticsData={analyticsData} />
          <OperatingSystem color={color} hideTable analyticsData={analyticsData} />
        </div>
      </Card>
    </div>
  );
}

export default Overview;
