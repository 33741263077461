/* eslint-disable no-undef */
import { ChevronRight, Loader2 } from 'lucide-react';
import { toast } from 'hooks/use-toast';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React, { useRef, useState } from 'react';
import SignupCover from 'assets/images/signup-cover.png';
import { useRouter } from 'hooks/router';
import { JOURNEYS, REGEX, TOKEN } from 'common/constants';
import darkLogo from 'assets/png/logo-dark.png';
import aiSignup from 'assets/images/ai-headhsot-signup.jpg';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import { useUserJourney } from 'hooks/auth';
import { Password } from 'components/Inputs';
import { useCreatePassword } from 'hooks/user';
import { useGetBrandDetails } from 'hooks/brand';
import { useAppContext } from 'AppContext';

const formSchema = z.object({
  newPassword: z.string().min(1, { message: 'Password is required.' }),
  confirmPassword: z
    .string()
    .min(1, { message: 'Confirm password is required.' }),
});

const CreatePassword = () => {
  const { logoUrl, name } = useGetBrandDetails();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { password: '' },
  });
  const { setIsLogin, initializeAuth } = useAppContext();
  const { location, navigate } = useRouter();
  const matchWithNewPassword = useRef();
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const { journey } = useUserJourney();

  const [confirmInvitation, { loading }] = useCreatePassword();

  function successCallback(accessToken, userData, refreshToken) {
    localStorage.setItem(TOKEN, accessToken);
    initializeAuth(accessToken, userData, refreshToken);
    setIsLogin(true);
  }

  const params = new URLSearchParams(location?.search);
  const token = params?.get('token');
  const uid = params?.get('uid');
  const workspaceId = params?.get('workspaceId');

  const onFinish = async ({ newPassword, confirmPassword }) => {
    try {
      if (newPassword === confirmPassword) {
        const response = await confirmInvitation({
          variables: {
            data: {
              password: newPassword,
              token,
              uid,
              workspaceId,
            },
          },
        });
        const { accessToken, data, refreshToken } =
          response?.data?.confirmInvitation || {};
        if (response?.data) {
          const userData = data;
          const tokenRefresh = refreshToken;

          if (successCallback) {
            successCallback(accessToken, userData, tokenRefresh);
          }
        }
        setIsPasswordChange(true);
      } else {
        // message.error('New & confirm password does not match');
        toast({
          closeicn: 'destructive',
          description: 'New & confirm password does not match',
        });
        matchWithNewPassword?.current?.focus({
          cursor: 'all',
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="flex min-h-screen h-full max-h-screen">
      <div
        className={cn(
          'flex flex-col justify-center w-full bg-primary-foreground p-[16px] overflow-auto',
          { 'bg-[#0A0B0C]': journey === JOURNEYS.AI_HEADSHOT },
        )}
      >
        <div className="w-full max-w-[600px] mx-[auto]">
          {!isPasswordChange ? (
            <>
              <img
                src={
                  journey === JOURNEYS.AI_HEADSHOT
                    ? darkLogo
                    : logoUrl || '/logo.svg'
                }
                height={40}
                width="auto"
                alt="logo"
              />
              <h1
                level={1}
                className="text-primary font-primary  text-[32px] font-semibold leading-[36px] mt-8 mb-2"
              >
                Change password
              </h1>
              <p className="text-secondary-500 text-[14px] font-medium leading-[25px] mb-9">
                Reset your password and regain access to {name}.
              </p>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onFinish)}
                  form={form}
                  className="space-y-[27px]"
                >
                  <FormField
                    control={form.control}
                    name="newPassword"
                    render={({ field }) => (
                      <FormItem className=" font-medium  text-[12px]">
                        <FormLabel
                          className={
                            (cn(' text-darkblue'),
                            {
                              ' text-white-0 ':
                                journey === JOURNEYS.AI_HEADSHOT,
                            })
                          }
                        >
                          New Password
                        </FormLabel>
                        <FormControl>
                          <Password
                            type="password"
                            className="bg-primary-foreground h-[32px] rounded transition duration-300 focus-within hover:border-1  hover:border-solid hover:border-primary hover:bg-primary-foreground  hover:shadow-custom "
                            placeholder="Enter new password"
                            maxLength={16}
                            disabled={loading}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="confirmPassword"
                    render={({ field }) => (
                      <FormItem className=" font-medium  text-[12px]">
                        <FormLabel
                          className={
                            (cn(' text-darkblue'),
                            {
                              ' text-white-0 ':
                                journey === JOURNEYS.AI_HEADSHOT,
                            })
                          }
                        >
                          Confirm Password
                        </FormLabel>
                        <FormControl>
                          <Password
                            type="password"
                            ref={matchWithNewPassword}
                            className="bg-primary-foreground h-[32px] rounded transition duration-300 focus-within hover:border-1 hover:border-primary hover:bg-primary-foreground hover:shadow-custom login-form-input"
                            placeholder="Enter confirm password"
                            disabled={loading}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Button htmlType="submit" className="h-[40px] w-full mt-2 ">
                    {loading && (
                      <Loader2 className="animate-spin mr-2 h-5 w-5 " />
                    )}
                    <div className="flex items-center space-x-1">
                      <span>Change password</span>
                      <ChevronRight width="20" height="20" />
                    </div>
                  </Button>
                </form>
              </Form>
            </>
          ) : (
            <>
              <div>
                <img src="/logo.svg" height={40} width={200} alt="logo" />
              </div>
              <h1
                level={1}
                className="text-primary font-primary  text-[32px] font-semibold leading-[36px]"
              >
                Password Changed
              </h1>
              <p className="text-secondary-500 text-[14px] font-medium leading-[25px]">
                Your password has been changed successfully.
              </p>
              <Button
                onClick={() => navigate('/auth/login')}
                htmlType="submit"
                className="h-[40px] w-full font-primary text-button-s mt-2"
              >
                <div>
                  Login <ChevronRight />
                </div>
              </Button>
            </>
          )}
        </div>
      </div>
      {/* <div
        style={{
          backgroundImage: `url(${
            journey === JOURNEYS.AI_HEADSHOT ? aiSignup : SignupCover
          })`,
        }}
        className="lg:hidden flex w-1/2 min-h-full gap-2.5 shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center"
      /> */}
    </div>
  );
};

export default CreatePassword;
