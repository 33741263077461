/* eslint-disable arrow-body-style */
import React from 'react';
import { Button } from 'components/ui/button';

const DeleteTemplate = ({ onClose, handleOnClick }) => {
  return (
    <div>
      <p className="font-medium text-lg">
        Are you sure you want to delete this template?
      </p>
      <div className="flex justify-end gap-3 pt-4">
        <Button
          className="w-[max-content]"
          onClick={() => onClose()}
          variant="outline"
          size="sm"
        >
          Cancel
        </Button>
        <Button onClick={handleOnClick} size="sm">
          Delete
        </Button>
      </div>
    </div>
  );
};

export default DeleteTemplate;
