/* eslint-disable arrow-body-style */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { cn } from 'lib/utils';
import Headshot from 'modules/Headshot';
import HeadshotControl from './HeadshotControl/HeadshotControl';

const HeadshotControlDialog = forwardRef(({ onHeadshotSelect }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="rounded-[6px] max-w-[1200px]">
        <div
          className={cn('grid grid-cols-[400px_1fr]', {})}
          style={{ height: 'calc(100vh - 71px)', overflow: 'auto' }}
        >
          <>
            <div
              className="bg-primary-foreground"
              style={{
                height: 'calc(100vh - 71px)',
                overflow: 'auto',
              }}
            >
              <div style={{ overflow: 'hidden', width: '100%' }}>
                <HeadshotControl />;
              </div>
            </div>
            <div
              className="w-full bg-primary-foreground"
              style={{
                height: 'calc(100vh - 71px)',
                overflow: 'auto',
                margin: 0,
              }}
              trigger={null}
            >
              <Headshot isModel onHeadshotSelect={onHeadshotSelect} />
            </div>
          </>
        </div>
      </DialogContent>
    </Dialog>
  );
});
export default HeadshotControlDialog;
