/* eslint-disable react/destructuring-assignment */
import { Input } from 'components/ui/input';
import { cn } from 'lib/utils';
import { CircleX } from 'lucide-react';
import React, { useRef } from 'react'

const ClearableInput = (props) => {
  const inputRef = useRef(null);
  const {className,...rest} = props


  const handleClear = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      if (props.onChange) {
        props.onChange({ target: inputRef.current });
      }
    }
  };

  return (
    <div className={cn('relative  inline-block w-full',className)} >
      <Input {...rest} ref={inputRef}  />
      {props?.value && (
        <span
          onClick={handleClear}
          className="cursor-pointer absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-500"
          >
          <CircleX size={16} color="#b3b3b3" />
        </span>
      )}
    </div>
  );
};
export default ClearableInput