import React, { useRef } from 'react';
import { useAppContext } from 'AppContext';
import Button from 'components/Button';
import { useRouter } from 'hooks/router';
import { get } from 'lodash';
import { useDeleteUser } from 'hooks/user';
import RemoveProfileDialog from './components/ActionDialog/DeleteForm';

function Profile() {
  const { navigate } = useRouter();
  const {
    state: { currentUser },
  } = useAppContext();

  const [deleteUser] = useDeleteUser();

  const removeProfileDialogRef = useRef();

  const handleRemoveProfile = async () => {
    const res = await deleteUser({
      variables: { id: currentUser?.id },
    });
    if (res.data) {
      navigate('/app/logout');
    }
  };

  return (
    <div className="bg-primary-foreground h-[calc(100vh_-_71px)]">
      <div className="p-[32px]">
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[40px] flex justify-center">
          Profile
        </div>
        <div className="items-start flex justify-center gap-[25px] lg:gri">
          <div className="rounded-lg max-w-[600px] h-full bg-white-0 p-4">
            <div className="mb-1 text-[20px] font-[600]">Email Address</div>
            <div className="mb-4 text-[15px] font-[500] ">
              {currentUser?.email}
            </div>
            <Button
              className="px-0 text-red-2"
              onClick={() => removeProfileDialogRef.current.open()}
              variant="link"
            >
              Delete Account
            </Button>
          </div>
        </div>
      </div>

      <RemoveProfileDialog
        ref={removeProfileDialogRef}
        onConfirm={handleRemoveProfile}
      />
    </div>
  );
}

export default Profile;
