import * as React from 'react';
import { cn } from 'lib/utils';

const Input = React.forwardRef(({ className, type, ...props }, ref) => (
  <input
    type={type}
    className={cn(
      'rounded focus:border-primary px-3 py-2 text-[14px] bg-background w-[calc(100%_-_27px)] border-solid border-1 border-gray-0  hover:border-primary focus:outline-none &>span]:line-clamp-1 ring-offset-background placeholder:text-muted-foreground  focus:ring-2 focus:ring-ring focus:ring-offset-1 disabled:cursor-not-allowed disabled:text-gray-400 disabled:opacity-50 disabled:hover:border-gray-200',
      className,
    )}
    ref={ref}
    {...props}
  />
));
Input.displayName = 'Input';
// eslint-disable-next-line import/prefer-default-export
export { Input };
