/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */

import { useBrandContext } from 'context/BrandContext';
import { useEffect } from 'react';

export const useTheme = () => {
  const {
    state: { brand }, setBrand,
  } = useBrandContext();

  useEffect(() => {
    document.documentElement.style.setProperty('--primary', brand.config.colors.primary);
    document.documentElement.style.setProperty(
      '--primary-foreground',
      brand.config.colors['primary-foreground'],
    );
  }, [brand?.config?.colors?.primary]);

  const updatePrimaryColor = ({ color, foregroundColor }) => {
    setBrand({
      ...brand,
      config: {
        colors: { primary: color, 'primary-foreground': foregroundColor },
      },
    });
    document.documentElement.style.setProperty('--primary', color);
    document.documentElement.style.setProperty(
      '--primary-foreground',
      foregroundColor,
    );
    localStorage.setItem('primary', color);
    localStorage.setItem('primary-foreground', foregroundColor);
  };

  return {
    color: brand.config.colors.primary,
    foregroundColor: brand.config.colors['primary-foreground'],
    updatePrimaryColor,
  };
};
