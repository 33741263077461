/* eslint-disable no-undef */
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'hooks/use-toast';
import LoaderComponent from 'components/LoaderComponent';
import { filter, get, map } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SendOutlined } from '@ant-design/icons';
import { ArrowLeft } from 'lucide-react';
import copy from 'copy-to-clipboard';
import { useAppContext } from 'AppContext';
import { HADSHOT_QUERY } from 'modules/Headshot/graphql/Queries';
import { useRouter } from 'hooks/router';
import {
  useFeatures,
  useUserPermission,
  useGetRoleForWorkspace,
} from 'hooks/user';
import {
  useGetSignature,
  useSendSignature,
  useShowBranding,
  useSignatureId,
} from 'hooks/signature';
import { addIconsInLinks, download } from 'common/utils';
import SendSignatureModal from 'modules/Signature/SendSignatureModal';
import { Button } from 'components/ui/button';
import ProfileAvatar from 'components/ProfileAvatar';
import { FEATURE_KEYS, FONT_FAMILY, ROLES } from 'common/constants';
import {
  AppleMail,
  ClipboardIcon,
  CodeIcon,
  Gmail,
  Outlook,
  Yahoo,
  ThunderbirdLogoIcon,
} from 'assets/svg';
import { useWorkspaceId } from 'hooks/workspace';
import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import { darkModeStyle } from 'components/TemplatesModel/utils/darkModeStyle';
import InstallGuide from './components/InstallGuide';
import SignatureLinkButtons from './components/SignatureLinkButtons';

const CurrentTemplate = ({ signature, hideBranding }) => {
  const { state } = useAppContext();

  const FinalTemplate = dynamicTemplate({
    templateKey: signature?.template?.key,
  });

  return FinalTemplate ? (
    <FinalTemplate
      signature={state.signature}
      showAwsIcons
      hideBranding={hideBranding}
    />
  ) : null;
};

function EmailSignature() {
  const {
    state: { signature },
  } = useAppContext();

  const { navigate } = useRouter();
  const { role } = useGetRoleForWorkspace();

  const { signatureId } = useSignatureId();
  const { workspaceId } = useWorkspaceId();
  const { isFeaturesAllowed } = useUserPermission();
  const { isUserUsingProFeatures } = useFeatures();
  const [sendSignature] = useSendSignature();
  const [open, setOpen] = useState(false);
  useShowBranding(false);
  const closeModal = () => {
    setOpen(false);
  };
  const hideBranding =
    isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
    role === ROLES.TEAMMATE ||
    role === ROLES.MANAGER;

  const font = get(signature, 'design.font', FONT_FAMILY);
  const contentRef = useRef();
  const [getSignature] = useGetSignature();

  const [openContentDialog, setOpenContentDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  // const linkArray = [
  //   {
  //     icon: <Gmail height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Gmail',
  //     url: 'https://syncsignature.com/blog/add-signature-gmail',
  //   },
  //   {
  //     icon: <Outlook height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Outlook',
  //     url: ' https://syncsignature.com/blog/add-signature-outlook',
  //   },
  //   {
  //     icon: <AppleMail height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Apple mail',
  //     url: 'https://syncsignature.com/blog/add-signature-apple-mail',
  //   },
  //   {
  //     icon: <Yahoo height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Yahoo',
  //     url: 'https://syncsignature.com/blog/add-signature-yahoo',
  //   },
  //   {
  //     label: 'Add to other clients',
  //     url: ' https://syncsignature.com/blog/install-email-signature',
  //   },
  // ];

  // const linkArray = [
  //   {
  //     icon: <Gmail height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Gmail',
  //     contentId: 'gmail',
  //   },
  //   {
  //     icon: <Outlook height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Outlook',
  //     contentId: 'outlook',
  //   },
  //   {
  //     icon: <AppleMail height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Apple Mail',
  //     contentId: 'applemail',
  //   },
  //   {
  //     icon: <Yahoo height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Yahoo',
  //     contentId: 'yahoo',
  //   },
  //   {
  //     icon: (
  //       <ThunderbirdLogoIcon height={28} width={28} className="mr-2 h-4 w-4" />
  //     ),
  //     label: 'Add to Mozilla Thunderbird (Windows)',
  //     contentId: 'thunderbird',
  //   },
  //   // {
  //   //   label: 'Add to other clients',
  //   //   contentId: 'other',
  //   // },
  // ];
  const handleCopyold = () => {
    const contentToCopy = contentRef?.current;
    if (contentToCopy) {
      const templateKey = signature?.template?.key || '';
      const styleContent = darkModeStyle(templateKey);

      const tempElement = document.createElement('div');
      tempElement.style.backgroundColor = '#ffffff'; // Set the desired background color (white)
      tempElement.style.padding = '16px'; // Add padding if necessary
      tempElement.innerHTML = contentToCopy.innerHTML;

      // Create and append the meta tags to the head
      const metaCharset = document.createElement('meta');
      metaCharset.setAttribute('http-equiv', 'Content-Type');
      metaCharset.setAttribute('content', 'text/html; charset=UTF-8');
      document.head.appendChild(metaCharset);

      const metaViewport = document.createElement('meta');
      metaViewport.setAttribute('name', 'viewport');
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0',
      );
      document.head.appendChild(metaViewport);

      // Create and append the title tag
      const title = document.createElement('title');
      title.textContent = 'Email Signature';
      document.head.appendChild(title);

      const style = document.createElement('style');
      style.textContent = styleContent;
      document.head.appendChild(style);

      // Append the temporary element to the document body
      document.body.appendChild(tempElement);

      // Create a range and select the temporary element's content
      const range = document.createRange();
      range.selectNode(tempElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      try {
        document.execCommand('copy');
        toast({
          closeicn: 'success',
          description: 'Email signature copied to clipboard!',
        });
      } catch (err) {
        toast({
          closeicn: 'destructive',
          description: 'Please try again',
        });
      }
      // Clean up by removing the temporary element
      document.body.removeChild(tempElement);
      window.getSelection().removeAllRanges();
    }
  };

  const handleCopy = () => {
    const contentToCopy = contentRef?.current;
    if (contentToCopy) {
      // Create a temporary element to hold the content to copy
      const tempElement = document.createElement('div');
      tempElement.style.backgroundColor = '#ffffff'; // Set the desired background color (white)
      tempElement.style.padding = '16px'; // Add padding if necessary
      tempElement.innerHTML = contentToCopy.innerHTML;

      // Create and append the meta tags to the head (if needed for the HTML content)
      const metaCharset = document.createElement('meta');
      metaCharset.setAttribute('http-equiv', 'Content-Type');
      metaCharset.setAttribute('content', 'text/html; charset=UTF-8');
      tempElement.appendChild(metaCharset);

      const metaViewport = document.createElement('meta');
      metaViewport.setAttribute('name', 'viewport');
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0',
      );
      tempElement.appendChild(metaViewport);

      // // Create and append the title tag (if needed for the HTML content)
      // const title = document.createElement('title');
      // title.textContent = 'Email Signature';
      // tempElement.appendChild(title);

      // Use Clipboard API to copy HTML content
      if (navigator.clipboard && navigator.clipboard.write) {
        const blob = new Blob([tempElement.innerHTML], { type: 'text/html' });
        const clipboardItem = new ClipboardItem({ 'text/html': blob });

        navigator.clipboard
          .write([clipboardItem])
          .then(() => {
            toast({
              closeicn: 'success',
              description: 'Email signature copied to clipboard!',
            });
          })
          .catch((err) => {
            toast({
              closeicn: 'destructive',
              description: 'Please try again',
            });
          });
      } else {
        // Fallback to execCommand('copy') if Clipboard API is not supported
        const range = document.createRange();
        range.selectNodeContents(tempElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        try {
          document.execCommand('copy');
          toast({
            closeicn: 'success',
            description: 'Email signature copied to clipboard!',
          });
        } catch (err) {
          toast({
            closeicn: 'destructive',
            description: 'Please try again',
          });
        }

        // Clean up by removing the temporary element
        selection.removeAllRanges();
      }
    }
  };

  // const handleCopyforoutlook = () => {
  //   const contentToCopy = contentRef?.current;

  //   if (contentToCopy) {
  //     const tempElement = document.createElement('div');
  //     tempElement.style.backgroundColor = '#ffffff'; // Set the desired background color (white)
  //     tempElement.style.padding = '16px'; // Add padding if necessary

  //     // Build the HTML content with tables
  //     const tableContent = `
  //       <table border="0" cellpadding="0" cellspacing="0" width="100%"">
  //         <tr>
  //           <td style="padding: 16px; background-color: #ffffff;">
  //             ${contentToCopy.innerHTML}
  //           </td>
  //         </tr>
  //       </table>
  //     `;

  //     tempElement.innerHTML = tableContent;

  //     // Append the temporary element to the document body
  //     document.body.appendChild(tempElement);

  //     // Create a range and select the temporary element's content
  //     const range = document.createRange();
  //     range.selectNode(tempElement);
  //     window.getSelection().removeAllRanges();
  //     window.getSelection().addRange(range);

  //     try {
  //       document.execCommand('copy');
  //       toast({
  //         closeicn: 'success',
  //         description: 'Email signature copied to clipboard!',
  //       });
  //     } catch (err) {
  //       toast({
  //         closeicn: 'destructive',
  //         description: 'Please try again',
  //       });
  //     }

  //     // Clean up by removing the temporary element
  //     document.body.removeChild(tempElement);
  //     window.getSelection().removeAllRanges();
  //   }

  // };

  const copyHtmlCode = () => {
    const templateKey = signature?.template?.key || '';
    const styleContent = darkModeStyle(templateKey);
    let htmlContent = contentRef?.current?.innerHTML || '';
    if (templateKey === 'TEMPLATE_26') {
      htmlContent = htmlContent.replace(/color:\s?[^;]+;?/g, '');
    }
    download('signature.html', htmlContent, styleContent);
  };

  const handleSendSignature = async (email) => {
    // sendSignature({
    //   variables: {
    //     data: {
    //       email,
    //       html: contentRef?.current?.innerHTML,
    //     },
    //   },
    // });
    try {
      if (email) {
        await sendSignature({
          variables: {
            data: {
              email,
              html: contentRef?.current?.innerHTML,
            },
          },
        });
      }
    } catch (error) {
      toast({
        closeicn: 'destructive',
        description: `Failed to send signature to ${email}`,
      });
    }
    toast({
      closeicn: 'success',
      description: 'Signature sent successfully!',
    });
    closeModal();
  };
  const dynamicSocialLinks = useMemo(
    () =>
      map(signature?.information?.shortSocialLinks, (link) => {
        const key = Object?.keys(link)?.[0];
        const filteredData = filter(
          addIconsInLinks(),
          (iconLinks) => iconLinks?.option === key,
        )?.[0];
        return {
          ...filteredData,
          url: link?.[key] || null,
        };
      }),
    [signature?.information?.shortSocialLinks],
  );

  useEffect(() => {
    if (isUserUsingProFeatures()) {
      navigate(`/app/${workspaceId}/signature/${signatureId}/information`);
      return;
    }
    getSignature({
      // add signature id from context for public journey
      variables: {
        where: { signatureId: signatureId || signature?.id || '', workspaceId },
      }, // signature id for QUERY
    });
  }, []);

  const handleLinkClick = (content) => {
    setDialogContent(content);
    setOpenContentDialog(true);
  };

  return (
    <>
      <SendSignatureModal
        open={open}
        closeModal={closeModal}
        onSignatureSend={handleSendSignature}
      />
      <InstallGuide
        open={openContentDialog}
        closeModal={() => setOpenContentDialog(false)}
        content={dialogContent}
      />
      <div className="w-full bg-primary-foreground relative h-[calc(100dvh_-_71px)] overflow-auto">
        <header className="flex justify-between items-center p-4">
          <div className="flex gap-x-[16px] items-center">
            <Button
              variant="outline"
              className="h-[40px]"
              label="Back"
              onClick={() => navigate(-1)}
            >
              Back to Editor
            </Button>
          </div>
        </header>
        <div
          style={{ height: 'calc(100vh - 190px)' }}
          className="p-[16px] overflow-auto"
        >
          <div className="flex justify-center mb-[32px]">
            <div className="text-center max-w-[508px]">
              <h4 className="font-primary text-h4 mb-[8px] mt-0 text-primary">
                Here's your new email signature
              </h4>
              <div className="font-medium text-[14px] leading-[20px] text-secondary-500">
                It's high time to put it to work. Copy it by clicking one of the
                buttons below the preview, and add it to your email client with
                our step-by-step guide.
              </div>
            </div>
          </div>
          <div className="flex justify-center my-[32px]">
            <div
              ref={contentRef}
              className="bg-white-0 w-max rounded shadow-template p-6 min-h-[350px] h-full flex justify-center items-left "
              style={{ flexDirection: 'column' }}
            >
              <CurrentTemplate
                signature={signature}
                hideBranding={hideBranding}
              />
            </div>
          </div>

          <div className="flex my-[32px] gap-x-[28px] w-full justify-center">
            {isFeaturesAllowed(FEATURE_KEYS.DOWNLOAD_HTML) && (
              <Button className="h-[54px]" onClick={copyHtmlCode}>
                <CodeIcon className="mr-2 h-4 w-4" />
                Download HTML
              </Button>
            )}
            <Button className="h-[54px]" onClick={handleCopyold}>
              <ClipboardIcon className="mr-2 h-4 w-4" /> Copy Signature{' '}
            </Button>
            {isFeaturesAllowed(FEATURE_KEYS.SHARE_VIA_EMAIL) && (
              <Button className="h-[54px] " onClick={() => setOpen(true)}>
                <SendOutlined className="mr-2 h-4 w-4" />
                Send Signature
              </Button>
            )}
            {/* kept for future */}
            {/* <Button
                label=" Send Via Email"
                icon={<EnvelopeIcon />}

                variant="custom"
                className="h-[54px] flex items-center font-primary text-button-s"
              /> */}
          </div>

          <div className="flex gap-x-[28px] w-full justify-center">
            <SignatureLinkButtons showLabel={false} />
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailSignature;
