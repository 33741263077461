import { getTemplateImages } from 'common/constants';

const Template10 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_10'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Ryan Purcell',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Tax Advisor, Lorex Accountants',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '4317 Saint Clair Street, Tupelo',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'purcell@lorex.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '662-572-9043',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://lorexsolutions.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: "Let's sechdule meeting",
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: 'https://google.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaBannerImg',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.skype.com/#',
        name: 'skype',
        type: 'company',
        label: 'SKYPE',
        value: 'https://www.skype.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'socialBanners',
        type: 'company',
        label: 'Social Banners',
        value: [],
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'companyLogoLink',
        type: 'company',
        label: 'Company Logo Link',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'companyPhone',
        type: 'company',
        label: 'Company Phone',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'companyEmail',
        type: 'company',
        label: 'Company Email',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'signOff',
        type: 'company',
        label: 'SignOff',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
      {
        link: '',
        name: 'customFields',
        type: 'company',
        label: 'Custom Fields',
        value: [],
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#dfa728',
      font: 'Arial, Helvetica, sans-serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            width: 224,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#353427',
            fontSize: 13,
            lineHeight: 1,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          type: 'square',
          style: {
            size: 18,
            color: '#dfa728',
          },
        },
        companyLogo: {
          style: {
            width: 200,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 121,
            borderRadius: 3,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#dfa728',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
            fontSize: 9,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#8E8E8E',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 2',
        image: null,
        key: 'STYLE_2',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 10',
      image: null,
      key: 'TEMPLATE_10',
      description: null,
      isActive: true,
      config: null,
      templateId: 10,
    },
    name: '10',
  },
];

export default Template10;
