/* eslint-disable no-console */
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  cloneAndRemoveTypename,
  fileUpload,
  isEmptyObject,
} from 'common/utils';
import { useBrandContext } from 'context/BrandContext';
import {
  DELETE_BRAND_MUTATION,
  UPDATE_BRAND_MUTATION,
  UPSERT_BRAND_MUTATION,
} from 'modules/Brand/graphql/Mutations';
import {
  GET_BRAND_BY_ID,
  GET_BRAND_BY_URL,
  GET_BRAND_LOGO_SIGNED_URL,
  GET_BRANDS,
  GET_FAVICON_SIGNED_URL,
} from 'modules/Brand/graphql/Queries';
import { toast } from './use-toast';

/* eslint-disable import/prefer-default-export */
export const useUpsertBrand = () => {
  const { setBrand } = useBrandContext();
  const [upsertBrand] = useMutation(UPSERT_BRAND_MUTATION, {
    onCompleted: (res) => {
      setBrand(cloneAndRemoveTypename(res?.upsertBrand?.data));
    },
    onError: () => {},
  });

  return [upsertBrand];
};

export const useUpdateBrand = () => {
  const { setBrand } = useBrandContext();
  const [updateBrand] = useMutation(UPDATE_BRAND_MUTATION, {
    onCompleted: (res) => {
      setBrand(cloneAndRemoveTypename(res?.updateBrand));
    },
    onError: () => {},
  });

  return [updateBrand];
};

export const useGetBrands = () => {
  const [getBrands, states] = useLazyQuery(GET_BRANDS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      // Handle the response if necessary
    },
    onError() {
      // window.location.href = '/app/';
    },
  });

  return [getBrands, states];
};

export const useGetBrandById = () => {
  const { setBrand } = useBrandContext();
  const [getBrandById, states] = useLazyQuery(GET_BRAND_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setBrand(cloneAndRemoveTypename(res?.getBrandById?.data));
    },
    onError() {
      // window.location.href = '/app/';
    },
  });

  return [getBrandById, states];
};

export const useGetBrandByUrl = () => {
  const { setBrand } = useBrandContext();
  const [getBrandByUrl, states] = useLazyQuery(GET_BRAND_BY_URL, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setBrand(cloneAndRemoveTypename(res?.getBrandByUrl?.data));
    },
    onError() {
      // window.location.href = '/app/';
    },
  });

  return [getBrandByUrl, states];
};

export const useDeleteBrand = () => {
  const { clearBrand } = useBrandContext();
  const [deleteBrand] = useMutation(DELETE_BRAND_MUTATION, {
    onCompleted: () => {
      clearBrand();
    },
    onError: () => {},
  });

  return [deleteBrand];
};

export const useGetBrandDetails = () => {
  const {
    state: { brand },
  } = useBrandContext();

  return brand;
};

export const useGetFaviconUploadSignedUrl = () => {
  const [getFaviconUploadSignedUrl] = useLazyQuery(GET_FAVICON_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: () => {},
  });

  return [getFaviconUploadSignedUrl];
};

export const useGetFaviconUrl = () => {
  const [getFaviconUploadSignedUrl] = useGetFaviconUploadSignedUrl();

  const handleFaviconUpload = async (file = null) => {
    if (!file) return;
    const { name } = file?.[0] || {};
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    const filename = name?.split('.')?.slice(0, -1)?.join('.');
    const newFilename = `${filename}.${ext}`;

    const res = await getFaviconUploadSignedUrl({
      variables: {
        data: {
          fileName: newFilename,
        },
      },
    });
    if (res) {
      const { signedUrl, key } = res?.data?.getFaviconUploadSignedUrl;
      try {
        await fileUpload(signedUrl, file?.[0]);
        const url = key && `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${key}`;
        return url;
      } catch (error) {
        toast({
          closeicn: 'destructive',
          description: 'Something went wrong',
        });
      }
    }
  };

  return { handleFaviconUpload };
};

export const useGetBrandLogoImageUrl = () => {
  const [getBrandLogoUploadSignedUrl] = useLazyQuery(
    GET_BRAND_LOGO_SIGNED_URL,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: () => {},
    },
  );

  const handleBrandLogoImageUpload = async (file = null) => {
    if (!file) return;
    const { name } = file?.[0] || {};
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    const filename = name?.split('.')?.slice(0, -1)?.join('.');
    const newFilename = `${filename}.${ext}`;

    const res = await getBrandLogoUploadSignedUrl({
      variables: {
        data: {
          fileName: newFilename,
        },
      },
    });

    if (res) {
      const { signedUrl, key } = res?.data?.getBrandLogoUploadSignedUrl;
      try {
        await fileUpload(signedUrl, file?.[0]);
        const url = key && `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${key}`;
        return url;
      } catch (error) {
        toast({
          closeicn: 'destructive',
          description: 'Something went wrong',
        });
      }
    }
  };

  return { handleBrandLogoImageUpload };
};

export const isFeatureAllowed = (featuresAllowed, feature) =>
  featuresAllowed?.includes(feature);

export const useFeatureChecks = () => {
  const {
    state: { brand },
  } = useBrandContext();
  const { featuresAllowed } = brand;

  const isRegistrationAllowed =
    isFeatureAllowed(featuresAllowed, 'REGISTRATION_ALLOWED') || false;
  const isPlansAllowed =
    isFeatureAllowed(featuresAllowed, 'PLANS_ALLOWED') || false;
  const isResellerAllowed =
    isFeatureAllowed(featuresAllowed, 'RESELLER_ALLOWED') || false;
  const isPublicHeadshotAllowed =
    isFeatureAllowed(featuresAllowed, 'PUBLICHEADSHOT_ALLOWED') || false;
  const isAIHeadshotsAllowed =
    isFeatureAllowed(featuresAllowed, 'AIHEADSHOTS_ALLOWED') || false;
  const isGoogleLoginAllowed =
    isFeatureAllowed(featuresAllowed, 'GOOGLE_LOGIN_ALLOWED') || false;

  return {
    isRegistrationAllowed,
    isPlansAllowed,
    isResellerAllowed,
    isPublicHeadshotAllowed,
    isAIHeadshotsAllowed,
    isGoogleLoginAllowed,
  };
};

export const useIsUserBrandOwner = () => {
  const {
    state: { brandwiseWorkspaceTeammate },
  } = useBrandContext();

  const isUserBrandOwner = (userId, workspaceId) => {
    if (
      !brandwiseWorkspaceTeammate ||
      !Array.isArray(brandwiseWorkspaceTeammate)
    ) {
      return false;
    }

    const teammate = brandwiseWorkspaceTeammate.find((wt) => {
      if (!workspaceId) {
        return (
          wt.roleId === 1 &&
          wt.userId === userId &&
          wt.workspaceId === workspaceId
        );
      }
      return wt.roleId === 1 && wt.userId === userId;
    });

    return !!teammate;
  };

  return { isUserBrandOwner };
};
