/* eslint-disable no-console */
/* eslint-disable no-undef */
import 'react-image-crop/dist/ReactCrop.css';
import { Loader2 } from 'lucide-react';
import { Button } from 'components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Slider } from 'components/ui/slider';
import { cn } from 'lib/utils';
import React, { useState, useRef } from 'react';
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const HeadshotEditorDialog = ({
  onOk, // Callback when cropping is completed
  cropperClass,
  cropperProps: initialCroperProps = {},
  showAspectbuttons = false,
  customCropSize = {},
  existingImage, // This will be the uploaded image passed to the cropper
}) => {
  const [cropperProps, setCropperProps] = useState(initialCroperProps);
  const { circularCrop = false } = cropperProps;
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
  });
  const [open, setOpen] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const blobUrlRef = useRef('');
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleCropshape = (props) => {
    const { aspect: newAspect, circularCrop: newCircularCrop } = props;
    setCropperProps((prev) => ({ ...prev, ...props }));

    if (imgRef.current) {
      const { width, height } = imgRef.current;
      const newCrop = centerAspectCrop(width, height, 1);
      setCrop(newCrop);
      setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    }
  };

  const onImageLoad = (e) => {
    if (!imgRef.current) return;

    const { offsetWidth: imageWidth, offsetHeight: imageHeight } =
      imgRef.current;

    if (Object.keys(customCropSize).length > 0 && customCropSize) {
      handleCropshape(customCropSize);
      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 1);
        setCrop(newCrop);
      }
    } else {
      // Default crop to the entire image
      const pixelCrop = {
        unit: '%',
        width: 100,
        height: 100,
        x: 0,
        y: 0,
      };
      handleCropshape({ aspect: 1, circularCrop: false });
      setCrop(pixelCrop);
      setCompletedCrop(convertToPixelCrop(pixelCrop, imageWidth, imageHeight));
    }
  };

  async function onDownloadCropClick() {
    setLoading(true);
    const image = imgRef.current;

    if (!image || !completedCrop) {
      console.error('Image or completedCrop does not exist');
      setLoading(false);
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Create an offscreen canvas with the size of the crop
    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2D context');
    }

    // Apply circular crop if needed
    if (circularCrop) {
      ctx.beginPath();
      ctx.arc(
        offscreen.width / 2,
        offscreen.height / 2,
        Math.min(offscreen.width, offscreen.height) / 2,
        0,
        Math.PI * 2,
      );
      ctx.clip();
    }

    // Draw the cropped image directly onto the offscreen canvas
    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    // Convert the offscreen canvas to a Blob
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    // Create a file from the Blob
    const file = new File([blob], 'cropped-image.png', { type: 'image/png' });

    // Clean up the old blob URL
    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }

    // Create a new blob URL for the cropped image
    blobUrlRef.current = URL.createObjectURL(blob);

    // Call the callback with the new file
    if (onOk) {
      const newFiles = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      onOk(newFiles);
    }

    setLoading(false);
    setOpen(false); // Close popover when Ok is clicked
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          className="relative flex items-center gap-2 px-4 py-2 text-white font-bold bg-white-0 hover:bg-white-0 active:bg-white-0 rounded-full"
          onClick={() => setOpen(true)}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            crossOrigin="anonymous"
            src={existingImage}
            style={{
              objectFit: 'contain',
              transform: `scale(${scale}) rotate(${rotate}deg)`,
              backgroundColor: 'grey',
            }}
            onLoad={onImageLoad}
            className="h-5 w-5 rounded-full"
            width="100%"
          />
          Open Editor
        </Button>
      </PopoverTrigger>
      <PopoverContent
        side="right"
        className={cn(
          'p-0 gap-0 max-w-[350px] max-h-[calc(100vh_-_10px)] overflow-auto',
          cropperClass,
        )}
      >
        <div className="p-2">
          <div
            style={{
              position: 'relative',
              width: '100%',
              background: '#333',
            }}
          >
            <ReactCrop
              crop={crop}
              keepSelection
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              className="w-full flex items-center justify-center"
              {...cropperProps}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                crossOrigin="anonymous"
                src={existingImage}
                style={{
                  objectFit: 'contain',
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                }}
                onLoad={onImageLoad}
                width="100%"
              />
            </ReactCrop>
            <canvas
              ref={previewCanvasRef}
              style={{
                display: 'none',
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop?.width,
                height: completedCrop?.height,
              }}
            />
          </div>
        </div>
        {showAspectbuttons && (
          <div className="flex gap-2 p-2">{/* Buttons for aspect ratio */}</div>
        )}
        <div className="p-4">
          <Slider
            defaultValue={[1]}
            min={1}
            max={3}
            step={0.1}
            className="w-full"
            onValueChange={([zoom]) => {
              setScale(zoom);
            }}
          />
        </div>
        <div className="flex gap-2 justify-end p-4">
          <Button onClick={() => onDownloadCropClick()} size="sm">
            {loading && <Loader2 className="animate-spin mr-2 h-5 w-5 " />}
            Ok
          </Button>
          <Button
            onClick={() => {
              setOpen(false); // Close popover when Cancel is clicked
            }}
            size="sm"
          >
            Cancel
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default HeadshotEditorDialog;
