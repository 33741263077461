/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_VALID_ACCESS_TOKEN = gql`
  query GetValidAccessToken {
    getValidAccessToken {
      status
    }
  }
`;

export const GET_WORKSPACE_CONNECTION_STATUS = gql`
  query GetWorkspaceConnectionStatus($where: ConnectGoogleMarketplaceInput!) {
    getWorkspaceConnectionStatus(where: $where) {
      isConnected
    }
  }
`;
