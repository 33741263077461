/* eslint-disable no-undef */
import React, { createContext, useContext, useReducer } from 'react';

// Initial state
const initialState = {
  brand: {
    config: {
      colors: {
        primary:
          localStorage.getItem('primary') ||
          getComputedStyle(document.documentElement).getPropertyValue(
            '--primary',
          ) ||
          '#7f56d9',
        'primary-foreground':
          localStorage.getItem('primary-foreground') ||
          getComputedStyle(document.documentElement).getPropertyValue(
            '--primary-foreground',
          ) ||
          'rgb(243, 237, 255)',
      },
    },
  },
  brandwiseWorkspaceTeammate: [], // Initialize as an empty array
};

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_BRAND':
      return { ...state, brand: { ...state.brand, ...action.data } };
    case 'CLEAR_BRAND':
      return { ...state, brand: null };
    case 'SET_BRANDWISE_WORKSPACE_TEAMMATE': // Add this case
      return { ...state, brandwiseWorkspaceTeammate: action.data };
    default:
      return state;
  }
};

// Create context
const BrandContext = createContext({
  state: initialState,
  dispatch: () => {},
});

// Provider component
const BrandContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setBrand = (data) => {
    localStorage.setItem('primary', data?.config?.colors?.primary);
    localStorage.setItem(
      'primary-foreground',
      data?.config?.colors?.['primary-foreground'],
    );
    document.documentElement.style.setProperty(
      '--primary',
      data.config.colors.primary,
    );
    document.documentElement.style.setProperty(
      '--primary-foreground',
      data.config.colors['primary-foreground'],
    );
    dispatch({ type: 'SET_BRAND', data });
  };

  const clearBrand = () => {
    dispatch({ type: 'CLEAR_BRAND' });
  };

  const setBrandwiseWorkspaceTeammate = (data) => {
    // Add this function
    dispatch({ type: 'SET_BRANDWISE_WORKSPACE_TEAMMATE', data });
  };

  const value = {
    state,
    setBrand,
    clearBrand,
    setBrandwiseWorkspaceTeammate, // Add this line
  };

  return (
    <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
  );
};

// Hook to use the context
const useBrandContext = () => {
  const context = useContext(BrandContext);
  if (!context) {
    throw new Error(
      'useBrandContext must be used within a BrandContextProvider',
    );
  }
  return context;
};

export { BrandContext, useBrandContext, BrandContextProvider };
