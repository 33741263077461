import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PROCCESS_HEADSHOT_IMAGE = gql`
  mutation ProcessHeadshotImage($where: ProcessHeadshotImageInput!) {
    processHeadshotImage(where: $where) {
      key
      message
    }
  }
`;

export const CREATE_HEADSHOT = gql`
  mutation CreateHeadshot($data: HeadshotInput!) {
    createHeadshot(data: $data) {
      data {
        id
        image
        config
        styleId
        style {
          id
          name
          image
          key
          description
          isActive
        }
      }
    }
  }
`;

export const UPDATE_HEADSHOT = gql`
  mutation UpdateHeadshot(
    $where: HeadshotUniqueWhereInput!
    $data: HeadshotInput!
  ) {
    updateHeadshot(where: $where, data: $data) {
      data {
        id
        image
        config
        styleId
        style {
          id
          name
          image
          key
          description
          isActive
        }
      }
    }
  }
`;

export const REMOVE_HEADSHOT = gql`
  mutation RemoveHeadshot(
    $where: HeadshotUniqueWhereInput!
  ) {
    removeHeadshot(where: $where) {
      message
    }
  }
`;
