import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useGetRoutes } from 'helpers/useGetRoutes';
import { userRoutes } from 'routes';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import LoaderComponent from 'components/LoaderComponent';
import { useFetchWorkspaces, useWorkspaceId } from 'hooks/workspace';
import SubscriptionModal from 'components/SubscriptionModal';
import { useFreeTrial, useGetWorkspaceOwner } from 'hooks/user';
import { useRouter } from 'hooks/router';
import Header from './Header';
import UserSideBar from './UserSideBar';

const UserLayout = () => {
  const [loading, setLoading] = useState(false);
  const { getRoutes } = useGetRoutes();
  const {
    location: { pathname },
  } = useRouter();

  const { workspaceId } = useWorkspaceId();
  const { isPlanExpired, redirectToPlan } = useFreeTrial();

  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();
  const [fetchWorkspaces] = useFetchWorkspaces();
  const [getWorkspaceOwner] = useGetWorkspaceOwner(); // Use the custom hook here

  const fetchWorkspaceTeammates = async () => {
    await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
  };

  const fetchWorkspaceTeammatesByWorkspaceId = async () => {
    setLoading(true);
    await fetchWorkspaces();
    await fetchWorkspaceTeammates();
    await getWorkspaceOwner({
      variables: {
        where: {
          id: workspaceId,
        },
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (isPlanExpired) redirectToPlan();
  }, [isPlanExpired, pathname]);

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspaceTeammatesByWorkspaceId();
    }
  }, [workspaceId]);

  const wrapperClass = 'grid grid-cols-[227px_1fr]';

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <SubscriptionModal />
      <div>
        <Header />
      </div>
      <div className={wrapperClass}>
        <UserSideBar />
        <div
          className="bg-primary-foreground"
          style={{
            height: 'calc(100vh - 71px)',
            overflow: 'auto',
          }}
        >
          <Routes>
            {getRoutes(userRoutes)}
            <Route
              path="*"
              element={<Navigate to={`/app/${workspaceId}/user/profile`} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
