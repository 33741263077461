import React from 'react';
import { Helmet } from 'react-helmet';
import { useGetBrandDetails } from 'hooks/brand';

const MetaTags = ({ defaultTitle }) => {
  const brand = useGetBrandDetails();
  const { name, url, favicon } = brand;
  return (
    <Helmet>
      <title>{name || defaultTitle}</title>
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={favicon ?? `${url}/favicon-null.ico`}
      />

      <meta property="og:url" content="https://app.dev.syncsignature.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Sync Signature" />
      <meta property="og:description" content="Sync Signature" />
      <meta
        property="og:image"
        content="https://app.dev.syncsignature.com/ogai.png"
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="app.dev.syncsignature.com" />
      <meta
        property="twitter:url"
        content="https://app.dev.syncsignature.com"
      />
      <meta name="twitter:title" content="Sync Signature" />
      <meta name="twitter:description" content="Sync Signature" />
      <meta
        name="twitter:image"
        content="https://app.dev.syncsignature.com/ogai.png"
      />
    </Helmet>
  );
};

export default MetaTags;
