import { Button } from 'components/ui/button';
import { useRouter } from 'hooks/router';
import React, { useState, useEffect } from 'react';

const SuccessPage = () => {
  const [counter, setCounter] = useState(10); // 5-second timer
  const { navigate } = useRouter();

  useEffect(() => {
    const countdown = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    // If the counter reaches 0, navigate to the desired route (e.g., dashboard or homepage)
    if (counter === 0) {
      navigate('/app');
    }

    // Clean up the interval when the component unmounts or counter changes
    return () => clearInterval(countdown);
  }, [counter, navigate]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-green-100">
      <div className="bg-white shadow-lg p-8 rounded-lg text-center">
        <h1 className="text-2xl font-bold text-green-600 mb-4">
          Payment Successful!
        </h1>
        <p className="text-lg">Thank you for your purchase.</p>
        <p className="text-sm text-gray-500 mt-2">
          Redirecting in {counter} seconds...
        </p>
        <div className="mt-4">
          <Button
            onClick={() => navigate('/app')}
            className="px-4 py-2 bg-green-500 text-white rounded-lg"
          >
            Go to Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
