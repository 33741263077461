import React from 'react';
import { get } from 'lodash';
import { Loader2 } from 'lucide-react';

function HeadshotImage({
  style = {},
  tdProps = {},
  data,
  align = 'center',
  design = {},
}) {
  const width = get(design, 'styles.headshot.style.width', 98);
  const borderRadius = get(design, 'styles.headshot.style.borderRadius', 0);
  const datavalue = typeof data?.value;

  return (
    <td
      align={align}
      style={{
        ...style,
        paddingBottom: '8px',
      }}
      {...tdProps}
    >
      {datavalue === 'string' ? (
        <img
          style={{
            border: '0px',
            lineHeight: '100%',
            outline: 'none',
            textDecoration: 'none',
            msInterpolationMode: 'bicubic',
            display: 'block',
            borderRadius: `${borderRadius}px`, // Apply the border radius here
          }}
          src={data?.value}
          alt="headshot"
          width={width}
          className="photo photo-size"
          title="Headshot Picture"
        />
      ) : (
        <div
          style={{
            width: `${width}px`,
            height: `${width}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader2 className="animate-spin mr-2 h-5 w-5 " />
        </div>
      )}
    </td>
  );
}

export default HeadshotImage;
